// import Link from 'next/link'
import {
  Activity,
  ArrowUpRight,
  CircleUser,
  CreditCard,
  DollarSign,
  Menu,
  Package2,
  Search,
  Users
} from 'lucide-react'

import { Avatar, AvatarFallback, AvatarImage } from '../ui/shadcn-avatar'
import { ShadCNButton as Button } from '../ui/shadcn-button'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from '../ui/shadcn-card'
// import {
//   DropdownMenu,
//   DropdownMenuContent,
//   DropdownMenuItem,
//   DropdownMenuLabel,
//   DropdownMenuSeparator,
//   DropdownMenuTrigger
// } from '../ui/shadcn-dropdown-menu'
// import { Input } from '../ui/input'
// import { Sheet, SheetContent, SheetTrigger } from '../ui/shadcn-sheet'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from '../ui/shadcn-table'

export function Dashboard ({
  currentRevenue,
  previousRevenue,
  currentSales,
  previousSales,
  currentCPA,
  previousCPA,
  currentCPL,
  previousCPL,
  topPerformers,
  recentSales
}) {
  var revenueChange = Math.round(
    (100 * (currentRevenue - previousRevenue)) / previousRevenue
  )

  var salesChange = Math.round(
    (100 * (currentSales - previousSales)) / previousSales
  )

  var CPAChange = Math.round((100 * (currentCPA - previousCPA)) / previousCPA)

  var CPLChange = Math.round((100 * (currentCPL - previousCPL)) / previousCPL)

  return (
    <div className='flex h-full w-full flex-col'>
      <main className='flex flex-1 flex-col gap-4 p-4 md:gap-8 md:p-8'>
        <div className=' grid gap-4 md:grid-cols-2 md:gap-8 lg:grid-cols-4'>
          <Card x-chunk='dashboard-01-chunk-0'>
            <CardHeader className='flex flex-row items-center justify-between space-y-0 pb-2'>
              <CardTitle className='text-sm font-medium'>Revenue</CardTitle>
              <DollarSign className='h-4 w-4 text-muted-foreground' />
            </CardHeader>
            <CardContent>
              <div className='text-2xl font-bold'>
                ₹{currentRevenue.toLocaleString()}
              </div>
              <p className='text-xs text-muted-foreground'>
                {revenueChange > 0
                  ? '+' + String(revenueChange) + '% from last period'
                  : String(revenueChange) + '% from last period'}
              </p>
            </CardContent>
          </Card>
          <Card x-chunk='dashboard-01-chunk-2'>
            <CardHeader className='flex flex-row items-center justify-between space-y-0 pb-2'>
              <CardTitle className='text-sm font-medium'>Sales</CardTitle>
              <CreditCard className='h-4 w-4 text-muted-foreground' />
            </CardHeader>
            <CardContent>
              <div className='text-2xl font-bold'>
                {currentSales.toLocaleString()}
              </div>
              <p className='text-xs text-muted-foreground'>
                {salesChange > 0
                  ? '+' + String(salesChange) + '% from last period'
                  : String(salesChange) + '% from last period'}
              </p>
            </CardContent>
          </Card>
          <Card x-chunk='dashboard-01-chunk-1'>
            <CardHeader className='flex flex-row items-center justify-between space-y-0 pb-2'>
              <CardTitle className='text-sm font-medium'>CPA</CardTitle>
              <Users className='h-4 w-4 text-muted-foreground' />
            </CardHeader>
            <CardContent>
              <div className='text-2xl font-bold'>
                ₹{currentCPA.toLocaleString()}
              </div>
              <p className='text-xs text-muted-foreground'>
                {CPAChange > 0
                  ? '+' + String(CPAChange) + '% from last period'
                  : String(CPAChange) + '% from last period'}
              </p>
            </CardContent>
          </Card>

          <Card x-chunk='dashboard-01-chunk-3'>
            <CardHeader className='flex flex-row items-center justify-between space-y-0 pb-2'>
              <CardTitle className='text-sm font-medium'>CPL</CardTitle>
              <Activity className='h-4 w-4 text-muted-foreground' />
            </CardHeader>
            <CardContent>
              <div className='text-2xl font-bold'>
                ₹{currentCPL.toLocaleString()}
              </div>
              <p className='text-xs text-muted-foreground'>
                {CPLChange > 0
                  ? '+' + String(CPLChange) + '% from last period'
                  : String(CPLChange) + '% from last period'}
              </p>
            </CardContent>
          </Card>
        </div>
        <div className='grid gap-4 md:gap-8 lg:grid-cols-2 xl:grid-cols-3'>
          <Card className='xl:col-span-2' x-chunk='dashboard-01-chunk-4'>
            <CardHeader className='flex flex-row items-center'>
              <div className='grid gap-2'>
                <CardTitle>Top Performers</CardTitle>
                <CardDescription>
                  Ads that have generated the highest revenue.
                </CardDescription>
              </div>
              <Button asChild size='sm' className='ml-auto gap-1'>
                {/* <Link href='#'>
                  View All
                  <ArrowUpRight className='h-4 w-4' />
                </Link> */}
              </Button>
            </CardHeader>
            <CardContent>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Ad Name</TableHead>
                    <TableHead>Clients</TableHead>
                    <TableHead>Revenue</TableHead>
                    <TableHead>CPA</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {topPerformers.map(row => {
                    return (
                      <TableRow>
                        <TableCell>
                          <div className='font-semibold font-medium'>
                            {row['ad_name']}
                          </div>
                          <div className='text-sm text-muted-foreground md:inline'>
                            {row['campaign_name']}
                          </div>
                        </TableCell>

                        <TableCell className=''>
                          {Math.round(row['clients']).toLocaleString()}
                        </TableCell>
                        <TableCell className=''>
                          ₹{Math.round(row['revenue']).toLocaleString()}
                        </TableCell>
                        <TableCell className=''>
                          ₹{Math.round(row['cpa']).toLocaleString()}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
          <Card x-chunk='dashboard-01-chunk-5'>
            <CardHeader>
              <CardTitle>Recent Sales</CardTitle>
            </CardHeader>
            <CardContent className='grid gap-8'>
              {recentSales.map(row => {
                return (
                  <div className='flex items-center'>
                    <div className='flex-grow'>
                      <p className='font-medium leading-none'>
                        <strong>{row['ad_name']}</strong>
                        <br />
                        <span className='text-sm text-muted-foreground'>
                          {row['campaign_name']}
                        </span>
                      </p>
                    </div>

                    <div className='text-right font-medium'>
                      <p className='font-medium leading-none'>
                        <strong>+₹{row['revenue'].toLocaleString()}</strong>
                        <br />
                        <span className='text-sm text-muted-foreground'>
                          {row['converted_on'].split('T')[0]}
                        </span>
                      </p>
                    </div>
                  </div>
                )
              })}
            </CardContent>
          </Card>
        </div>
      </main>
    </div>
  )
}
