import React from 'react'
import { useState, useEffect } from 'react'
import axios from 'axios'
import { TypewriterEffect } from '../components/ui/typewriter-effect'
import { BackgroundBeams } from '../components/ui/background-beams'
import { Boxes } from '../components/ui/background-boxes'
import { cn } from '../utils/cn'
import { MultiStepLoader } from '../components/ui/multi-step-loader'
import { Button, Navbar } from 'react-bootstrap'
import { ShadCNButton } from '../components/ui/shadcn-button'
import Dropdown from 'react-bootstrap/Dropdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faScroll } from '@fortawesome/free-solid-svg-icons'

function SlotsOpenScreen ({ checkAuthenticationInResponse, APIEndpointDomain }) {
  var loadingStates = [
    { text: 'connecting to server' },
    { text: 'fetching slots' }
  ]

  var [data, setData] = useState([])
  var [isFetching, setIsFetching] = useState(false)
  var [isError, setIsError] = useState(false)

  useEffect(() => {
    getSlotsOpen()
  }, [])

  function getSlotsOpen () {
    setIsFetching(true)
    axios
      .get(APIEndpointDomain + '/get-calendar-slots-open', {
        params: {},
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        console.log(response)
        setData(response.data['data'])
        setIsFetching(false)
        setIsError(false)
      })
      .catch(error => {
        checkAuthenticationInResponse(Number(error.response.status))
        console.log(error)
        setData([])
        setIsFetching(false)
        setIsError(true)
      })
  }
  return (
    <div className='h-[50rem] h-screen w-screen w-full relative flex items-center justify-center'>
      <div className='absolute h-screen w-screen pointer-events-null inset-0 flex items-center justify-center'></div>
      <div className='grid grid-rows-4 gap-0 bg-opacity-0 h-screen w-screen'>
        <div className='w-screen bg-opacity-0'>
          {!isFetching && !isError && data.length > 0 ? (
            <>
              <div className='flex items-center justify-center min-h-screen bg-gray-100'>
                <div className='w-full max-w-4xl px-4'>
                  <h1 className='text-2xl font-bold text-center mb-6 text-slate-900'>
                    Slots Open
                  </h1>
                  <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
                    {data.map(row => {
                      return (
                        <div
                          key={row['type']}
                          className='bg-slate-900 p-4 rounded-xl text-white font-bold border-slate-900 shadow-lg'
                        >
                          <div className='p-2'>
                            <h2 className='text-lg'>{row['type']}</h2>
                          </div>
                          {Object.keys(row['slot_info'])
                            .sort((a, b) => {
                              return new Date(a) - new Date(b)
                            })
                            .map(k => {
                              return (
                                <div
                                  className={
                                    row['slot_info'][k]['slots_available'] == 0
                                      ? 'p-2 bg-red-900'
                                      : 'p-2 bg-green-900'
                                  }
                                >
                                  <p>
                                    {k +
                                      ' => ' +
                                      row['slot_info'][k][
                                        'slots_filled'
                                      ].toLocaleString('en-IN') +
                                      ' filled '}
                                    <strong>
                                      {row['slot_info'][k]['slots_available'] ==
                                      0
                                        ? '(CLOSED)'
                                        : '(OPEN)'}
                                    </strong>
                                  </p>
                                </div>
                              )
                            })}
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </>
          ) : isFetching ? (
            <MultiStepLoader
              className='bg-opacity-0'
              loadingStates={loadingStates}
              loading={isFetching}
              duration={2000}
            ></MultiStepLoader>
          ) : isError ? (
            <div className='flex justify-center items-center text-center h-full'>
              <TypewriterEffect
                className='text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-400 to-neutral-500 py-8'
                words={'Server failed to return data!'.split(' ').map(word => {
                  return { text: word }
                })}
              ></TypewriterEffect>
            </div>
          ) : (
            <div className='flex justify-center items-center text-center h-full'>
              <TypewriterEffect
                className='text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-400 to-neutral-500 py-8'
                words={'Unrecognized error occured!'.split(' ').map(word => {
                  return { text: word }
                })}
              ></TypewriterEffect>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default SlotsOpenScreen
