import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { Button } from 'react-bootstrap'
import axios from 'axios'
import { useState } from 'react'
import APIRequestStatusModal from '../CommonSubComponents/APIRequestStatusMessageModal'

function UpdateBudgetModal ({
  show,
  showSetter,
  APIEndpointDomain,
  selectedLevel,
  selectedID,
  selectedAccount,
  budget,
  setCampaignBudgets,
  setAdsetBudgets,
  adsetBudgets,
  campaignBudgets
}) {
  var [inputtedMessage, setInputtedMessage] = useState()
  var [showAPIStatusModal, setShowAPIStatusModal] = useState(false)
  var [showAPIStatusMessage, setShowAPIStatusMessage] = useState(false)

  function updateBudget (
    APIEndpointDomain,
    selectedLevel,
    selectedID,
    showSetter
  ) {
    axios
      .get(APIEndpointDomain + '/performance/update-budget', {
        params: {
          new_budget: inputtedMessage,
          account: selectedAccount,
          level: selectedLevel,
          id: selectedID
        },
        headers: {
          'ngrok-skip-browser-warning': 'any',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        console.log(response)

        if (selectedLevel == 'Adset') {
          var newAdsetBudgets = adsetBudgets.find(
            row => row.folder_id == selectedID
          )

          newAdsetBudgets['budget'] = inputtedMessage

          setAdsetBudgets([
            ...adsetBudgets.filter(row => row.folder_id != selectedID),
            newAdsetBudgets
          ])
        } else if (selectedLevel == 'Campaign') {
          var newCampaignBudgets = campaignBudgets.find(
            row => row.folder_id == selectedID
          )

          newCampaignBudgets['budget'] = inputtedMessage

          setCampaignBudgets([
            ...campaignBudgets.filter(row => row.folder_id != selectedID),
            newCampaignBudgets
          ])
        }

        try {
          setShowAPIStatusMessage('Successfully updated your budget!')
        } finally {
          setShowAPIStatusModal(true)
        }

        showSetter(false)
      })
      .catch(error => {
        setShowAPIStatusMessage('Failed to update your budget!')

        showSetter(false)
        console.log(error)
        setShowAPIStatusModal(true)
      })
  }

  return (
    <div>
      <APIRequestStatusModal
        show={showAPIStatusModal}
        setShow={setShowAPIStatusModal}
        message={showAPIStatusMessage}
      />
      <Modal size='sm' show={show}>
        <Modal.Header
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            backgroundColor: '#525252',
            color: 'white'
          }}
          closeButton
          onClick={() => showSetter(false)}
        >
          <Modal.Title id='contained-modal-title-vcenter'>
            Update Budget
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Current budget: <strong>₹{budget.toLocaleString('en-IN')}</strong>
          </p>
          {inputtedMessage - budget > (1 / 3) * inputtedMessage ? (
            <div style={{ backgroundColor: '#941400', color: 'white' }}>
              <p>
                Please lower your new budget to avoid triggering learning phase!
              </p>
            </div>
          ) : null}
          {budget - inputtedMessage > (1 / 3) * budget ? (
            <div style={{ backgroundColor: '#941400', color: 'white' }}>
              <p>
                Please increase your new budget to avoid triggering learning
                phase!
              </p>
            </div>
          ) : null}
          <Form>
            <Form.Group
              className='mb-3'
              controlId='exampleForm.ControlTextarea1'
            >
              <Form.Label>Amount</Form.Label>
              <Form.Control
                onChange={e => setInputtedMessage(e.target.value)}
                type='number'
                defaultValue={budget}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Button
          onClick={() =>
            updateBudget(
              APIEndpointDomain,
              selectedLevel,
              selectedID,
              showSetter
            )
          }
          style={{ backgroundColor: '#186900' }}
          className='alarm-badge'
          disabled={
            inputtedMessage - budget > (1 / 3) * inputtedMessage ||
            budget - inputtedMessage > (1 / 3) * budget
          }
        >
          {'Update Budget'}
        </Button>
      </Modal>
    </div>
  )
}

export default UpdateBudgetModal
