import React from 'react'
import { useEffect } from 'react'
import EmailUploader from '../components/EmailAnalyzerComponents/EmailUpload'
import { useState } from 'react'
import { Nav, Navbar } from 'react-bootstrap'
import { cn } from '../utils/cn'
import Dropdown from 'react-bootstrap/Dropdown'
import Button from 'react-bootstrap/Button'
import { useMemo, useRef } from 'react'
import { TypewriterEffect } from '../components/ui/typewriter-effect'
import CreateReportModal from '../components/EmailAnalyzerComponents/CreateReportModal'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBarsProgress,
  faPenToSquare,
  faDownload,
  faEdit,
  faTable
} from '@fortawesome/free-solid-svg-icons'
import { AgGridReact } from 'ag-grid-react'
import SaveEmailReportModal from '../components/EmailAnalyzerComponents/SaveReportModal'
import APIRequestStatusModal from '../components/CommonSubComponents/APIRequestStatusMessageModal'
import { CSVLink } from 'react-csv'
import SaveNewClusterViewModal from '../components/PerformanceClustersComponents/SaveNewClusterViewModal'
import EditClusterViewModal from '../components/PerformanceClustersComponents/EditClusterViewModal'
import { Form } from 'react-bootstrap'
import { Col } from 'react-bootstrap'
import CustomizeColumnsModal from '../components/PerformanceHeaderSubComponents/CustomizeColumnsModal'
import AtomicSpinner from 'atomic-spinner'

function PerformanceClustersScreen ({
  checkAuthenticationInResponse,
  APIEndpointDomain
}) {
  var today = new Date()

  var end = new Date(new Date().setDate(today.getDate() - 1))
    .toJSON()
    .slice(0, 10)
  var start = new Date(new Date().setDate(today.getDate() - 30))
    .toJSON()
    .slice(0, 10)

  var [columnModalShow, setColumnModalShow] = useState(false)

  var [selectedColumnFilters, setSelectedColumnFilters] = useState([
    'spend',
    'revenue_during',
    'roi_during',
    'leads',
    'qualified',
    'quality',
    'h_good',
    'lead_to_h_good_percentage',
    'acquired_during',
    'cpl',
    'cpql',
    'cpgl_historic',
    'cpa_during',
    'pending_p'
  ])
  var [selectedLeadType, setSelectedLeadType] = useState('Premium')
  var [startDate, setStartDate] = useState(start)
  var [endDate, setEndDate] = useState(end)
  var [selectedView, setSelectedView] = useState('')
  var [availableViews, setAvailableViews] = useState([])
  var [data, setData] = useState([])
  var [showCreateClusterViewModal, setShowCreateClusterViewModal] =
    useState(false)
  var [editingView, setEditingView] = useState('')
  var [showEditClusterViewModal, setShowEditClusterViewModal] = useState(false)
  var [availableClusters, setAvailableClusters] = useState([])
  var [viewInformation, setViewInformation] = useState([])
  var [selectedClusters, setSelectedClusters] = useState([])
  var [isLoading, setIsLoading] = useState(false)
  var [isError, setIsError] = useState(false)
  var [dataResponse, setDataResponse] = useState([])

  function getExistingView (selectedView, start_date, end_date, lead_type) {
    setIsLoading(true)
    setIsError(false)

    var clusters = viewInformation.find(
      viewed => viewed['name'] == selectedView
    )['clusters']

    console.log('sending clusters', clusters, 'for view', selectedView)

    axios
      .post(
        APIEndpointDomain + '/performance/clusters/generate-view',
        {
          start_date: start_date,
          end_date: end_date,
          lead_type: lead_type.toLowerCase().replace('-', '_'),
          selected_tag_names: clusters
        },
        {
          headers: {
            'ngrok-skip-browser-warning': 'any',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`
          }
        }
      )
      .then(response => {
        setIsLoading(false)

        console.log(response)
        const responseData = response.data['data']
        setDataResponse(responseData)
        setData(responseData)
      })
      .catch(error => {
        setIsLoading(false)
        setIsError(true)
        console.log(error)
        checkAuthenticationInResponse(Number(error.response.status))
        setData([])
      })
  }

  useEffect(() => {
    getClusters()
    getViews()
  }, [])

  function getClusters () {
    axios
      .get(APIEndpointDomain + '/performance/get-clusters', {
        params: {},
        headers: {
          'ngrok-skip-browser-warning': 'any',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        setAvailableClusters(response.data['data'])
      })
      .catch(error => {
        console.log(error)
        checkAuthenticationInResponse(Number(error.response.status))
      })
  }

  function getViews () {
    axios
      .get(APIEndpointDomain + '/performance/clusters/get-all-views', {
        params: {},
        headers: {
          'ngrok-skip-browser-warning': 'any',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        console.log(response)
        setViewInformation(response.data['data'])
        setAvailableViews(response.data['data'].map(view => view['name']))
      })
      .catch(error => {
        console.log(error)
        checkAuthenticationInResponse(Number(error.response.status))
      })
  }

  function handleStartDate (e) {
    setStartDate(e.target.value)
  }

  function handleEndDate (e) {
    setEndDate(e.target.value)
  }

  //   MORE

  var percentageMetrics = [
    'acquisition_loss_rate',
    'acquisition_validation_rate',
    'acquisitions_pending_validation_p',
    'good_percentage',
    'pending_p',
    'pressure_to_acquisition',
    'projected_roi',
    'quality',
    'roi',
    'uhl_to_acquisition',
    'roi_during',
    'h_good_percentage',
    'lead_to_h_good_percentage',
    'ctr',
    'hook_rate',
    'hold_rate',
    'view_to_click',
    'click_to_lead',
    'fifteen_to_25p',
    'twenty_five_p_to_50p',
    'fifty_p_to_75p'
  ]

  var currencyMetrics = [
    'cpaa',
    'premium_ms_revenue',
    'avg_purchase',
    'budget',
    'cpa',
    'cpgl',
    'cpl',
    'cpql',
    'projected_cpa',
    'revenue',
    'total_revenue',
    'upfront_payments',
    'cpaa_during',
    'premium_ms_revenue_during',
    'admit_up_rev_dur',
    'cpa_during',
    'revenue_during',
    'total_revenue_during',
    'upfront_payments_during',
    'cpgl_historic',
    'h_cpa',
    'spend',
    'cpm',
    'cost_per_thruplay',
    'cpc',
    '5_day_cpl',
    '5_day_cpql',
    '7_day_cpgl_h',
    '12_day_cpuhl_h',
    '22_day_cpa',
    'adset_budget'
  ]

  function isNumber (n) {
    return Number(n) === n
  }

  useEffect(() => {
    var calculatedData = calculateNewColumns(data)
    setData(calculatedData)
  }, [dataResponse])

  function calculateNewColumns (Data) {
    Data.map(data => {
      var cpm =
        data['impressions'] !== 0
          ? (1000 * data['spend']) / data['impressions']
          : 0

      var cpv = data['thruplays'] !== 0 ? data['spend'] / data['thruplays'] : 0

      var cpc =
        data['link_clicks'] !== 0 ? data['spend'] / data['link_clicks'] : 0

      var hook_rate =
        data['impressions'] !== 0
          ? (100 * data['three_sec_plays']) / data['impressions']
          : 0

      var hold_rate_thruplay =
        data['three_sec_plays'] !== 0
          ? (100 * data['thruplays']) / data['three_sec_plays']
          : 0

      var hold_rate_50_p =
        data['three_sec_plays'] !== 0
          ? (100 * data['fifty_p_plays']) / data['three_sec_plays']
          : 0

      var hold_rate_75_p =
        data['fifty_p_plays'] !== 0
          ? (100 * data['seventy_five_p_plays']) / data['fifty_p_plays']
          : 0

      var view_to_click =
        data['thruplays'] !== 0
          ? (100 * data['link_clicks']) / data['thruplays']
          : 0

      var quality =
        data['leads'] !== 0
          ? (100 * data['qualified']) / (data['leads'] - data['pending'])
          : 0

      var cpl = data['leads'] !== 0 ? data['spend'] / data['leads'] : 0

      var cpql = data['qualified'] !== 0 ? data['spend'] / data['qualified'] : 0

      var cpgl =
        data['good_leads'] !== 0 ? data['spend'] / data['good_leads'] : 0

      var cpa =
        data['acquisitions'] !== 0 ? data['spend'] / data['acquisitions'] : 0

      var h_cpa =
        data['h_acquisitions'] !== 0
          ? data['spend'] / data['h_acquisitions']
          : 0
      var cpaa =
        data['actual_acquisitions'] !== 0
          ? data['spend'] / data['actual_acquisitions']
          : 0

      var acquisitions_lost = data['h_acquisitions'] - data['acquisitions']
      var acquisition_loss_rate =
        data['h_acquisitions'] > 0
          ? (100 * (data['h_acquisitions'] - data['acquisitions'])) /
            data['h_acquisitions']
          : 0

      var acquisition_validation_rate =
        data['actual_acquisitions'] +
          (data['h_acquisitions'] - data['acquisitions']) >
        0
          ? (100 * data['actual_acquisitions']) /
            (data['actual_acquisitions'] +
              (data['h_acquisitions'] - data['acquisitions']))
          : 0

      var acquisitions_pending_validation =
        data['h_acquisitions'] -
        (data['actual_acquisitions'] +
          (data['h_acquisitions'] - data['acquisitions']))

      var acquisitions_pending_validation_p =
        data['h_acquisitions'] > 0
          ? (100 *
              (data['h_acquisitions'] -
                (data['actual_acquisitions'] +
                  (data['h_acquisitions'] - data['acquisitions'])))) /
            data['h_acquisitions']
          : 0

      var cpaa_during =
        data['actually_acquired_during'] !== 0
          ? data['spend'] / data['actually_acquired_during']
          : 0

      var ttl_revenue = data['revenue'] + data['admit_up_rev']

      var roi =
        data['spend'] !== 0
          ? (data['revenue'] + data['admit_up_rev']) / data['spend']
          : 0

      var roi_during =
        data['spend'] !== 0 ? data['revenue_during'] / data['spend'] : 0

      var pending_p =
        data['leads'] !== 0 ? (100 * data['pending']) / data['leads'] : 0

      var cpgl_historic =
        data['h_good'] !== 0 ? data['spend'] / data['h_good'] : 0

      var cpa_during =
        data['acquired_during'] !== 0
          ? data['spend'] / data['acquired_during']
          : 0

      var ttl_revenue_du = data['admit_up_rev_dur'] + data['revenue_during']

      var good_percentage =
        data['qualified'] !== 0
          ? (100 * data['good_leads']) / data['qualified']
          : 0

      var h_good_percentage =
        data['qualified'] !== 0 ? (100 * data['h_good']) / data['qualified'] : 0

      var lead_to_h_good_percentage =
        data['leads'] !== 0 ? (100 * data['h_good']) / data['leads'] : 0

      var uhl_to_acquisition =
        data['h_ultra'] !== 0
          ? (100 * data['acquisitions']) / (data['h_ultra'] - data['ultra_hot'])
          : 0

      var pressure_to_acquisition =
        data['h_pressure'] !== 0
          ? (100 * data['acquisitions']) /
            (data['h_pressure'] - data['pressure_sell'])
          : 0

      var projected_acquisitions =
        data['acquisitions'] +
        (data['ultra_hot'] / 100) * uhl_to_acquisition +
        (data['pressure_sell'] / 100) * pressure_to_acquisition

      var projected_cpa =
        data['acquisitions'] +
          (data['ultra_hot'] / 100) * uhl_to_acquisition +
          (data['pressure_sell'] / 100) * pressure_to_acquisition !==
        0
          ? data['spend'] /
            (data['acquisitions'] +
              (data['ultra_hot'] / 100) * uhl_to_acquisition +
              (data['pressure_sell'] / 100) * pressure_to_acquisition)
          : 0

      var avg_purchase =
        data['acquisitions'] !== 0 ? data['revenue'] / data['acquisitions'] : 0

      var projected_roi =
        data['spend'] !== 0
          ? (100 *
              (data['revenue'] +
                data['admit_up_rev'] +
                (avg_purchase * data['ultra_hot'] * uhl_to_acquisition) / 100 +
                (avg_purchase *
                  data['pressure_sell'] *
                  pressure_to_acquisition) /
                  100)) /
            data['spend']
          : 0

      var avg_conversion_period =
        data['acquisitions'] !== 0
          ? data['conversion_period'] / data['acquisitions']
          : 0

      var avg_validation_period =
        data['actual_acquisitions'] !== 0
          ? data['validation_period'] / data['actual_acquisitions']
          : 0

      var avg_complete_cycle_period =
        data['actual_acquisitions'] !== 0
          ? data['creation_to_complete_validation_period'] /
            data['actual_acquisitions']
          : 0

      data['acquisitions_pending_validation'] = acquisitions_pending_validation
      data['acquisitions_pending_validation_p'] =
        acquisitions_pending_validation_p
      data['cpm'] = isNumber(cpm) ? Math.round(cpm) : cpm
      data['cpv'] = isNumber(cpv) ? Math.round(cpv) : cpv
      data['cpc'] = isNumber(cpc) ? Math.round(cpc) : cpc
      data['hook_rate'] = isNumber(hook_rate)
        ? Math.round(hook_rate)
        : hook_rate
      data['hold_rate_thruplay'] = isNumber(hold_rate_thruplay)
        ? Math.round(hold_rate_thruplay)
        : hold_rate_thruplay
      data['hold_rate_50_p'] = isNumber(hold_rate_50_p)
        ? Math.round(hold_rate_50_p)
        : hold_rate_50_p
      data['hold_rate_75_p'] = isNumber(hold_rate_75_p)
        ? Math.round(hold_rate_75_p)
        : hold_rate_75_p
      data['view_to_click'] = isNumber(view_to_click)
        ? Math.round(view_to_click)
        : view_to_click

      data['acquisitions_lost'] = acquisitions_lost
      data['acquisition_loss_rate'] = acquisition_loss_rate
      data['avg_complete_cycle_period'] = avg_complete_cycle_period

      data['quality'] = isNumber(quality) ? Math.round(quality) : 0
      data['cpl'] = isNumber(cpl) ? Math.round(cpl) : cpl
      data['cpql'] = isNumber(cpql) ? Math.round(cpql) : cpql
      data['cpgl'] = isNumber(cpgl) ? Math.round(cpgl) : cpgl
      data['cpa'] = isNumber(cpa) ? Math.round(cpa) : cpa
      data['h_cpa'] = isNumber(h_cpa) ? Math.round(h_cpa) : h_cpa
      data['cpaa'] = isNumber(cpaa) ? Math.round(cpaa) : cpaa
      data['cpaa_during'] = isNumber(cpaa_during)
        ? Math.round(cpaa_during)
        : cpaa_during

      data['lead_to_h_good_percentage'] = isNumber(lead_to_h_good_percentage)
        ? Math.round(lead_to_h_good_percentage)
        : lead_to_h_good_percentage

      data['total_revenue'] = ttl_revenue
      data['roi'] = isNumber(roi) ? Math.round(100 * roi) : roi
      data['roi_during'] = isNumber(roi_during)
        ? Math.round(100 * roi_during)
        : roi_during
      data['pending_p'] = isNumber(pending_p)
        ? Math.round(pending_p)
        : pending_p
      data['cpgl_historic'] = isNumber(cpgl_historic)
        ? Math.round(cpgl_historic)
        : cpgl_historic

      data['h_cpgl'] = isNumber(cpgl_historic)
        ? Math.round(cpgl_historic)
        : cpgl_historic
      data['cpa_during'] = isNumber(cpa_during)
        ? Math.round(cpa_during)
        : cpa_during
      data['total_revenue_during'] = ttl_revenue_du
      data['good_percentage'] = good_percentage
      data['h_good_percentage'] = h_good_percentage
      data['pressure_to_acquisition'] = pressure_to_acquisition
      data['uhl_to_acquisition'] = uhl_to_acquisition

      data['avg_validation_period'] = avg_validation_period

      data['projected_acquisitions'] = projected_acquisitions
      data['projected_cpa'] = projected_cpa
      data['projected_roi'] = projected_roi
      data['avg_purchase'] = avg_purchase
      data['avg_conversion_period'] = avg_conversion_period
      data['acquisition_validation_rate'] = acquisition_validation_rate
      data['upfront_payments'] =
        isNumber(data['revenue']) && selectedLeadType.toLowerCase() === 'cpa'
          ? data['revenue'] - 20000 * data['acquisitions']
          : isNumber(data['revenue']) &&
            selectedLeadType.toLowerCase() === 'premium'
          ? data['revenue']
          : 0

      data['upfront_payments_during'] =
        isNumber(data['revenue_during']) &&
        selectedLeadType.toLowerCase() === 'cpa'
          ? data['revenue_during'] - 20000 * data['acquired_during']
          : isNumber(data['revenue_during']) &&
            selectedLeadType.toLowerCase() === 'premium'
          ? data['revenue_during']
          : 0
    })

    return Data
  }

  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), [])

  // set background colour on every row, this is probably bad, should be using CSS classes
  const rowStyle = { background: '#eaeaea' }

  // set background colour on even rows again, this looks bad, should be using CSS classes
  const getRowStyle = params => {
    if (params.node.rowIndex % 2 === 0) {
      return { background: '#f6f6f6' }
    }
  }

  function cellRenderer (params) {
    var key = params.colDef['field']
    var data = params.data[key]

    if (key === 'tag_name') {
      return (
        <p style={{ textTransform: 'capitalize' }}>
          {String(data).replace(/_/g, ' ')}
        </p>
      )
    } else if (currencyMetrics.includes(key)) {
      return <p>₹{Math.round(data).toLocaleString('en-IN') + '  '}</p>
    } else if (percentageMetrics.includes(key) && key !== 'ctr') {
      return (
        <p>
          {Math.round(data).toLocaleString('en-IN')}%{'  '}
        </p>
      )
    } else if (percentageMetrics.includes(key) && key == 'ctr') {
      return (
        <p>
          {data.toLocaleString('en-IN')}%{'  '}
        </p>
      )
    } else {
      return <p>{data}</p>
    }
  }

  var headers = selectedColumnFilters.filter(
    column =>
      column.toLowerCase() !== 'ad_id' &&
      column.toLowerCase() !== 'ad_name' &&
      column.toLowerCase() !== 'adset_id' &&
      column.toLowerCase() !== 'adset_name' &&
      column.toLowerCase() !== 'campaign_id' &&
      column.toLowerCase() !== 'campaign_name' &&
      column.toLowerCase() !== 'budget'
  )

  headers.unshift('tag_name')

  var stringColumns = ['tag_name']

  headers = headers.map(header_name => {
    return {
      field: header_name,
      filter: !stringColumns.includes(header_name)
        ? 'agNumberColumnFilter'
        : 'agTextColumnFilter',
      floatingFilter: true,
      cellRenderer: cellRenderer,
      pinned: header_name == 'tag_name' ? 'left' : null
    }
  })

  const defaultColDef = useMemo(() => {
    return {
      width: 170,
      filter: true,
      cellStyle: { fontSize: '14px', textAlign: 'center' },
      enableValue: true,
      // allow every column to be grouped
      enableRowGroup: true,
      // allow every column to be pivoted
      enablePivot: true
    }
  }, [])

  var columnCategory = {
    'Ad Metrics': [
      'spend',
      'impressions',
      'reach',
      'three_sec_plays',
      'thruplays',
      'twenty_five_p_plays',
      'fifty_p_plays',
      'seventy_five_p_plays',
      'link_clicks',
      'cpm',
      'cost_per_thruplay',
      'cpc',
      'ctr',
      'hook_rate',
      'hold_rate',
      'view_to_click',
      'click_to_lead',
      'campaign_id',
      'adset_id',
      'ad_id',
      'fifteen_to_25p',
      'twenty_five_p_to_50p',
      'fifty_p_to_75p',
      'held_per_1k_impressions'
    ],

    'Current Stages': [
      'nurturing',
      'dnp',
      'no_show',
      'backburners',
      'lead_lost'
    ],

    'Standard Appointment Metrics': [
      'admit_up',
      'admit_up_rev',
      'actual_acquisitions',
      'acquisitions_lost',
      'acquisition_loss_rate',
      'acquisition_validation_rate',
      'acquisitions_pending_validation',
      'acquisitions_pending_validation_p',
      'avg_validation_period',
      'avg_complete_cycle_period',
      'cpaa',
      'premium_ms_revenue',
      'acquisitions',
      'avg_purchase',
      'avg_conversion_period',
      'adset_name',
      'budget',
      'campaign_name',
      'cpa',
      'cpgl',
      'cpl',
      // "cpm",
      'cpql',
      // "cpv",
      'days_active',
      'deleted',
      'dnp_recovered',
      'good_leads',
      'good_percentage',
      'leads',
      // "link_clicks",
      'pending',
      'pending_p',
      'pressure_sell',
      'pressure_to_acquisition',
      'projected_acquisitions',
      'projected_cpa',
      'projected_roi',
      'qualified',
      'quality',
      // "reach",
      'revenue',
      'roi',
      // "status",
      // "thruplays",
      // "three_sec_plays",
      'total_revenue',
      'ultra_hot',
      'uhl_to_acquisition',
      'upfront_payments'
      // "view_to_click",
    ],
    'During Metrics': [
      'cpaa_during',
      'premium_ms_revenue_during',
      'admit_up_during',
      'admit_up_rev_dur',
      'actually_acquired_during',
      'acquired_during',
      'cb_during',
      'cpa_during',
      'deleted_during',
      'dnp_during',
      'revenue_during',
      'roi_during',
      'real_revenue_during',
      'real_acquisitions_during',
      'real_roi_during',
      'cpra_during',
      // "seventy_five_p_plays",

      'total_revenue_during',

      'upfront_payments_during'
    ],
    'Historic Metrics': [
      'cpgl_historic',

      // "fifty_p_plays",

      // "hold_rate_50_p",
      // "hold_rate_75_p",
      // "hold_rate_thruplay",
      // "hook_rate",
      'h_good',
      'h_good_percentage',
      'h_pressure',
      'h_ultra',
      'h_acquisitions',
      'lead_to_h_good_percentage',

      'h_cpa'
      // "impressions",
    ]
    // "Calendar Metrics": [
    //   "calls_booked_during",
    //   "valid_calls_cb_p",
    //   "assigned_calls_cb_p",
    //   "calls_left_cb_p",
    //   "dnp_cb_p",
    //   "deleted_cb_p",
    //   "good_leads_cb_p",
    //   "valid_calls_cb",
    //   "assigned_calls_cb",
    //   "calls_left_cb",
    //   "good_leads_cb",
    //   "deleted_cb",
    //   "dnp_cb",
    // ],
  }
  function columnFilterColor (filter) {
    var color = selectedColumnFilters.includes(filter) ? 'success' : 'black'
    return color
  }
  // Drag logic

  var dragColumn = useRef()
  var dragOverColumn = useRef()

  function dragColumnStart (__, position) {
    dragColumn.current = position
  }

  function dragColumnEnter (__, position) {
    dragOverColumn.current = position
  }

  function dropColumn (__) {
    var updatedSelectedColumnFilters = [...selectedColumnFilters]
    var dragItemContent = updatedSelectedColumnFilters[dragColumn.current]
    updatedSelectedColumnFilters.splice(dragColumn.current, 1)
    updatedSelectedColumnFilters.splice(
      dragOverColumn.current,
      0,
      dragItemContent
    )
    dragColumn.current = null
    dragOverColumn.current = null
    setSelectedColumnFilters(updatedSelectedColumnFilters)
  }

  function columnSelector (selectedColumn) {
    var selectedColumns = selectedColumnFilters.includes(selectedColumn)
      ? selectedColumnFilters.filter(column => column !== selectedColumn)
      : [...selectedColumnFilters, selectedColumn]

    setSelectedColumnFilters(selectedColumns)
  }

  function showColumnModal () {
    var showCModal =
      columnModalShow === false
        ? setColumnModalShow(true)
        : setColumnModalShow(false)
    return showCModal
  }

  var preColumns = [
    'fifteen_to_25p',
    'twenty_five_p_to_50p',
    'fifty_p_to_75p',
    'held_per_1k_impressions',
    'impressions',
    'reach',
    'three_sec_plays',
    'thruplays',
    'twenty_five_p_plays',
    'fifty_p_plays',
    'seventy_five_p_plays',
    'link_clicks',
    'cpm',
    'cost_per_thruplay',
    'cpc',
    'ctr',
    'hook_rate',
    'hold_rate',
    'view_to_click',
    'click_to_lead',
    'acquisitions',
    'acquired_during',
    'avg_purchase',
    'avg_conversion_period',
    'adset_name',
    'budget',
    'campaign_name',
    'cb_during',
    'cpa',
    'cpa_during',
    'cpgl',
    'cpgl_historic',
    'cpl',
    // "cpm",
    'cpql',
    // "cpv",
    'days_active',
    'deleted',
    'deleted_during',
    'dnp_during',
    'dnp_recovered',
    // "fifty_p_plays",
    'good_leads',
    'good_percentage',
    'lead_to_h_good_percentage',
    // "hold_rate_50_p",
    // "hold_rate_75_p",
    // "hold_rate_thruplay",
    // "hook_rate",
    'h_good',
    'h_good_percentage',
    'h_pressure',
    'h_ultra',
    // "impressions",
    'leads',
    // "link_clicks",
    'pending',
    'pending_p',
    'pressure_sell',
    'pressure_to_acquisition',
    'projected_acquisitions',
    'projected_cpa',
    'projected_roi',
    'qualified',
    'quality',
    // "reach",
    'revenue',
    'revenue_during',
    'roi',
    'roi_during',
    // "seventy_five_p_plays",
    'spend',
    // "status",
    // "thruplays",
    // "three_sec_plays",
    'total_revenue',
    'total_revenue_during',
    'ultra_hot',
    'uhl_to_acquisition',
    'upfront_payments',
    'upfront_payments_during',
    // "view_to_click",
    'campaign_id',
    'adset_id',
    'ad_id',
    'nurturing',
    'dnp',
    'no_show',
    'backburners',
    'lead_lost'
  ]

  var allColumns =
    selectedLeadType.toLowerCase() !== 'cpa'
      ? preColumns
      : [
          ...preColumns,
          'admit_up',
          'admit_up_during',
          'admit_up_rev',
          'admit_up_rev_dur',

          'actually_acquired_during',
          'h_acquisitions',
          'actual_acquisitions',
          'premium_ms_revenue',
          'premium_ms_revenue_during',
          'h_cpa',
          'cpaa',
          'cpaa_during',
          'acquisitions_lost',
          'acquisition_loss_rate',
          'acquisition_validation_rate',
          'acquisitions_pending_validation',
          'acquisitions_pending_validation_p',
          'avg_validation_period',
          'avg_complete_cycle_period',
          'real_revenue_during',
          'real_acquisitions_during',
          'real_roi_during',
          'cpra_during'
        ].sort()

  var allColumnFilters = allColumns

  return (
    <div className='h-[50rem] h-screen w-screen w-full dark:bg-black bg-white  dark:bg-grid-white/[0.2] bg-grid-black/[0.2] relative flex items-center justify-center'>
      <div className='absolute h-screen w-screen pointer-events-none inset-0 flex items-center justify-center dark:bg-black bg-white [mask-image:radial-gradient(ellipse_at_center,transparent_20%,black)]'></div>
      <div className='grid grid-rows-2 gap-0 bg-opacity-0 h-screen w-screen'>
        <SaveNewClusterViewModal
          show={showCreateClusterViewModal}
          showSetter={setShowCreateClusterViewModal}
          APIEndpointDomain={APIEndpointDomain}
          clusterNames={availableClusters}
          setViewInformation={setViewInformation}
          setAvailableViews={setAvailableViews}
        />
        <EditClusterViewModal
          show={showEditClusterViewModal}
          showSetter={setShowEditClusterViewModal}
          APIEndpointDomain={APIEndpointDomain}
          clusterNames={availableClusters}
          viewName={editingView}
          viewInformation={viewInformation}
          selectedClusters={selectedClusters}
          setSelectedClusters={setSelectedClusters}
          setViewInformation={setViewInformation}
          setAvailableViews={setAvailableViews}
        />

        <CustomizeColumnsModal
          columnModalShow={columnModalShow}
          columnCategory={columnCategory}
          showColumnModal={showColumnModal}
          columnFilters={allColumnFilters}
          columnFilterColor={columnFilterColor}
          selectedColumnFilters={selectedColumnFilters}
          columnSelector={columnSelector}
          dragColumnStart={dragColumnStart}
          dragColumnEnter={dragColumnEnter}
          dropColumn={dropColumn}
        />
        <Navbar
          fixed='top'
          //   className='flex justify-between items-center'
          style={{
            borderWidth: 0
          }}
        >
          <div className='flex justify-between items-center w-screen'>
            <Dropdown
              disabled={columnModalShow || isLoading}
              className='mt-3 ml-3'
            >
              <Dropdown.Toggle variant='secondary' id='dropdown-basic'>
                {selectedView == '' ? (
                  <>
                    <FontAwesomeIcon icon={faPenToSquare} />
                  </>
                ) : (
                  selectedView
                )}
              </Dropdown.Toggle>
              <Dropdown.Menu className='custom-report-dropdown-menu'>
                {availableViews.length > 0
                  ? availableViews.map(view => (
                      <Dropdown.Item disabled={columnModalShow || isLoading}>
                        <Button
                          onClick={e => {
                            setSelectedView(view)
                          }}
                          variant='light'
                        >
                          {' '}
                          {view}
                        </Button>
                        <Button
                          onClick={() => {
                            setEditingView(view)
                            setShowEditClusterViewModal(true)
                            setSelectedClusters(
                              viewInformation.find(
                                viewed => viewed['name'] == view
                              )['clusters']
                            )
                          }}
                        >
                          <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon>
                        </Button>
                      </Dropdown.Item>
                    ))
                  : null}
              </Dropdown.Menu>
            </Dropdown>
            <Button
              disabled={columnModalShow || isLoading}
              className='mt-3'
              variant='secondary'
              onClick={() => setColumnModalShow(true)}
            >
              <FontAwesomeIcon icon={faTable}></FontAwesomeIcon>
            </Button>
            <Button
              disabled={columnModalShow || isLoading}
              onClick={() => setShowCreateClusterViewModal(true)}
              variant='secondary'
              className='mt-3'
            >
              Create View
            </Button>

            <h4 className='mt-3 text-3xl text-slate-600 font-bold mx-auto'>
              Performance Clusters
            </h4>
            <Col className='mt-3' sm='0' style={{ marginTop: '10px' }}>
              {/* <OverlayTrigger
                    placement="left"
                    delay={{ show: 1500, hide: 400 }}
                    overlay={renderButtonTooltip("Select required lead type.")}
                  > */}
              <Dropdown disabled={columnModalShow || isLoading}>
                <Dropdown.Toggle variant='secondary' id='dropdown-basic'>
                  {selectedLeadType}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    disabled={columnModalShow || isLoading}
                    onClick={e => setSelectedLeadType(e.target.text)}
                  >
                    CPA
                  </Dropdown.Item>
                  <Dropdown.Item
                    disabled={columnModalShow || isLoading}
                    onClick={e => setSelectedLeadType(e.target.text)}
                  >
                    Premium
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              {/* </OverlayTrigger> */}
            </Col>
            <Col className='mt-3' sm='0' style={{ marginTop: '10px' }}>
              <Form.Control
                disabled={columnModalShow || isLoading}
                defaultValue={start}
                onSelect={handleStartDate}
                type='date'
                size='md'
              />
            </Col>
            {/* </Form.Group> */}
            <Col className='mt-3' sm='0' style={{ marginTop: '10px' }}>
              <Form.Control
                disabled={columnModalShow || isLoading}
                defaultValue={end}
                onSelect={handleEndDate}
                type='date'
                size='md'
              />
            </Col>
            <Button
              disabled={columnModalShow || isLoading}
              className='mt-3 mr-3 '
              size='sm'
              id='toggle-check'
              variant='danger'
              onClick={() =>
                getExistingView(
                  selectedView,
                  startDate,
                  endDate,
                  selectedLeadType
                )
              }
            >
              Go
            </Button>
            {data.length > 0 ? (
              <button className='mt-3 mr-3 px-8 py-2 rounded-full bg-slate-700 text-white text-sm hover:shadow-2xl hover:shadow-white/[0.1] transition duration-200 border border-slate-600'>
                <div className='absolute inset-x-0 h-px w-1/2 mx-auto -top-px shadow-2xl bg-gradient-to-r from-transparent via-teal-500 to-transparent' />
                <span className='relative z-20'>
                  <CSVLink filename='user-tracker-table-export.csv' data={data}>
                    <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                  </CSVLink>
                </span>
              </button>
            ) : null}
          </div>
        </Navbar>
        <div className='pt-28 bg-opacity-0 w-screen h-screen '>
          {data.length > 0 && !isLoading && !isError ? (
            <div style={gridStyle} className={'ag-theme-quartz'}>
              <AgGridReact
                rowData={data}
                columnDefs={headers}
                defaultColDef={defaultColDef}
                rowDragManaged={true}
                rowSelection='multiple'
                rowDragMultiRow={true}
                rowStyle={rowStyle}
                getRowStyle={getRowStyle}
              />
            </div>
          ) : data.length == 0 && !isLoading ? (
            <div
              style={{ paddingBottom: '200px' }}
              className='flex justify-center items-center h-screen text-center'
            >
              {' '}
              <TypewriterEffect
                className='text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-400 to-neutral-500 py-8'
                words={'Create a clusters view or load an existing one!'
                  .split(' ')
                  .map(word => {
                    return { text: word }
                  })}
              ></TypewriterEffect>
            </div>
          ) : isLoading ? (
            <div
              style={{ paddingBottom: '200px' }}
              class='flex justify-center items-center h-screen text-center'
            >
              {/* <FontAwesomeIcon
              style={{ width: '5rem', height: '5rem' }}
              icon={faBarsProgress}
              className='fa-flip'
            /> */}
              <AtomicSpinner
                electronColorPalette={['#404040', '#737373', '#A6A6A6']}
              ></AtomicSpinner>
            </div>
          ) : isError ? (
            <div
              style={{ paddingBottom: '200px' }}
              className='flex justify-center items-center h-screen text-center'
            >
              {' '}
              <TypewriterEffect
                className='text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-400 to-neutral-500 py-8'
                words={'Sorry, we failed to generate your view!'
                  .split(' ')
                  .map(word => {
                    return { text: word }
                  })}
              ></TypewriterEffect>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default PerformanceClustersScreen
