import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { Form } from 'react-bootstrap'

function SaveCustomReportModal ({
  showNameReportModal,
  setShowNameReportModal,
  setNamingCustomReport,
  saveCustomReportAs,
  favoritesData
}) {
  return (
    <Modal size='sm' show={showNameReportModal}>
      <Modal.Header closeButton onClick={() => setShowNameReportModal(false)}>
        <Modal.Title id='contained-modal-title-vcenter'>
          Name your Report
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Control
          type='text'
          placeholder='Name'
          onChange={e => setNamingCustomReport(e.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          color='primary'
          onClick={() => saveCustomReportAs(favoritesData)}
        >
          Create
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default SaveCustomReportModal
