import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import ListGroup from 'react-bootstrap/ListGroup'
import Modal from 'react-bootstrap/Modal'
import Table from 'react-bootstrap/Table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShare } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'react-bootstrap'

function AdDetailedInformationModal ({
  showAdPreviewModal,
  setShowAdPreviewModal,
  setHealthOrPreview,
  previewingAd,

  changePreviewPlacement,
  previewPlacement,
  adAllTimeStats,
  setAdAllTimeStats,
  healthOrPreview,
  previewHTML,
  adID
}) {
  function copyPublicAdReportLink (id) {
    const url = window.location.origin + '/performance/alltime-stats/ad/' + id

    const textArea = document.createElement('textarea')
    textArea.value = url

    // Append the textarea element to the document
    document.body.appendChild(textArea)

    // Select the URL in the textarea
    textArea.select()

    // Execute the copy command
    document.execCommand('copy')

    // Remove the temporary textarea element
    document.body.removeChild(textArea)

    // Check if the URL was successfully copied to the clipboard
    // if (document.queryCommandSupported("copy")) {
    //   console.log("URL copied to clipboard: " + url);
    // } else {
    //   console.error("Copy to clipboard not supported in this browser.");
    // }
  }

  return (
    <Modal
      size='lg'
      show={showAdPreviewModal}
      onHide={() => {
        setShowAdPreviewModal(false)
        setHealthOrPreview('Lifetime Stats')
        setAdAllTimeStats('')
      }}
    >
      <Modal.Header
        onClick={() => {
          setShowAdPreviewModal(false)
          setHealthOrPreview('Lifetime Stats')
          setAdAllTimeStats('')
        }}
        style={{
          display: 'flex',
          justifyContent: 'space-between', // Aligns items to each end of the container
          alignItems: 'center',
          height: '100%',
          backgroundColor: '#525252',
          color: 'white'
        }}
      >
        <Modal.Title style={{ flex: 1 }}>{previewingAd}</Modal.Title>
      </Modal.Header>
      <div className='level-tabs-container'>
        <Tabs
          defaultActiveKey='Lifetime Stats'
          className='mb-3'
          fill
          activeKey={healthOrPreview}
          onSelect={e => setHealthOrPreview(e)}
        >
          <Tab eventKey='Lifetime Stats' title={'Lifetime Stats'} />

          <Tab eventKey='Preview' title={'Preview'}></Tab>
        </Tabs>
      </div>
      {healthOrPreview === 'Preview' ? (
        <>
          <Modal.Body
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              minHeight: '600px',
              backgroundColor: '#F7F7F7'
            }}
          >
            {/* <div className="modal-content-container"> */}

            <div className='row'>
              <div className='placement-tabs-container'>
                <Tabs
                  defaultActiveKey='DESKTOP_FEED_STANDARD'
                  className='mb-3'
                  fill
                  activeKey={previewPlacement}
                  onSelect={e => {
                    changePreviewPlacement(e)
                  }}
                >
                  <Tab
                    eventKey='DESKTOP_FEED_STANDARD'
                    title={'DESKTOP_FEED_STANDARD'}
                  />
                  <Tab
                    eventKey='MOBILE_FEED_STANDARD'
                    title={'MOBILE_FEED_STANDARD'}
                  ></Tab>
                  <Tab
                    eventKey='RIGHT_COLUMN_STANDARD'
                    title={'RIGHT_COLUMN_STANDARD'}
                  ></Tab>

                  <Tab
                    eventKey='INSTAGRAM_STANDARD'
                    title={'INSTAGRAM_STANDARD'}
                  ></Tab>
                  <Tab
                    eventKey='INSTAGRAM_STORY'
                    title={'INSTAGRAM_STORY'}
                  ></Tab>
                  <Tab
                    eventKey='INSTAGRAM_REELS'
                    title={'INSTAGRAM_REELS'}
                  ></Tab>
                </Tabs>
              </div>
            </div>
            <div>
              <div dangerouslySetInnerHTML={{ __html: previewHTML }}></div>
            </div>
          </Modal.Body>
        </>
      ) : healthOrPreview === 'Lifetime Stats' ? (
        <>
          {Object.keys(adAllTimeStats).length > 0 ? (
            <>
              <Modal.Body
                style={{
                  // display: "flex",
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  backgroundColor: '#dedddc'
                }}
              >
                <div>
                  <Table
                    responsive='xl'
                    striped
                    hover
                    size='md'
                    className='fixed-table'
                    // variant="dark"
                  >
                    <tbody>
                      {Object.keys(adAllTimeStats).map(key => {
                        return (
                          <tr>
                            <td
                              style={{
                                textAlign: 'center'
                              }}
                            >
                              {key}
                            </td>
                            <td
                              style={{
                                textAlign: 'left'
                              }}
                            >
                              {Math.round(adAllTimeStats[key])}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </div>
              </Modal.Body>
            </>
          ) : (
            <>
              <Modal.Body
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',

                  backgroundColor: '#dedddc'
                }}
              >
                {' '}
                <h3>Loading...</h3>
              </Modal.Body>
            </>
          )}
        </>
      ) : null}
    </Modal>
  )
}

export default AdDetailedInformationModal
