import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { Button, Badge } from 'react-bootstrap'
import axios from 'axios'
import { useState } from 'react'
import APIRequestStatusModal from '../CommonSubComponents/APIRequestStatusMessageModal'
import { ListGroup } from 'react-bootstrap'
import InputGroup from 'react-bootstrap/InputGroup'

function AssignCustomTagModal ({
  show,
  showSetter,
  APIEndpointDomain,
  rowInformation,
  clusterNames,
  inputtedName,
  setInputtedName,
  setAPIData,
  apiData
}) {
  var selectedVideoTitle = rowInformation ? rowInformation['title'] : null
  var selectedVideoID = rowInformation ? rowInformation['video_id'] : null
  var [tagSearch, setTagSearch] = useState('')
  var [showAPIStatusModal, setShowAPIStatusModal] = useState(false)
  var [showAPIStatusMessage, setShowAPIStatusMessage] = useState(false)

  function updateObject (array, idToFind, keyToUpdate, newValue) {
    // Find the index of the object with the given id
    const index = array.findIndex(obj => obj.video_id === idToFind)

    // If the object is found
    if (index !== -1) {
      // Update the value of the specified key
      array[index][keyToUpdate] = newValue
      // Return the updated object
      return array
    } else {
      // Object with the given id not found
      console.log('Object not found')
      return null
    }
  }

  function assignTag (APIEndpointDomain, video_id, custom_tags) {
    axios
      .get(APIEndpointDomain + '/youtube/video/update-custom-tags', {
        params: {
          video_id: video_id,
          new_tags: custom_tags
        },
        headers: {
          'ngrok-skip-browser-warning': 'any',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        setAPIData(
          updateObject(
            apiData,
            selectedVideoID,
            'custom_tags',
            custom_tags.split(', ')
          )
        )
        console.log(custom_tags)
        console.log(video_id)
        showSetter(false)
        console.log(response)
      })
      .catch(error => {
        setShowAPIStatusMessage('Failed to assign the tag!')

        showSetter(false)
        console.log(error)
        setShowAPIStatusModal(true)
      })
  }

  function AddClusterName (cluster) {
    if (inputtedName == '') {
      setInputtedName(cluster)
    } else {
      setInputtedName(inputtedName + ', ' + cluster)
    }
  }

  function removeClusterName (clusterToRemove) {
    if (inputtedName !== '') {
      const clusterArray = inputtedName.split(', ')
      const updatedClusterArray = clusterArray.filter(
        cluster => cluster !== clusterToRemove
      )
      setInputtedName(updatedClusterArray.join(', '))
    }
  }

  return (
    <div>
      <APIRequestStatusModal
        show={showAPIStatusModal}
        setShow={setShowAPIStatusModal}
        message={showAPIStatusMessage}
      />
      <Modal size='md' show={show}>
        <Modal.Header
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            backgroundColor: '#525252',
            color: 'white'
          }}
          closeButton
          onClick={() => showSetter(false)}
        >
          <Modal.Title id='contained-modal-title-vcenter'>
            {selectedVideoTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group
              className='mb-3'
              controlId='exampleForm.ControlTextarea1'
            >
              <InputGroup className='mb-3'>
                <InputGroup.Text id='basic-addon1'>
                  Groupings added
                </InputGroup.Text>
                <Form.Control
                  disabled
                  onChange={e => setInputtedName(e.target.value)}
                  type='text'
                  value={inputtedName}
                />
              </InputGroup>
            </Form.Group>
          </Form>
          <ListGroup horizontal='sm' className='mt-4 flex-wrap'>
            {['MBA', 'MiM', 'MFin', 'MSBA', 'MeM', 'Test Prep'].map(cluster => (
              <div className='py-1'>
                <div style={{ marginRight: '5px' }}>
                  {/* <ListGroup.Item> */}
                  <Button
                    id='toggle-check'
                    type='checkbox'
                    variant={
                      inputtedName.includes(cluster) ? 'success' : 'light'
                    }
                    // checked={inputtedName.includes(
                    //     cluster.toLowerCase()
                    //   )}
                    value={cluster}
                    // key={index}
                    onClick={() =>
                      inputtedName.includes(cluster)
                        ? removeClusterName(cluster)
                        : AddClusterName(cluster)
                    }
                  >
                    {cluster}
                  </Button>
                  {/* </ListGroup.Item> */}
                </div>
              </div>
            ))}
          </ListGroup>
        </Modal.Body>
        <div className='flex justify-center'>
          <Button
            onClick={() =>
              assignTag(APIEndpointDomain, selectedVideoID, inputtedName)
            }
            className='alarm-badge mt-3 mb-2'
            size='md'
          >
            {'Assign Grouping'}
          </Button>
        </div>
      </Modal>
    </div>
  )
}

export default AssignCustomTagModal
