import Modal from 'react-bootstrap/Modal'
import { ToggleButton } from 'react-bootstrap'
import ButtonGroup from 'react-bootstrap/ButtonGroup'

function AdvancedFilterModal ({
  advancedFilterModalShow,
  showAdvancedFiltersModal,
  consolidationType,
  setStatusFilters,
  statusFilters,
  contentTypeFilters,
  setContentTypeFilters
}) {
  return (
    <Modal size='md' show={advancedFilterModalShow}>
      <Modal.Header
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          backgroundColor: '#525252',
          color: 'white'
        }}
        closeButton
        onClick={showAdvancedFiltersModal}
      >
        <Modal.Title id='contained-modal-title-vcenter'>
          Set Advanced Filters
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='mb-5'>
          <div className='row mb-5'>
            {consolidationType !== 'name' ? (
              <div className='col'>
                <div className='row'>
                  <div className='col'>
                    <h5>
                      <strong>Select Ad Status</strong>
                    </h5>
                  </div>
                </div>
                <ButtonGroup className='mb-2'>
                  <ToggleButton
                    id='toggle-check'
                    type='checkbox'
                    variant='outline-success'
                    value='active'
                    checked={statusFilters.active}
                    className='active-status'
                    onClick={() =>
                      setStatusFilters({
                        paused: statusFilters['paused'],
                        active: statusFilters['active'] === true ? false : true
                      })
                    }
                  >
                    Active
                  </ToggleButton>
                </ButtonGroup>
                <ButtonGroup className='mb-2'>
                  <ToggleButton
                    id='toggle-check'
                    type='checkbox'
                    variant='outline-primary'
                    value='1'
                    checked={statusFilters['paused']}
                    className='paused-status'
                    onClick={() =>
                      setStatusFilters({
                        active: statusFilters['active'],
                        paused: statusFilters['paused'] === true ? false : true
                      })
                    }
                  >
                    Paused
                  </ToggleButton>
                </ButtonGroup>
              </div>
            ) : (
              <div className='col'>
                <h5>
                  <strong>Ad Status Filter NA</strong>
                </h5>
              </div>
            )}

            <div className='col'>
              <div className='row'>
                <div className='col'>
                  <h5>
                    <strong>Select Content Type</strong>
                  </h5>
                </div>
              </div>
              <ButtonGroup className='mb-2'>
                <ToggleButton
                  id='toggle-check'
                  type='checkbox'
                  variant='outline-success'
                  value='active'
                  checked={contentTypeFilters.reel}
                  className='active-status'
                  onClick={() =>
                    setContentTypeFilters({
                      image: contentTypeFilters['image'],
                      reel: contentTypeFilters['reel'] === true ? false : true
                    })
                  }
                >
                  Reel
                </ToggleButton>
              </ButtonGroup>
              <ButtonGroup className='mb-2'>
                <ToggleButton
                  id='toggle-check'
                  type='checkbox'
                  variant='outline-success'
                  value='1'
                  checked={contentTypeFilters['image']}
                  className='paused-status'
                  onClick={() =>
                    setContentTypeFilters({
                      reel: contentTypeFilters['reel'],
                      image: contentTypeFilters['image'] === true ? false : true
                    })
                  }
                >
                  Image
                </ToggleButton>
              </ButtonGroup>
            </div>
          </div>
          {/* <div className="row mb-3">
            <div className="col">
              <Form.Label>Min. ROI</Form.Label>
              <Form.Control
                placeholder="Minimum"
                defaultValue={advancedFiltersSelections["minROI"]}
                onChange={(e) =>
                  handleAdvanceFilterChange(e.target.value, "minROI")
                }
              />
            </div>
            <div className="col">
              <Form.Label>Min. Revenue</Form.Label>
              <Form.Control
                placeholder="Minimum"
                defaultValue={advancedFiltersSelections["minRevenue"]}
                onChange={(e) =>
                  handleAdvanceFilterChange(e.target.value, "minRevenue")
                }
              />
            </div>

            <div className="col">
              <Form.Label>Max. ROI</Form.Label>
              <Form.Control
                placeholder="Maximum"
                defaultValue={advancedFiltersSelections["maxROI"]}
                onChange={(e) =>
                  handleAdvanceFilterChange(e.target.value, "maxROI")
                }
              />
            </div>
            <div className="col">
              <Form.Label>Max. Revenue</Form.Label>
              <Form.Control
                placeholder="Maximum"
                defaultValue={advancedFiltersSelections["maxRevenue"]}
                onChange={(e) =>
                  handleAdvanceFilterChange(e.target.value, "maxRevenue")
                }
              />
            </div>
            <div className="col">
              <Form.Label>Min. Days Active</Form.Label>
              <Form.Control
                placeholder="Minimum"
                defaultValue={advancedFiltersSelections["minDaysActive"]}
                onChange={(e) =>
                  handleAdvanceFilterChange(e.target.value, "minDaysActive")
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Form.Label>Min. Clients During</Form.Label>
              <Form.Control
                placeholder="Minimum"
                defaultValue={advancedFiltersSelections["minAcquisitionDuring"]}
                onChange={(e) =>
                  handleAdvanceFilterChange(
                    e.target.value,
                    "minAcquisitionDuring"
                  )
                }
              />
            </div>
            <div className="col">
              <Form.Label>Min. Revenue During</Form.Label>
              <Form.Control
                placeholder="Minimum"
                defaultValue={advancedFiltersSelections["minRevenueDuring"]}
                onChange={(e) =>
                  handleAdvanceFilterChange(e.target.value, "minRevenueDuring")
                }
              />
            </div>

            <div className="col">
              <Form.Label>Max. Clients During</Form.Label>
              <Form.Control
                placeholder="Maximum"
                defaultValue={advancedFiltersSelections["maxAcquisitionDuring"]}
                onChange={(e) =>
                  handleAdvanceFilterChange(
                    e.target.value,
                    "maxAcquisitionDuring"
                  )
                }
              />
            </div>
            <div className="col">
              <Form.Label>Max. Revenue During</Form.Label>
              <Form.Control
                placeholder="Maximum"
                defaultValue={advancedFiltersSelections["maxRevenueDuring"]}
                onChange={(e) =>
                  handleAdvanceFilterChange(e.target.value, "maxRevenueDuring")
                }
              />
            </div>
            <div className="col">
              <Form.Label>Max. Days Active</Form.Label>
              <Form.Control
                placeholder="Maximum"
                defaultValue={advancedFiltersSelections["maxDaysActive"]}
                onChange={(e) =>
                  handleAdvanceFilterChange(e.target.value, "maxDaysActive")
                }
              />
            </div>
          </div>
        </div>
        <div className="mb-5" style={{ borderTop: "1px dotted black" }}>
          <div className="row">
            <div className="col">
              <Form.Label>Min. Spend</Form.Label>
              <Form.Control
                placeholder="Minimum"
                defaultValue={advancedFiltersSelections["minSpend"]}
                onChange={(e) =>
                  handleAdvanceFilterChange(e.target.value, "minSpend")
                }
              />
            </div>
            <div className="col">
              <Form.Label>Min. Leads</Form.Label>
              <Form.Control
                placeholder="Minimum"
                defaultValue={advancedFiltersSelections["minLeads"]}
                onChange={(e) =>
                  handleAdvanceFilterChange(e.target.value, "minLeads")
                }
              />
            </div>
            <div className="col">
              <Form.Label>Min. Qualified</Form.Label>
              <Form.Control
                placeholder="Minimum"
                defaultValue={advancedFiltersSelections["minQualified"]}
                onChange={(e) =>
                  handleAdvanceFilterChange(e.target.value, "minQualified")
                }
              />
            </div>
            <div className="col">
              <Form.Label>Min. Good</Form.Label>
              <Form.Control
                placeholder="Minimum"
                defaultValue={advancedFiltersSelections["minGood"]}
                onChange={(e) =>
                  handleAdvanceFilterChange(e.target.value, "minGood")
                }
              />
            </div>
            <div className="col">
              <Form.Label>Min. Clients</Form.Label>
              <Form.Control
                placeholder="Minimum"
                defaultValue={advancedFiltersSelections["minClients"]}
                onChange={(e) =>
                  handleAdvanceFilterChange(e.target.value, "minClients")
                }
              />
            </div>
            <div className="col">
              <Form.Label>Min. Quality</Form.Label>
              <Form.Control
                placeholder="Minimum"
                defaultValue={advancedFiltersSelections["minQuality"]}
                onChange={(e) =>
                  handleAdvanceFilterChange(e.target.value, "minQuality")
                }
              />
            </div>
            <div className="col">
              <Form.Label>Min. CPL</Form.Label>
              <Form.Control
                placeholder="Minimum"
                defaultValue={advancedFiltersSelections["minCPL"]}
                onChange={(e) =>
                  handleAdvanceFilterChange(e.target.value, "minCPL")
                }
              />
            </div>
            <div className="col">
              <Form.Label>Min. CPQL</Form.Label>
              <Form.Control
                placeholder="Minimum"
                defaultValue={advancedFiltersSelections["minCPQL"]}
                onChange={(e) =>
                  handleAdvanceFilterChange(e.target.value, "minCPQL")
                }
              />
            </div>
            <div className="col">
              <Form.Label>Min. CPGL</Form.Label>
              <Form.Control
                placeholder="Minimum"
                defaultValue={advancedFiltersSelections["minCPGL"]}
                onChange={(e) =>
                  handleAdvanceFilterChange(e.target.value, "minCPGL")
                }
              />
            </div>
            <div className="col">
              <Form.Label>Min. CPA</Form.Label>
              <Form.Control
                placeholder="Minimum"
                defaultValue={advancedFiltersSelections["minCPA"]}
                onChange={(e) =>
                  handleAdvanceFilterChange(e.target.value, "minCPA")
                }
              />
            </div>
          </div>
        </div>
        <div className="mb-5">
          <div className="row">
            <div className="col">
              <Form.Label>Max. Spend</Form.Label>
              <Form.Control
                placeholder="Maximum"
                defaultValue={advancedFiltersSelections["maxSpend"]}
                onChange={(e) =>
                  handleAdvanceFilterChange(e.target.value, "maxSpend")
                }
              />
            </div>
            <div className="col">
              <Form.Label>Max. Leads</Form.Label>
              <Form.Control
                placeholder="Maximum"
                defaultValue={advancedFiltersSelections["maxLeads"]}
                onChange={(e) =>
                  handleAdvanceFilterChange(e.target.value, "maxLeads")
                }
              />
            </div>
            <div className="col">
              <Form.Label>Max. Qualified</Form.Label>
              <Form.Control
                placeholder="Maximum"
                defaultValue={advancedFiltersSelections["maxQualified"]}
                onChange={(e) =>
                  handleAdvanceFilterChange(e.target.value, "maxQualified")
                }
              />
            </div>
            <div className="col">
              <Form.Label>Max. Good</Form.Label>
              <Form.Control
                placeholder="Maximum"
                defaultValue={advancedFiltersSelections["maxGood"]}
                onChange={(e) =>
                  handleAdvanceFilterChange(e.target.value, "maxGood")
                }
              />
            </div>
            <div className="col">
              <Form.Label>Max. Clients</Form.Label>
              <Form.Control
                placeholder="Maximum"
                defaultValue={advancedFiltersSelections["maxClients"]}
                onChange={(e) =>
                  handleAdvanceFilterChange(e.target.value, "maxClients")
                }
              />
            </div>
            <div className="col">
              <Form.Label>Max. Quality</Form.Label>
              <Form.Control
                placeholder="Maximum"
                defaultValue={advancedFiltersSelections["maxQuality"]}
                onChange={(e) =>
                  handleAdvanceFilterChange(e.target.value, "maxQuality")
                }
              />
            </div>
            <div className="col">
              <Form.Label>Max. CPL</Form.Label>
              <Form.Control
                placeholder="Maximum"
                defaultValue={advancedFiltersSelections["maxCPL"]}
                onChange={(e) =>
                  handleAdvanceFilterChange(e.target.value, "maxCPL")
                }
              />
            </div>
            <div className="col">
              <Form.Label>Max. CPQL</Form.Label>
              <Form.Control
                placeholder="Maximum"
                defaultValue={advancedFiltersSelections["maxCPQL"]}
                onChange={(e) =>
                  handleAdvanceFilterChange(e.target.value, "maxCPQL")
                }
              />
            </div>
            <div className="col">
              <Form.Label>Max. CPGL</Form.Label>
              <Form.Control
                placeholder="Maximum"
                defaultValue={advancedFiltersSelections["maxCPGL"]}
                onChange={(e) =>
                  handleAdvanceFilterChange(e.target.value, "maxCPGL")
                }
              />
            </div>
            <div className="col">
              <Form.Label>Max. CPA</Form.Label>
              <Form.Control
                placeholder="Maximum"
                defaultValue={advancedFiltersSelections["maxCPA"]}
                onChange={(e) =>
                  handleAdvanceFilterChange(e.target.value, "maxCPA")
                }
              />
            </div>
          </div> */}
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default AdvancedFilterModal
