import axios from 'axios'
import { useEffect, useState, useMemo, useRef, useCallback } from 'react'
import '../css/YouTubeVideosScreen.css'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css' // Core CSS
import 'ag-grid-community/styles/ag-theme-quartz.css' // Theme
import { Navbar } from 'react-bootstrap'
import Dropdown from 'react-bootstrap/Dropdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faDownload,
  faUser,
  faArrowUp,
  faArrowDown,
  faEye,
  faEyeDropperEmpty
} from '@fortawesome/free-solid-svg-icons'
import { Button } from 'react-bootstrap'
import { Col } from 'react-bootstrap'
import { Form } from 'react-bootstrap'
import { MultiStepLoader } from '../components/ui/multi-step-loader'
import { CSVLink } from 'react-csv'
import { TypewriterEffect } from '../components/ui/typewriter-effect'
import { ShadCNButton } from '../components/ui/shadcn-button'
import { cn } from '../utils/cn'
import { ToggleButton } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import Badge from 'react-bootstrap/Badge'
import {
  Widget,
  addResponseMessage,
  toggleWidget,
  addUserMessage,
  deleteMessages
} from 'react-chat-widget'
import '../css/GlobalChatbox.css'
import 'react-chat-widget/lib/styles.css'
function InstagramBoostingAnalytics ({
  checkAuthenticationInResponse,
  APIEndpointDomain
}) {
  var [userImage, setUserImage] = useState(
    'https://i.pinimg.com/736x/b8/38/4d/b8384dc3955cd72a44224e3fd9089745.jpg'
  )
  var [chatboxTitle, setChatboxTitle] = useState('Ask')
  var [chatboxSubtitle, setChatboxSubtitle] = useState("Hint: type 'commands'")

  useEffect(() => {
    fetchAllMessages()
  })

  function handleNewUserMessage (message) {
    axios
      .get(APIEndpointDomain + '/marketingchatapp/process-user-message', {
        params: {
          command: message.toLowerCase()
        },

        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        fetchAllMessages()
        console.log(response)
      })
      .catch(error => {
        checkAuthenticationInResponse(Number(error.response.status))
        console.log(error)
        alert('Message failed to deliver!')
      })
  }

  function fetchAllMessages () {
    axios
      .get(APIEndpointDomain + '/marketingchatapp/generate-initial-view', {
        params: {},

        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })

      .then(response => {
        console.log(response)
        var chats = response.data['data']
        for (let i = 0; i < chats.length; i++) {
          if (chats[i]['sender'] == 'user' && chats[i]['message']) {
            console.log('user_found')
            addUserMessage(chats[i]['message'])
          } else if (chats[i]['message']) {
            console.log('not_user_found')
            addResponseMessage(chats[i]['message'])
          }
        }
      })
      .catch(error => {
        checkAuthenticationInResponse(Number(error.response.status))
        console.log(error)
        alert('Message failed to deliver!')
      })
  }

  const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection'
  }
  var loadingStates = [
    {
      text: 'fetching ads'
    },

    {
      text: 'calculating performance'
    }
  ]
  function isNumber (n) {
    return Number(n) === n
  }
  const [selectedDayRange, setSelectedDayRange] = useState({
    from: null,
    to: null
  })

  console.log(selectedDayRange)

  var [selectedView, setSelectedView] = useState('Ad')

  var [averageStandards, setAverageStandards] = useState([])
  var [averageStandardMetrics, setAverageStandardMetrics] = useState([])

  var [campaignFilters, setCampaignFilters] = useState([])

  var [previewedAdID, setPreviewedAdID] = useState(0)
  var [statusFilter, setStatusFilter] = useState('All Status')
  var [campaignFilter, setCampaignFilter] = useState('All Campaigns')
  var today = new Date()
  var start = new Date(new Date().setDate(today.getDate()))
    .toJSON()
    .slice(0, 10)

  var [showPreviewModal, setShowPreviewModal] = useState(false)
  var [userExportData, setUserExportData] = useState([])

  var [startDate, setStartDate] = useState(start)
  var [endDate, setEndDate] = useState(start)
  var [totals, setTotals] = useState({})

  var [selectedLeadType, setSelectedLeadType] = useState('Premium')
  var [data, setData] = useState([])
  var [allData, setAllData] = useState([])
  var [total, setTotal] = useState([])
  var [pinnedRowData, setPinnedRowData] = useState([])
  var [pinnedBottomRowData, setPinnedBottomRowData] = useState([])
  var [previewHTML, setPreviewHTML] = useState('')
  var [isFetching, setIsFetching] = useState(false)
  var [isServerError, setIsServerError] = useState(false)
  var [previewPlacement, setPreviewPlacement] = useState('INSTAGRAM_REELS')
  var [columnOrder, setColumnOrder] = useState([])
  var [postMoveHeaders, setPostMoveHeaders] = useState([])
  var [headers, setHeaders] = useState([])

  function handleStartDate (e) {
    console.log(e.target.value)

    setStartDate(e.target.value)
  }

  useEffect(() => {
    setData(allData)
  }, [allData])

  useEffect(() => {
    var duplicateAllData = allData

    if (statusFilter == 'Active') {
      var statusFilteredData = duplicateAllData.filter(row => row.budget > 0)
    } else if (statusFilter == 'Inactive') {
      var statusFilteredData = duplicateAllData.filter(row => row.budget == 0)
    } else {
      var statusFilteredData = duplicateAllData
    }

    if (campaignFilter == 'All Campaigns') setData(statusFilteredData)
    else {
      setData(
        statusFilteredData.filter(row =>
          row.campaign_name.includes(campaignFilter)
        )
      )
    }
  }, [statusFilter, campaignFilter, allData])

  useEffect(() => {}, [])

  function getTotals (arr) {
    const sums = {}

    arr.forEach(obj => {
      for (let key in obj) {
        // Skip keys containing '_rate'
        if (key.includes('_rate') || key.includes('name') || key === 'theme') {
          continue
        }
        if (sums[key]) {
          sums[key] += obj[key]
        } else {
          sums[key] = obj[key]
        }
      }
    })

    return sums
  }

  // Output: { key1: 5, key2: 7 }

  useEffect(() => {
    var totaled = getTotals(data)

    totaled['roi'] = Math.round(
      (100 * totaled['revenue_during']) / totaled['spend']
    )
    totaled['cost_per_engagement'] = (
      totaled['spend'] / totaled['engagement']
    ).toFixed(3)
    totaled['cost_per_comment'] = Math.round(
      totaled['spend'] / totaled['comments']
    )
    totaled['cpc'] = Math.round(totaled['spend'] / totaled['link_clicks'])
    totaled['cost_per_message'] = Math.round(
      totaled['spend'] / totaled['messages']
    )
    totaled['cpl'] = Math.round(totaled['spend'] / totaled['leads'])
    totaled['cpql'] = Math.round(totaled['spend'] / totaled['qualified'])
    totaled['cpql'] = Math.round(totaled['spend'] / totaled['qualified'])
    totaled['quality'] = Math.round(
      (100 * totaled['qualified']) / totaled['leads']
    )
    totaled['h_cpgl'] = Math.round(totaled['spend'] / totaled['h_good'])
    totaled['cpa_during'] = Math.round(
      totaled['spend'] / totaled['acquired_during']
    )

    setTotals(totaled)
  }, [data])

  const gridRef = useRef()

  function onFilterChanged () {
    const filteredNodes = gridRef.current.api.getRenderedNodes()
    const filteredData = filteredNodes.map(node => node.data)
    var totaled = getTotals(filteredData)

    totaled['roi'] = Math.round(
      (100 * totaled['revenue_during']) / totaled['spend']
    )
    totaled['cost_per_engagement'] = (
      totaled['spend'] / totaled['engagement']
    ).toFixed(3)
    totaled['cost_per_comment'] = Math.round(
      totaled['spend'] / totaled['comments']
    )
    totaled['cpc'] = Math.round(totaled['spend'] / totaled['link_clicks'])
    totaled['cost_per_message'] = Math.round(
      totaled['spend'] / totaled['messages']
    )
    totaled['cpl'] = Math.round(totaled['spend'] / totaled['leads'])
    totaled['cpql'] = Math.round(totaled['spend'] / totaled['qualified'])
    totaled['cpql'] = Math.round(totaled['spend'] / totaled['qualified'])
    totaled['quality'] = Math.round(
      (100 * totaled['qualified']) / totaled['leads']
    )
    totaled['h_cpgl'] = Math.round(totaled['spend'] / totaled['h_good'])
    totaled['cpa_during'] = Math.round(
      totaled['spend'] / totaled['acquired_during']
    )

    setTotals(totaled)
  }

  function handleEndDate (e) {
    setEndDate(e.target.value)

    if (selectedLeadType == 'Jobs' && e.target.value < '2024-05-20') {
      console.log('bugger')
      alert(`Please select a date on or after 2024-05-20`)
    } else {
      setEndDate(e.target.value)
    }
  }

  var [dataIsFor, setDataIsFor] = useState('ad')

  function getData (selectedView, selectedLeadType, startDate, endDate) {
    setIsFetching(true)
    axios
      .get(APIEndpointDomain + '/instagram-boosting-analytics/generate-view', {
        params: {
          lead_type: selectedLeadType.toLowerCase(),
          start_date: startDate,
          end_date: endDate,
          theme_view: selectedView == 'Theme' ? true : false
        },
        headers: {
          'Access-Control-Allow-Origin': '*',

          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        console.log(response)
        setAllData(response.data['data'])
        setAverageStandards(response.data['average_standards'])
        setAverageStandardMetrics(
          Object.keys(response.data['average_standards'])
        )
        setCampaignFilters(response.data['campaigns'])
        console.log(response.data['data'])
        // setTotal(response.data['total'])
        // setPinnedRowData(response.data['pinned_data'])
        // setPinnedBottomRowData(response.data['pinned_bottom_data'])
        setIsFetching(false)
        setIsServerError(false)
        setDataIsFor(response.data['data_is_for'])
      })
      .catch(error => {
        checkAuthenticationInResponse(Number(error.response.status))

        console.log(error)
        setAllData([])
        setTotal([])
        setIsFetching(false)
        setIsServerError(true)
        setPinnedRowData([])
        setPinnedBottomRowData([])
      })
  }

  function handleGoButtonClick (
    selectedView,
    selectedLeadType,
    startDate,
    endDate
  ) {
    getData(selectedView, selectedLeadType, startDate, endDate)
  }

  //
  const defaultColDef = useMemo(() => {
    return {
      //   height: '300px',
      filter: true,
      cellStyle: { fontSize: '14px', textAlign: 'center' }
    }
  }, [])

  const gridStyle = useMemo(
    () => ({ height: '100%', width: '100%', backgroundColor: '#e8ebe9' }),
    []
  )
  //
  //   console.log('total', total)
  const rowStyle = { background: '#eaeaea' }

  const getRowStyle = params => {
    var pinned = Object.keys(params.node).includes('rowPinned') ? true : false

    if (pinned) {
      return { background: '#2c9c72', color: 'white', fontWeight: 'bold' }
    } else if (params.data['budget'] == 0) {
      return { background: '#fecaca' }
    } else if (params.node.rowIndex % 2 === 0) {
      return { background: '#f6f6f6' }
    }
  }

  useEffect(() => {
    if (dataIsFor == 'ad') {
      var heads =
        data.length > 0 && data
          ? [...Object.keys(data[0])]
              .filter(
                k =>
                  !k.includes('_id') &&
                  !k.includes('name') &&
                  ![
                    'backburners',
                    'lead_lost',
                    'invalid',
                    'pressure_sell',
                    'ultra_hot',
                    'nurturing',
                    'indirect_leads',
                    'direct_leads'
                  ].includes(k)
              )
              .map(key => {
                return {
                  field: key,
                  filter:
                    key != 'theme'
                      ? 'agNumberColumnFilter'
                      : 'agTextColumnFilter',
                  floatingFilter: !key.includes('name'),
                  cellRenderer: cellRenderer,
                  pinned: ['spend', 'budget'].includes(key) ? 'left' : null,
                  width: 150,
                  autoHeight: true
                }
              })
          : []

      heads = [
        {
          headerName: 'Content Piece',
          field: 'ad_name',
          filter: 'agTextColumnFilter',
          floatingFilter: true,
          filterParams: {
            caseSensitive: false,
            defaultOption: 'contains'
          },
          wrapText: true,
          autoHeight: true,
          autoWidth: true,
          pinned: 'left',
          cellRenderer: cellRenderer
        },
        ...heads
      ]

      setHeaders(data && data.length > 0 ? heads : [])
    } else if (dataIsFor == 'theme') {
      var heads =
        data.length > 0 && data
          ? [...Object.keys(data[0])]
              .filter(
                k =>
                  !k.includes('_id') &&
                  !k.includes('name') &&
                  ![
                    'backburners',
                    'lead_lost',
                    'invalid',
                    'pressure_sell',
                    'ultra_hot',
                    'nurturing',
                    'indirect_leads',
                    'direct_leads'
                  ].includes(k)
              )
              .map(key => {
                return {
                  field: key,
                  filter:
                    key != 'theme'
                      ? 'agNumberColumnFilter'
                      : 'agTextColumnFilter',
                  floatingFilter: !key.includes('name'),
                  cellRenderer: cellRenderer,
                  pinned: ['spend', 'budget'].includes(key) ? 'left' : null,
                  width: 150,
                  autoHeight: true
                }
              })
          : []

      heads = [
        {
          headerName: 'Theme',
          field: 'theme',
          filter: 'agTextColumnFilter',
          floatingFilter: true,
          filterParams: {
            caseSensitive: false,
            defaultOption: 'contains'
          },
          wrapText: true,
          autoHeight: true,
          autoWidth: true,
          pinned: 'left',
          cellRenderer: cellRenderer
        },
        ...heads
      ]

      setHeaders(data && data.length > 0 ? heads : [])
    }
  }, [data])

  function cellRenderer (params) {
    var key = params.colDef['field']
    var row = params.data
    var data = params.data[key]
    var pinned = Object.keys(params.node).includes('rowPinned') ? true : false

    var indicator =
      pinned !== true &&
      averageStandardMetrics.includes(key) &&
      [
        'cpl',
        'cpql',
        'h_cpgl',
        'cpa_during',
        'cost_per_engagement',
        'cost_per_comment',
        'cpc',
        'cost_per_message'
      ].includes(key) &&
      (data >= averageStandards[key] || data == 0) ? (
        <Badge bg={'danger'}>
          <FontAwesomeIcon icon={faArrowUp} />
        </Badge>
      ) : pinned !== true &&
        averageStandardMetrics.includes(key) &&
        [
          'cpl',
          'cpql',
          'h_cpgl',
          'cpa_during',
          'cost_per_engagement',
          'cost_per_comment',
          'cpc',
          'cost_per_message'
        ].includes(key) &&
        (data <= averageStandards[key] || data == 0) ? (
        <Badge bg={'success'}>
          <FontAwesomeIcon icon={faArrowDown} />
        </Badge>
      ) : pinned !== true &&
        averageStandardMetrics.includes(key) &&
        ['quality', 'roi_during'].includes(key) &&
        (data <= averageStandards[key] || data == 0) ? (
        <Badge bg={'danger'}>
          <FontAwesomeIcon icon={faArrowDown} />
        </Badge>
      ) : pinned !== true &&
        averageStandardMetrics.includes(key) &&
        ['quality', 'roi_during'].includes(key) &&
        (data >= averageStandards[key] || data == 0) ? (
        <Badge bg={'success'}>
          <FontAwesomeIcon icon={faArrowUp} />
        </Badge>
      ) : null

    if (key == 'qualified') {
      return (
        <>
          <p style={{ lineHeight: '1.8' }}>
            {data} {indicator}
            {!pinned && (
              <>
                <br></br>
                <Badge bg='success' style={{ fontSize: '9.5px' }}>
                  PS - {params.data['pressure_sell']}
                </Badge>{' '}
                <Badge bg='success' style={{ fontSize: '9.5px' }}>
                  UHL - {params.data['ultra_hot']}
                </Badge>
                <br></br>
                <Badge bg='primary' style={{ fontSize: '9.5px' }}>
                  Nurture - {params.data['nurturing']}
                </Badge>
                <Badge
                  bg='#fad4cd'
                  style={{
                    fontSize: '9.5px',
                    background: '#fad4cd',
                    color: 'black'
                  }}
                >
                  Lost - {params.data['lead_lost']}
                </Badge>
              </>
            )}
          </p>
        </>
      )
    } else if (key == 'leads') {
      return (
        <>
          <p style={{ lineHeight: '1.8' }}>
            {data} {indicator}
            {!pinned && (
              <>
                <br></br>
                <Badge
                  bg='#fad4cd'
                  style={{
                    fontSize: '9.5px',
                    background: '#fad4cd',
                    color: 'black'
                  }}
                >
                  Invalid - {params.data['invalid']}
                </Badge>{' '}
                <Badge
                  bg='#fad4cd'
                  style={{
                    fontSize: '9.5px',
                    background: '#fad4cd',
                    color: 'black'
                  }}
                >
                  BB - {params.data['backburners']}
                </Badge>
                <br></br>
                <Badge bg='light' style={{ fontSize: '9.5px' }}>
                  Direct - {params.data['direct_leads']}
                </Badge>
                <Badge
                  bg='light'
                  style={{
                    fontSize: '9.5px'
                    // background: '#fad4cd',
                    // color: 'black'
                  }}
                >
                  Indirect - {params.data['indirect_leads']}
                </Badge>
              </>
            )}
          </p>
        </>
      )
    } else if (
      key.includes('cp') ||
      key.includes('cost') ||
      key.includes('spend') ||
      key.includes('budget') ||
      key.includes('revenue')
    ) {
      return (
        <p>
          {data !== Number.POSITIVE_INFINITY &&
          data !== Number.NEGATIVE_INFINITY
            ? '₹' + data.toLocaleString('en-IN')
            : data}{' '}
          {indicator}
        </p>
      )
    } else if (key.includes('roi') || key.includes('quality')) {
      return (
        <p>
          {data !== Number.POSITIVE_INFINITY &&
          data !== Number.NEGATIVE_INFINITY
            ? data.toLocaleString('en-IN') + '%'
            : data}{' '}
          {indicator}
        </p>
      )
    } else if (key.includes('_name')) {
      return (
        <p style={{ lineHeight: '1.8' }}>
          {data} <br></br>
          {!pinned && (
            <>
              <Badge
                bg='light'
                style={{
                  fontSize: '9.5px'
                }}
                onClick={() => {
                  showAdPreview(row['ad_id'], previewPlacement)
                  setPreviewedAdID(row['ad_id'])
                }}
              >
                <FontAwesomeIcon icon={faEye}></FontAwesomeIcon>
              </Badge>
              <br></br>
              <Badge
                bg='dark'
                style={{
                  fontSize: '9.5px'
                }}
              >
                #{row['adset_name']}
              </Badge>{' '}
              <Badge
                bg='dark'
                style={{
                  fontSize: '9.5px'
                }}
              >
                #{row['campaign_name']}
              </Badge>
              <br></br>
              <Badge
                bg='dark'
                style={{
                  fontSize: '9.5px'
                }}
              >
                #{row['theme']}
              </Badge>
            </>
          )}
        </p>
      )
    } else if (key == 'theme') {
      return <p>{data}</p>
    } else {
      return (
        <p>
          {data !== Number.POSITIVE_INFINITY &&
          data !== Number.NEGATIVE_INFINITY
            ? data.toLocaleString('en-IN')
            : data}{' '}
          {indicator}
        </p>
      )
    }
  }

  useEffect(() => {
    if (postMoveHeaders.length <= 5) {
      setPostMoveHeaders(headers)
    }
  }, [data, headers])

  function showAdPreview (adID, placement) {
    console.log('fetching preview...')
    axios
      .get(
        'https://graph.facebook.com/v17.0/' +
          adID.toString() +
          '/previews?ad_format=' +
          placement,
        {
          params: {
            access_token:
              'EAADfl4bMMZAYBOzcL8hGvpD1tKyjux9wBnmts3P9vndZCILjDm7t7QS0OLttf75tUZB0OBznDLh7CFWzOQp3oVtcx4NhpbfZCXpDKwx9esnh5NSXjZBSNtGg8xaPbNetOw0i1VxyhBCZANZCp9Ro0XRzQri4V9R6gXMIxpXpcTR1KzZA2s4PZAsiJQBui'
          }
        }
      )
      .then(response => {
        const htmlString = response.data.data[0].body
        console.log(htmlString)
        setPreviewHTML(htmlString)
        setShowPreviewModal(true)
      })
      .catch(error => {
        console.log(error)
        alert('failed to fetch preview')
      })
  }
  const onColumnMoved = useCallback(
    params => {
      const newColumnState = params.columnApi.getColumnState()
      const newColumnDefs = newColumnState.map(colState => {
        const colDef = headers.find(col => col.field === colState.colId)
        return {
          ...colDef,
          pinned: colState.pinned,
          sort: colState.sort,
          sortIndex: colState.sortIndex,
          hide: colState.hide,
          width: colState.width
        }
      })

      setPostMoveHeaders(newColumnDefs)
      setColumnOrder(newColumnState.map(state => state.colId))
    },
    [headers]
  )

  function handleSelect (ranges) {
    console.log(ranges)
    // {
    //   selection: {
    //     startDate: [native Date Object],
    //     endDate: [native Date Object],
    //   }
    // }
  }

  //   useEffect(() => {
  //     // Increment key to force re-render of modal when previewHTML changes
  //     setKey(prevKey => prevKey + 1)
  //   }, [previewHTML])

  return (
    <div className='h-[50rem] w-full dark:bg-black bg-white  dark:bg-dot-white/[0.2] bg-dot-black/[0.2] relative flex items-center justify-center'>
      {/* Radial gradient for the container to give a faded look */}
      <div className='absolute pointer-events-none inset-0 flex items-center justify-center dark:bg-black bg-white [mask-image:radial-gradient(ellipse_at_center,transparent_20%,black)]'></div>
      <div className='w-screen bg-opacity-0'>
        <Navbar
          fixed='top'
          className='justify-content-between'
          style={{ borderWidth: 0 }}
        >
          <div className='mr-3 ml-3 flex mt-2 justify-between items-center w-screen'>
            <Col sm='0'>
              <Form.Control
                disabled={isFetching}
                defaultValue={start}
                onChange={handleStartDate}
                type='date'
                size='md'
              />
            </Col>
            <Col sm='0'>
              <Form.Control
                disabled={isFetching}
                defaultValue={start}
                onChange={handleEndDate}
                type='date'
                size='md'
              />
            </Col>
            <Col sm='0'></Col>
            {dataIsFor !== 'theme' && (
              <>
                <Dropdown>
                  <Dropdown.Toggle
                    style={{
                      backgroundColor: '#475569',
                      borderColor: '#475569',
                      textTransform: 'lowercase'
                    }}
                    className='font-normal bg-slate-200 border-slate-200'
                  >
                    {statusFilter}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      disabled={isFetching}
                      onClick={e => setStatusFilter(e.target.text)}
                    >
                      All Status
                    </Dropdown.Item>
                    <Dropdown.Item
                      disabled={isFetching}
                      onClick={e => setStatusFilter(e.target.text)}
                    >
                      Active
                    </Dropdown.Item>
                    <Dropdown.Item
                      disabled={isFetching}
                      onClick={e => setStatusFilter(e.target.text)}
                    >
                      Inactive
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {campaignFilters && campaignFilters.length > 0 && (
                  <Dropdown>
                    <Dropdown.Toggle
                      style={{
                        backgroundColor: '#475569',
                        borderColor: '#475569',
                        textTransform: 'lowercase'
                      }}
                      className='font-normal bg-slate-200 border-slate-200'
                    >
                      {campaignFilter}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        disabled={isFetching}
                        onClick={e => setCampaignFilter(e.target.text)}
                      >
                        All Campaigns
                      </Dropdown.Item>
                      {campaignFilters.map(campaign => {
                        return (
                          <Dropdown.Item
                            disabled={isFetching}
                            onClick={e => setCampaignFilter(e.target.text)}
                          >
                            {campaign}
                          </Dropdown.Item>
                        )
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </>
            )}

            <h4 className='text-3xl text-slate-600 font-bold mx-auto'>
              Instagram Boosting
            </h4>

            <Dropdown>
              <Dropdown.Toggle
                style={{
                  backgroundColor: '#475569',
                  borderColor: '#475569',
                  textTransform: 'lowercase'
                }}
                className='font-normal bg-slate-200 border-slate-200'
              >
                {selectedView}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  disabled={isFetching}
                  onClick={e => setSelectedView(e.target.text)}
                >
                  Theme
                </Dropdown.Item>
                <Dropdown.Item
                  disabled={isFetching}
                  onClick={e => setSelectedView(e.target.text)}
                >
                  Ad
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown>
              <Dropdown.Toggle
                style={{
                  backgroundColor: '#475569',
                  borderColor: '#475569',
                  textTransform: 'lowercase'
                }}
                className='font-normal bg-slate-200 border-slate-200'
              >
                {selectedLeadType}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  disabled={isFetching}
                  onClick={e => setSelectedLeadType(e.target.text)}
                >
                  CPA
                </Dropdown.Item>
                <Dropdown.Item
                  disabled={isFetching}
                  onClick={e => setSelectedLeadType(e.target.text)}
                >
                  Premium
                </Dropdown.Item>
                <Dropdown.Item
                  disabled={isFetching}
                  onClick={e => setSelectedLeadType(e.target.text)}
                >
                  Premium + CPA
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Button className='' variant='secondary' size='md'>
              <CSVLink
                filename='instagram-boosting-analytics-table.csv'
                data={data.concat(pinnedRowData)}
              >
                <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
              </CSVLink>
            </Button>
            <Button
              className=''
              size='md'
              id='toggle-check'
              variant='danger'
              onClick={() =>
                handleGoButtonClick(
                  selectedView,
                  selectedLeadType,
                  startDate,
                  endDate
                )
              }
              disabled={isFetching}
            >
              Go
            </Button>
          </div>
        </Navbar>
        <Modal
          show={showPreviewModal}
          onHide={() => setShowPreviewModal(false)}
          centered
          size='lg'
        >
          <Modal.Body>
            <div className='row'>
              <div className='placement-tabs-container'>
                <Tabs
                  defaultActiveKey='DESKTOP_FEED_STANDARD'
                  className='mb-3'
                  fill
                  activeKey={previewPlacement}
                  onSelect={e => {
                    showAdPreview(previewedAdID, e)
                    setPreviewPlacement(e)
                  }}
                >
                  <Tab
                    eventKey='DESKTOP_FEED_STANDARD'
                    title={'DESKTOP_FEED_STANDARD'}
                  />
                  <Tab
                    eventKey='MOBILE_FEED_STANDARD'
                    title={'MOBILE_FEED_STANDARD'}
                  ></Tab>
                  <Tab
                    eventKey='RIGHT_COLUMN_STANDARD'
                    title={'RIGHT_COLUMN_STANDARD'}
                  ></Tab>

                  <Tab
                    eventKey='INSTAGRAM_STANDARD'
                    title={'INSTAGRAM_STANDARD'}
                  ></Tab>
                  <Tab
                    eventKey='INSTAGRAM_STORY'
                    title={'INSTAGRAM_STORY'}
                  ></Tab>
                  <Tab
                    eventKey='INSTAGRAM_REELS'
                    title={'INSTAGRAM_REELS'}
                  ></Tab>
                </Tabs>
              </div>
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: previewHTML }}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                width: '100%'
              }}
            ></div>
          </Modal.Body>
        </Modal>
        <div
          style={{
            marginTop: '75px',
            minWidth: '100%'
          }}
          className='public-youtube-report-primary-table-body-container'
        >
          {!isFetching && !isServerError && data && data.length > 0 ? (
            <div style={gridStyle} className={'ag-theme-quartz'}>
              <AgGridReact
                ref={gridRef}
                rowData={data}
                columnDefs={postMoveHeaders}
                defaultColDef={defaultColDef}
                rowDragManaged={true}
                rowSelection='multiple'
                rowDragMultiRow={true}
                rowStyle={rowStyle}
                getRowStyle={getRowStyle}
                onFilterChanged={onFilterChanged}
                pinnedBottomRowData={[totals]}
                maintainColumnOrder={true}
                onColumnMoved={onColumnMoved}
              />
            </div>
          ) : isFetching ? (
            <MultiStepLoader
              className='bg-opacity-0'
              loadingStates={loadingStates}
              loading={isFetching}
              duration={750}
            ></MultiStepLoader>
          ) : isServerError ? (
            <div className='flex justify-center items-center text-center h-full'>
              <TypewriterEffect
                className='text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-400 to-neutral-500 py-8'
                words={"Server returned no data, maybe calls haven't started yet?"
                  .split(' ')
                  .map(word => {
                    return { text: word }
                  })}
              ></TypewriterEffect>
            </div>
          ) : (
            <div className='flex justify-center items-center text-center h-full'>
              <TypewriterEffect
                className='text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-400 to-neutral-500 py-8'
                words={'Hit "GO" and start analyzing!'.split(' ').map(word => {
                  return { text: word }
                })}
              ></TypewriterEffect>
            </div>
          )}
        </div>
        <div className='App'>
          <Widget
            handleNewUserMessage={handleNewUserMessage}
            isWidgetOpened={true}
            title={chatboxTitle}
            subtitle={chatboxSubtitle}
            // handleToggle={handleChatToggle}
            profileAvatar={userImage}
            profileClientAvatar={
              'https://w0.peakpx.com/wallpaper/471/473/HD-wallpaper-daffy-duck-black-funny-duck-daffy-thumbnail.jpg'
            }
            emojis={true}
            resizable={true}
          />
        </div>
      </div>
    </div>
  )
}

export default InstagramBoostingAnalytics
