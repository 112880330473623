import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBarsProgress } from '@fortawesome/free-solid-svg-icons'
import { useState, useRef, useEffect } from 'react'
import AtomicSpinner from 'atomic-spinner'
function LoadingMessage () {
  //   const generateFood = () => {
  //     return {
  //       x: Math.floor(Math.random() * 30), // Adjusted for a wider canvas
  //       y: Math.floor(Math.random() * 20),
  //     };
  //   };
  //   const initialSnake = [
  //     { x: 10, y: 10 },
  //     { x: 9, y: 10 },
  //     { x: 7, y: 10 },
  //     { x: 6, y: 10 },
  //     { x: 5, y: 10 },
  //   ];
  //   const initialFood = generateFood();

  //   const [snake, setSnake] = useState(initialSnake);
  //   const [food, setFood] = useState(initialFood);
  //   const [direction, setDirection] = useState('RIGHT');
  //   const [isGameOver, setIsGameOver] = useState(false);
  //   const [score, setScore] = useState(0);
  //   const [level, setLevel] = useState(1);

  //   const canvasRef = useRef(null);

  //   const gameSpeed = 30; // in milliseconds

  //   const drawSnake = () => {
  //     const canvas = canvasRef.current;
  //     const ctx = canvas.getContext('2d');
  //     ctx.clearRect(0, 0, canvas.width, canvas.height);

  //     snake.forEach((segment) => {
  //       ctx.fillStyle = 'green';
  //       ctx.fillRect(segment.x * 20, segment.y * 20, 20, 20);
  //     });

  //     ctx.fillStyle = 'red';
  //     ctx.fillRect(food.x * 20, food.y * 20, 20, 20);

  //     // Display score and level
  //     ctx.fillStyle = 'black';
  //     ctx.font = '20px Arial';
  //     ctx.fillText(`Score: ${score}`, 10, 20);
  //     ctx.fillText(`Level: ${level}`, 10, 40);
  //   };

  //   const moveSnake = () => {
  //     if (!isGameOver) {
  //       const newSnake = snake.map((segment) => ({ ...segment }));
  //       const head = { ...newSnake[0] };

  //       switch (direction) {
  //         case 'UP':
  //           head.y -= 1;
  //           break;
  //         case 'DOWN':
  //           head.y += 1;
  //           break;
  //         case 'LEFT':
  //           head.x -= 1;
  //           break;
  //         case 'RIGHT':
  //           head.x += 1;
  //           break;
  //         default:
  //           break;
  //       }

  //       newSnake.unshift(head);

  //       // Check if the snake eats the food
  //       if (head.x === food.x && head.y === food.y) {
  //         setFood(generateFood());
  //         setScore(score + 1);

  //         // Increase level every 5 points
  //         if ((score + 1) % 5 === 0) {
  //           setLevel(level + 1);
  //         }

  //         // Append a new segment to the snake to make it longer
  //         const newTail = { x: -1, y: -1 }; // Use any out-of-bounds position
  //         newSnake.push(newTail);
  //       } else {
  //         newSnake.pop();
  //       }

  //       checkCollision(newSnake);
  //       setSnake(newSnake);
  //     }
  //   };

  //   const checkCollision = (snake) => {
  //     const head = snake[0];

  //     // Check wall collision
  //     if (head.x < 0 || head.x >= window.innerWidth / 20 || head.y < 0 || head.y >= (window.innerHeight - 170) / 20) { // Adjusted for a wider canvas
  //       setIsGameOver(true);
  //     }

  //     // Check self-collision
  //     for (let i = 1; i < snake.length; i++) {
  //       if (head.x === snake[i].x && head.y === snake[i].y) {
  //         setIsGameOver(true);
  //       }
  //     }
  //   };

  //   const handleKeyPress = (e) => {
  //     switch (e.key) {
  //       case 'ArrowUp':
  //         setDirection('UP');
  //         break;
  //       case 'ArrowDown':
  //         setDirection('DOWN');
  //         break;
  //       case 'ArrowLeft':
  //         setDirection('LEFT');
  //         break;
  //       case 'ArrowRight':
  //         setDirection('RIGHT');
  //         break;
  //       default:
  //         break;
  //     }
  //   };

  //   const restartGame = () => {
  //     setSnake(initialSnake);
  //     setFood(initialFood);
  //     setDirection('RIGHT');
  //     setIsGameOver(false);
  //     setScore(0);
  //     setLevel(1);
  //   };

  //   useEffect(() => {
  //     const intervalId = setInterval(moveSnake, gameSpeed);

  //     document.addEventListener('keydown', handleKeyPress);

  //     return () => {
  //       clearInterval(intervalId);
  //       document.removeEventListener('keydown', handleKeyPress);
  //     };
  //   }, [snake, isGameOver]);

  //   useEffect(() => {
  //     drawSnake();
  //   }, [snake, food, isGameOver, score, level]);

  //   return (
  //     <div style={{flex: "1",
  //     // backgroundColor: "#f0f0f0",
  //     padding: "0px",
  //   minHeight: "100VH" }}
  //   >
  //   {isGameOver && (
  //       <div style={{ textAlign: 'center' }}>
  //         <p style={{ fontSize: '20px', color: 'red' }}>Game Over!</p>
  //         <button onClick={restartGame}>Restart</button>
  //       </div>
  //     )}
  //       <canvas ref={canvasRef} width={window.innerWidth} height={window.innerHeight - 170} style={{ border: '1px solid grey',
  //       // margin: 'auto', display: 'block', minWidth: "100%", maxHeight: "100%",
  //       flex: "1",
  //       backgroundColor: "#f0f0f0",
  //       padding: "0px" }} />
  //     </div>
  //   );
  // };
  return (
    // <div
    //   style={{
    //     display: 'flex',
    //     justifyContent: 'center',
    //     height: '100VH',
    //     marginTop: '-15VH',
    //     width: '110VW',
    //     backgroundColor: '#f7f7f7'
    //   }}
    // >
    <div className='h-[50rem] w-screen w-full dark:bg-black bg-white  dark:bg-grid-white/[0.2] bg-grid-black/[0.2] relative flex items-center justify-center'>
      {/* Radial gradient for the container to give a faded look */}
      <div className='absolute w-screen pointer-events-none inset-0 flex items-center justify-center dark:bg-black bg-white [mask-image:radial-gradient(ellipse_at_center,transparent_20%,black)]'></div>
      <AtomicSpinner
        electronColorPalette={['#404040', '#737373', '#A6A6A6']}
      ></AtomicSpinner>
      {/* </Spinner> */}
      {/* </body> */}
    </div>
    // </div>
  )
}

export default LoadingMessage
