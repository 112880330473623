import { useParams } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Navbar } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "../css/PerformancePublicAlltimeAdStats.css";



function PerformanceAdDetailedReportScreen({ APIEndpointDomain }) {
  const { id } = useParams();
  const [adName, setAdName] = useState("");
  const [allTimeStats, setAllTimeStats] = useState([]);
  var [selectedLevel, setSelectedLevel] = useState("Preview");
  const [tableData, setTableData] = useState([]);
  var [previewHTML, setPreviewHTML] = useState(null);
  var [previewPlacement, setPreviewPlacement] = useState(
    "DESKTOP_FEED_STANDARD"
  );

  function levelSelector(level) {
    setSelectedLevel(level);

    if (level.toLowerCase() === "cpa") {
      setTableData(allTimeStats["cpa_all_time_stats"]);
    } else if (level.toLowerCase() === "premium") {
      setTableData(allTimeStats["premium_all_time_stats"]);
    }
  }

  useEffect(() => {
    changePreviewPlacement(previewPlacement);
    axios
      .get(APIEndpointDomain + "/performance/get-alltime-stats", {
        params: {
          ad_id: id,
          is_public: true,
        },
        headers: {
          "ngrok-skip-browser-warning": "any",
          "Access-Control-Allow-Origin": "*",
          //   Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        setAdName(response.data["ad_name"]);
        console.log(response);
        const all_time_stats = response.data["all_time_stats"];
        setAllTimeStats(all_time_stats);
        setTableData(all_time_stats["premium_all_time_stats"]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  function changePreviewPlacement(placement) {
    setPreviewPlacement(placement);

    axios
      .get(
        "https://graph.facebook.com/v17.0/" +
          id.toString() +
          "/previews?ad_format=" +
          placement,
        {
          params: {
            access_token:
              "EAADfl4bMMZAYBO3goK189PfQ4KubSSY9sUergCOplOoOo724YrjVRhYFo5EcuBKmnMNsmr4YXvpOeCeYZApsaxkdZARfdjZCdS3PuZAY3ZAyLSbecEcEZBH8ZA6o1oqlYVg2xKE3b9RcmZAeZAZCRox0iE24ZCvaOR1GU4w32aQ0lnFAsdB3QZCC3YbQpP6CW0yCMsTnL",
          },
        }
      )
      .then((response) => {
        const htmlString = response.data.data[0].body;
        setPreviewHTML(htmlString);
        console.log(response)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div style={{ backgroundColor: "#f0f1f2" }}>
      <Navbar
        fixed="top"
        className="justify-content-center"
        style={{
          borderColor: "#f0f1f2",
          backgroundColor: "#f0f1f2",
        }}
      >
        <div
          style={{
            position: "relative",
            margin: "auto",
            width: "50%",
            // border: "3px solid green",
            // padding: "10px",
            backgroundColor: "gray",
            color: "white",
            textAlign: "center",
          }}
        >
          <h2>
            {adName} - {selectedLevel}
          </h2>
        </div>
        <div className="level-tabs-container">
          <Tabs
            defaultActiveKey="Preview"
            className="mb-0"
            fill
            activeKey={selectedLevel}
            onSelect={(e) => levelSelector(e)}
          >
            <Tab eventKey="Premium" title={"Premium"} />
            <Tab eventKey="CPA" title={"CPA"}></Tab>
            <Tab eventKey="Preview" title={"Preview"}></Tab>
          </Tabs>
        </div>
      </Navbar>

      {selectedLevel !== "Preview" ? (
        <div className="public-alltime-ad-report-primary-table-body-container">
          <Table
            responsive="xl"
            striped
            hover
            size="md"
            className="fixed-table"
            style={{flex: "1"}}
            // variant="dark"
          >
            <tbody>
              {Object.keys(tableData).map((key) => {
                return (
                  <tr>
                    <td
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {key}
                    </td>
                    <td
                      style={{
                        textAlign: "left",
                      }}
                    >
                      {Math.round(tableData[key])}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      ) : (
        <>
          <div className="public-alltime-ad-report-primary-table-body-container">
            <div className="row">
              <div className="placement-tabs-container">
                <Tabs
                  defaultActiveKey="DESKTOP_FEED_STANDARD"
                  className="mb-3"
                  fill
                  activeKey={previewPlacement}
                  onSelect={(e) => {
                    changePreviewPlacement(e);
                  }}
                >
                  <Tab
                    eventKey="DESKTOP_FEED_STANDARD"
                    title={"DESKTOP_FEED_STANDARD"}
                  />
                  <Tab
                    eventKey="MOBILE_FEED_STANDARD"
                    title={"MOBILE_FEED_STANDARD"}
                  ></Tab>
                  <Tab
                    eventKey="RIGHT_COLUMN_STANDARD"
                    title={"RIGHT_COLUMN_STANDARD"}
                  ></Tab>

                  <Tab
                    eventKey="INSTAGRAM_STANDARD"
                    title={"INSTAGRAM_STANDARD"}
                  ></Tab>
                  <Tab
                    eventKey="INSTAGRAM_STORY"
                    title={"INSTAGRAM_STORY"}
                  ></Tab>
                  <Tab
                    eventKey="INSTAGRAM_REELS"
                    title={"INSTAGRAM_REELS"}
                  ></Tab>
                </Tabs>
              </div>
            </div>
            <div>
              <div
                style={{
                  position: "relative",
                  margin: "auto",
                  width: "50%",
                  color: "white",
                  textAlign: "center",
                }}
                dangerouslySetInnerHTML={{ __html: previewHTML }}
              ></div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default PerformanceAdDetailedReportScreen;
