import axios from 'axios'
import { useEffect, useState, useMemo } from 'react'
import '../css/YouTubeVideosScreen.css'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css' // Core CSS
import 'ag-grid-community/styles/ag-theme-quartz.css' // Theme
import { Navbar } from 'react-bootstrap'
import Dropdown from 'react-bootstrap/Dropdown'
import LoadingMessage from '../components/CommonSubComponents/LoadingMessage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'react-bootstrap'
import ServerReturnedNoDataMessage from '../components/CommonSubComponents/ServerReturnedNoDataMessage'

function ClientsScreen ({ checkAuthenticationInResponse, APIEndpointDomain }) {
  var [selectedLeadType, setSelectedLeadType] = useState('Premium')
  var [data, setData] = useState([])

  var [isFetchingClients, setIsFetchingClients] = useState(false)
  var [isServerError, setIsServerError] = useState(false)

  function getClients (selectedLeadType) {
    setIsFetchingClients(true)
    axios
      .get(APIEndpointDomain + '/marketing/generate-clients-view', {
        params: {
          lead_type: selectedLeadType.toLowerCase()
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        console.log(response)
        setData(response.data['data'])

        setIsFetchingClients(false)
        setIsServerError(false)
      })
      .catch(error => {
        console.log(error)
        checkAuthenticationInResponse(Number(error.response.status))

        setData([])
        setIsFetchingClients(false)
        setIsServerError(true)
      })
  }

  useEffect(() => {
    getClients(selectedLeadType)
  }, [selectedLeadType])

  const defaultColDef = useMemo(() => {
    return {
      //   height: '300px',
      filter: true,
      cellStyle: { fontSize: '14px', textAlign: 'center' }
    }
  }, [])

  const gridStyle = useMemo(
    () => ({ height: '100%', width: '100%', backgroundColor: '#e8ebe9' }),
    []
  )

  const rowStyle = { background: '#eaeaea' }

  const getRowStyle = params => {
    if (params.node.rowIndex % 2 === 0) {
      return { background: '#f6f6f6' }
    }
  }

  function cellRenderer (params) {
    var key = params.colDef['field']
    var data = params.data[key]

    if (key === 'initial_source') {
      console.log(data)
    }

    if (['stage_updated_at', 'created_at'].includes(key)) {
      return <p>{data.split('T')[0]}</p>
    }

    if (key.includes('initial_source')) {
      return (
        <p>
          Source: {data['utm_source'] ? data['utm_source'] : 'None'}; Medium:{' '}
          {data['utm_medium'] ? data['utm_medium'] : 'None'}; Campaign:{' '}
          {data['utm_campaign'] ? data['utm_campaign'] : 'None'}; Term:{' '}
          {data['utm_term'] ? data['utm_term'] : 'None'}; Content:{' '}
          {data['utm_content'] ? data['utm_content'] : 'None'};
          Referring_domain:{' '}
          {data['referring_domain'] ? data['referring_domain'] : 'None'}
        </p>
      )
    } else {
      return <p>{data}</p>
    }
  }

  var headers =
    data.length > 0 && data
      ? Object.keys(data[0]).map(key => {
          if (['created_at', 'stage_updated_at'].includes(key)) {
            return {
              headerName: String(key).replace(/_/g, ' '),
              field: key,
              filter: 'agDateColumnFilter',
              floatingFilter: true,
              cellRenderer: cellRenderer
              // width: 150
              // autoWidth: true
              // height: 300
            }
          } else if (key == 'amount_sold') {
            return {
              headerName: String(key).replace(/_/g, ' '),
              field: key,
              filter: 'agNumberColumnFilter',
              floatingFilter: true,
              cellRenderer: cellRenderer
            }
          } else {
            return {
              headerName: String(key).replace('_', ' '),
              field: key,
              filter: 'agTextColumnFilter',
              floatingFilter: true,
              cellRenderer: cellRenderer
            }
          }
        })
      : []

  return (
    <div>
      <Navbar
        fixed='top'
        className='justify-content-between'
        style={{ borderWidth: 0 }}
      >
        <div
          className='row'
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '60px',
            marginLeft: '20px'
          }}
        >
          <Button variant='dark' onClick={() => getClients(selectedLeadType)}>
            <FontAwesomeIcon icon={faArrowsRotate}></FontAwesomeIcon>
          </Button>
        </div>

        <div
          className='row'
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '60px'
          }}
        >
          <h3>
            <strong
              style={{
                color: '#333',
                marginLeft: '20px'
              }}
            >
              #ClientsLiveTracker
            </strong>
          </h3>
        </div>
        <div
          className='row'
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '60px',
            marginRight: '20px'
          }}
        >
          <Dropdown>
            <Dropdown.Toggle variant='dark' id='dropdown-basic'>
              {selectedLeadType}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                disabled={isFetchingClients}
                onClick={e => setSelectedLeadType(e.target.text)}
              >
                CPA
              </Dropdown.Item>
              <Dropdown.Item
                disabled={isFetchingClients}
                onClick={e => setSelectedLeadType(e.target.text)}
              >
                Premium
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Navbar>
      <div
        style={{
          marginTop: '75px',
          minWidth: '100%'
        }}
        className='public-youtube-report-primary-table-body-container'
      >
        {!isFetchingClients && !isServerError ? (
          <div style={gridStyle} className={'ag-theme-quartz'}>
            <AgGridReact
              rowData={data}
              columnDefs={headers}
              defaultColDef={defaultColDef}
              rowDragManaged={true}
              rowSelection='multiple'
              rowDragMultiRow={true}
              rowStyle={rowStyle}
              getRowStyle={getRowStyle}
              // pinnedTopRowData={}
            />
          </div>
        ) : isFetchingClients ? (
          <LoadingMessage></LoadingMessage>
        ) : (
          <ServerReturnedNoDataMessage></ServerReturnedNoDataMessage>
        )}
      </div>
    </div>
  )
}

export default ClientsScreen
