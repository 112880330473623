import { useState } from 'react'
import { Button, Box } from '@mui/material'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { parse } from 'csv-parse/browser/esm/sync'

function EmailUploader ({ setCSVData }) {
  const [filename, setFilename] = useState('')

  const handleFileUpload = e => {
    if (!e.target.files) {
      return
    }
    const file = e.target.files[0]
    const { name } = file
    setFilename(name)

    const reader = new FileReader()
    reader.onload = evt => {
      if (!evt?.target?.result) {
        return
      }
      const { result } = evt.target
      const records = parse(result, {
        columns: ['email'],
        delimiter: ',',
        trim: true,
        skip_empty_lines: true
      })
      setCSVData(records.map(row => row.email.trim()))
    }
    reader.readAsBinaryString(file)
  }

  return (
    <>
      <Button
        size='small'
        className='px-4 rounded-md border border-gray-700 bg-white text-black text-sm hover:shadow-[4px_4px_0px_0px_rgba(0,0,0)] transition duration-200'
        component='label'
      >
        Upload
        <input type='file' accept='.csv' hidden onChange={handleFileUpload} />
      </Button>

      <Box>{filename}</Box>
    </>
  )
}

export default EmailUploader
