import Modal from "react-bootstrap/Modal";
import { Table } from "react-bootstrap";
import React from "react";

function DeleteReasonModal({
  showDeletionModal,
  setShowDeletionModal,
  DeletionData,
}) {
  return (
    <div>
      <Modal size="lg" show={showDeletionModal}>
        <Modal.Header closeButton onClick={() => setShowDeletionModal(false)}>
          <Modal.Title id="contained-modal-title-vcenter">
            Delete Reasons
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table
            responsive="sm"
            striped
            hover
            size="sm"
            className="fixed-table"
            variant="dark"
          >
            <thead>
              <tr>
                <td>Reason</td>
                <td>Leads</td>
              </tr>
            </thead>
            {DeletionData ? (
              <tbody>
                {DeletionData.map((row, index) => {
                  return (
                    <tr>
                      <td key={index}>{row.delete_reason}</td>
                      <td>{row.count}</td>
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              <tr>
                <td>
                  <h3>Loading...</h3>
                </td>
              </tr>
            )}
          </Table>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default DeleteReasonModal;
