import { useParams } from 'react-router-dom'
import axios from 'axios'
import { useEffect, useState, useMemo } from 'react'
import '../css/PerformanceCustomReportScreen.css'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css' // Core CSS
import 'ag-grid-community/styles/ag-theme-quartz.css' // Theme
import Badge from 'react-bootstrap/Badge'

function PerformanceCustomReportScreen ({ APIEndpointDomain }) {
  const { id } = useParams()

  const [favorites, setFavorites] = useState([])

  const stringColumns = [
    'campaign_name',
    'adset_name',
    'ad_name',
    'name',
    'message'
  ]

  // Metric definitions

  var removableColumns = [
    'campaign_id',
    // "adset_id",
    'ad_id',
    'campaign_name',
    'adset_name',
    'ad_name',
    'type',
    'status',
    'name',
    'message',
    'level',
    'start_date',
    'end_date',
    'deleted',
    'backburners',
    'no_show',
    'dnp',
    'pressure_sell',
    'ultra_hot',
    'lead_lost',
    'nurturing',
    'sales_usa_cpa',
    'sales_usa_pre',
    'sales_uk_cpa',
    'h_good_usa_cpa',
    'h_good_usa_pre',
    'h_good_uk_cpa',
    'acquired_during_uk_cpa',
    'acquired_during_usa_cpa',
    'acquired_during_usa_pre'
  ]

  var percentageMetrics = [
    'acquisition_loss_rate',
    'acquisition_validation_rate',
    'acquisitions_pending_validation_p',
    'good_percentage',
    'pending_p',
    'pressure_to_acquisition',
    'projected_roi',
    'quality',
    'roi',
    'uhl_to_acquisition',
    'roi_during',
    'h_good_percentage',
    'lead_to_h_good_percentage',
    'ctr',
    'hook_rate',
    'hold_rate',
    'view_to_click',
    'click_to_lead',
    'fifteen_to_25p',
    'twenty_five_p_to_50p',
    'fifty_p_to_75p'
  ]

  var currencyMetrics = [
    'cpaa',
    'premium_ms_revenue',
    'avg_purchase',
    'budget',
    'cpa',
    'cpgl',
    'cpl',
    'cpql',
    'projected_cpa',
    'revenue',
    'total_revenue',
    'upfront_payments',
    'cpaa_during',
    'premium_ms_revenue_during',
    'admit_up_rev_dur',
    'cpa_during',
    'revenue_during',
    'total_revenue_during',
    'upfront_payments_during',
    'cpgl_historic',
    'h_cpa',
    'spend',
    'cpm',
    'cost_per_thruplay',
    'cpc',
    '5_day_cpl',
    '5_day_cpql',
    '7_day_cpgl_h',
    '12_day_cpuhl_h',
    '22_day_cpa',
    'adset_budget'
  ]

  useEffect(() => {
    axios
      .get(APIEndpointDomain + '/performance/get-custom-report', {
        params: {
          name: id,
          is_public: true
        },
        headers: {
          'ngrok-skip-browser-warning': 'any',
          'Access-Control-Allow-Origin': '*',
          //   Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        console.log(response)
        const favorites = response.data['custom_report']
        setFavorites(favorites)
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  //
  const rowData = favorites.map(favorite => favorite['favorites'])

  //

  var headers =
    favorites.length > 0
      ? Object.keys(favorites[0]['favorites']).filter(
          header => !removableColumns.includes(header)
        )
      : []

  var headers2 = headers.map(header => ({
    field: header,
    filter: !stringColumns.includes(header)
      ? 'agNumberColumnFilter'
      : 'agTextColumnFilter',
    floatingFilter: true,
    cellRenderer,
    cellRenderer
  }))

  var headers3 = [
    {
      field: 'name',
      rowDrag: true,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      filterParams: {
        caseSensitive: false,
        defaultOption: 'contains'
      },
      cellRenderer: NameCellRenderer,
      wrapText: false,
      autoHeight: true,
      width: 300,
      pinned: 'left'
    },
    {
      field: 'message',
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      filterParams: {
        caseSensitive: false,
        defaultOption: 'contains'
      },
      editable: true,
      cellEditor: 'agTextCellEditor',
      cellEditorParams: {
        maxLength: 10000
      },
      wrapText: true,
      autoHeight: true,
      pinned: 'left'
    },

    ...headers2
  ]

  //
  const defaultColDef = useMemo(() => {
    return {
      width: 170,
      filter: true,
      cellStyle: { fontSize: '14px', textAlign: 'center' }
    }
  }, [])

  function NameCellRenderer (params) {
    const { data } = params

    return (
      <>
        <p style={{ lineHeight: '1.8' }}>
          {data['name']}
          <br></br>
          {data['start_date'] ? (
            <>
              <Badge bg={'dark'} style={{ marginTop: '0px' }}>
                {data['start_date'] + ' TO ' + data['end_date']}
              </Badge>
              <br></br>
            </>
          ) : null}
          {data['campaign_name'] ? (
            <Badge style={{ backgroundColor: '#0e62b0', marginTop: '5px' }}>
              {'#' + data['campaign_name']}
            </Badge>
          ) : null}
          {data['adset_name'] ? (
            <Badge style={{ backgroundColor: '#0e62b0' }}>
              {'#' + data['adset_name']}
            </Badge>
          ) : null}
        </p>
      </>
    )
  }

  function cellRenderer (params) {
    var key = params.colDef['field']
    var row = params.data
    var data = params.data[key]
    var pinned = Object.keys(params.node).includes('rowPinned') ? true : false
    var level = row['level']

    if (
      (key === 'budget' && pinned) ||
      (key === 'budget' && row['level'] == 'Ad')
    ) {
      return ''
    } else if (key === 'budget' && level.toLowerCase() === 'campaign' && data) {
      return (
        <p>
          {'₹' + Math.round(data).toLocaleString('en-IN')}
          {'  '}
        </p>
      )
    } else if (key === 'budget' && level.toLowerCase() === 'adset' && data) {
      return <p>{'₹' + Math.round(data)}</p>
    } else if (key === 'budget' && level.toLowerCase() === 'campaign') {
      return <p>Using adset budget</p>
    } else if (key === 'budget' && level.toLowerCase() === 'adset') {
      ;<p>Using campaign budget</p>
    } else if (key === 'budget' && level.toLowerCase() === 'ad') {
      return <p>Using adset budget</p>
    } else if (currencyMetrics.includes(key)) {
      return <p>₹{Math.round(data).toLocaleString('en-IN') + '  '}</p>
    } else if (percentageMetrics.includes(key) && key !== 'ctr') {
      return (
        <p>
          {Math.round(data).toLocaleString('en-IN')}%{'  '}
        </p>
      )
    } else if (percentageMetrics.includes(key) && key == 'ctr') {
      return (
        <p>
          {data.toLocaleString('en-IN')}%{'  '}
        </p>
      )
    } else {
      return <p>{data}</p>
    }
  }

  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), [])
  //
  const rowStyle = { background: '#eaeaea' }

  const getRowStyle = params => {
    if (params.node.rowIndex % 2 === 0) {
      return { background: '#f6f6f6' }
    }
  }

  return (
    <div className='public-custom-report-primary-table-body-container'>
      <div style={gridStyle} className={'ag-theme-quartz'}>
        <AgGridReact
          rowData={rowData}
          columnDefs={headers3}
          defaultColDef={defaultColDef}
          rowDragManaged={true}
          rowSelection='multiple'
          rowDragMultiRow={true}
          rowStyle={rowStyle}
          getRowStyle={getRowStyle}
        />
      </div>
    </div>
  )
}

export default PerformanceCustomReportScreen
