import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import styled from "styled-components";

const BIRD_HEIGHT = 28;
const BIRD_WIDTH = 33;
const WALL_HEIGHT = 600;
const WALL_WIDTH = 400;
const GRAVITY = 5;
const OBJ_WIDTH = 52;
const OBJ_SPEED = 6;
const OBJ_GAP = 200;


function FlappyBirdScreen({ APIEndpointDomain }) {
  const [isStart, setIsStart] = useState(false);
  const [birdpos, setBirspos] = useState(300);
  const [objHeight, setObjHeight] = useState(0);
  const [objPos, setObjPos] = useState(WALL_WIDTH);
  const [score, setScore] = useState(0);
  const [isSpacePressed, setIsSpacePressed] = useState(false);

  const [allTimeHighestInformation, setAllTimeHighestInformation] =
    useState({});

  const [allTimeUserHighestInformation, setAllTimeUserHighestInformation] =
    useState({});

  useEffect(() => {
    let intVal;
    if (isStart && birdpos < WALL_HEIGHT - BIRD_HEIGHT) {
      intVal = setInterval(() => {
        setBirspos((birdpos) => birdpos + GRAVITY);
      }, 24);
    }
    return () => clearInterval(intVal);
  }, [isStart, birdpos]);

  useEffect(() => {
    let objval;
    if (isStart && objPos >= -OBJ_WIDTH) {
      objval = setInterval(() => {
        setObjPos((objPos) => objPos - OBJ_SPEED);
      }, 24);

      return () => {
        clearInterval(objval);
      };
    } else {
      setObjPos(WALL_WIDTH);
      setObjHeight(Math.floor(Math.random() * (WALL_HEIGHT - OBJ_GAP)));
      if (isStart) setScore((score) => score + 1);
    }

    axios
      .get(APIEndpointDomain + "/flappy-bird/get-scores", {
        params: {
          score: score,
        },
        headers: {
          "ngrok-skip-browser-warning": "any",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        console.log(response);
        setAllTimeHighestInformation(response.data["highest_score"]);
        setAllTimeUserHighestInformation(
          response.data["user_highest_score"]
            ? response.data["user_highest_score"]
            : 0
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, [isStart, objPos, score]);

  useEffect(() => {
    let topObj = birdpos >= 0 && birdpos < objHeight;
    let bottomObj =
      birdpos <= WALL_HEIGHT &&
      birdpos >=
        WALL_HEIGHT - (WALL_HEIGHT - OBJ_GAP - objHeight) - BIRD_HEIGHT;

    if (
      objPos >= OBJ_WIDTH &&
      objPos <= OBJ_WIDTH + 80 &&
      (topObj || bottomObj)
    ) {
      setIsStart(false);
      setBirspos(300);
      setScore(0);
      axios
        .get(APIEndpointDomain + "/flappy-bird/save-score", {
          params: {
            score: score,
          },
          headers: {
            "ngrok-skip-browser-warning": "any",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        })
        .then((response) => {
          console.log(response);
          setAllTimeHighestInformation(response.data["highest_score"]);
          setAllTimeUserHighestInformation(response.data["user_highest_score"]);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [isStart, birdpos, objHeight, objPos, score]);

  const handleJump = useCallback(() => {
    if (birdpos < BIRD_HEIGHT) {
      setBirspos(0);
    } else {
      setBirspos((birdpos) => birdpos - 50);
    }
  }, [birdpos]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.code === "Space" && isStart) {
        setIsSpacePressed(true);
        handleJump();
      }
    };

    const handleKeyUp = (event) => {
      if (event.code === "Space") {
        setIsSpacePressed(false);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, [handleJump, isStart]);

  const handleClick = () => {
    if (!isStart) setIsStart(true);
    else if (birdpos < BIRD_HEIGHT) setBirspos(0);
    else setBirspos((birdpos) => birdpos - 50);
  };

  return (
    <div
      style={{
        backgroundColor: "black",
        height: "100vh",
        minWidth: "100vw",
        backgroundSize: "cover",
        backgroundImage:
          "url(https://img.freepik.com/free-photo/green-paint-brush-textured-background_53876-104801.jpg?w=1480&t=st=1699106562~exp=1699107162~hmac=7518dcdd25af6a1ede0fe4a60d24c83e8730781c60817122325a28d681e6e95c)",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          minHeight: "60px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h4>
          <strong>
            Highest Score: {allTimeHighestInformation["score"]}{" "}
            {" (" + allTimeHighestInformation["username"] + ")"}
          </strong>
        </h4>
        <h4>
          <strong>Current Score: {score}</strong>
        </h4>
        <h4>
          <strong>
            Your Highest Score: {allTimeUserHighestInformation["score"]}
          </strong>
        </h4>
      </div>
      <Home onClick={handleClick} onKeyDown={handleJump} tabIndex={0}>
        <Background height={WALL_HEIGHT} width={WALL_WIDTH}>
          {!isStart ? <Startboard>Click To Start</Startboard> : null}
          <Obj
            height={objHeight}
            width={OBJ_WIDTH}
            left={objPos}
            top={0}
            deg={180}
          />
          <Bird
            height={BIRD_HEIGHT}
            width={BIRD_WIDTH}
            top={birdpos}
            left={100}
          />
          <Obj
            height={WALL_HEIGHT - OBJ_GAP - objHeight}
            width={OBJ_WIDTH}
            left={objPos}
            top={
              WALL_HEIGHT - (objHeight + (WALL_HEIGHT - OBJ_GAP - objHeight))
            }
            deg={0}
          />
        </Background>
      </Home>
    </div>
  );
}

export default FlappyBirdScreen;

const Home = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Background = styled.div`
  background-image: url("https://raw.githubusercontent.com/krishnenduroy52/Flappy-bird/main/public/images/background-day.png");
  background-repeat: no-repeat;
  background-size: ${(props) => props.width}px ${(props) => props.height}px;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  position: relative;
  overflow: hidden;
  border: 2px solid black;
`;

const Bird = styled.div`
  position: absolute;
  background-image: url("https://raw.githubusercontent.com/krishnenduroy52/Flappy-bird/main/public/images/yellowbird-upflap.png");
  background-repeat: no-repeat;
  background-size: ${(props) => props.width}px ${(props) => props.height}px;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
`;

const Obj = styled.div`
  position: relative;
  background-image: url("https://raw.githubusercontent.com/krishnenduroy52/Flappy-bird/main/public/images/pipe-green.png");
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  left: ${(props) => props.left}px;
  top: ${(props) => props.top}px;
  transform: rotate(${(props) => props.deg}deg);
`;

const Startboard = styled.div`
  position: relative;
  top: 49%;
  background-color: black;
  padding: 10px;
  width: 100px;
  left: 50%;
  margin-left: -50px;
  text-align: center;
  font-size: 20px;
  border-radius: 10px;
  color: #fff;
  font-weight: 600;
`;

const ScoreShow = styled.div`
  text-align: center;
  background: transparent;
`;
