import '../css/MarketingHeader.css'
import { Navbar } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { ToggleButton } from 'react-bootstrap'
import React, { useState, useRef, useEffect } from 'react'
import { ListGroup } from 'react-bootstrap'
import { Form } from 'react-bootstrap'
import Dropdown from 'react-bootstrap/Dropdown'
import axios from 'axios'
import { Col } from 'react-bootstrap'
import { Alert } from 'react-bootstrap'
import InputGroup from 'react-bootstrap/InputGroup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFilter,
  faTableColumns,
  faPenToSquare,
  faEye,
  faShareNodes,
  faClipboard,
  faDownload
} from '@fortawesome/free-solid-svg-icons'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import SaveCustomReportModal from './PerformanceHeaderSubComponents/SaveCustomReportModal'
import ShareCustomReportModal from './PerformanceHeaderSubComponents/ShareCustomReportModal'
import APIRequestStatusModal from './CommonSubComponents/APIRequestStatusMessageModal'
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger
} from './ui/drawer.js'
import { ShadCNButton } from '../components/ui/shadcn-button'
import { Minus, Plus } from 'lucide-react'
import { ResponsiveContainer } from 'recharts'
import { CSVLink } from 'react-csv'

function MarketingHeader ({
  columnFilters,
  setShowOnlyCreatedBetween,
  showOnlyCreatedBetween,
  setSourceSearch,
  setMediumSearch,
  setCampaignSearch,
  setIdSearch,
  setTermSearch,
  setContentSearch,
  setStartDate,
  setEndDate,
  defaultStartDate,
  defaultEndDate,
  setNewAdvanceFilter,
  advancedFiltersSelections,
  selectedColumnFilters,
  setSelectedColumnFilters,
  setSelectedDomain,
  setSelectedLeadType,
  selectedLeadType,
  selectedDomain,
  defaultAdvancedFilters,
  setAdvancedFilterModalShow,
  advancedFilterModalShow,
  setSelectedGrouping,
  selectedGrouping,
  setSelectedSourceType,
  selectedSourceType,
  allViews,
  setAllViews,
  APIEndpointDomain,
  loadingP,
  setisCBMetricSelected,
  isCBMetricSeleted,
  getPrimaryTD,
  startDate,
  endDate,
  compareRangeRequirement,
  setCompareRangeRequirement,
  tableDataAPICalling,
  selectedLevel,
  setSelectedLevel,
  favoritesData,
  setFavoritesData,
  customReportNames,
  setCustomReportNames,
  selectedCustomReport,
  setSelectedCustomReport,
  goTriggerPrimaryAPI,
  setGoTriggerPrimaryAPI,
  sourceSearch,
  mediumSearch,
  campaignSearch,
  idSearch,
  termSearch,
  contentSearch,
  Data
}) {
  var [columnModalShow, setColumnModalShow] = useState(false)
  var [tagSearch, setTagSearch] = useState('')
  var [utmDrawerOpen, setUtmDrawerOpen] = useState(false)

  function handleAdvanceFilterChange (value, key) {
    var currentAdvanceFilterSelections = advancedFiltersSelections

    currentAdvanceFilterSelections[key] = value
    setNewAdvanceFilter(currentAdvanceFilterSelections)
  }

  var [showShareCustomReportModal, setShowCustomReportModal] = useState(false)
  var [ShareCustomReportWith, setShareCustomReportWith] = useState('')
  var [
    ShareCustomReportWithErrorMessage,
    setShareCustomReportWithErrorMessage
  ] = useState('')

  var [showCustomReportShareStatusAlert, setShowCustomReportShareStatusAlert] =
    useState(false)
  var [CustomReportShareStatusMessage, setCustomReportShareStatusMessage] =
    useState('')

  function ShareCustomReport (report_name) {
    axios
      .get(APIEndpointDomain + '/marketing/share-custom-report', {
        params: {
          custom_report_name: report_name,
          share_with: ShareCustomReportWith
        },
        headers: {
          'ngrok-skip-browser-warning': 'any',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        console.log(response)
        setShowCustomReportModal(false)
        setShowCustomReportShareStatusAlert(true)
        setCustomReportShareStatusMessage('Custom report shared successfully!')
      })
      .catch(error => {
        console.log(error)
        setShareCustomReportWithErrorMessage(error.data['message'])
        setShowCustomReportShareStatusAlert(true)
        setCustomReportShareStatusMessage('Failed to share your custom report!')
      })
  }

  var [showNameReportModal, setShowNameReportModal] = useState(false)
  var [namingCustomReport, setNamingCustomReport] = useState('')

  function deleteFavorites () {
    axios
      .get(APIEndpointDomain + '/marketing/delete-favorites', {
        params: {},
        headers: {
          'ngrok-skip-browser-warning': 'any',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        console.log(response)
        const favorites = response.data['favorite']
        setFavoritesData(favorites)

        setSelectedCustomReport('')
      })
      .catch(error => {
        console.log(error)
      })
  }

  function getCustomReport (name) {
    setSelectedCustomReport(name)
    axios
      .get(APIEndpointDomain + '/marketing/get-custom-report', {
        params: {
          name: name
        },
        headers: {
          'ngrok-skip-browser-warning': 'any',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        console.log(response)
        const favorites = response.data['custom_report']
        setFavoritesData(favorites)
      })
      .catch(error => {
        console.log(error)
      })
  }

  var [
    showCustomReportUpdateStatusAlert,
    setShowCustomReportUpdateStatusAlert
  ] = useState(false)
  var [CustomReportUpdateStatusMessage, setCustomReportUpdateStatusMessage] =
    useState('')

  function saveCustomReport (favorite, name) {
    const encodedData = encodeURIComponent(JSON.stringify(favorite))

    axios
      .get(APIEndpointDomain + '/marketing/update-custom-report', {
        params: {
          custom_report_name: name,
          custom_report: encodedData
        },
        headers: {
          'ngrok-skip-browser-warning': 'any',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        console.log(response)

        const favorites = response.data['favorite']
        setFavoritesData(favorites)

        setShowCustomReportUpdateStatusAlert(true)
        setCustomReportUpdateStatusMessage(
          'Custom report updated successfully!'
        )
      })
      .catch(error => {
        console.log(error)
        setShowCustomReportUpdateStatusAlert(true)
        setCustomReportUpdateStatusMessage(
          'Failed to update your custom report!'
        )
      })
  }

  var [showCustomReportSaveStatusAlert, setShowCustomReportSaveStatusAlert] =
    useState(false)
  var [CustomReportSaveStatusMessage, setCustomReportSaveStatusMessage] =
    useState('')

  function saveCustomReportAs (favorite) {
    const encodedData = encodeURIComponent(JSON.stringify(favorite))

    axios
      .get(APIEndpointDomain + '/marketing/save-custom-report', {
        params: {
          favorite: encodedData,
          name: namingCustomReport
        },
        headers: {
          'ngrok-skip-browser-warning': 'any',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        console.log(response)
        const names = response.data['names']
        setCustomReportNames(names)
        setShowNameReportModal(false)
        setSelectedCustomReport(response.data['current_name'])
        setShowCustomReportSaveStatusAlert(true)
        setCustomReportSaveStatusMessage('Custom report saved successfully!')
      })
      .catch(error => {
        console.log(error)
        setShowCustomReportSaveStatusAlert(true)
        setCustomReportSaveStatusMessage('Failed to save your custom report!')
      })
  }

  function showColumnModal () {
    var showCModal =
      columnModalShow === false
        ? setColumnModalShow(true)
        : setColumnModalShow(false)
    return showCModal
  }

  function levelSelector (level) {
    setSelectedLevel(level)
  }

  function calculateNewDates (startDate, endDate) {
    // Calculate the difference in days between the start and end dates
    var startDate = new Date(startDate)
    var endDate = new Date(endDate)
    const daysDifference = Math.floor(
      (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)
    )

    // Calculate new end date (one day before the old start date)
    const newEndDate = new Date(startDate)
    newEndDate.setDate(startDate.getDate() - 1)

    // Calculate new start date (by subtracting the same number of days as the original difference)
    const newStartDate = new Date(newEndDate)
    newStartDate.setDate(newEndDate.getDate() - daysDifference)

    return [
      newStartDate.toISOString().slice(0, 10),
      newEndDate.toISOString().slice(0, 10)
    ]
  }

  function handleComparisonButtonClick (current) {
    current === false
      ? setCompareRangeRequirement(true)
      : setCompareRangeRequirement(false)

    current === false ? primaryTDCall(true) : primaryTDCall(false)
  }

  function primaryTDCall (compareRangeRequirement) {
    if (compareRangeRequirement === false) {
      getPrimaryTD(
        '2028-01-01',
        '2028-01-01',
        selectedLeadType,
        selectedSourceType,
        selectedColumnFilters,
        selectedDomain,
        'compare'
      )

      getPrimaryTD(
        startDate,
        endDate,
        selectedLeadType,
        selectedSourceType,
        selectedColumnFilters,
        selectedDomain,
        'primary'
      )
    } else if (compareRangeRequirement === true) {
      getPrimaryTD(
        compareStartDate,
        compareEndDate,
        selectedLeadType,
        selectedSourceType,
        selectedColumnFilters,
        selectedDomain,
        'compare'
      )
      resetFilters()
    }
  }

  function columnSelector (selectedColumn) {
    var selectedColumns = selectedColumnFilters.includes(selectedColumn)
      ? selectedColumnFilters.filter(column => column !== selectedColumn)
      : [...selectedColumnFilters, selectedColumn]

    setSelectedColumnFilters(selectedColumns)
  }

  function columnFilterColor (filter) {
    var color = selectedColumnFilters.includes(filter) ? 'success' : 'black'
    return color
  }

  function showAdvancedFiltersModal () {
    var showAFModal =
      advancedFilterModalShow === false
        ? setAdvancedFilterModalShow(true)
        : setAdvancedFilterModalShow(false)
    return showAFModal
  }

  useEffect(() => {
    var containsCBMetrics = selectedColumnFilters.filter(
      filter =>
        filter.toString().toLowerCase().includes('_cb') ||
        filter.toString().toLowerCase().includes('call_booked')
    )

    if (containsCBMetrics.length > 0) {
      if (isCBMetricSeleted === true) {
        setisCBMetricSelected(false)
      } else if (isCBMetricSeleted === false) {
        setisCBMetricSelected(true)
      }
    }
  }, [selectedColumnFilters])

  var [showNameYourViewModal, setShowNameYourViewModal] = useState(false)

  var [namingView, setNamingView] = useState('')
  var [selectedView, setSelectedView] = useState('')
  var [showViewSavedSuccessAlert, setShowViewSavedSuccessAlert] =
    useState(false)
  var [postViewSaveMessage, setPostViewSaveMessage] = useState('')

  function selectView (name) {
    setSelectedView(name)

    axios
      .get(APIEndpointDomain + '/marketing/select-view', {
        params: {
          name: name
        },
        headers: {
          'ngrok-skip-browser-warning': 'any',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        console.log(response)
        const columns = response.data['columns']
        const advancedFilters = response.data['advanced_filters']
        const dataRelevancy = response.data['data_relevancy']
        const sourceType = response.data['source_type']

        setNewAdvanceFilter(advancedFilters)
        setSelectedColumnFilters(columns)
        setSelectedSourceType(sourceType)
        dataRelevancy === 'relevant_data'
          ? setShowOnlyCreatedBetween(true)
          : setShowOnlyCreatedBetween(false)
      })
      .catch(error => {
        console.log(error)
      })
  }

  function copyCustomReport (name) {
    const url =
      window.location.origin +
      '/marketing/custom-report/' +
      encodeURIComponent(name)

    // Create a temporary textarea element to hold the URL
    const textArea = document.createElement('textarea')
    textArea.value = url

    // Append the textarea element to the document
    document.body.appendChild(textArea)

    // Select the URL in the textarea
    textArea.select()

    // Execute the copy command
    document.execCommand('copy')

    // Remove the temporary textarea element
    document.body.removeChild(textArea)

    // Check if the URL was successfully copied to the clipboard
    // if (document.queryCommandSupported("copy")) {
    //   console.log("URL copied to clipboard: " + url);
    // } else {
    //   console.error("Copy to clipboard not supported in this browser.");
    // }
  }

  function saveViewSubmit (
    advancedFilterSelections,
    selectedColumnFilters,
    namingView
  ) {
    setShowNameYourViewModal(false)
    axios
      .post(
        APIEndpointDomain + '/marketing/save-view',
        {
          name: namingView,
          columns: selectedColumnFilters,
          advanced_filters: advancedFilterSelections,
          data_relevancy:
            showOnlyCreatedBetween === true ? 'relevant_data' : 'all_data',
          source_type: selectedSourceType
        },
        {
          headers: {
            'ngrok-skip-browser-warning': 'any',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`
          }
        }
      )
      .then(response => {
        const newViews = [...allViews, { name: namingView }]
        setAllViews(newViews)
        setShowViewSavedSuccessAlert(true)
        setPostViewSaveMessage(response.data['message'])
      })
      .catch(error => {
        setShowViewSavedSuccessAlert(true)
        setPostViewSaveMessage(error.data['message'])
      })
  }

  function resetFilters () {
    setNewAdvanceFilter(defaultAdvancedFilters)
  }

  function handleSourceSearch (e) {
    setSourceSearch(e.target.value)
  }

  function handleMediumSearch (e) {
    setMediumSearch(e.target.value)
  }

  function handleCampaignSearch (e) {
    setCampaignSearch(e.target.value)
  }

  function handleIdSearch (e) {
    setIdSearch(e.target.value)
  }

  function handleTermSearch (e) {
    setTermSearch(e.target.value)
  }

  function handleContentSearch (e) {
    setContentSearch(e.target.value)
  }

  function handleStartDate (e) {
    // console.log(e.toLocaleDateString());
    // const dateObject = new Date(e);

    // const formattedDateString = dateObject.toISOString().slice(0, 10);

    setStartDate(e.target.value)
  }

  function handleEndDate (e) {
    setEndDate(e.target.value)
  }

  var [compareStartDate, setCompareStartDate] = useState(
    calculateNewDates(startDate, endDate)[0]
  )
  var [compareEndDate, setCompareEndDate] = useState(
    calculateNewDates(startDate, endDate)[1]
  )

  function handleCompareStartDate (e) {
    setCompareStartDate(e.target.value)
  }

  function handleCompareEndDate (e) {
    setCompareEndDate(e.target.value)
  }

  useEffect(() => {
    setCompareRangeRequirement(false)
  }, [selectedLeadType, selectedDomain, isCBMetricSeleted])

  useEffect(() => {
    if (compareRangeRequirement) {
      primaryTDCall(compareRangeRequirement)
    }
  }, [compareEndDate, compareStartDate])

  var columnCategory = {
    'Standard Metrics': [
      'roi',
      'cpl',
      'cpql',
      'cpgl',
      'cpa',
      'cpaa',

      'spend',
      'acquisitions_lost',
      'acquisition_loss_rate',
      'acquisition_validation_rate',
      'acquisitions_pending_validation',
      'acquisitions_pending_validation_p',
      'avg_validation_period',
      'avg_complete_cycle_period',
      'acquisitions',
      'admit_up',
      'avg_purchase',
      'avg_conversion_period',
      'cpa_acquisitions',

      'deleted',
      'good_leads',
      'good_percentage',
      'leads',
      'pending',
      'pending_p',
      'pressure_sell',
      'premium_acquisitions',
      'premium_ms_revenue',
      'pressure_to_acquisition',
      'projected_acquisitions',
      'qualified',
      'quality',
      'revenue',
      'spend',
      'ultra_hot',
      'upfront_payments',
      'uhl_to_acquisition',
      'admit_up_rev',
      'actual_acquisitions'
    ],
    'During Metrics': [
      'admit_up_during',
      'acquired_during',
      'admit_up_rev_dur',
      'actually_acquired_during',
      'cpa_during',
      'cpa_acquired_during',
      'cpgl_during',
      'good_leads_during',
      'pressure_sell_during',
      'premium_acquired_during',

      'premium_ms_revenue_during',

      'revenue_during',
      'ultra_hot_during',
      'upfront_payments_during',
      'roi_during',

      'real_revenue_during',
      'real_acquisitions_during',
      'real_roi_during',
      'cpra_during'
    ],
    'Historic Metrics': [
      'h_good',
      'h_good_p',
      'h_pressure',
      'h_ultra',
      'h_acquisitions',
      'h_cpa_acquisitions',
      'h_premium_acquisitions',

      'h_cpa',
      'h_cpgl'
    ],
    'Calendar Metrics': [
      'calls_booked_during',
      'valid_calls_cb_p',
      'assigned_calls_cb_p',
      'calls_left_cb_p',
      'dnp_cb_p',
      'deleted_cb_p',
      'good_leads_cb_p',
      'valid_calls_cb',
      'assigned_calls_cb',
      'calls_left_cb',
      'good_leads_cb',
      'deleted_cb',
      'dnp_cb'
    ]
  }

  // Drag logic

  var dragColumn = useRef()
  var dragOverColumn = useRef()

  function dragColumnStart (__, position) {
    dragColumn.current = position
  }

  function dragColumnEnter (__, position) {
    dragOverColumn.current = position
  }

  function dropColumn (__) {
    var updatedSelectedColumnFilters = [...selectedColumnFilters]
    var dragItemContent = updatedSelectedColumnFilters[dragColumn.current]
    updatedSelectedColumnFilters.splice(dragColumn.current, 1)
    updatedSelectedColumnFilters.splice(
      dragOverColumn.current,
      0,
      dragItemContent
    )
    dragColumn.current = null
    dragOverColumn.current = null
    setSelectedColumnFilters(updatedSelectedColumnFilters)
  }

  return (
    <Navbar
      // fixed='top'
      // className='entire-navbar '
      className='ml-3 mr-3'
      fixed='top'
      style={{
        borderWidth: 0
      }}
    >
      <APIRequestStatusModal
        show={showCustomReportSaveStatusAlert}
        setShow={setShowCustomReportSaveStatusAlert}
        message={CustomReportSaveStatusMessage}
      />

      <APIRequestStatusModal
        show={showCustomReportUpdateStatusAlert}
        setShow={setShowCustomReportUpdateStatusAlert}
        message={CustomReportUpdateStatusMessage}
      />

      <APIRequestStatusModal
        show={showCustomReportShareStatusAlert}
        setShow={setShowCustomReportShareStatusAlert}
        message={CustomReportShareStatusMessage}
      />

      {/* Add Client Data Modal */}

      {/* View Save Success/Fail Alert */}

      <Alert show={showViewSavedSuccessAlert} variant='success'>
        <Alert.Heading>{postViewSaveMessage}</Alert.Heading>
        {/* <div className="d-flex justify-content-end"> */}
        <Button
          onClick={() => setShowViewSavedSuccessAlert(false)}
          variant='outline-danger'
        >
          Close me
        </Button>
        {/* </div> */}
      </Alert>

      {/* Customize Column Modal */}

      <Modal size='xl' show={columnModalShow}>
        <Modal.Header
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            backgroundColor: '#525252',
            color: 'white'
          }}
          closeButton
          onClick={showColumnModal}
        >
          <Modal.Title id='contained-modal-title-vcenter'>
            Customize your columns
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div className='col-sm-9'>
              <Form.Control
                onChange={e => setTagSearch(e.target.value)}
                type='text'
                placeholder='Search for metrics containing...'
                value={tagSearch}
                style={{ background: '#f2f2f2' }}
              />
              <br></br>
              {Object.keys(columnCategory).map(key => (
                <>
                  <Modal.Title id='contained-modal-title-vcenter'>
                    {key}
                  </Modal.Title>
                  <div className='py-4'>
                    <ListGroup horizontal='sm' className='flex-wrap'>
                      {columnCategory[key].map(filter => {
                        if (
                          columnFilters.includes(filter) &&
                          filter.toLowerCase().includes(tagSearch)
                        ) {
                          return (
                            <>
                              <div className='py-2'>
                                <div style={{ marginRight: '20px' }}>
                                  <ListGroup.Item>
                                    <ToggleButton
                                      id='toggle-check'
                                      type='checkbox'
                                      variant={columnFilterColor(filter)}
                                      checked={selectedColumnFilters.includes(
                                        filter
                                      )}
                                      value={filter}
                                      // key={index}
                                      onClick={() => columnSelector(filter)}
                                    >
                                      {filter}
                                    </ToggleButton>
                                  </ListGroup.Item>
                                </div>
                              </div>
                            </>
                          )
                        }
                      })}
                    </ListGroup>
                  </div>
                </>
              ))}
            </div>
            <div className='col-sm-1'>
              <div className='row'>
                <p>
                  <strong>Rearrange Columns</strong>
                </p>
              </div>
              <ListGroup horizontal='sm' className='flex-wrap'>
                {selectedColumnFilters.map((column, index) => (
                  <ListGroup.Item key={index}>
                    <div
                      onDragStart={e => dragColumnStart(e, index)}
                      onDragEnter={e => dragColumnEnter(e, index)}
                      onDragEnd={dropColumn}
                      key={index}
                      draggable
                      className='mb-2'
                    >
                      {column}
                    </div>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button color='success' onClick={showColumnModal}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Save name modal */}

      <Modal size='sm' show={showNameYourViewModal}>
        <Modal.Header
          closeButton
          onClick={() => setShowNameYourViewModal(false)}
        >
          <Modal.Title id='contained-modal-title-vcenter'>
            Name your view
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            type='text'
            placeholder='Name'
            onChange={e => setNamingView(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            color='primary'
            onClick={() =>
              saveViewSubmit(
                advancedFiltersSelections,
                selectedColumnFilters,
                namingView
              )
            }
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Advanced Filter Modal */}

      <Modal size='xl' show={advancedFilterModalShow}>
        <Modal.Header closeButton onClick={showAdvancedFiltersModal}>
          <Modal.Title id='contained-modal-title-vcenter'>
            Set Advanced Filters
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='mb-5'>
            <div className='row mb-5'></div>
            <div className='row mb-3'>
              <div className='col'>
                <Form.Label>Min. Revenue</Form.Label>
                <Form.Control
                  placeholder='Minimum'
                  defaultValue={advancedFiltersSelections['minRevenue']}
                  onChange={e =>
                    handleAdvanceFilterChange(e.target.value, 'minRevenue')
                  }
                />
              </div>

              <div className='col'>
                <Form.Label>Max. Revenue</Form.Label>
                <Form.Control
                  placeholder='Maximum'
                  defaultValue={advancedFiltersSelections['maxRevenue']}
                  onChange={e =>
                    handleAdvanceFilterChange(e.target.value, 'maxRevenue')
                  }
                />
              </div>
            </div>
            <div className='row'>
              <div className='col'>
                <Form.Label>Min. Clients During</Form.Label>
                <Form.Control
                  placeholder='Minimum'
                  defaultValue={
                    advancedFiltersSelections['minAcquisitionDuring']
                  }
                  onChange={e =>
                    handleAdvanceFilterChange(
                      e.target.value,
                      'minAcquisitionDuring'
                    )
                  }
                />
              </div>
              <div className='col'>
                <Form.Label>Min. Revenue During</Form.Label>
                <Form.Control
                  placeholder='Minimum'
                  defaultValue={advancedFiltersSelections['minRevenueDuring']}
                  onChange={e =>
                    handleAdvanceFilterChange(
                      e.target.value,
                      'minRevenueDuring'
                    )
                  }
                />
              </div>

              <div className='col'>
                <Form.Label>Max. Clients During</Form.Label>
                <Form.Control
                  placeholder='Maximum'
                  defaultValue={
                    advancedFiltersSelections['maxAcquisitionDuring']
                  }
                  onChange={e =>
                    handleAdvanceFilterChange(
                      e.target.value,
                      'maxAcquisitionDuring'
                    )
                  }
                />
              </div>
              <div className='col'>
                <Form.Label>Max. Revenue During</Form.Label>
                <Form.Control
                  placeholder='Maximum'
                  defaultValue={advancedFiltersSelections['maxRevenueDuring']}
                  onChange={e =>
                    handleAdvanceFilterChange(
                      e.target.value,
                      'maxRevenueDuring'
                    )
                  }
                />
              </div>
            </div>
          </div>
          <div className='mb-5' style={{ borderTop: '1px dotted black' }}>
            <div className='row'>
              <div className='col'>
                <Form.Label>Min. Leads</Form.Label>
                <Form.Control
                  placeholder='Minimum'
                  defaultValue={advancedFiltersSelections['minLeads']}
                  onChange={e =>
                    handleAdvanceFilterChange(e.target.value, 'minLeads')
                  }
                />
              </div>
              <div className='col'>
                <Form.Label>Min. Qualified</Form.Label>
                <Form.Control
                  placeholder='Minimum'
                  defaultValue={advancedFiltersSelections['minQualified']}
                  onChange={e =>
                    handleAdvanceFilterChange(e.target.value, 'minQualified')
                  }
                />
              </div>
              <div className='col'>
                <Form.Label>Min. Good</Form.Label>
                <Form.Control
                  placeholder='Minimum'
                  defaultValue={advancedFiltersSelections['minGood']}
                  onChange={e =>
                    handleAdvanceFilterChange(e.target.value, 'minGood')
                  }
                />
              </div>
              <div className='col'>
                <Form.Label>Min. Clients</Form.Label>
                <Form.Control
                  placeholder='Minimum'
                  defaultValue={advancedFiltersSelections['minClients']}
                  onChange={e =>
                    handleAdvanceFilterChange(e.target.value, 'minClients')
                  }
                />
              </div>
              <div className='col'>
                <Form.Label>Min. Quality</Form.Label>
                <Form.Control
                  placeholder='Minimum'
                  defaultValue={advancedFiltersSelections['minQuality']}
                  onChange={e =>
                    handleAdvanceFilterChange(e.target.value, 'minQuality')
                  }
                />
              </div>
            </div>
          </div>
          <div className='mb-5'>
            <div className='row'>
              <div className='col'>
                <Form.Label>Max. Leads</Form.Label>
                <Form.Control
                  placeholder='Maximum'
                  defaultValue={advancedFiltersSelections['maxLeads']}
                  onChange={e =>
                    handleAdvanceFilterChange(e.target.value, 'maxLeads')
                  }
                />
              </div>
              <div className='col'>
                <Form.Label>Max. Qualified</Form.Label>
                <Form.Control
                  placeholder='Maximum'
                  defaultValue={advancedFiltersSelections['maxQualified']}
                  onChange={e =>
                    handleAdvanceFilterChange(e.target.value, 'maxQualified')
                  }
                />
              </div>
              <div className='col'>
                <Form.Label>Max. Good</Form.Label>
                <Form.Control
                  placeholder='Maximum'
                  defaultValue={advancedFiltersSelections['maxGood']}
                  onChange={e =>
                    handleAdvanceFilterChange(e.target.value, 'maxGood')
                  }
                />
              </div>
              <div className='col'>
                <Form.Label>Max. Clients</Form.Label>
                <Form.Control
                  placeholder='Maximum'
                  defaultValue={advancedFiltersSelections['maxClients']}
                  onChange={e =>
                    handleAdvanceFilterChange(e.target.value, 'maxClients')
                  }
                />
              </div>
              <div className='col'>
                <Form.Label>Max. Quality</Form.Label>
                <Form.Control
                  placeholder='Maximum'
                  defaultValue={advancedFiltersSelections['maxQuality']}
                  onChange={e =>
                    handleAdvanceFilterChange(e.target.value, 'maxQuality')
                  }
                />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <SaveCustomReportModal
        showNameReportModal={showNameReportModal}
        setShowNameReportModal={setShowNameReportModal}
        setNamingCustomReport={setNamingCustomReport}
        saveCustomReportAs={saveCustomReportAs}
        favoritesData={favoritesData}
      />

      <ShareCustomReportModal
        showShareCustomReportModal={showShareCustomReportModal}
        setShowCustomReportModal={setShowCustomReportModal}
        ShareCustomReportWithErrorMessage={ShareCustomReportWithErrorMessage}
        ShareCustomReportWith={ShareCustomReportWith}
        setShareCustomReportWith={setShareCustomReportWith}
        ShareCustomReport={ShareCustomReport}
        selectedCustomReport={selectedCustomReport}
      />

      {/* Header STARTS HERE  */}

      <div className='w-screen '>
        <div className='row'>
          <Col xs='auto' className='no-space ml-3'>
            <Drawer>
              <DrawerTrigger asChild>
                <Button
                  variant='secondary'
                  // onClick={() => setUtmDrawerOpen(true)}
                >
                  <FontAwesomeIcon icon={faFilter}></FontAwesomeIcon>
                </Button>
              </DrawerTrigger>
              <DrawerContent className='bg-slate-100'>
                <div className='mx-auto w-full px-20'>
                  <DrawerHeader>
                    <div
                      className='content-center'
                      style={{
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                    >
                      <h4 className='text-3xl text-slate-800 font-bold mx-auto'>
                        Apply Filters
                      </h4>
                    </div>
                  </DrawerHeader>

                  <div className=''>
                    <div class='flex items-center justify-center flex-row mt-4'>
                      <Form.Control
                        style={{ width: '150px' }}
                        size='md'
                        type='text'
                        id='source'
                        placeholder={'Source Search'}
                        value={sourceSearch}
                        onChange={handleSourceSearch}
                      />

                      <Form.Control
                        style={{ width: '150px' }}
                        size='md'
                        type='text'
                        id='medium'
                        placeholder={'Medium Search'}
                        value={mediumSearch}
                        onChange={handleMediumSearch}
                      />

                      <Form.Control
                        style={{ width: '150px' }}
                        size='md'
                        type='text'
                        id='campaign'
                        placeholder={'Campaign Search'}
                        value={campaignSearch}
                        onChange={handleCampaignSearch}
                      />

                      {selectedSourceType !== 'FTP' ? (
                        <Form.Control
                          style={{ width: '150px' }}
                          size='md'
                          type='text'
                          id='id'
                          value={idSearch}
                          placeholder={'Id Search'}
                          onChange={handleIdSearch}
                        />
                      ) : null}

                      <Form.Control
                        style={{ width: '150px' }}
                        size='md'
                        type='text'
                        id='term'
                        value={termSearch}
                        placeholder={'Term Search'}
                        onChange={handleTermSearch}
                      />

                      <Form.Control
                        style={{ width: '150px' }}
                        size='md'
                        type='text'
                        id='content'
                        placeholder={'Content Search'}
                        value={contentSearch}
                        onChange={handleContentSearch}
                      />
                    </div>
                    <div
                      class='flex items-center justify-center  space-x-4 mt-4'
                      onMouseEnter={() => setUtmDrawerOpen(true)}
                      onMouseLeave={() => setUtmDrawerOpen(false)}
                    >
                      <Col xs='auto' className='no-space'>
                        <ToggleButton
                          style={{ width: '150px' }}
                          id='toggle-check'
                          type='checkbox'
                          variant={
                            selectedGrouping.source ? 'primary' : 'secondary'
                          }
                          checked={selectedGrouping.source}
                          // onClick={setSelectedGrouping({})}
                          value='source'
                          onClick={() =>
                            selectedGrouping.source === true
                              ? setSelectedGrouping({
                                  ...selectedGrouping,
                                  source: false
                                })
                              : setSelectedGrouping({
                                  ...selectedGrouping,
                                  source: true
                                })
                          }
                        >
                          Source
                        </ToggleButton>
                      </Col>

                      <Col xs='auto' className='no-space'>
                        <ToggleButton
                          style={{ width: '150px' }}
                          id='toggle-check'
                          type='checkbox'
                          variant={
                            selectedGrouping.medium ? 'primary' : 'secondary'
                          }
                          checked={selectedGrouping.medium}
                          value='medium'
                          onClick={() =>
                            selectedGrouping.medium === true
                              ? setSelectedGrouping({
                                  ...selectedGrouping,
                                  medium: false
                                })
                              : setSelectedGrouping({
                                  ...selectedGrouping,
                                  medium: true
                                })
                          }
                        >
                          medium
                        </ToggleButton>
                      </Col>
                      <Col xs='auto' className='no-space'>
                        <ToggleButton
                          style={{ width: '150px' }}
                          id='toggle-check'
                          type='checkbox'
                          variant={
                            selectedGrouping.campaign ? 'primary' : 'secondary'
                          }
                          checked={selectedGrouping.campaign}
                          value='campaign'
                          onClick={() =>
                            selectedGrouping.campaign === true
                              ? setSelectedGrouping({
                                  ...selectedGrouping,
                                  campaign: false
                                })
                              : setSelectedGrouping({
                                  ...selectedGrouping,
                                  campaign: true
                                })
                          }
                        >
                          campaign
                        </ToggleButton>
                      </Col>

                      {selectedSourceType !== 'FTP' ? (
                        <Col xs='auto' className='no-space'>
                          <ToggleButton
                            style={{ width: '150px' }}
                            id='toggle-check'
                            type='checkbox'
                            variant={
                              selectedGrouping.id ? 'primary' : 'secondary'
                            }
                            checked={selectedGrouping.id}
                            value='id'
                            onClick={() =>
                              selectedGrouping.id === true
                                ? setSelectedGrouping({
                                    ...selectedGrouping,
                                    id: false
                                  })
                                : setSelectedGrouping({
                                    ...selectedGrouping,
                                    id: true
                                  })
                            }
                          >
                            id
                          </ToggleButton>
                        </Col>
                      ) : null}

                      <Col xs='auto' className='no-space'>
                        <ToggleButton
                          id='toggle-check'
                          type='checkbox'
                          variant={
                            selectedGrouping.term ? 'primary' : 'secondary'
                          }
                          checked={selectedGrouping.term}
                          value='term'
                          style={{ width: '150px' }}
                          onClick={() =>
                            selectedGrouping.term === true
                              ? setSelectedGrouping({
                                  ...selectedGrouping,
                                  term: false
                                })
                              : setSelectedGrouping({
                                  ...selectedGrouping,
                                  term: true
                                })
                          }
                        >
                          term
                        </ToggleButton>
                      </Col>
                      <Col xs='auto' className='no-space'>
                        <ToggleButton
                          id='toggle-check'
                          type='checkbox'
                          variant={
                            selectedGrouping.content ? 'primary' : 'secondary'
                          }
                          checked={selectedGrouping.content}
                          value='content'
                          style={{ width: '150px' }}
                          onClick={() =>
                            selectedGrouping.content === true
                              ? setSelectedGrouping({
                                  ...selectedGrouping,
                                  content: false
                                })
                              : setSelectedGrouping({
                                  ...selectedGrouping,
                                  content: true
                                })
                          }
                        >
                          content
                        </ToggleButton>
                      </Col>
                    </div>
                  </div>

                  <div className='mx-auto w-full max-w-sm mt-4'>
                    <DrawerFooter>
                      <DrawerClose asChild>
                        <Button
                          onClick={() => setUtmDrawerOpen(false)}
                          variant='danger'
                        >
                          Close
                        </Button>
                      </DrawerClose>
                    </DrawerFooter>
                  </div>
                </div>
              </DrawerContent>
            </Drawer>
          </Col>

          <Col xs='auto' className='no-space'>
            <Dropdown>
              <Dropdown.Toggle variant='secondary' id='dropdown-basic'>
                {selectedView == '' ? (
                  <FontAwesomeIcon icon={faEye}></FontAwesomeIcon>
                ) : (
                  selectedView
                )}{' '}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {allViews.map(view => {
                  return (
                    <Dropdown.Item onClick={e => selectView(e.target.text)}>
                      {view['name']}
                    </Dropdown.Item>
                  )
                })}
                <Dropdown.Item>
                  <Button
                    onClick={() => setShowNameYourViewModal(true)}
                    variant='success'
                  >
                    Save Current View
                  </Button>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col xs='auto' className='no-space'>
            <Button variant='light' size='md' onClick={showColumnModal}>
              {/* {"Columns "}{" "} */}
              <FontAwesomeIcon icon={faTableColumns}></FontAwesomeIcon>{' '}
            </Button>
          </Col>
          {selectedLevel !== 'Custom Report' && favoritesData ? (
            <Col xs='auto' className='no-space'>
              <Dropdown>
                <Dropdown.Toggle variant='secondary' id='dropdown-basic'>
                  {selectedSourceType}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={e => setSelectedSourceType(e.target.text)}
                  >
                    LTP
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={e => {
                      setSelectedSourceType(e.target.text)
                      setSelectedGrouping({
                        ...selectedGrouping,
                        id: false
                      })
                      setIdSearch('')
                    }}
                  >
                    FTP
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={e => {
                      setSelectedSourceType(e.target.text)
                    }}
                  >
                    Combination
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          ) : null}
          {/* <Col xs="auto" className="no-space">
                <Button onClick={handleCopyClick}>Copy Table</Button>
              </Col> */}

          {/* <div
                className="col"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <h3>
                  <strong>Marketing</strong>
                </h3>
              </div> */}

          <div
            className='col'
            style={{
              display: 'flex',
              justifyContent:
                selectedLevel === 'Custom Report' ? 'left' : 'right',
              padding: '0px',
              margin: '0%'
            }}
          >
            {selectedLevel === 'Custom Report' && favoritesData ? (
              <>
                <Dropdown>
                  <Dropdown.Toggle variant='secondary' id='dropdown-basic'>
                    {selectedCustomReport == '' ? (
                      <>
                        <FontAwesomeIcon icon={faPenToSquare} />
                      </>
                    ) : (
                      selectedCustomReport
                    )}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className='custom-report-dropdown-menu'>
                    {customReportNames.length > 0
                      ? customReportNames.map(report => (
                          <Dropdown.Item>
                            <Button
                              onClick={() => {
                                getCustomReport(report.name)
                              }}
                              variant='light'
                            >
                              {' '}
                              {report.name}
                            </Button>
                            <Button
                              onClick={() => {
                                setShowCustomReportModal(true)
                                setSelectedCustomReport(report.name)
                              }}
                              variant='light'
                            >
                              <FontAwesomeIcon icon={faShareNodes} />
                            </Button>
                            <Button
                              onClick={() => {
                                copyCustomReport(report.name)
                              }}
                            >
                              <FontAwesomeIcon icon={faClipboard} />
                            </Button>
                          </Dropdown.Item>
                        ))
                      : null}
                  </Dropdown.Menu>
                </Dropdown>

                {selectedLevel === 'Custom Report' ? (
                  <>
                    <Button
                      variant='primary'
                      onClick={() =>
                        saveCustomReport(favoritesData, selectedCustomReport)
                      }
                    >
                      Save Changes
                    </Button>
                    <Button
                      variant='success'
                      onClick={() => setShowNameReportModal(true)}
                    >
                      Save As New
                    </Button>
                    <Button onClick={() => deleteFavorites()} variant='danger'>
                      Reset
                    </Button>
                  </>
                ) : null}
              </>
            ) : (
              <>
                <Col xs='auto' className='no-space'>
                  <Dropdown>
                    <Dropdown.Toggle variant='secondary' id='dropdown-basic'>
                      {selectedDomain}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        disabled={tableDataAPICalling ? true : false}
                        onClick={e => setSelectedDomain(e.target.text)}
                      >
                        MentR-Me
                      </Dropdown.Item>
                      <Dropdown.Item
                        disabled={tableDataAPICalling ? true : false}
                        onClick={e => setSelectedDomain(e.target.text)}
                      >
                        MiM-Essay
                      </Dropdown.Item>
                      <Dropdown.Item
                        disabled={tableDataAPICalling ? true : false}
                        onClick={e => setSelectedDomain(e.target.text)}
                      >
                        All Domains
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
                <Col xs='auto' className='no-space'>
                  <Dropdown>
                    <Dropdown.Toggle variant='secondary' id='dropdown-basic'>
                      {selectedLeadType}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        disabled={tableDataAPICalling ? true : false}
                        onClick={e => setSelectedLeadType(e.target.text)}
                      >
                        GMAT
                      </Dropdown.Item>
                      <Dropdown.Item
                        disabled={tableDataAPICalling ? true : false}
                        onClick={e => setSelectedLeadType(e.target.text)}
                      >
                        Jobs
                      </Dropdown.Item>
                      <Dropdown.Item
                        disabled={tableDataAPICalling ? true : false}
                        onClick={e => setSelectedLeadType(e.target.text)}
                      >
                        CPA
                      </Dropdown.Item>
                      <Dropdown.Item
                        disabled={tableDataAPICalling ? true : false}
                        onClick={e => setSelectedLeadType(e.target.text)}
                      >
                        Premium
                      </Dropdown.Item>
                      <Dropdown.Item
                        disabled={tableDataAPICalling ? true : false}
                        onClick={e => setSelectedLeadType(e.target.text)}
                      >
                        Premium + CPA
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
                <Col sm='0'>
                  <Form.Control
                    disabled={tableDataAPICalling}
                    defaultValue={defaultStartDate}
                    onChange={handleStartDate}
                    type='date'
                    size='md'
                  />
                </Col>
                <Col sm='0'>
                  <Form.Control
                    disabled={tableDataAPICalling}
                    defaultValue={defaultEndDate}
                    onChange={handleEndDate}
                    type='date'
                    size='md'
                  />
                </Col>
                <Col sm='0'>
                  {/* <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 1500, hide: 400 }}
                    overlay={renderButtonTooltip(
                      "Click to fetch the latest data! (Only required if you've changed the date, ad account or lead type)"
                    )}
                  > */}
                  <Button
                    className='mb-2'
                    size='sm'
                    id='toggle-check'
                    variant='danger'
                    onClick={() => {
                      // setUtmDrawerOpen(false)

                      getPrimaryTD(
                        startDate,
                        endDate,
                        selectedLeadType,
                        selectedSourceType,
                        selectedColumnFilters,
                        selectedDomain,
                        'primary'
                      )
                    }}
                    disabled={
                      !(loadingP >= 100) ||
                      utmDrawerOpen ||
                      advancedFilterModalShow ||
                      columnModalShow
                    }
                  >
                    Go!
                  </Button>
                  {/* </OverlayTrigger> */}
                </Col>
                <Col sm='0'>
                  {/* Advanced Filter Button */}
                  {/* <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 1500, hide: 400 }}
                      overlay={renderButtonTooltip(
                        "Apply filterss to narrow down your data."
                      )}
                    > */}
                  <Button variant='light' size='sm'>
                    <CSVLink filename='performance-analytics.csv' data={Data}>
                      <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                    </CSVLink>
                  </Button>
                  {/* </OverlayTrigger> */}
                </Col>
              </>
            )}
          </div>
        </div>
        <div className='row mb-3'>
          <ListGroup horizontal='sm'>
            {/* <Button variant="light" size="sm" onClick={showColumnModal}>
                  Customize Columns
                </Button> */}

            {/* Advanced Filter Button */}
            {/* 
                <Button
                  variant="light"
                  size="sm"
                  onClick={showAdvancedFiltersModal}
                >
                  Advanced Filters
                </Button> */}
            {/* <Col sm='0'>
              Advanced Filter Button

              <Button
                variant='light'
                size='md'
                onClick={() => setShowDrawer(!showDrawer)}
                disabled={compareRangeRequirement}
              >
                <FontAwesomeIcon
                  className='fa-light'
                  icon={faFilter}
                ></FontAwesomeIcon>{' '}
              </Button>
            </Col> */}

            {/* Reset FILTERS Button */}
            {/* 
                <Button variant="dark" size="sm" onClick={resetFilters}>
                  Reset Filters
                </Button> */}

            {/* <Col sm='0'>
              Reset FILTERS Button

              <Button variant='dark' size='md' onClick={resetFilters}>
                Reset
              </Button>
            </Col> */}

            {/* <ListGroup.Item variant="warning" key="source-search">
                  <Form.Control
                    type="text"
                    id="source"
                    placeholder="Source Search"
                    onChange={handleSourceSearch}
                  />
                </ListGroup.Item> */}
          </ListGroup>
        </div>
      </div>
    </Navbar>
  )
}

export default MarketingHeader
