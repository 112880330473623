import React, { useEffect } from 'react'
import { Navbar } from 'react-bootstrap'
import { TabsATUI } from '../components/ui/tabs'
import { AgGridReact } from 'ag-grid-react'
import { useMemo, useState } from 'react'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import '../css/PerformanceTable.css'
import { faL } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import Dropdown from 'react-bootstrap/Dropdown'
import LoadingMessage from '../components/CommonSubComponents/LoadingMessage'
import { MultiStepLoader } from '../components/ui/multi-step-loader'
import { Form } from 'react-bootstrap'
import { Col } from 'react-bootstrap'
import ServerReturnedNoDataMessage from '../components/CommonSubComponents/ServerReturnedNoDataMessage'
import Button from 'react-bootstrap/Button'
import { TypewriterEffect } from '../components/ui/typewriter-effect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faDownload,
  faUser,
  faArrowUp,
  faArrowDown,
  faEye,
  faEyeDropperEmpty
} from '@fortawesome/free-solid-svg-icons'
import { CSVLink } from 'react-csv'

function PremiumSalesPerformance ({
  checkAuthenticationInResponse,
  APIEndpointDomain
}) {
  var today = new Date()
  var defaultStartDate = new Date(new Date().setDate(today.getDate() - 30))
    .toJSON()
    .slice(0, 10)

  var defaultEndDate = new Date(new Date().setDate(today.getDate() - 1))
    .toJSON()
    .slice(0, 10)

  var [startDate, setStartDate] = useState(defaultStartDate)
  var [endDate, setEndDate] = useState(defaultEndDate)
  var [isFetching, setIsFetching] = useState(false)
  var [isError, setIsError] = useState(false)
  var [data, setData] = useState([])
  var [totalData, setTotalData] = useState([])

  function handleStartDate (e) {
    console.log(e.target.value)
    setStartDate(e.target.value)
  }

  function handleEndDate (e) {
    console.log(e.target.value)
    setEndDate(e.target.value)
  }

  var loadingStates = [
    {
      text: 'Connecting to Server'
    },
    {
      text: 'Finding Sales POCs'
    },
    {
      text: 'Analyzing their Performance'
    },
    {
      text: 'Calculating totals'
    },
    {
      text: 'Finalizing data'
    }
  ]

  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), [])
  const rowStyle = { background: '#black' }
  const defaultColDef = useMemo(() => {
    return {
      width: 170,
      filter: true,
      cellStyle: { fontSize: '14px', textAlign: 'center' },
      enableValue: true,
      enableRowGroup: true,
      enablePivot: true,
      filterParams: { newRowsAction: 'keep' }
    }
  }, [])

  const getRowStyle = params => {
    if (params.node.rowIndex % 2 !== 0) {
      return { background: '#f1f5f9' }
    } else if (params.node.rowIndex % 2 === 0) {
      return { background: '#f8fafc' }
    }
  }

  function handleGoClick () {
    getStats(startDate, endDate)
  }

  function getStats (start_date, end_date) {
    setIsFetching(true)
    setIsError(false)
    axios
      .get(APIEndpointDomain + '/sales/poc-performance/premium-stats', {
        params: {
          start_date: start_date,
          end_date: end_date
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        console.log(response)
        setData(response.data['sales_performance'])
        setTotalData(response.data['total'])
        setIsFetching(false)
        setIsError(false)
      })
      .catch(error => {
        checkAuthenticationInResponse(Number(error.response.status))
        console.log(error)
        setIsFetching(false)
        setIsError(true)
      })
  }

  function cellRenderer (params) {
    var key = params.colDef['field']

    var data = params.data[key]
    var datas = String(data).split(' ')

    return (
      <p style={{ lineHeight: 2 }}>
        {datas[0]}
        {datas[1] ? (
          <>
            <br></br>
            {datas[1]}
          </>
        ) : null}
      </p>
    )
  }
  var headers =
    data.length > 0 && data
      ? Object.keys(data[0])
          .filter(key => key !== 'sales_poc')
          .map(key => {
            if (key !== 'sales_poc') {
              return {
                headerName:
                  String(key).replace(/_/g, ' ') +
                  ' ' +
                  String(totalData[key]) +
                  (key != 'assigned' && key != 'revenue'
                    ? ' (' +
                      String(
                        Math.round(
                          (100 * totalData[key]) / totalData['assigned']
                        )
                      ) +
                      '%' +
                      ')'
                    : ''),
                field: key,
                floatingFilter: false,
                cellRenderer: cellRenderer,
                // width: 150
                autoHeight: true,
                width:
                  data && data.length > 0
                    ? window.screen.width / Object.keys(data[0]).length
                    : 180
              }
            }
          })
      : []

  headers = [
    {
      headerName: 'sales poc',
      field: 'sales_poc',
      filter: 'agTextColumnFilter',
      floatingFilter: false,
      filterParams: {
        caseSensitive: false,
        defaultOption: 'contains'
      },
      autoWidth: true,
      pinned: 'left',
      rowDrag: true,
      width:
        data && data.length > 0
          ? window.screen.width / Object.keys(data[0]).length
          : 180
    },
    ...headers
  ]

  return (
    <div className='h-[50rem] w-full dark:bg-black bg-white  dark:bg-dot-white/[0.2] bg-dot-black/[0.2] relative flex items-center justify-center'>
      {/* Radial gradient for the container to give a faded look */}
      <div className='absolute pointer-events-none inset-0 flex items-center justify-center dark:bg-black bg-white [mask-image:radial-gradient(ellipse_at_center,transparent_20%,black)]'></div>
      <div className='w-screen bg-opacity-0'>
        <Navbar
          fixed='top'
          style={{
            borderWidth: 0
          }}
        >
          <div className='w-screen '>
            {/* <div className='py-0 '> */}
            <div
              className='content-center'
              style={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <h4 className='text-3xl text-slate-600 font-bold mx-auto'>
                Premium Sales Performance
              </h4>
            </div>
            <div
              className='pt-1 content-center'
              style={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Col sm='0'>
                <Form.Control
                  disabled={isFetching}
                  defaultValue={defaultStartDate}
                  onChange={handleStartDate}
                  type='date'
                  size='md'
                />
              </Col>
              <Col sm='0'>
                <Form.Control
                  disabled={isFetching}
                  defaultValue={defaultEndDate}
                  onChange={handleEndDate}
                  type='date'
                  size='md'
                />
              </Col>
              <Col sm='0'>
                <Button
                  className='mb-2'
                  size='sm'
                  id='toggle-check'
                  variant='danger'
                  onClick={() => handleGoClick()}
                  disabled={isFetching}
                >
                  Go
                </Button>
              </Col>
              <Col sm='0'>
                <Button className='' variant='secondary' size='sm'>
                  <CSVLink
                    filename='premium-sales-performance-table.csv'
                    data={data}
                  >
                    <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                  </CSVLink>
                </Button>
              </Col>
            </div>
          </div>
        </Navbar>
        <div className='pt-28 bg-opacity-0 w-screen h-screen '>
          {' '}
          {!isFetching && !isError && data && data.length == 0 ? (
            <div className='flex justify-center items-center text-center h-full'>
              <TypewriterEffect
                className='text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-400 to-neutral-500 py-8'
                words={'Click "GO" to generate Sales Performance Report!'
                  .split(' ')
                  .map(word => {
                    return { text: word }
                  })}
              ></TypewriterEffect>
            </div>
          ) : !isFetching && !isError && data && data.length > 0 ? (
            <div style={gridStyle} className={'ag-theme-quartz'}>
              <AgGridReact
                rowData={data}
                columnDefs={headers}
                defaultColDef={defaultColDef}
                rowDragManaged={true}
                rowSelection='multiple'
                rowDragMultiRow={true}
                rowStyle={rowStyle}
                getRowStyle={getRowStyle}
                sideBar={true}
              />
            </div>
          ) : isFetching ? (
            // <div className='w-full h-[60vh] flex items-center justify-center'>
            <MultiStepLoader
              className='bg-opacity-0'
              loadingStates={loadingStates}
              loading={isFetching}
              duration={2000}
            ></MultiStepLoader>
          ) : // </div>
          isError ? (
            <div className='flex justify-center items-center text-center h-full'>
              <TypewriterEffect
                className='text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-400 to-neutral-500 py-8'
                words={'Server returned no data!'.split(' ').map(word => {
                  return { text: word }
                })}
              ></TypewriterEffect>
            </div>
          ) : (
            <div className='flex justify-center items-center text-center h-full'>
              <TypewriterEffect
                className='text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-400 to-neutral-500 py-8'
                words={'Unrecognized error'.split(' ').map(word => {
                  return { text: word }
                })}
              ></TypewriterEffect>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default PremiumSalesPerformance
