import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { Button, Badge } from 'react-bootstrap'
import axios from 'axios'
import { useState } from 'react'
import APIRequestStatusModal from '../CommonSubComponents/APIRequestStatusMessageModal'
import { ListGroup } from 'react-bootstrap'
import InputGroup from 'react-bootstrap/InputGroup'

function AssignAdTagModal ({
  show,
  showSetter,
  APIEndpointDomain,
  setClusterNames,
  adInformation,
  adsTagged,
  setAdsTagged,
  adsTaggedIDs,
  clusterNames,
  inputtedName,
  setInputtedName
}) {
  console.log('tagged', adsTagged)
  console.log(adInformation)

  var selectedAdName = adInformation['ad_name']
  var selectedAID = adInformation['ad_id']
  var [tagSearch, setTagSearch] = useState('')
  var [showAPIStatusModal, setShowAPIStatusModal] = useState(false)
  var [showAPIStatusMessage, setShowAPIStatusMessage] = useState(false)

  function assignTag (APIEndpointDomain, selectedIDs, showSetter) {
    axios
      .get(APIEndpointDomain + '/performance/assign-tag', {
        params: {
          tag_name: inputtedName,
          selected_aid: selectedAID
        },
        headers: {
          'ngrok-skip-browser-warning': 'any',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        console.log(response)
        console.log('ads', adsTagged)
        setClusterNames(response.data['all_tags'])
        setShowAPIStatusMessage('Successfully assigned your tag to these ads!')
        showSetter(false)
        setShowAPIStatusModal(true)
        setAdsTagged([
          ...adsTagged.filter(ad => ad.ad_id != Number(selectedAID)),
          { tag_name: inputtedName, ad_id: Number(selectedAID) }
        ])
      })
      .catch(error => {
        setShowAPIStatusMessage('Failed to assign the tag!')

        showSetter(false)
        console.log(error)
        setShowAPIStatusModal(true)
      })
  }

  function AddClusterName (cluster) {
    if (inputtedName == '') {
      setInputtedName(cluster)
    } else {
      setInputtedName(inputtedName + ', ' + cluster)
    }
  }

  function removeClusterName (clusterToRemove) {
    if (inputtedName !== '') {
      const clusterArray = inputtedName.split(', ')
      const updatedClusterArray = clusterArray.filter(
        cluster => cluster !== clusterToRemove
      )
      setInputtedName(updatedClusterArray.join(', '))
    }
  }

  return (
    <div>
      <APIRequestStatusModal
        show={showAPIStatusModal}
        setShow={setShowAPIStatusModal}
        message={showAPIStatusMessage}
      />
      <Modal size='lg' show={show}>
        <Modal.Header
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            backgroundColor: '#525252',
            color: 'white'
          }}
          closeButton
          onClick={() => showSetter(false)}
        >
          <Modal.Title id='contained-modal-title-vcenter'>
            {selectedAdName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group
              className='mb-3'
              controlId='exampleForm.ControlTextarea1'
            >
              <InputGroup className='mb-3'>
                <InputGroup.Text id='basic-addon1'>Tags added</InputGroup.Text>
                <Form.Control
                  onChange={e => setInputtedName(e.target.value)}
                  type='text'
                  value={inputtedName}
                />
              </InputGroup>
            </Form.Group>
          </Form>
          <Form.Label>Choose from existing tags:</Form.Label>
          <Form.Control
            onChange={e => setTagSearch(e.target.value)}
            type='text'
            placeholder='search tags containing...'
            value={tagSearch}
          />
          <ListGroup horizontal='sm' className='flex-wrap'>
            {clusterNames.map(cluster =>
              cluster.includes(tagSearch.toLowerCase()) ? (
                <div className='py-1'>
                  <div style={{ marginRight: '5px' }}>
                    {/* <ListGroup.Item> */}
                    <Badge
                      id='toggle-check'
                      type='checkbox'
                      bg={
                        inputtedName.includes(cluster.toLowerCase())
                          ? 'success'
                          : 'light'
                      }
                      // checked={inputtedName.includes(
                      //     cluster.toLowerCase()
                      //   )}
                      value={cluster}
                      // key={index}
                      onClick={() =>
                        inputtedName.includes(cluster)
                          ? removeClusterName(cluster)
                          : AddClusterName(cluster)
                      }
                    >
                      {cluster}
                    </Badge>
                    {/* </ListGroup.Item> */}
                  </div>
                </div>
              ) : null
            )}
          </ListGroup>
        </Modal.Body>
        <Button
          onClick={() => assignTag(APIEndpointDomain, selectedAID, showSetter)}
          style={{ backgroundColor: '#186900' }}
          className='alarm-badge'
          size='xl'
        >
          {'Assign Tag'}
        </Button>
      </Modal>
    </div>
  )
}

export default AssignAdTagModal
