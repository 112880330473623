import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";

function AddClientModal({
  addClientModalShow,
  setAddClientModalShow,
  clientFormData,
  setClientFormData,
  handleClientModalSubmit,
}) {
  return (
    <Modal size="xl" show={addClientModalShow}>
      <Modal.Header style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            backgroundColor: "#525252",
            color: "white",
          }} closeButton onClick={() => setAddClientModalShow(false)}>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Unattributed Clients To Your Reports
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row mb-3 mt-3">
          <div className="col">
            <Form.Label>Campaign ID</Form.Label>
            <Form.Control
              type="number"
              value={clientFormData.campaign_id}
              onChange={(e) =>
                setClientFormData({
                  ...clientFormData,
                  campaign_id: e.target.value,
                })
              }
            />
          </div>
          <div className="col">
            <Form.Label>Adset ID</Form.Label>
            <Form.Control
              type="number"
              value={clientFormData.adset_id}
              onChange={(e) =>
                setClientFormData({
                  ...clientFormData,
                  adset_id: e.target.value,
                })
              }
            />
          </div>
          <div className="col">
            <Form.Label>Ad ID</Form.Label>
            <Form.Control
              type="number"
              value={clientFormData.ad_id}
              onChange={(e) =>
                setClientFormData({
                  ...clientFormData,
                  ad_id: e.target.value,
                })
              }
            />
          </div>
          <div className="col">
            <Form.Label>Amount Sold</Form.Label>
            <Form.Control
              type="number"
              value={clientFormData.amount_sold}
              onChange={(e) =>
                setClientFormData({
                  ...clientFormData,
                  amount_sold: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="row mt-3 mb-3">
          <div className="col">
            <Form.Label>Converted At</Form.Label>
            <Form.Control
              type="date"
              value={clientFormData.converted_at}
              onChange={(e) =>
                setClientFormData({
                  ...clientFormData,
                  converted_at: e.target.value,
                })
              }
            />
          </div>
          <div className="col">
            <Form.Label>Created At</Form.Label>
            <Form.Control
              type="date"
              value={clientFormData.created_at}
              onChange={(e) =>
                setClientFormData({
                  ...clientFormData,
                  created_at: e.target.value,
                })
              }
            />
          </div>
          <div className="col">
            <Form.Label>Ad Account</Form.Label>

            <Dropdown>
              <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                {clientFormData.ad_account}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={(e) =>
                    setClientFormData({
                      ...clientFormData,
                      ad_account: e.target.text,
                    })
                  }
                >
                  MentR_Me
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={(e) =>
                    setClientFormData({
                      ...clientFormData,
                      ad_account: e.target.text,
                    })
                  }
                >
                  MiM_Essay
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="col">
            <Form.Label>Lead Type</Form.Label>

            <Dropdown>
              <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                {clientFormData.lead_type}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={(e) =>
                    setClientFormData({
                      ...clientFormData,
                      lead_type: e.target.text,
                    })
                  }
                >
                  Premium
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={(e) =>
                    setClientFormData({
                      ...clientFormData,
                      lead_type: e.target.text,
                    })
                  }
                >
                  CPA
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="row mt-5">
          <div
            className="col"
            style={{ display: "flex", justifyContent: "right" }}
          >
            <Button
              variant="success
        "
              type="submit"
              onClick={handleClientModalSubmit}
            >
              Add Client Data
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default AddClientModal;
