import Table from 'react-bootstrap/Table'
import { useState } from 'react'
import '../css/MarketingTable.css'
import { useEffect, useMemo, useRef } from 'react'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faStar,
  faStarHalf,
  faDownload
} from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-bootstrap/Modal'
import axios from 'axios'
import LoadingMessage from './CommonSubComponents/LoadingMessage.js'
import ServerReturnedNoDataMessage from './CommonSubComponents/ServerReturnedNoDataMessage.js'
import NoDataMessage from './CommonSubComponents/NoDataMessage.js'
import UnknownErrorMessage from './CommonSubComponents/UnknownErrorMessage.js'
import FavoritesTable from './MarketingTableSubComponents/FavoritesTable.js'
import GeneralTDClickModal from './PerformanceTableSubComponents/GeneralTDClickModal.js'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css' // Core CSS
import 'ag-grid-community/styles/ag-theme-quartz.css' // Theme
import FirstLoad from './CommonSubComponents/FirstLoad.js'
import Badge from 'react-bootstrap/Badge'
import { MultiStepLoader } from './ui/multi-step-loader.js'
import { TypewriterEffect } from './ui/typewriter-effect.js'
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger
} from './ui/drawer.js'
import { ShadCNButton } from '../components/ui/shadcn-button'
import * as React from 'react'
import { Minus, Plus } from 'lucide-react'
import { ResponsiveContainer } from 'recharts'
import { CSVLink } from 'react-csv'

function PerformanceTable ({
  startDate,
  endDate,
  selectedLeadType,
  selectedDomain: selectedAdAccount,
  tableData,
  campaignSearch,
  sourceSearch,
  mediumSearch,
  idSearch,
  termSearch,
  contentSearch,
  selectedSourceType,
  loadingP,
  setLoadingP,
  advancedFilterSelections,
  selectedColumnFilters,
  advancedFilterModalShow,
  setSelectedRows,
  selectedRows,
  checkAuthenticationInResponse,
  APIEndpointDomain,
  Data,
  setData,
  selectedGrouping,
  showOnlyCreatedBetween,
  compareRangeRequirement,
  comparisonTableData,
  PrimaryRangeProcessed,
  CompareRangeProcessed,
  setPrimaryRangeProcessed,
  setCompareRangeProcessed,
  tableDataAPICalling,
  selectedLevel,
  favoritesData,
  setFavoritesData,
  DataIsLeadType,
  isError
}) {
  var loadingStates = [
    {
      text: 'Connecting to Server'
    },
    {
      text: 'Fetching Appointments'
    },

    {
      text: 'Performing Lead Calculations'
    },

    {
      text: 'Finalizing data'
    }
  ]
  var [DeletionData, setDeletionData] = useState()
  var [sortType, setSortType] = useState()
  var [sortHeader, setSortHeader] = useState('Spend')
  var [headerTotal, setHeaderTotal] = useState({})
  var [sortHeaderDependency, setSortHeaderDependency] = useState(false)
  var [showDeletionModal, setShowDeletionModal] = useState(false)
  var [clickedDeletionUTMParams, setClickedDeletionUTMParams] = useState({
    utm_source: null,
    utm_medium: null,
    utm_campaign: null,
    utm_id: null,
    utm_term: null,
    utm_content: null
  })

  var [showTableDataClickModal, setShowTableDataClickModal] = useState(false)

  var [clickedTableDataUTMParams, setClickedTableDataUTMParams] = useState({
    utm_source: null,
    utm_medium: null,
    utm_campaign: null,
    utm_id: null,
    utm_term: null,
    utm_content: null
  })

  function handleDeletionDataClick (name, column_name) {
    showDeletionModal === false
      ? setShowDeletionModal(true)
      : setShowDeletionModal(false)

    setClickedDeletionUTMParams({
      name: name,
      column_name: column_name,
      source_type: selectedSourceType,
      source_grouped: selectedGrouping.source,
      medium_grouped: selectedGrouping.medium,
      campaign_grouped: selectedGrouping.campaign,
      id_grouped: selectedGrouping.id,
      term_grouped: selectedGrouping.term,
      content_grouped: selectedGrouping.content
    })

    axios
      .get(APIEndpointDomain + '/marketing/deletion', {
        params: {
          start_date: startDate,
          end_date: endDate,
          lead_type: selectedLeadType.toLowerCase().replace('-', '_'),
          ad_account: selectedAdAccount.toLowerCase().replace('-', '_'),
          name: name,
          column_name: column_name,
          source_type: clickedDeletionUTMParams['source_type'],
          source_grouped: clickedDeletionUTMParams['source_grouped'],
          medium_grouped: clickedDeletionUTMParams['medium_grouped'],
          campaign_grouped: clickedDeletionUTMParams['campaign_grouped'],
          id_grouped: clickedDeletionUTMParams['id_grouped'],
          term_grouped: clickedDeletionUTMParams['term_grouped'],
          content_grouped: clickedDeletionUTMParams['content_grouped']
        },
        headers: {
          'ngrok-skip-browser-warning': 'any',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        const responseData = response.data['deletion_data']
        const filteredReponse = responseData.filter(row => {
          return row.count > 0
        })
        setDeletionData(filteredReponse)
      })
      .catch(error => {
        setDeletionData([{ delete_reason: null }])
        checkAuthenticationInResponse(Number(error.response.status))
      })

    setDeletionData(null)
  }

  function saveFavorite (id, data) {
    if (!data) {
      var dataForName = Data.find(row => row.name === id)

      dataForName['start_date'] = startDate
      dataForName['end_date'] = endDate

      if (!dataForName['message']) {
        dataForName['message'] = ''
      }
    } else {
      var dataForName = data['favorite']
    }

    const encodedData = encodeURIComponent(JSON.stringify(dataForName))

    axios
      .get(APIEndpointDomain + '/marketing/save-favorite', {
        params: {
          id: id,
          favorite: encodedData
        },
        headers: {
          'ngrok-skip-browser-warning': 'any',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        console.log(response)
        const favorites = response.data['favorite']
        setFavoritesData(favorites)
      })
      .catch(error => {
        console.log(error)
      })
  }

  function updateFavoriteMessage (row, message) {
    const encodedData = encodeURIComponent(JSON.stringify(row))

    axios
      .get(APIEndpointDomain + '/marketing/update-favorite-message', {
        params: {
          favorite: encodedData,
          new_message: message
        },
        headers: {
          'ngrok-skip-browser-warning': 'any',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        console.log(response)
        const favorites = response.data['favorite']

        setFavoritesData(favorites)
      })
      .catch(error => {
        console.log(error)
      })
  }
  var [trendlinesTD, setTrendlinesTD] = useState([])
  var [profileInformationTD, setProfileInformationTD] = useState([])
  var [currentStagesTD, setCurrentStagesTD] = useState([])

  function handleTableDataClick (name, column_name) {
    // setTrendlinesTD([])
    // setProfileInformationTD([])
    // setCurrentStagesTD([])
    // showTableDataClickModal === false
    //   ? setShowTableDataClickModal(true)
    //   : setShowTableDataClickModal(false)
    // setClickedTableDataUTMParams({
    //   name: name,
    //   column_name: column_name,
    //   source_type: selectedSourceType,
    //   source_grouped: selectedGrouping.source,
    //   medium_grouped: selectedGrouping.medium,
    //   campaign_grouped: selectedGrouping.campaign,
    //   id_grouped: selectedGrouping.id,
    //   term_grouped: selectedGrouping.term,
    //   content_grouped: selectedGrouping.content
    // })
    // axios
    //   .get(APIEndpointDomain + '/marketing/detailed-information', {
    //     params: {
    //       start_date: startDate,
    //       end_date: endDate,
    //       lead_type: selectedLeadType.toLowerCase().replace('-', '_'),
    //       ad_account: selectedAdAccount.toLowerCase().replace('-', '_'),
    //       name: name,
    //       column_name: column_name,
    //       source_type: selectedSourceType.toLowerCase(),
    //       source_grouped: selectedGrouping.source,
    //       medium_grouped: selectedGrouping.medium,
    //       campaign_grouped: selectedGrouping.campaign,
    //       id_grouped: selectedGrouping.id,
    //       term_grouped: selectedGrouping.term,
    //       content_grouped: selectedGrouping.content
    //     },
    //     headers: {
    //       'ngrok-skip-browser-warning': 'any',
    //       'Access-Control-Allow-Origin': '*',
    //       Authorization: `Bearer ${localStorage.getItem('access_token')}`
    //     }
    //   })
    //   .then(response => {
    //     console.log(response)
    //     if (
    //       response.data['stages'].length == 0 &&
    //       response.data['trendlines'].length == 0
    //     ) {
    //       alert(
    //         'There were no leads matching this filter, close popup and try again!'
    //       )
    //     }
    //     setTrendlinesTD(response.data['trendlines'])
    //     setProfileInformationTD(response.data['profile_information'])
    //     setCurrentStagesTD(response.data['stages'])
    //   })
    //   .catch(error => {
    //     console.log(error)
    //     checkAuthenticationInResponse(Number(error.response.status))
    //     alert('Failed to load, please close the popup and try again!')
    //   })
  }

  function isNumber (n) {
    return Number(n) === n
  }

  function calculateNewColumns (groupedData) {
    groupedData.map(data => {
      if (data['calls_booked_during']) {
        var valid_calls_cb_p =
          data['calls_booked_during'] !== 0 && data['calls_booked_during']
            ? (100 * data['valid_calls_cb']) / data['calls_booked_during']
            : 0

        var good_leads_cb_p =
          data['calls_booked_during'] !== 0 && data['calls_booked_during']
            ? (100 * data['good_leads_cb']) / data['calls_booked_during']
            : 0

        var assigned_calls_cb_p =
          data['calls_booked_during'] !== 0 && data['calls_booked_during']
            ? (100 * data['assigned_calls_cb']) / data['calls_booked_during']
            : 0

        var calls_left_cb_p =
          data['calls_booked_during'] !== 0 && data['calls_booked_during']
            ? (100 * data['calls_left_cb']) / data['calls_booked_during']
            : 0

        var dnp_cb_p =
          data['calls_booked_during'] !== 0 && data['calls_booked_during']
            ? (100 * data['dnp_cb']) / data['calls_booked_during']
            : 0

        var deleted_cb_p =
          data['calls_booked_during'] !== 0 && data['calls_booked_during']
            ? (100 * data['deleted_cb']) / data['calls_booked_during']
            : 0
      }

      var quality =
        data['leads'] !== 0
          ? (100 * data['qualified']) / (data['leads'] - data['pending'])
          : 0

      var acquisitions_lost = data['h_acquisitions'] - data['acquisitions']
      var acquisition_loss_rate =
        data['h_acquisitions'] > 0
          ? (100 * (data['h_acquisitions'] - data['acquisitions'])) /
            data['h_acquisitions']
          : 0

      var acquisition_validation_rate =
        data['actual_acquisitions'] +
          (data['h_acquisitions'] - data['acquisitions']) >
        0
          ? (100 * data['actual_acquisitions']) /
            (data['actual_acquisitions'] +
              (data['h_acquisitions'] - data['acquisitions']))
          : 0

      var acquisitions_pending_validation =
        data['h_acquisitions'] -
        (data['actual_acquisitions'] +
          (data['h_acquisitions'] - data['acquisitions']))

      var acquisitions_pending_validation_p =
        data['h_acquisitions'] > 0
          ? (100 *
              (data['h_acquisitions'] -
                (data['actual_acquisitions'] +
                  (data['h_acquisitions'] - data['acquisitions'])))) /
            data['h_acquisitions']
          : 0

      var ttl_revenue = data['revenue'] + data['admit_up_rev']

      var pending_p =
        data['leads'] !== 0 ? (100 * data['pending']) / data['leads'] : 0

      var ttl_revenue_du = data['admit_up_rev_dur'] + data['revenue_during']

      var good_percentage =
        data['qualified'] !== 0
          ? (100 * data['good_leads']) / data['qualified']
          : 0

      var h_good_percentage =
        data['qualified'] !== 0 ? (100 * data['h_good']) / data['qualified'] : 0

      var uhl_to_acquisition =
        data['h_ultra'] !== 0
          ? (100 * data['acquisitions']) / (data['h_ultra'] - data['ultra_hot'])
          : 0

      var pressure_to_acquisition =
        data['h_pressure'] !== 0
          ? (100 * data['acquisitions']) /
            (data['h_pressure'] - data['pressure_sell'])
          : 0

      var projected_acquisitions =
        data['acquisitions'] +
        (data['ultra_hot'] / 100) * uhl_to_acquisition +
        (data['pressure_sell'] / 100) * pressure_to_acquisition

      var avg_purchase =
        data['acquisitions'] !== 0 ? data['revenue'] / data['acquisitions'] : 0

      var avg_conversion_period =
        data['acquired_during'] !== 0
          ? data['conversion_period'] / data['acquired_during']
          : 0

      var avg_validation_period =
        data['actual_acquisitions'] !== 0
          ? data['validation_period'] / data['actual_acquisitions']
          : 0

      var avg_complete_cycle_period =
        data['actual_acquisitions'] !== 0
          ? data['creation_to_complete_validation_period'] /
            data['actual_acquisitions']
          : 0

      //

      var roi =
        data['spend'] !== 0
          ? (data['revenue'] + data['admit_up_rev']) / data['spend']
          : 0

      var cpra_during =
        data['real_acquisitions_during'] !== 0
          ? data['spend'] / data['real_acquisitions_during']
          : 0

      var real_roi_during =
        data['spend'] !== 0
          ? (100 * data['real_revenue_during']) / data['spend']
          : 0

      var roi_during =
        data['spend'] !== 0 ? data['revenue_during'] / data['spend'] : 0

      var cpl = data['leads'] !== 0 ? data['spend'] / data['leads'] : 0

      var cpql = data['qualified'] !== 0 ? data['spend'] / data['qualified'] : 0

      var cpgl =
        data['good_leads'] !== 0 ? data['spend'] / data['good_leads'] : 0

      var cpgl_during =
        data['good_leads_during'] !== 0
          ? data['spend'] / data['good_leads_during']
          : 0

      var cpa =
        data['acquisitions'] !== 0 ? data['spend'] / data['acquisitions'] : 0

      var h_cpa =
        data['h_acquisitions'] !== 0
          ? data['spend'] / data['h_acquisitions']
          : 0
      var cpaa =
        data['actual_acquisitions'] !== 0
          ? data['spend'] / data['actual_acquisitions']
          : 0

      var cpgl_hist = data['h_good'] !== 0 ? data['spend'] / data['h_good'] : 0

      var cpa_during =
        data['acquired_during'] !== 0
          ? data['spend'] / data['acquired_during']
          : 0

      if (data['calls_booked_during']) {
        data['valid_calls_cb_p'] = valid_calls_cb_p
        data['assigned_calls_cb_p'] = assigned_calls_cb_p
        data['calls_left_cb_p'] = calls_left_cb_p
        data['dnp_cb_p'] = dnp_cb_p
        data['deleted_cb_p'] = deleted_cb_p
        data['good_leads_cb_p'] = good_leads_cb_p
      }

      data['acquisitions_pending_validation'] = acquisitions_pending_validation
      data['acquisitions_pending_validation_p'] =
        acquisitions_pending_validation_p

      data['acquisitions_lost'] = acquisitions_lost
      data['acquisition_loss_rate'] = acquisition_loss_rate
      data['avg_complete_cycle_period'] = avg_complete_cycle_period

      data['quality'] = isNumber(quality) ? Math.round(quality) : 0

      data['total_revenue'] = ttl_revenue

      data['pending_p'] = isNumber(pending_p)
        ? Math.round(pending_p)
        : pending_p

      data['total_revenue_during'] = ttl_revenue_du
      data['good_percentage'] = good_percentage
      data['h_good_p'] = h_good_percentage
      data['pressure_to_acquisition'] = pressure_to_acquisition
      data['uhl_to_acquisition'] = uhl_to_acquisition

      data['avg_validation_period'] = avg_validation_period

      data['projected_acquisitions'] = projected_acquisitions
      data['real_roi_during'] = isNumber(real_roi_during)
        ? Math.round(real_roi_during)
        : real_roi_during

      data['avg_purchase'] = avg_purchase
      data['avg_conversion_period'] = avg_conversion_period
      data['acquisition_validation_rate'] = acquisition_validation_rate
      data['upfront_payments'] =
        isNumber(data['revenue']) && selectedLeadType.toLowerCase() === 'cpa'
          ? data['revenue'] - 20000 * data['acquisitions']
          : isNumber(data['revenue']) &&
            selectedLeadType.toLowerCase() === 'premium'
          ? data['revenue']
          : 0

      data['upfront_payments_during'] =
        isNumber(data['revenue_during']) &&
        selectedLeadType.toLowerCase() === 'cpa'
          ? data['revenue_during'] - 20000 * data['acquired_during']
          : isNumber(data['revenue_during']) &&
            selectedLeadType.toLowerCase() === 'premium'
          ? data['revenue_during']
          : 0

      data['cpra_during'] = isNumber(cpra_during)
        ? Math.round(cpra_during)
        : cpra_during
      data['roi'] = 100 * roi
      data['roi_during'] = 100 * roi_during
      data['cpl'] = cpl
      data['cpql'] = cpql
      data['cpgl'] = cpgl
      data['cpa'] = cpa
      data['h_cpa'] = h_cpa
      data['cpaa'] = cpaa
      data['h_cpgl'] = cpgl_hist
      data['cpa_during'] = cpa_during
      data['cpgl_during'] = cpgl_during
    })

    return groupedData
  }

  function sumEachColumn (data) {
    var useData =
      selectedNames.length > 0
        ? data.filter(row => selectedNames.includes(row.name))
        : data

    var calculatedHeader = {}

    useData.forEach(obj => {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          calculatedHeader[key] = (calculatedHeader[key] || 0) + obj[key]
        }
      }
    })

    return calculatedHeader
  }

  function calculateHeaderTotals (calculatedHeader) {
    calculatedHeader['name'] = ''
    calculatedHeader['utm_source'] = ''
    calculatedHeader['utm_medium'] = ''
    calculatedHeader['utm_campaign'] = ''
    calculatedHeader['utm_id'] = ''
    calculatedHeader['utm_term'] = ''
    calculatedHeader['utm_content'] = ''

    if (calculatedHeader['calls_booked_during']) {
      calculatedHeader['good_leads_cb_p'] =
        calculatedHeader['calls_booked_during'] !== 0
          ? (100 * calculatedHeader['good_leads_cb']) /
            calculatedHeader['calls_booked_during']
          : 0

      calculatedHeader['valid_calls_cb_p'] =
        calculatedHeader['calls_booked_during'] !== 0
          ? (100 * calculatedHeader['valid_calls_cb']) /
            calculatedHeader['calls_booked_during']
          : 0

      calculatedHeader['assigned_calls_cb_p'] =
        calculatedHeader['calls_booked_during'] !== 0
          ? (100 * calculatedHeader['assigned_calls_cb']) /
            calculatedHeader['calls_booked_during']
          : 0

      calculatedHeader['calls_left_cb_p'] =
        calculatedHeader['calls_booked_during'] !== 0
          ? (100 * calculatedHeader['calls_left_cb']) /
            calculatedHeader['calls_booked_during']
          : 0

      calculatedHeader['real_roi_during'] = calculatedHeader['spend']
        ? (100 * calculatedHeader['real_revenue_during']) /
          calculatedHeader['spend']
        : 0

      calculatedHeader['dnp_cb_p'] =
        calculatedHeader['calls_booked_during'] !== 0
          ? (100 * calculatedHeader['dnp_cb']) /
            calculatedHeader['calls_booked_during']
          : 0

      calculatedHeader['deleted_cb_p'] =
        calculatedHeader['calls_booked_during'] !== 0
          ? (100 * calculatedHeader['deleted_cb']) /
            calculatedHeader['calls_booked_during']
          : 0
    }

    calculatedHeader['quality'] =
      (100 * calculatedHeader['qualified']) /
      (calculatedHeader['leads'] - calculatedHeader['pending'])

    calculatedHeader['avg_complete_cycle_period'] =
      calculatedHeader['actual_acquisitions'] !== 0
        ? calculatedHeader['creation_to_complete_validation_period'] /
          calculatedHeader['actual_acquisitions']
        : 0
    calculatedHeader['cpra_during'] =
      calculatedHeader['real_acquisitions_during'] !== 0
        ? calculatedHeader['spend'] /
          calculatedHeader['real_acquisitions_during']
        : 0
    calculatedHeader['ttl_revenue'] =
      calculatedHeader['revenue'] + calculatedHeader['admit_up_rev']

    calculatedHeader['pending_p'] =
      (100 * calculatedHeader['pending']) / calculatedHeader['leads']

    calculatedHeader['good_percentage'] =
      (100 * calculatedHeader['good_leads']) / calculatedHeader['qualified']

    calculatedHeader['h_good_p'] =
      (100 * calculatedHeader['h_good']) / calculatedHeader['qualified']

    calculatedHeader['ttl_revenue_du'] =
      calculatedHeader['admit_up_rev_dur'] + calculatedHeader['revenue_during']

    calculatedHeader['pressure_to_acquisition'] =
      (100 * calculatedHeader['acquisitions']) /
      (calculatedHeader['h_pressure'] - calculatedHeader['pressure_sell'])

    calculatedHeader['uhl_to_acquisition'] =
      (100 * calculatedHeader['acquisitions']) /
      (calculatedHeader['h_ultra'] - calculatedHeader['ultra_hot'])

    calculatedHeader['avg_purchase'] =
      calculatedHeader['revenue'] / calculatedHeader['acquisitions']

    calculatedHeader['acquisition_loss_rate'] =
      calculatedHeader['h_acquisitions'] !== 0
        ? (100 * calculatedHeader['acquisitions_lost']) /
          calculatedHeader['h_acquisitions']
        : 0

    calculatedHeader['acquisition_validation_rate'] =
      calculatedHeader['actual_acquisitions'] +
        calculatedHeader['acquisitions_lost'] !==
      0
        ? (100 * calculatedHeader['actual_acquisitions']) /
          (calculatedHeader['actual_acquisitions'] +
            calculatedHeader['acquisitions_lost'])
        : 0

    calculatedHeader['days_active'] = ''
    calculatedHeader['avg_conversion_period'] =
      calculatedHeader['conversion_period'] /
      calculatedHeader['acquired_during']
    calculatedHeader['avg_validation_period'] =
      calculatedHeader['validation_period'] /
      calculatedHeader['actual_acquisitions']

    calculatedHeader['acquisitions_pending_validation'] =
      calculatedHeader['h_acquisitions'] -
      (calculatedHeader['actual_acquisitions'] +
        (calculatedHeader['h_acquisitions'] - calculatedHeader['acquisitions']))

    calculatedHeader['acquisitions_pending_validation_p'] =
      calculatedHeader['h_acquisitions'] > 0
        ? (100 *
            (calculatedHeader['h_acquisitions'] -
              (calculatedHeader['actual_acquisitions'] +
                (calculatedHeader['h_acquisitions'] -
                  calculatedHeader['acquisitions'])))) /
          calculatedHeader['h_acquisitions']
        : 0

    //

    calculatedHeader['roi'] =
      calculatedHeader['spend'] !== 0
        ? (100 *
            (calculatedHeader['revenue'] + calculatedHeader['admit_up_rev'])) /
          calculatedHeader['spend']
        : 0

    calculatedHeader['roi_during'] =
      calculatedHeader['spend'] !== 0
        ? (100 * calculatedHeader['revenue_during']) / calculatedHeader['spend']
        : 0

    calculatedHeader['cpl'] =
      calculatedHeader['leads'] !== 0
        ? calculatedHeader['spend'] / calculatedHeader['leads']
        : 0

    calculatedHeader['cpql'] =
      calculatedHeader['qualified'] !== 0
        ? calculatedHeader['spend'] / calculatedHeader['qualified']
        : 0

    calculatedHeader['cpgl'] =
      calculatedHeader['good_leads'] !== 0
        ? calculatedHeader['spend'] / calculatedHeader['good_leads']
        : 0

    calculatedHeader['cpgl_during'] =
      calculatedHeader['good_leads_during'] !== 0
        ? calculatedHeader['spend'] / calculatedHeader['good_leads_during']
        : 0

    calculatedHeader['cpa'] =
      calculatedHeader['acquisitions'] !== 0
        ? calculatedHeader['spend'] / calculatedHeader['acquisitions']
        : 0

    calculatedHeader['h_cpa'] =
      calculatedHeader['h_acquisitions'] !== 0
        ? calculatedHeader['spend'] / calculatedHeader['h_acquisitions']
        : 0
    calculatedHeader['cpaa'] =
      calculatedHeader['actual_acquisitions'] !== 0
        ? calculatedHeader['spend'] / calculatedHeader['actual_acquisitions']
        : 0

    calculatedHeader['cpgl_hist'] =
      calculatedHeader['h_good'] !== 0
        ? calculatedHeader['spend'] / calculatedHeader['h_good']
        : 0

    calculatedHeader['cpa_during'] =
      calculatedHeader['acquired_during'] !== 0
        ? calculatedHeader['spend'] / calculatedHeader['acquired_during']
        : 0

    return calculatedHeader
  }

  function advanceFilteration (columnAddedData, advancedFilterSelections) {
    var advanceFilteredData = columnAddedData.filter(data => {
      return (
        data['total_revenue'] >= advancedFilterSelections['minRevenue'] &&
        data['total_revenue'] <= advancedFilterSelections['maxRevenue'] &&
        data['leads'] >= advancedFilterSelections['minLeads'] &&
        data['leads'] <= advancedFilterSelections['maxLeads'] &&
        data['qualified'] >= advancedFilterSelections['minQualified'] &&
        data['qualified'] <= advancedFilterSelections['maxQualified'] &&
        data['good_leads'] >= advancedFilterSelections['minGood'] &&
        data['good_leads'] <= advancedFilterSelections['maxGood'] &&
        data['acquisitions'] >= advancedFilterSelections['minClients'] &&
        data['acquisitions'] <= advancedFilterSelections['maxClients'] &&
        data['quality'] >= advancedFilterSelections['minQuality'] &&
        data['quality'] <= advancedFilterSelections['maxQuality'] &&
        data['acquired_during'] <=
          advancedFilterSelections['maxAcquisitionDuring'] &&
        data['acquired_during'] >=
          advancedFilterSelections['minAcquisitionDuring'] &&
        data['revenue_during'] <=
          advancedFilterSelections['maxRevenueDuring'] &&
        data['revenue_during'] >= advancedFilterSelections['minRevenueDuring']
      )
    })
    return advanceFilteredData
  }

  function groupByUTMParams (td) {
    if (td && td.length >= 1) {
      var groupings = Object.keys(selectedGrouping).filter(
        key => selectedGrouping[key] === true
      )

      groupings = groupings.map(key => 'utm_' + key)

      var removableColumns = [
        // "name",
        // "utm_source",
        // "utm_medium",
        // "utm_campaign",
        // "utm_id",
        // "utm_term",
        // "utm_content",
      ]

      var identifiers = []
      var groupedData = []
      var all_keys = Object.keys(td[0]).filter(
        key => !removableColumns.includes(key)
      )

      td.map(row => {
        var name = groupings
          .map(param => {
            return row[param]
          })
          .join(' | ')

        if (!identifiers.includes(name)) {
          identifiers.push(name)

          var updatedRow = {}

          all_keys.map(key => {
            if (
              key.includes('_export') &&
              (!Array.isArray(row[key]) || row[key] == 0)
            ) {
              updatedRow[key] = []
            } else {
              updatedRow[key] = row[key]
            }
          })

          updatedRow[name] = name
          updatedRow['name'] = name

          groupedData.push(updatedRow)
        } else if (identifiers.includes(name)) {
          var existingObject = groupedData.filter(
            groupedObject => groupedObject[name] == name
          )[0]

          // all_keys.forEach(key => {
          //   if (key.includes('_export') && row[key] == 0) {
          //     console.log('logging empty', [...existingObject[key]])
          //   } else if (key.includes('_export') && row[key] != 0) {
          //     console.log('logging non empty', [
          //       ...existingObject[key],
          //       ...row[key]
          //     ])
          //   }
          // })

          all_keys.map(key => {
            existingObject[key] =
              key.includes('_export') &&
              (!Array.isArray(row[key]) || row[key] == 0)
                ? [...existingObject[key]]
                : key.includes('_export')
                ? [...existingObject[key], ...row[key]]
                : existingObject[key] + row[key]
            existingObject['name'] = name
            existingObject[name] = name
          })
        }
      })
      return groupedData
    } else {
      return tableData
    }
  }

  function selectingRows (selectedRow) {
    var newSelectedRows = selectedNames.includes(selectedRow.name)
      ? selectedRows.filter(row => row.name !== selectedRow.name)
      : [...selectedRows, selectedRow]

    setSelectedRows(newSelectedRows)
  }

  function selectUnselectAll () {
    var rows = []

    if (selectedRows.length === 0) {
      for (let i = 0; i < Data.length; i++) {
        rows.push({
          name: Data[i]['name']
        })
      }
    }

    setSelectedRows(rows)
  }

  function handleTableHeaderClick (header) {
    sortType ? setSortType(false) : setSortType(true)
    setSortHeader(header)
    sortHeaderDependency
      ? setSortHeaderDependency(false)
      : setSortHeaderDependency(true)
  }

  var [subData, setSubData] = useState([{}])
  var [showNameClickModal, setShowNameClickModal] = useState(false)

  function onNameClick (name, column_name) {
    setSubData([{}])
    setClickedTableDataUTMParams({
      name: name,
      column_name: column_name,
      source_type: selectedSourceType,
      source_grouped: selectedGrouping.source,
      medium_grouped: selectedGrouping.medium,
      campaign_grouped: selectedGrouping.campaign,
      id_grouped: selectedGrouping.id,
      term_grouped: selectedGrouping.term,
      content_grouped: selectedGrouping.content
    })

    setShowNameClickModal(true)
    axios
      .get(APIEndpointDomain + '/marketing/source-breakdown', {
        params: {
          start_date: startDate,
          end_date: endDate,
          lead_type: selectedLeadType.toLowerCase().replace('-', '_'),
          domain: selectedAdAccount.toLowerCase().replace('-', '_'),
          created_between: showOnlyCreatedBetween,
          name: name,
          column_name: clickedTableDataUTMParams['column_name'],
          source_type: clickedTableDataUTMParams['source_type'],
          source_grouped: clickedTableDataUTMParams['source_grouped'],
          medium_grouped: clickedTableDataUTMParams['medium_grouped'],
          campaign_grouped: clickedTableDataUTMParams['campaign_grouped'],
          id_grouped: clickedTableDataUTMParams['id_grouped'],
          term_grouped: clickedTableDataUTMParams['term_grouped'],
          content_grouped: clickedTableDataUTMParams['content_grouped']
        },
        headers: {
          'ngrok-skip-browser-warning': 'any',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        console.log(response)

        const subResponseData = response.data['data']

        var subGroupedData = groupByUTMParams(subResponseData)

        var subDataAddedColumns = calculateNewColumns(subGroupedData)

        var subSortedData = sortType
          ? [...subDataAddedColumns].sort((a, b) => {
              if (a[sortHeader] < b[sortHeader]) {
                return -1
              } else if (a[sortHeader] > b[sortHeader]) {
                return 1
              }
              return 0
            })
          : [...subDataAddedColumns].sort((a, b) => {
              if (a[sortHeader] < b[sortHeader]) {
                return 1
              } else if (a[sortHeader] > b[sortHeader]) {
                return -1
              }
              return 0
            })

        var subReorderedData = subSortedData.map(row => {
          var rearrangedRow = {}
          var requiredColumnOrder = [
            'name',
            'leads_export',
            'qualified_export',
            'h_good_export',
            'h_ultra_export',
            'pending_export',
            'acquired_during_export',
            ...selectedColumnFilters
          ]

          requiredColumnOrder.forEach(key => {
            if (row.hasOwnProperty(key)) {
              rearrangedRow[key] = row[key]
            }
          })
          return rearrangedRow
        })

        setSubData(subReorderedData)
      })
      .catch(error => {
        checkAuthenticationInResponse(Number(error.response.status))
      })
  }

  useEffect(() => {
    setPrimaryRangeProcessed(false)

    var filteredData = tableData.filter(
      data =>
        data['utm_source']
          .toString()
          .toLowerCase()
          .includes(sourceSearch.toLowerCase()) &&
        data['utm_medium']
          .toString()
          .toLowerCase()
          .includes(mediumSearch.toLowerCase()) &&
        data['utm_campaign']
          .toString()
          .toLowerCase()
          .includes(campaignSearch.toLowerCase()) &&
        data['utm_id']
          .toString()
          .toLowerCase()
          .includes(idSearch.toLowerCase()) &&
        data['utm_term']
          .toString()
          .toLowerCase()
          .includes(termSearch.toLowerCase()) &&
        data['utm_content']
          .toString()
          .toLowerCase()
          .includes(contentSearch.toLowerCase())
    )

    var groupedData = groupByUTMParams(filteredData)

    var dataAddedColumns = calculateNewColumns(groupedData)

    // console.log(dataAddedColumns);

    var advanceFilteredData = advanceFilteration(
      dataAddedColumns,
      advancedFilterSelections
    )

    advanceFilteredData.length === 0 ? setLoadingP(10000) : setLoadingP(98)

    const headerTotals = calculateHeaderTotals(
      sumEachColumn(advanceFilteredData)
    )

    setHeaderTotal(headerTotals)

    var sortedData = sortType
      ? [...advanceFilteredData].sort((a, b) => {
          if (a[sortHeader] < b[sortHeader]) {
            return -1
          } else if (a[sortHeader] > b[sortHeader]) {
            return 1
          }
          return 0
        })
      : [...advanceFilteredData].sort((a, b) => {
          if (a[sortHeader] < b[sortHeader]) {
            return 1
          } else if (a[sortHeader] > b[sortHeader]) {
            return -1
          }
          return 0
        })

    var reorderedData = sortedData.map(row => {
      var rearrangedRow = {}
      var requiredColumnOrder = [
        'name',
        'pressure_sell',
        'ultra_hot',
        'deleted',
        'dnp',
        'lead_lost',
        'no_show',
        'nurturing',
        'backburners',
        'sales_usa_cpa',
        'sales_usa_pre',
        'sales_uk_cpa',
        'h_good_usa_cpa',
        'h_good_usa_pre',
        'h_good_uk_cpa',
        'acquired_during_uk_cpa',
        'acquired_during_usa_cpa',
        'acquired_during_usa_pre',
        'leads_export',
        'qualified_export',
        'h_good_export',
        'h_ultra_export',
        'pending_export',
        'acquired_during_export',
        ...selectedColumnFilters
      ]

      requiredColumnOrder.forEach(key => {
        if (row.hasOwnProperty(key)) {
          rearrangedRow[key] = row[key]
        }
      })
      return rearrangedRow
    })

    setPrimaryRangeProcessed(true)

    setData(reorderedData)
    setLoadingP(100)
  }, [
    selectedGrouping,
    sourceSearch,
    mediumSearch,
    campaignSearch,
    idSearch,
    termSearch,
    contentSearch,
    sortHeaderDependency,
    tableData,
    advancedFilterSelections,
    advancedFilterModalShow,
    selectedColumnFilters,
    selectedRows
  ])

  const gridRef = useRef()

  function onFilterChanged () {
    const filteredNodes = gridRef.current.api.getRenderedNodes()
    const filteredData = filteredNodes.map(node => node.data)
    const headerTotals = calculateHeaderTotals(sumEachColumn(filteredData))

    setHeaderTotal(headerTotals)
  }

  useEffect(() => {
    setCompareRangeProcessed(false)

    if (comparisonTableData.length > 0) {
      var filteredData = comparisonTableData.filter(
        data =>
          data['utm_source']
            .toString()
            .toLowerCase()
            .includes(sourceSearch.toLowerCase()) &&
          data['utm_medium']
            .toString()
            .toLowerCase()
            .includes(mediumSearch.toLowerCase()) &&
          data['utm_campaign']
            .toString()
            .toLowerCase()
            .includes(campaignSearch.toLowerCase()) &&
          data['utm_id']
            .toString()
            .toLowerCase()
            .includes(idSearch.toLowerCase()) &&
          data['utm_term']
            .toString()
            .toLowerCase()
            .includes(termSearch.toLowerCase()) &&
          data['utm_content']
            .toString()
            .toLowerCase()
            .includes(contentSearch.toLowerCase())
      )

      var groupedData = groupByUTMParams(filteredData)

      var dataAddedColumns = calculateNewColumns(groupedData)

      // console.log(dataAddedColumns);

      var advanceFilteredData = advanceFilteration(
        dataAddedColumns,
        advancedFilterSelections
      )

      advanceFilteredData.length === 0 ? setLoadingP(10000) : setLoadingP(98)

      // const headerTotals = calculateHeaderTotals(
      //   sumEachColumn(advanceFilteredData)
      // );

      // setHeaderTotal(headerTotals);

      var sortedData = sortType
        ? [...advanceFilteredData].sort((a, b) => {
            if (a[sortHeader] < b[sortHeader]) {
              return -1
            } else if (a[sortHeader] > b[sortHeader]) {
              return 1
            }
            return 0
          })
        : [...advanceFilteredData].sort((a, b) => {
            if (a[sortHeader] < b[sortHeader]) {
              return 1
            } else if (a[sortHeader] > b[sortHeader]) {
              return -1
            }
            return 0
          })

      var reorderedData = sortedData.map(row => {
        var rearrangedRow = {}
        var requiredColumnOrder = ['name', ...selectedColumnFilters]

        requiredColumnOrder.forEach(key => {
          if (row.hasOwnProperty(key)) {
            rearrangedRow[key] = row[key]
          }
        })
        return rearrangedRow
      })

      setComparisonData(reorderedData)
      setCompareRangeProcessed(true)
      setLoadingP(100)
    } else {
      setLoadingP(100)
      setComparisonData([])
      setCompareRangeProcessed(true)
    }
  }, [
    selectedGrouping,
    sourceSearch,
    mediumSearch,
    campaignSearch,
    idSearch,
    termSearch,
    contentSearch,
    sortHeaderDependency,
    comparisonTableData,
    advancedFilterSelections,
    advancedFilterModalShow,
    selectedColumnFilters,
    selectedRows
  ])

  useEffect(() => {
    if (!compareRangeRequirement && PrimaryRangeProcessed) {
      setLoadingP(100)
    } else if (
      compareRangeRequirement &&
      PrimaryRangeProcessed &&
      CompareRangeProcessed
    ) {
      setLoadingP(100)
    } else {
      // No action needed
    }
  }, [loadingP, PrimaryRangeProcessed, CompareRangeProcessed])

  var [ComparisonData, setComparisonData] = useState([])

  const includeColumns = selectedColumnFilters

  var tableDataColumnsAllowedToBeClickable = [
    'acquisitions',
    'acquired_during',
    'h_good',
    'good_leads',
    'leads',
    'qualified'
  ]

  var selectedNames = selectedRows.map(row => row.name)

  const ColumnSizeDecider =
    Data && Data.length > 0
      ? Object.keys(Data[0]).map((key, index) => {
          if (index === 0 && includeColumns.includes(key)) {
            return <col key={index} className='first-column' />
          } else if (includeColumns.includes(key)) {
            return (
              <col
                key={index}
                className={index === 1 ? 'second-column' : 'not-fixed-column'}
              />
            )
          }
        })
      : null
  var [detailedInformationTab, setDetailedInformationTab] = useState('Stages')
  const Headers =
    Data && Data.length > 0
      ? selectedColumnFilters.map((key, index) => {
          if (includeColumns.includes(key) === true) {
            return (
              <th
                key={index}
                onClick={() => handleTableHeaderClick(key)}
                className={
                  key === 'name' || key === 'adset_name' || key === 'ad_name'
                    ? 'second-column'
                    : 'other-columns'
                }
                style={{
                  textAlign: 'center'
                }}
              >
                {key !== 'campaign_name' &&
                key !== 'adset_name' &&
                key !== 'ad_name'
                  ? key.split('_').join(' ').toUpperCase().replace(' P', ' (%)')
                  : key
                      .split('_')
                      .join(' ')
                      .toUpperCase()
                      .replace(' P', ' (%)')}
              </th>
            )
          }
          return null
        })
      : null

  // NEW

  function selectionHeaderRenderer (params) {
    return (
      <p
        onClick={selectUnselectAll}
        key={10000000000}
        className='first-column'
        style={{
          textAlign: 'center'
        }}
      >
        {selectedRows.length === 0 ? 'Select  All' : 'Unselect'}
      </p>
    )
  }

  function handleCellClick (data) {
    var column = data.colDef.field
    var row = data.data

    if (column === 'deleted' || column === 'deleted_cb') {
      handleDeletionDataClick(row['name'], column)
    } else if (tableDataColumnsAllowedToBeClickable.includes(column)) {
      handleTableDataClick(row['name'], column)
    } else if (
      column === 'name' &&
      selectedSourceType.toLowerCase() !== 'combination'
    ) {
      onNameClick(row['name'], column)
    }
  }

  var percentageMetrics = [
    'acquisition_loss_rate',
    'acquisition_validation_rate',
    'acquisitions_pending_validation_p',
    'good_percentage',
    'pending_p',
    'pressure_to_acquisition',
    'projected_roi',
    'quality',
    'roi',
    'uhl_to_acquisition',
    'roi_during',
    'h_good_percentage',
    'h_good_p',
    'lead_to_h_good_percentage',
    'ctr',
    'hook_rate',
    'hold_rate',
    'view_to_click',
    'click_to_lead',
    'fifteen_to_25p',
    'twenty_five_p_to_50p',
    'fifty_p_to_75p',
    'valid_calls_cb_p',
    'assigned_calls_cb_p',
    'calls_left_cb_p',
    'dnp_cb_p',
    'deleted_cb_p',
    'good_leads_cb_p',
    'real_roi_during'
  ]

  var currencyMetrics = [
    'cpaa',
    'premium_ms_revenue',
    'avg_purchase',
    'budget',
    'cpa',
    'cpgl',
    'cpl',
    'cpql',
    'projected_cpa',
    'revenue',
    'total_revenue',
    'upfront_payments',
    'cpaa_during',
    'premium_ms_revenue_during',
    'admit_up_rev_dur',
    'cpa_during',
    'revenue_during',
    'total_revenue_during',
    'upfront_payments_during',
    'cpgl_historic',
    'h_cpa',
    'spend',
    'cpm',
    'cost_per_thruplay',
    'cpc',
    'cpra_during',
    'real_revenue_during',
    'h_cpgl'
  ]

  function NameCellRenderer (params) {
    var key = params.colDef['field']
    var row = params.data
    var data = row[key]
    var pinned = Object.keys(params.node).includes('rowPinned') ? true : false

    if (pinned === true) {
      return <></>
    } else if (key === 'name') {
      return (
        <>
          <p style={{ lineHeight: '1.8' }}>
            <strong>{data + ' '}</strong>
          </p>
        </>
      )
    }
  }

  function cellRenderer (params) {
    var key = params.colDef['field']
    var row = params.data
    var data = params.data[key]

    var pinned = Object.keys(params.node).includes('rowPinned') ? true : false
    var invalid_count = params.data['deleted']
      ? params.data['deleted']
      : 0 + params.data['dnp']
      ? params.data['dnp']
      : 0 + params.data['no_show']
      ? params.data['no_show']
      : 0

    var h_good_usa_cpa =
      params.data['h_good_usa_cpa'] +
      params.data['h_good_uk_cpa'] +
      params.data['h_good_usa_pre']
        ? Math.round(
            (100 * params.data['h_good_usa_cpa']) /
              (params.data['h_good_usa_cpa'] +
                params.data['h_good_uk_cpa'] +
                params.data['h_good_usa_pre'])
          ) + '%'
        : 0 + '%'
    var h_good_usa_pre =
      params.data['h_good_usa_cpa'] +
      params.data['h_good_uk_cpa'] +
      params.data['h_good_usa_pre']
        ? Math.round(
            (100 * params.data['h_good_usa_pre']) /
              (params.data['h_good_usa_cpa'] +
                params.data['h_good_uk_cpa'] +
                params.data['h_good_usa_pre'])
          ) + '%'
        : 0 + '%'
    var h_good_uk_cpa =
      params.data['h_good_usa_cpa'] +
      params.data['h_good_uk_cpa'] +
      params.data['h_good_usa_pre']
        ? Math.round(
            (100 * params.data['h_good_uk_cpa']) /
              (params.data['h_good_usa_cpa'] +
                params.data['h_good_uk_cpa'] +
                params.data['h_good_usa_pre'])
          ) + '%'
        : 0 + '%'

    var qualified_usa_cpa =
      params.data['sales_usa_cpa'] +
      params.data['sales_uk_cpa'] +
      params.data['sales_usa_pre']
        ? Math.round(
            (100 * params.data['sales_usa_cpa']) /
              (params.data['sales_usa_cpa'] +
                params.data['sales_uk_cpa'] +
                params.data['sales_usa_pre'])
          ) + '%'
        : 0 + '%'
    var qualified_usa_pre =
      params.data['sales_usa_cpa'] +
      params.data['sales_uk_cpa'] +
      params.data['sales_usa_pre']
        ? Math.round(
            (100 * params.data['sales_usa_pre']) /
              (params.data['sales_usa_cpa'] +
                params.data['sales_uk_cpa'] +
                params.data['sales_usa_pre'])
          ) + '%'
        : 0 + '%'
    var qualified_uk_cpa =
      params.data['sales_usa_cpa'] +
      params.data['sales_uk_cpa'] +
      params.data['sales_usa_pre']
        ? Math.round(
            (100 * params.data['sales_uk_cpa']) /
              (params.data['sales_usa_cpa'] +
                params.data['sales_uk_cpa'] +
                params.data['sales_usa_pre'])
          ) + '%'
        : 0 + '%'

    if (key == 'leads' && !pinned) {
      return (
        <p style={{ lineHeight: '1.5' }}>
          {data}
          <br></br>
          <Badge
            bg='#fad4cd'
            style={{ fontSize: '9.5px', background: '#fad4cd', color: 'black' }}
          >
            Invalid - {invalid_count}
          </Badge>{' '}
          <Badge
            bg='#fad4cd'
            style={{ fontSize: '9.5px', background: '#fad4cd', color: 'black' }}
          >
            BB - {params.data['backburners']}
          </Badge>
          <br></br>
          <Badge bg={'light'} pill>
            <CSVLink
              filename={key + '-crm-export.csv'}
              data={row['leads_export']}
            >
              <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
            </CSVLink>
          </Badge>
        </p>
      )
    } else if (
      key == 'qualified' &&
      !pinned &&
      ['premium', 'jobs'].includes(DataIsLeadType)
    ) {
      return (
        <>
          <p style={{ lineHeight: '1.5' }}>
            {data}
            <br></br>
            <Badge bg='success' style={{ fontSize: '9.5px' }}>
              PS - {params.data['pressure_sell']}
            </Badge>{' '}
            <Badge bg='success' style={{ fontSize: '9.5px' }}>
              UHL - {params.data['ultra_hot']}
            </Badge>
            <br></br>
            <Badge bg='primary' style={{ fontSize: '9.5px' }}>
              Nurture - {params.data['nurturing']}
            </Badge>{' '}
            <Badge
              bg='#fad4cd'
              style={{
                fontSize: '9.5px',
                background: '#fad4cd',
                color: 'black'
              }}
            >
              Lost - {params.data['lead_lost']}
            </Badge>
            <br></br>
            <Badge bg={'light'} pill>
              <CSVLink
                filename={key + '-crm-export.csv'}
                data={row['qualified_export']}
              >
                <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
              </CSVLink>
            </Badge>
          </p>
        </>
      )
    } else if (
      key === 'real_acquisitions_during' &&
      DataIsLeadType.toLowerCase() == 'cpa'
    ) {
      return (
        <>
          <p style={{ lineHeight: '1.8' }}>
            {data}
            {!pinned ? (
              <>
                <br></br>
                <Badge bg='success' style={{ fontSize: '9.5px' }}>
                  us cpa - {row['acquired_during_usa_cpa']}
                </Badge>{' '}
                <Badge bg='success' style={{ fontSize: '9.5px' }}>
                  uk cpa - {row['acquired_during_uk_cpa']}
                </Badge>
                <br></br>
                <Badge bg='primary' style={{ fontSize: '9.5px' }}>
                  us pre - {row['acquired_during_usa_pre']}
                </Badge>
              </>
            ) : null}
          </p>
        </>
      )
    } else if (key === 'acquired_during') {
      return (
        <>
          <p style={{ lineHeight: '1.8' }}>
            {data}
            {!pinned ? (
              <>
                <br></br>
                <Badge bg={'light'} pill>
                  <CSVLink
                    filename={key + '-crm-export.csv'}
                    data={row['acquired_during_export']}
                  >
                    <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                  </CSVLink>
                </Badge>
              </>
            ) : null}
          </p>
        </>
      )
    } else if (key === 'qualified' && DataIsLeadType.toLowerCase() == 'cpa') {
      return (
        <>
          <p style={{ lineHeight: '1.8' }}>
            {data}
            {!pinned ? (
              <>
                <br></br>
                <Badge bg='success' style={{ fontSize: '9.5px' }}>
                  us cpa - {qualified_usa_cpa}
                </Badge>{' '}
                <Badge bg='success' style={{ fontSize: '9.5px' }}>
                  uk cpa - {qualified_uk_cpa}
                </Badge>
                <br></br>
                <Badge bg='primary' style={{ fontSize: '9.5px' }}>
                  us pre - {qualified_usa_pre}
                </Badge>
                <br></br>
                <Badge bg={'light'} pill>
                  <CSVLink
                    filename={key + '-crm-export.csv'}
                    data={row['qualified_export']}
                  >
                    <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                  </CSVLink>
                </Badge>
              </>
            ) : null}
          </p>
        </>
      )
    } else if (key === 'h_good' && DataIsLeadType.toLowerCase() == 'cpa') {
      return (
        <>
          <p style={{ lineHeight: '1.8' }}>
            {data}
            {!pinned ? (
              <>
                <br></br>
                <Badge bg='success' style={{ fontSize: '9.5px' }}>
                  us cpa - {h_good_usa_cpa}
                </Badge>{' '}
                <Badge bg='success' style={{ fontSize: '9.5px' }}>
                  uk cpa - {h_good_uk_cpa}
                </Badge>
                <br></br>
                <Badge bg='primary' style={{ fontSize: '9.5px' }}>
                  us pre - {h_good_usa_pre}
                </Badge>
                <br></br>
                <Badge bg={'light'} pill>
                  <CSVLink
                    filename={key + '-crm-export.csv'}
                    data={row['h_good_export']}
                  >
                    <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                  </CSVLink>
                </Badge>
              </>
            ) : null}
          </p>
        </>
      )
    } else if (key === 'h_good') {
      return (
        <>
          <p style={{ lineHeight: '1.8' }}>
            {data}
            {!pinned ? (
              <>
                <br></br>
                <Badge bg={'light'} pill>
                  <CSVLink
                    filename={key + '-crm-export.csv'}
                    data={row['h_good_export']}
                  >
                    <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                  </CSVLink>
                </Badge>
              </>
            ) : null}
          </p>
        </>
      )
    } else if (key === 'h_ultra') {
      return (
        <>
          <p style={{ lineHeight: '1.8' }}>
            {data}
            {!pinned ? (
              <>
                <br></br>
                <Badge bg={'light'} pill>
                  <CSVLink
                    filename={key + '-crm-export.csv'}
                    data={row['h_ultra_export']}
                  >
                    <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                  </CSVLink>
                </Badge>
              </>
            ) : null}
          </p>
        </>
      )
    } else if (percentageMetrics.includes(key)) {
      return <p> {Math.round(data).toLocaleString('en-IN') + '%'}</p>
    } else if (currencyMetrics.includes(key)) {
      return <p> {'₹' + Math.round(data).toLocaleString('en-IN')}</p>
    } else if (isNumber(data)) {
      return <p> {Math.round(data).toLocaleString('en-IN')}</p>
    } else if (key === 'avg_conversion_period') {
      return (
        <p>
          {' '}
          {data} + {' days'}
        </p>
      )
    } else {
      return <p> {data}</p>
    }
  }

  var headers =
    Data && Data.length > 0
      ? Object.keys(Data[0])
          .map((key, index) => (includeColumns.includes(key) ? key : null))
          .filter(key => key !== null && !key.includes('_export'))
      : []

  var headers = headers.map(header =>
    ['name'].includes(header)
      ? {
          headerName: 'Source',
          field: header,
          onCellClicked: handleCellClick,
          cellRenderer: NameCellRenderer,
          filter: 'agTextColumnFilter',
          floatingFilter: true,
          filterParams: {
            caseSensitive: false,
            defaultOption: 'contains'
          },
          wrapText: true,
          autoHeight: true,
          width: 180,
          pinned: ['name'].includes(header) ? 'left' : false
        }
      : {
          field: header,
          filter: 'agNumberColumnFilter',
          floatingFilter: true,
          onCellClicked: handleCellClick,
          cellRenderer: cellRenderer,
          autoHeight: true,
          width: ['leads', 'qualified', 'h_good'].includes(header) ? 170 : 130,
          pinned: ['budget', 'spend'].includes(header) ? 'left' : false
        }
  )

  function checkboxCellRenderer (params) {
    var key = params.colDef['field']
    var row = params.data
    var data = row[key]
    var pinned = Object.keys(params.node).includes('rowPinned') ? true : false

    if (!pinned) {
      return (
        <input
          checked={selectedNames.includes(row['name']) ? true : false}
          onClick={() => {
            selectingRows({
              name: row['name']
            })
          }}
          type='checkbox'
        ></input>
      )
    } else {
      return <></>
    }
  }

  var headers = [
    {
      field: 'Select',
      cellRenderer: checkboxCellRenderer,
      filter: false,
      rowDrag: true,
      sortable: false,
      headerComponent: selectionHeaderRenderer,

      wrapText: true,
      autoHeight: true,
      width: 100,
      pinned: 'left'
    },
    {
      field: '',
      cellRenderer: ButtonsCellRenderer,
      rowDrag: false,
      sortable: false,
      autoHeight: true,
      width: 100,
      pinned: 'left'
    },

    ...headers
  ]

  const defaultColDef = useMemo(() => {
    return {
      width: 170,
      filter: true,
      cellStyle: { fontSize: '14px', textAlign: 'center' },
      enableValue: true,
      enableRowGroup: true,
      enablePivot: true
    }
  }, [])

  function ButtonsCellRenderer (params) {
    var row = params.data
    var pinned = Object.keys(params.node).includes('rowPinned') ? true : false

    if (!pinned) {
      return (
        <>
          <p
            style={{
              textAlign: 'center'
            }}
            className={'first-column table-light'}
          >
            <Button
              variant={
                favoritesData.filter(
                  favorite =>
                    favorite['favorite']['name'] === row.name &&
                    favorite['favorite']['start_date'] == startDate &&
                    favorite['favorite']['end_date'] == endDate
                ).length > 0
                  ? 'success'
                  : 'dark'
              }
              onClick={() => saveFavorite(row.name)}
            >
              {favoritesData.filter(
                favorite =>
                  favorite['favorite']['name'] === row.name &&
                  favorite['favorite']['start_date'] == startDate &&
                  favorite['favorite']['end_date'] == endDate
              ).length > 0 ? (
                <FontAwesomeIcon className='fa-solid fa-star' icon={faStar} />
              ) : (
                <FontAwesomeIcon
                  className='fa-regular fa-star'
                  icon={faStarHalf}
                />
              )}
            </Button>
          </p>
        </>
      )
    } else {
      return <></>
    }
  }

  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), [])

  const rowStyle = { background: '#eaeaea' }

  // set background colour on even rows again, this looks bad, should be using CSS classes
  const getRowStyle = params => {
    var pinned = Object.keys(params.node).includes('rowPinned') ? true : false

    if (pinned) {
      return { background: '#2c9c72', color: 'white', fontWeight: 'bold' }
    } else if (params.node.rowIndex % 2 === 0) {
      return { background: '#f6f6f6' }
    }
  }

  var footerData = [
    {
      ...headerTotal,
      name: 'TOTAL',
      Select: '',
      '': '',
      budget: ''
    }
  ]

  return (
    <div className='pt-14 bg-opacity-0 w-screen h-screen '>
      {!tableDataAPICalling && !isError && (!Data || Data.length == 0) ? (
        <div className='flex justify-center items-center text-center h-full'>
          <TypewriterEffect
            className='text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-400 to-neutral-500 py-8'
            words={'Click "GO" and start analyzing!'.split(' ').map(word => {
              return { text: word }
            })}
          ></TypewriterEffect>
        </div>
      ) : Data.length > 0 &&
        Data &&
        loadingP > 99 &&
        loadingP < 101 &&
        !tableDataAPICalling &&
        selectedLevel === 'General Report' ? (
        <>
          <GeneralTDClickModal
            showTableDataClickModal={showTableDataClickModal}
            setShowTableDataClickModal={setShowTableDataClickModal}
            setDetailedInformationTab={setDetailedInformationTab}
            clickedTableDataIDS={clickedTableDataUTMParams}
            detailedInformationTab={detailedInformationTab}
            currentStagesTD={currentStagesTD}
            trendlinesTD={trendlinesTD}
            profileInformationTD={profileInformationTD}
          />
          <Modal size='lg' show={showDeletionModal}>
            <Modal.Header
              closeButton
              onClick={() => setShowDeletionModal(false)}
            >
              <Modal.Title id='contained-modal-title-vcenter'>
                Delete Reasons
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Table
                responsive='sm'
                striped
                hover
                size='sm'
                className='fixed-table'
                variant='dark'
              >
                <thead>
                  <tr>
                    <td>Reason</td>
                    <td>Leads</td>
                  </tr>
                </thead>
                {DeletionData ? (
                  <tbody>
                    {DeletionData.map((row, index) => {
                      return (
                        <tr>
                          <td key={index}>{row.delete_reason}</td>
                          <td>{row.count}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                ) : (
                  <tr>
                    <td>
                      <h3>Loading...</h3>
                    </td>
                  </tr>
                )}
              </Table>
            </Modal.Body>
          </Modal>
          {/* Subdata modal */}
          <Modal size='xl' show={showNameClickModal}>
            <Modal.Header
              closeButton
              onClick={() => setShowNameClickModal(false)}
            >
              <Modal.Title id='contained-modal-title-vcenter'>
                {selectedSourceType.toLowerCase() === 'ftp'
                  ? 'Last Touch Points'
                  : 'First Touch Points'}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Table
                responsive='xl'
                striped
                hover
                size='xl'
                className='fixed-table'
                variant='dark'
              >
                <thead>
                  <tr>
                    {Object.keys(subData[0]).map(key => (
                      <td>{key}</td>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(subData[0]).length >= 1 ? (
                    subData.map((row, index) => (
                      <tr key={index}>
                        {Object.keys(subData[0]).map(key => (
                          <td key={key}>{row[key]}</td>
                        ))}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td>
                        <h3>Loading...</h3>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Modal.Body>
          </Modal>
          <div style={gridStyle} className={' h-full ag-theme-quartz'}>
            <AgGridReact
              rowData={Data}
              columnDefs={headers}
              defaultColDef={defaultColDef}
              rowDragManaged={true}
              rowSelection='multiple'
              rowDragMultiRow={true}
              rowStyle={rowStyle}
              getRowStyle={getRowStyle}
              sideBar={true}
              pinnedBottomRowData={footerData}
              onFilterChanged={onFilterChanged}
              ref={gridRef}
            />
          </div>
        </>
      ) : selectedLevel === 'Custom Report' ? (
        <FavoritesTable
          favorites={favoritesData}
          updateMessage={updateFavoriteMessage}
          saveFavorite={saveFavorite}
          setFavorites={setFavoritesData}
        />
      ) : loadingP < 99 && loadingP > 2 ? (
        <MultiStepLoader
          className='bg-opacity-0'
          loadingStates={loadingStates}
          loading={tableDataAPICalling}
          duration={2000}
        ></MultiStepLoader>
      ) : isError ? (
        <div className='flex justify-center items-center text-center h-full'>
          <TypewriterEffect
            className='text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-400 to-neutral-500 py-8'
            words={'Server returned no data!'.split(' ').map(word => {
              return { text: word }
            })}
          ></TypewriterEffect>
        </div>
      ) : !tableDataAPICalling && loadingP === 100 && Data.length === 0 ? (
        <div className='flex justify-center items-center text-center h-full'>
          <TypewriterEffect
            className='text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-400 to-neutral-500 py-8'
            words={'No data found for selected filters!'
              .split(' ')
              .map(word => {
                return { text: word }
              })}
          ></TypewriterEffect>
        </div>
      ) : (
        <div className='flex justify-center items-center text-center h-full'>
          <TypewriterEffect
            className='text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-400 to-neutral-500 py-8'
            words={'Oops! Unknown error found🥲'.split(' ').map(word => {
              return { text: word }
            })}
          ></TypewriterEffect>
        </div>
      )}
    </div>
  )
}

export default PerformanceTable
