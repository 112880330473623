import '../css/MarketingHeader.css'
import { Navbar } from 'react-bootstrap'
import { useState } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import { Col } from 'react-bootstrap'
import { Button } from 'react-bootstrap'
import axios from 'axios'
function CommentManagementHeader ({
  onAdAccountSelect,
  setSelectedDomain,

  selectedDomain,
  APICalling,
  selectedCommentIDs,
  setSelectedCommentIDs,
  APIEndpointDomain,
  selectedAdAccount,
  setTableData,
  tableData
}) {
  var [isDeletingComments, setIsDeletingComments] = useState(false)

  function DeleteComments (comment_ids) {
    setIsDeletingComments(true)
    console.log(APIEndpointDomain + '/comment-management/delete-comments')
    console.log(comment_ids)
    axios
      .post(
        APIEndpointDomain + '/comment-management/delete-comments',
        {
          comment_ids: comment_ids,
          ad_account: selectedAdAccount,
          type: 'comment'
        },
        {
          headers: {
            'ngrok-skip-browser-warning': 'any',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`
          }
        }
      )
      .then(response => {
        console.log(response)
        setTableData(
          tableData.filter(row => !comment_ids.includes(row.comment_id))
        )
        // setShakeCommentIDs(shakeCommentIDs.filter(id => id !== comment_id))
        alert('Successfully deleted the comment!')
        setSelectedCommentIDs([])
        setIsDeletingComments(false)
      })
      .catch(error => {
        console.log(error)
        // setShakeCommentIDs(shakeCommentIDs.filter(id => id !== comment_id))
        alert('Failed to delete the comment!')
        setIsDeletingComments(false)
      })
  }

  return (
    <Navbar
      fixed='top'
      style={{
        borderWidth: 0
      }}
    >
      {' '}
      <div className='w-screen '>
        {/* <div className='py-0 '> */}
        <div
          className='content-center'
          style={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Dropdown className='ml-3 bg-opacity-0'>
            <Dropdown.Toggle variant='secondary' disabled={APICalling}>
              {selectedDomain}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                onClick={e => {
                  setSelectedDomain(e.target.text)
                  onAdAccountSelect(e.target.text)
                }}
                disabled={APICalling}
              >
                Prohed
              </Dropdown.Item>      
              <Dropdown.Item
                onClick={e => {
                  setSelectedDomain(e.target.text)
                  onAdAccountSelect(e.target.text)
                }}
                disabled={APICalling}
              >
                MentR-Me
              </Dropdown.Item>
              <Dropdown.Item
                onClick={e => {
                  setSelectedDomain(e.target.text)
                  onAdAccountSelect(e.target.text)
                }}
                disabled={APICalling}
              >
                MiM-Essay
              </Dropdown.Item>
              <Dropdown.Item
                onClick={e => {
                  setSelectedDomain(e.target.text)
                  onAdAccountSelect(e.target.text)
                }}
                disabled={APICalling}
              >
                MiM-Essay Alternate
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          {selectedCommentIDs.length > 0 && (
            <Button
              variant='danger'
              size='md'
              // style={{ textTransform: 'lowercase' }}
              disabled={isDeletingComments}
              onClick={() => DeleteComments(selectedCommentIDs)}
            >
              {isDeletingComments ? 'Deleting...' : 'Delete Selected'}
            </Button>
          )}

          <h4 className='mx-auto text-3xl text-slate-800 font-bold mx-auto'>
            Comment Manager
          </h4>
          {selectedCommentIDs.length > 0 && (
            <Button
              className='mr-3'
              variant='warning'
              size='md'
              disabled={isDeletingComments}
              onClick={() => setSelectedCommentIDs([])}
            >
              Unselect All
            </Button>
          )}
        </div>
      </div>
    </Navbar>
  )
}

export default CommentManagementHeader
