import { useParams } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Badge from "react-bootstrap/Badge";

import "../css/PerformanceCustomReportScreen.css";



function MarketingCustomReportScreen({ APIEndpointDomain }) {
  const { id } = useParams();

  const [favorites, setFavorites] = useState([]);

  var removableColumns = [
    "campaign_id",
    "adset_id",
    "ad_id",
    "campaign_name",
    "adset_name",
    "ad_name",
    "type",
    "status",
    "name",
    "message",
    "level",
    "start_date",
    "end_date",
  ];

  useEffect(() => {
    axios
      .get(APIEndpointDomain + "/marketing/get-custom-report", {
        params: {
          name: id,
          is_public: true,
        },
        headers: {
          "ngrok-skip-browser-warning": "any",
          "Access-Control-Allow-Origin": "*",
          //   Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        console.log(response);
        const favorites = response.data["custom_report"];
        setFavorites(favorites);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  var headers =
    favorites.length > 0
      ? Object.keys(favorites[0]["favorite"]).filter(
          (header) => !removableColumns.includes(header)
        )
      : [];


  function isNumber(n) {
    return Number(n) === n;
  }

  return (
    <div className="public-custom-report-primary-table-body-container">
      <Table
        responsive="sm"
        hover
        size="xl"
        className="fixed-table"
        variant="dark"
        striped
      >
        <thead>
          <tr>
            <th
              style={{
                textAlign: "center",
              }}
              className="first-column"
            >
              NAME
            </th>

            <th
              style={{
                textAlign: "center",
              }}
              className="other-columns"
            >
              MESSAGE
            </th>
            {headers.map((key) => (
              <th
                style={{
                  textAlign: "center",
                }}
              >
                {key !== "name"
                  ? key.split("_").join(" ").toUpperCase()
                  : key.split("_").join(" ").toUpperCase()}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {favorites.map((row) => (
            <tr>
              <td
                style={{
                  textAlign: "center",
                }}
                className="first-column table-light"
              >
                <p>
                  {row["favorite"]["name"]}
                  <br></br>
                  <Badge bg={"dark"}>
                    {row["favorite"]["start_date"] +
                      " TO " +
                      row["favorite"]["end_date"]}
                  </Badge>
                </p>
              </td>

              <td
                style={{
                  textAlign: "center",
                  minWidth: "250px",
                }}
                className="other-columns table-light"
              >
                {row["favorite"]["message"]}
              </td>

              {headers.map((key) => (
                <td
                  className="other-columns table-light"
                  style={{
                    textAlign: "center",
                  }}
                >
                  {(key === "roi" ||
                    key === "pending_p" ||
                    key === "quality" ||
                    key === "roi_during" ||
                    key === "good_percentage" ||
                    key === "h_good_percentage" ||
                    key === "uhl_to_acquisition" ||
                    key === "pressure_to_acquisition" ||
                    key === "projected_roi" ||
                    key === "acquisition_loss_rate" ||
                    key === "acquisition_validation_rate" ||
                    key === "acquisitions_pending_validation_p") &&
                  isNumber(row["favorite"][key]) ? (
                    <p>
                      {Math.round(row["favorite"][key]).toLocaleString(
                        "en-IN"
                      ) + "%"}
                    </p>
                  ) : (key === "cpl" ||
                      key === "cpql" ||
                      key === "cpgl" ||
                      key === "cpa" ||
                      key === "spend" ||
                      key === "revenue" ||
                      key === "revenue_during" ||
                      key === "total_revenue" ||
                      key === "total_revenue_during" ||
                      key === "cpa_during" ||
                      key === "cpg_during" ||
                      key === "cpgl_historic" ||
                      key === "admit_up_rev" ||
                      key === "admit_up_rev_dur" ||
                      key === "projected_cpa" ||
                      key === "avg_purchase" ||
                      key === "budget" ||
                      key === "premium_ms_revenue" ||
                      key === "premium_ms_revenue_during" ||
                      key === "h_cpa" ||
                      key === "cpaa" ||
                      key === "cpaa_during") &&
                    isNumber(row["favorite"][key]) ? (
                    <p>
                      {"₹" +
                        Math.round(row["favorite"][key]).toLocaleString(
                          "en-IN"
                        )}
                    </p>
                  ) : isNumber(row["favorite"][key]) ? (
                    <p>
                      {Math.round(row["favorite"][key])
                        .toLocaleString("en-IN")
                        .toString()}
                    </p>
                  ) : key === "avg_conversion_period" ? (
                    row["favorite"][key] + " days"
                  ) : (
                    <p>{row["favorite"][key]}</p>
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default MarketingCustomReportScreen;
