import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import axios from "axios";
import { useState } from "react";
import APIRequestStatusModal from "../CommonSubComponents/APIRequestStatusMessageModal";



function ChangeAdStatusConfirmationModal({
  show,
  showSetter,
  APIEndpointDomain,
  selectedLevel,
  selectedID,
  selectedAccount,
  selectedFolderName,
  activeAds,
  setActiveAds,
  activeAdsets,
  setActiveAdsets,
  activeCampaigns,
  setActiveCampaigns,
  currentStatus,
}) {
  var [showAPIStatusModal, setShowAPIStatusModal] = useState(false);
  var [showAPIStatusMessage, setShowAPIStatusMessage] = useState(false);
  var requiredStatus = currentStatus == "ACTIVE" ? "Pause" : "Active";


  function updateStatus(
    APIEndpointDomain,
    selectedLevel,
    selectedID,
    showSetter,
    currentStatus
  ) {
    var requiredStatus = currentStatus == "ACTIVE" ? "PAUSED" : "ACTIVE";
    axios
      .get(APIEndpointDomain + "/performance/update-status", {
        params: {
          account: selectedAccount,
          level: selectedLevel,
          id: selectedID,
          currentStatus: currentStatus,
        },
        headers: {
          "ngrok-skip-browser-warning": "any",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        console.log(response);
        if (selectedLevel === "Campaign" && requiredStatus == "PAUSED") {
          setActiveCampaigns([
            ...activeCampaigns.filter(
              (campaign_id) => Number(campaign_id) !== Number(selectedID)
            ),
          ]);
        } else if (selectedLevel === "Adset" && requiredStatus == "PAUSED") {
          setActiveAdsets([
            ...activeAdsets.filter(
              (adset_id) => Number(adset_id) != Number(selectedID)
            ),
          ]);
        } else if (selectedLevel === "Ad" && requiredStatus == "PAUSED") {
          setActiveAds([
            ...activeAds.filter((ad_id) => Number(ad_id) != Number(selectedID)),
          ]);
        } else if (selectedLevel === "Campaign" && requiredStatus == "ACTIVE") {
          setActiveCampaigns([...activeCampaigns, selectedID]);
        } else if (selectedLevel === "Adset" && requiredStatus == "ACTIVE") {
          setActiveAdsets([...activeAdsets, selectedID]);
        } else if (selectedLevel === "Ad" && requiredStatus == "ACTIVE") {
          setActiveAds([...activeAds, selectedID]);
        }

        setShowAPIStatusMessage(
          "Successfully updated your " +
            selectedLevel +
            " status to " +
            requiredStatus +
            "!"
        );
        showSetter(false);
        setShowAPIStatusModal(true);
      })
      .catch((error) => {
        setShowAPIStatusMessage(
          "Failed to update your " +
            selectedLevel +
            " status to " +
            requiredStatus +
            "!"
        );
        showSetter(false);
        console.log(error);
        setShowAPIStatusModal(true);
      });
  }

  return (
    <div>
      <APIRequestStatusModal
        show={showAPIStatusModal}
        setShow={setShowAPIStatusModal}
        message={showAPIStatusMessage}
      />
      <Modal size="sm" show={show}>
        <Modal.Header
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            backgroundColor: "#525252",
            color: "white",
          }}
          closeButton
          onClick={() => showSetter(false)}
        >
          <Modal.Title id="contained-modal-title-vcenter">
            {"Are you sure you want to "} <strong>{requiredStatus}</strong>{" "}
            {selectedFolderName} {"?"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button
            onClick={() =>
              updateStatus(
                APIEndpointDomain,
                selectedLevel,
                selectedID,
                showSetter,
                currentStatus
              )
            }
            style={{ backgroundColor: "#186900" }}
            className="alarm-badge"
          >
            {"Confirm"}
          </Button>
          <Button onClick={() => showSetter(false)} variant="danger">
            {"Cancel"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ChangeAdStatusConfirmationModal;
