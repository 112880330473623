import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { ListGroup } from 'react-bootstrap'
import { ToggleButton } from 'react-bootstrap'
import { Form } from 'react-bootstrap'
import { useState } from 'react'
function CustomizeColumnsModal ({
  columnModalShow,
  columnCategory,
  showColumnModal,
  columnFilters,
  columnFilterColor,
  selectedColumnFilters,
  columnSelector,
  dragColumnStart,
  dragColumnEnter,
  dropColumn
}) {
  var [tagSearch, setTagSearch] = useState('')

  return (
    <Modal size='xl' show={columnModalShow}>
      <Modal.Header
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          backgroundColor: '#525252',
          color: 'white'
        }}
        closeButton
        onClick={showColumnModal}
      >
        <Modal.Title id='contained-modal-title-vcenter'>
          Customize your columns
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='row'>
          <div className='mr-3 mb-3'>
            <Form.Control
              onChange={e => setTagSearch(e.target.value)}
              type='text'
              placeholder='Search for metrics containing...'
              value={tagSearch}
              style={{ background: '#f2f2f2' }}
            />
          </div>
          <br></br>
          {Object.keys(columnCategory).map(key => (
            <>
              <Modal.Title
                id='contained-modal-title-vcenter'
                className='bg-slate-600 text-white'
              >
                {key}
              </Modal.Title>
              <div className='py-4'>
                <ListGroup horizontal='sm' className='flex-wrap'>
                  {columnCategory[key].sort().map(filter => {
                    if (
                      columnFilters.includes(filter) &&
                      filter.toLowerCase().includes(tagSearch)
                    ) {
                      return (
                        <>
                          <div className='py-2'>
                            <div style={{ marginRight: '20px' }}>
                              <ListGroup.Item>
                                <ToggleButton
                                  id='toggle-check'
                                  type='checkbox'
                                  variant={columnFilterColor(filter)}
                                  checked={selectedColumnFilters.includes(
                                    filter
                                  )}
                                  value={filter}
                                  // key={index}
                                  onClick={() => columnSelector(filter)}
                                >
                                  {filter}
                                </ToggleButton>
                              </ListGroup.Item>
                            </div>
                          </div>
                        </>
                      )
                    }
                  })}
                </ListGroup>
              </div>
            </>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button color='primary' onClick={showColumnModal}>
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default CustomizeColumnsModal
