import { Modal } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Table from "react-bootstrap/Table";


function GeneralTDClickModal({
  showTableDataClickModal,
  setShowTableDataClickModal,
  setDetailedInformationTab,
  clickedTableDataIDS,
  detailedInformationTab,
  currentStagesTD,
  trendlinesTD,
  profileInformationTD,
}) {
  return (
    <Modal size={"lg"} show={showTableDataClickModal}>
      <Modal.Header
        closeButton
        onClick={() => {
          setShowTableDataClickModal(false);
          setDetailedInformationTab("Stages");
        }}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          backgroundColor: "#525252",
          color: "white",
        }}
      >
        <Modal.Title>{clickedTableDataIDS["column_name"]}</Modal.Title>
      </Modal.Header>
      <div className="level-tabs-container">
        <Tabs
          defaultActiveKey="Stages"
          className="mb-3"
          fill
          activeKey={detailedInformationTab}
          onSelect={(e) => setDetailedInformationTab(e)}
        >
          <Tab eventKey="Trendlines" title={"Trendlines"} />

          <Tab eventKey="Stages" title={"Stages"} />

          <Tab
            eventKey="Profile Information"
            title={"Profile Information"}
          ></Tab>
        </Tabs>
      </div>
      {detailedInformationTab === "Stages" ? (
        <>
          {currentStagesTD.length > 0 ? (
            <Modal.Body
              style={{
                // display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",

                backgroundColor: "#dedddc",
              }}
            >
              <div>
                <Table
                  responsive="xl"
                  striped
                  hover
                  size="md"
                  className="fixed-table"
                  // variant="dark"
                >
                  <tbody>
                    {currentStagesTD.map((row) => {
                      return (
                        <tr
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {Object.keys(row).map((key) => {
                            return <td>{row[key]}</td>;
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </Modal.Body>
          ) : (
            <>
              <Modal.Body
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",

                  backgroundColor: "#dedddc",
                }}
              >
                {" "}
                <h3>Loading...</h3>
              </Modal.Body>
            </>
          )}
        </>
      ) : detailedInformationTab === "Trendlines" ? (
        <>
          {trendlinesTD.length > 0 ? (
            <Modal.Body
              style={{
                // display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",

                backgroundColor: "#dedddc",
              }}
            >
              <div>
                <Table
                  responsive="xl"
                  striped
                  hover
                  size="md"
                  className="fixed-table"
                  // variant="dark"
                >
                  <thead>
                    <tr
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {Object.keys(trendlinesTD[0]).map((key) => {
                        return (
                          <th>
                            <td>{key}</td>
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {trendlinesTD.map((row) => {
                      return (
                        <tr
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {Object.keys(row).map((key) => {
                            return <td>{row[key]}</td>;
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </Modal.Body>
          ) : (
            <>
              <Modal.Body
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",

                  backgroundColor: "#dedddc",
                }}
              >
                {" "}
                <h3>Loading...</h3>
              </Modal.Body>
            </>
          )}
        </>
      ) : detailedInformationTab === "Profile Information" ? (
        <>
          {Object.keys(profileInformationTD).length > 0 ? (
            <>
              <div className="row">
                {profileInformationTD
                  ? Object.keys(profileInformationTD).map((table) => {
                      return (
                        <div className="col">
                          <p>{table}</p>
                          <Table
                            responsive="sm"
                            striped
                            hover
                            size="sm"
                            className="fixed-table"
                            variant="dark"
                          >
                            <>
                              <thead>
                                <tr>
                                  <td>Category</td>
                                  <td>Count</td>
                                </tr>
                              </thead>
                              <tbody>
                                {Object.keys(profileInformationTD[table]).map(
                                  (category, index) => {
                                    return (
                                      <tr>
                                        <td key={index}>{category}</td>
                                        <td>
                                          {
                                            profileInformationTD[table][
                                              category
                                            ]
                                          }
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            </>
                          </Table>
                        </div>
                      );
                    })
                  : null}
              </div>
            </>
          ) : (
            <>
              <Modal.Body
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",

                  backgroundColor: "#dedddc",
                }}
              >
                {" "}
                <h3>Loading...</h3>
              </Modal.Body>
            </>
          )}
        </>
      ) : null}
    </Modal>
  );
}

export default GeneralTDClickModal;
