import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import axios from "axios";
import { useState } from "react";


function NotificationModal({
  show,
  showSetter,
  // data,
  APIEndpointDomain,
  selectedLevel,
  selectedID,
  setReminders,
  Reminders,
}) {
  if (Reminders && selectedLevel === "Campaign") {
    try {
      var reminderObject = Reminders.filter((reminder) => {
        return reminder["campaign_id"] == selectedID;
      })[0];
      var reminderDate = reminderObject["date"];
      var reminderMessage = reminderObject["message"];
    } catch (error) {
      var today = new Date();
      var reminderDate = new Date(new Date().setDate(today.getDate() + 40))
        .toJSON()
        .slice(0, 10);

      var reminderMessage = "";
    }
  } else if (Reminders && selectedLevel === "Adset") {
    try {
      var reminderObject = Reminders.filter((reminder) => {
        return reminder["adset_id"] == selectedID;
      })[0];
      var reminderDate = reminderObject["date"];
      var reminderMessage = reminderObject["message"];
    } catch (error) {
      var today = new Date();
      var reminderDate = new Date(new Date().setDate(today.getDate() - 30))
        .toJSON()
        .slice(0, 10);

      var reminderMessage = "";
    }
  } else if (Reminders && selectedLevel === "Ad") {
    try {
      var reminderObject = Reminders.filter((reminder) => {
        return reminder["ad_id"] == selectedID;
      })[0];
      var reminderDate = reminderObject["date"];
      var reminderDate = reminderDate.split("T")[0];
      var reminderMessage = reminderObject["message"];

    } catch (error) {
      var today = new Date();
      var reminderDate = new Date(new Date().setDate(today.getDate() - 30))
        .toJSON()
        .slice(0, 10);

      var reminderMessage = "";
    }
  } else {
  }


  var [inputtedDate, setInputtedDate] = useState(reminderDate);
  var [inputtedMessage, setInputtedMessage] = useState(reminderMessage);

  function setReminder(
    selectedDate,
    messagedAdded,
    APIEndpointDomain,
    selectedLevel,
    selectedID,
    showSetter,
    defaultReminderDate,
    defaultReminderMessage
  ) {
    axios
      .get(APIEndpointDomain + "/performance/set-reminder", {
        params: {
          date: selectedDate === "" ? defaultReminderDate : selectedDate,
          message:
            messagedAdded === "" ? defaultReminderMessage : messagedAdded,
          level: selectedLevel,
          id: selectedID,
        },
        headers: {
          "ngrok-skip-browser-warning": "any",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
      .then((response) => { console.log(response)})
      .catch((error) => {
        console.log(error);
      });

    showSetter(false);
    setInputtedDate("");
    setInputtedDate("");
  }

  function deleteReminder(
    selectedDate,
    messagedAdded,
    APIEndpointDomain,
    selectedLevel,
    selectedID,
    showSetter,
    setReminders,
    Reminders
  ) {
    axios
      .get(APIEndpointDomain + "/performance/delete-reminder", {
        params: {
          date: selectedDate,
          message: messagedAdded,
          level: selectedLevel,
          id: selectedID,
        },
        headers: {
          "ngrok-skip-browser-warning": "any",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
      .then((response) => { console.log(response)})
      .catch((error) => {
        console.log(error);
      });

    showSetter(false);
    setInputtedDate("");
    setInputtedDate("");

    if (selectedLevel == "Campaign") {
      var newReminders = Reminders.filter(
        (row) => row.campaign_id !== Number(selectedID)
      );
    } else if (selectedLevel == "Adset") {
      var newReminders = Reminders.filter(
        (row) => row.adset_id !== Number(selectedID)
      );
    } else if (selectedLevel == "Ad") {
      var newReminders = Reminders.filter(
        (row) => row.ad_id !== Number(selectedID)
      );
    }

    setReminders(newReminders);
  }

  return (
    <div>
      <Modal size="sm" show={show}>
        <Modal.Header
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            backgroundColor: "#525252",
            color: "white",
          }}
          closeButton
          onClick={() => showSetter(false)}
        >
          <Modal.Title id="contained-modal-title-vcenter">
            Set a Reminder
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>When do you need a reminder?</Form.Label>
              <Form.Control
                onChange={(e) => setInputtedDate(e.target.value)}
                type="date"
                defaultValue={reminderDate ? reminderDate : null}
              />
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>What do you need to check?</Form.Label>
              <Form.Control
                onChange={(e) => setInputtedMessage(e.target.value)}
                as="textarea"
                defaultValue={reminderMessage ? reminderMessage : null}
                rows={3}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Button
          onClick={() =>
            setReminder(
              inputtedDate,
              inputtedMessage,
              APIEndpointDomain,
              selectedLevel,
              selectedID,
              showSetter,
              reminderDate,
              reminderMessage
            )
          }
          style={{ backgroundColor: "#186900" }}
          className="alarm-badge"
        >
          {"Remind Me "}
          <FontAwesomeIcon icon={faBell} />
        </Button>
        <Button
          onClick={() =>
            deleteReminder(
              inputtedDate,
              inputtedMessage,
              APIEndpointDomain,
              selectedLevel,
              selectedID,
              showSetter,
              setReminders,
              Reminders
            )
          }
          style={{ backgroundColor: "#691700" }}
          className="alarm-badge"
        >
          {"DELETE"}
        </Button>
      </Modal>
    </div>
  );
}

export default NotificationModal;
