import axios from 'axios'
import { useEffect, useState, useMemo } from 'react'
import '../css/YouTubeVideosScreen.css'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css' // Core CSS
import 'ag-grid-community/styles/ag-theme-quartz.css' // Theme
import { Navbar } from 'react-bootstrap'
import Dropdown from 'react-bootstrap/Dropdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'react-bootstrap'
import { io } from 'socket.io-client'
import Badge from 'react-bootstrap/Badge'
import InputGroup from 'react-bootstrap/InputGroup'
import { Form } from 'react-bootstrap'
import { MultiStepLoader } from '../components/ui/multi-step-loader'
import { TypewriterEffect } from '../components/ui/typewriter-effect'
import { CSVLink } from 'react-csv'
import { Col } from 'react-bootstrap'

function LeadFrequencyScreen ({
  checkAuthenticationInResponse,
  APIEndpointDomain
}) {
  var [selectedDomain, setSelectedDomain] = useState('mim-essay')
  var [isFetching, setIsFetching] = useState(false)
  var [isError, setIsError] = useState(false)
  var [data, setData] = useState([])
  var [selectedTimeFrames, setSelectedTimeFrames] = useState([])

  var loadingStates = [
    {
      text: 'connecting to server'
    },

    {
      text: 'calculating frequencies'
    }
  ]

  var [sourceSearch, setSourceSearch] = useState('')
  var [selectedTotals, setSelectedTotals] = useState({
    today: 0,
    yesterday: 0,
    last_3_days: 0,
    last_7_days: 0,
    last_14_days: 0,
    last_30_days: 0,
    last_90_days: 0
  })

  function handleSourceSearch (e) {
    setSourceSearch(e.target.value)
  }

  function getTotalSums (data, timeFrames) {
    const totalSums = {
      today: 0,
      yesterday: 0,
      last_3_days: 0,
      last_7_days: 0,
      last_14_days: 0,
      last_30_days: 0,
      last_90_days: 0
    }

    timeFrames.forEach(timeFrame => {
      const matchingData = data.find(item => item.time_frame == timeFrame)
      if (matchingData) {
        totalSums.today += matchingData.today
        totalSums.yesterday += matchingData.yesterday
        totalSums.last_3_days += matchingData.last_3_days
        totalSums.last_7_days += matchingData.last_7_days
        totalSums.last_14_days += matchingData.last_14_days
        totalSums.last_30_days += matchingData.last_30_days
        totalSums.last_90_days += matchingData.last_90_days
      }
    })
    console.log(totalSums, 'total_sums')
    return totalSums
  }

  useEffect(() => {
    setSelectedTotals(getTotalSums(data, selectedTimeFrames))
  }, [selectedTimeFrames])

  console.log(selectedTotals)

  function getLeadFrequency (domain, source) {
    setIsFetching(true)
    setIsError(false)
    axios
      .get(APIEndpointDomain + '/lead-frequency/generate-view', {
        params: {
          domain: domain,
          source: source
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        console.log(response)
        setData(response.data['data'])
        setIsFetching(false)
        setIsError(false)
      })
      .catch(error => {
        checkAuthenticationInResponse(Number(error.response.status))
        console.log(error)
        setIsFetching(false)
        setIsError(true)
      })
  }

  useEffect(() => {
    getLeadFrequency(selectedDomain, sourceSearch)
  }, [selectedDomain])

  function toTitleCase (str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
  }

  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), [])
  const rowStyle = { background: '#black' }
  const defaultColDef = useMemo(() => {
    return {
      width: 170,
      filter: true,
      cellStyle: { fontSize: '14px', textAlign: 'center' },
      enableValue: true,
      enableRowGroup: true,
      enablePivot: true,
      filterParams: { newRowsAction: 'keep' }
    }
  }, [])

  console.log(selectedTimeFrames)

  function checkboxCellRenderer (params) {
    var key = params.colDef['field']
    var row = params.data
    var data = row[key]
    var pinned = Object.keys(params.node).includes('rowPinned') ? true : false

    if (!pinned) {
      return (
        // <Button
        //   variant='white'
        //   onClick={() => {
        //     selectingRows({
        //       campaignID:
        //         selectedLevel === 'Campaign' ? row['campaign_id'] : null,
        //       adsetID: selectedLevel === 'Adset' ? row['adset_id'] : null,
        //       adID: selectedLevel === 'Ad' ? row['ad_id'] : null
        //     })
        //   }}
        // >
        <>
          {row['time_frame'] != 'total' && (
            <input
              checked={selectedTimeFrames.includes(row['time_frame'])}
              onClick={() => {
                selectedTimeFrames.includes(row['time_frame'])
                  ? setSelectedTimeFrames(
                      selectedTimeFrames.filter(tf => tf != row['time_frame'])
                    )
                  : setSelectedTimeFrames([
                      row['time_frame'],
                      ...selectedTimeFrames
                    ])
              }}
              type='checkbox'
            ></input>
          )}
        </>
        // </Button>
      )
    } else {
      return <></>
    }
  }

  const getRowStyle = params => {
    var row = params.data

    if (row['needs_attention']) {
      return { background: '#fecaca' }
    } else if (params.node.rowIndex % 2 !== 0) {
      return { background: '#f1f5f9' }
    } else if (params.node.rowIndex % 2 === 0) {
      return { background: '#f8fafc' }
    }
  }

  var headers =
    data && data.length > 0 ? Object.keys(data[0]).map(header => header) : []

  headers = headers.map(header => {
    return {
      headerName:
        selectedTimeFrames.length == 0
          ? toTitleCase(header.replace(/_/g, ' '))
          : toTitleCase(header.replace(/_/g, ' ')) +
            ' (' +
            String(selectedTotals[header]) +
            ')',
      field: header,
      // onCellClicked: handleCellClick,
      //   cellRenderer: cellRenderer,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      filterParams: {
        caseSensitive: false,
        defaultOption: 'contains'
      },

      autoHeight: true,
      width: window.screen.width / headers.length,
      pinned: ['time_frame'].includes(header) ? 'left' : false
    }
  })

  function selectionHeaderRenderer (params) {
    return (
      <div
        key={10000000000}
        className='first-column'
        style={{
          textAlign: 'center'
        }}
      ></div>
    )
  }

  headers = [
    {
      field: 'select',
      cellRenderer: checkboxCellRenderer,
      filter: false,
      rowDrag: true,
      sortable: false,
      headerComponent: selectionHeaderRenderer,

      wrapText: true,
      autoHeight: true,
      width: 100,
      pinned: 'left'
    },
    ...headers
  ]

  return (
    <div className='h-[50rem] h-screen w-screen w-full dark:bg-black bg-white  dark:bg-grid-white/[0.2] bg-grid-black/[0.2] relative flex items-center justify-center'>
      <div className='absolute h-screen w-screen pointer-events-null inset-0 flex items-center justify-center dark:bg-black bg-white [mask-image:radial-gradient(ellipse_at_center,transparent_20%,black)]'></div>
      <div className='grid grid-rows-2 gap-0 bg-opacity-0 h-screen w-screen'>
        <div className='w-screen bg-opacity-0'>
          <Navbar
            fixed='top'
            style={{
              borderWidth: 0
            }}
          >
            <div className='w-screen '>
              {/* <div className='py-0 '> */}
              <div
                className='content-center'
                style={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <Col sm='0'>
                  <Dropdown className='ml-3'>
                    <Dropdown.Toggle variant='secondary' id='dropdown-basic'>
                      {selectedDomain}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={e => setSelectedDomain(e.target.text)}
                      >
                        mim-essay
                      </Dropdown.Item>

                      <Dropdown.Item
                        onClick={e => setSelectedDomain(e.target.text)}
                      >
                        mentr-me
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>

                <h4 className='text-3xl text-slate-600 font-bold mx-auto'>
                  #LeadFrequency
                </h4>
                <Col sm='0'>
                  <Button variant='secondary'>
                    <CSVLink filename='lead-frequency-export.csv' data={data}>
                      <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                    </CSVLink>
                  </Button>
                </Col>

                <Col sm='0'>
                  <Form.Control
                    size='md'
                    type='text'
                    id='campaign'
                    placeholder={'search source and hit go...'}
                    onChange={handleSourceSearch}
                    value={sourceSearch}
                  />
                </Col>

                <Col sm='0'>
                  <Button
                    size='md'
                    id='toggle-check'
                    variant='danger'
                    className='mr-3'
                    onClick={() =>
                      getLeadFrequency(selectedDomain, sourceSearch)
                    }
                    disabled={isFetching}
                  >
                    Go
                  </Button>
                </Col>
              </div>
            </div>
          </Navbar>
          <div className='pt-14 bg-opacity-0 w-screen h-screen '>
            {/* <>hi</> */}

            {!isFetching && !isError && data && data.length > 0 ? (
              <div style={gridStyle} className={'ag-theme-quartz'}>
                <AgGridReact
                  rowData={data}
                  columnDefs={headers}
                  defaultColDef={defaultColDef}
                  rowDragManaged={true}
                  rowSelection='multiple'
                  rowDragMultiRow={true}
                  rowStyle={rowStyle}
                  getRowStyle={getRowStyle}
                  sideBar={true}
                />
              </div>
            ) : isFetching ? (
              <MultiStepLoader
                className='bg-opacity-0'
                loadingStates={loadingStates}
                loading={isFetching}
                duration={500}
              ></MultiStepLoader>
            ) : isError ? (
              <div className='flex justify-center items-center text-center h-full'>
                <TypewriterEffect
                  className='text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-400 to-neutral-500 py-8'
                  words={'Server returned no data!'.split(' ').map(word => {
                    return { text: word }
                  })}
                ></TypewriterEffect>
              </div>
            ) : !isError && !isFetching && data.length == 0 ? (
              <div className='flex justify-center items-center text-center h-full'>
                <TypewriterEffect
                  className='text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-400 to-neutral-500 py-8'
                  words={'No appointments match the filters!'
                    .split(' ')
                    .map(word => {
                      return { text: word }
                    })}
                ></TypewriterEffect>
              </div>
            ) : (
              <div className='flex justify-center items-center text-center h-full'>
                <TypewriterEffect
                  className='text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-400 to-neutral-500 py-8'
                  words={'Unrecognized error occured!'.split(' ').map(word => {
                    return { text: word }
                  })}
                ></TypewriterEffect>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LeadFrequencyScreen
