import { Button, Table } from 'react-bootstrap'
import Badge from 'react-bootstrap/Badge'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFaceSadTear, faStar } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'

function FavoritesTable ({
  favorites,
  updateMessage,
  saveFavorite,
  setFavorites
}) {
  function isNumber (n) {
    return Number(n) === n
  }

  var [sortType, setSortType] = useState(false)

  var removableColumns = [
    'campaign_id',
    'adset_id',
    'ad_id',
    'campaign_name',
    'adset_name',
    'ad_name',
    'type',
    'status',
    'name',
    'message',
    'level',
    'start_date',
    'end_date'
  ]

  var headers =
    favorites.length > 0
      ? Object.keys(favorites[0]['favorite']).filter(
          header => !removableColumns.includes(header)
        )
      : []

  function handleHeaderClick (header, sort) {
    setSortType(!sort)

    var sortedData = !sort
      ? [...favorites].sort((a, b) => {
          if (Number(a[header]) < Number(b[header])) {
            return -1
          } else if (Number(a[header]) > Number(b[header])) {
            return 1
          }
          return 0
        })
      : [...favorites].sort((a, b) => {
          if (a[header] < b[header]) {
            return 1
          } else if (a[header] > b[header]) {
            return -1
          }
          return 0
        })

    setFavorites(sortedData)
  }

  return favorites.length > 0 && favorites ? (
    <div className='outer-primary-table-body-container'>
      <div className='performance-primary-table-body-container'>
        <Table
          responsive='sm'
          hover
          size='xl'
          className='fixed-table'
          variant='dark'
          striped
        >
          <thead>
            <tr>
              <th
                style={{
                  textAlign: 'center'
                }}
                className='first-column'
                onClick={() => handleHeaderClick('name', sortType)}
              >
                NAME
              </th>

              <th
                style={{
                  textAlign: 'center'
                }}
                className='other-columns'
                onClick={e => handleHeaderClick(e.target, sortType)}
              ></th>
              <th
                style={{
                  textAlign: 'center'
                }}
                className='other-columns'
              >
                MESSAGE
              </th>
              {headers.map(key => (
                <th
                  style={{
                    textAlign: 'center'
                  }}
                  onClick={() => handleHeaderClick(key, sortType)}
                >
                  {key !== 'name'
                    ? key.split('_').join(' ').toUpperCase()
                    : key.split('_').join(' ').toUpperCase()}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {favorites.map(row => (
              <tr>
                <td
                  style={{
                    textAlign: 'center'
                  }}
                  className='first-column table-light'
                >
                  <p>
                    {row['favorite']['name']}
                    <br></br>
                    <Badge bg={'dark'}>
                      {row['favorite']['start_date'] +
                        ' TO ' +
                        row['favorite']['end_date']}
                    </Badge>
                  </p>
                </td>

                <td
                  style={{
                    textAlign: 'center'
                  }}
                  className='second-column table-light'
                >
                  <Button
                    variant={'success'}
                    onClick={() => saveFavorite(row['favorite']['name'], row)}
                  >
                    <FontAwesomeIcon
                      className='fa-regular fa-star'
                      icon={faStar}
                    />
                  </Button>
                </td>
                <td
                  style={{
                    textAlign: 'center',
                    minWidth: '250px'
                  }}
                  className='other-columns table-light'
                  contentEditable
                  onBlur={e => {
                    updateMessage(row, e.target.textContent)
                  }}
                  //   dangerouslySetInnerHTML={{
                  //     __html: makeUrlsClickable(content),
                  //   }}
                >
                  {row['favorite']['message']}
                </td>

                {headers.map(key => (
                  <td
                    className='other-columns table-light'
                    style={{
                      textAlign: 'center'
                    }}
                  >
                    {(key === 'roi' ||
                      key === 'pending_p' ||
                      key === 'quality' ||
                      key === 'roi_during' ||
                      key === 'good_percentage' ||
                      key === 'h_good_percentage' ||
                      key === 'uhl_to_acquisition' ||
                      key === 'pressure_to_acquisition' ||
                      key === 'projected_roi' ||
                      key === 'acquisition_loss_rate' ||
                      key === 'acquisition_validation_rate' ||
                      key === 'acquisitions_pending_validation_p') &&
                    isNumber(row['favorite'][key]) ? (
                      <p>
                        {Math.round(row['favorite'][key]).toLocaleString(
                          'en-IN'
                        ) + '%'}
                      </p>
                    ) : (key === 'cpl' ||
                        key === 'cpql' ||
                        key === 'cpgl' ||
                        key === 'cpa' ||
                        key === 'spend' ||
                        key === 'revenue' ||
                        key === 'revenue_during' ||
                        key === 'total_revenue' ||
                        key === 'total_revenue_during' ||
                        key === 'cpa_during' ||
                        key === 'cpg_during' ||
                        key === 'cpgl_historic' ||
                        key === 'admit_up_rev' ||
                        key === 'admit_up_rev_dur' ||
                        key === 'projected_cpa' ||
                        key === 'avg_purchase' ||
                        key === 'budget' ||
                        key === 'premium_ms_revenue' ||
                        key === 'premium_ms_revenue_during' ||
                        key === 'h_cpa' ||
                        key === 'cpaa' ||
                        key === 'cpaa_during') &&
                      isNumber(row['favorite'][key]) ? (
                      <p>
                        {'₹' +
                          Math.round(row['favorite'][key]).toLocaleString(
                            'en-IN'
                          )}
                      </p>
                    ) : isNumber(row['favorite'][key]) ? (
                      <p>
                        {Math.round(row['favorite'][key])
                          .toLocaleString('en-IN')
                          .toString()}
                      </p>
                    ) : key === 'avg_conversion_period' ? (
                      row['favorite'][key] + ' days'
                    ) : (
                      <p>{row['favorite'][key]}</p>
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  ) : (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        height: '100VH',
        marginTop: '-15VH',
        width: '110VW',
        backgroundColor: '#f7f7f7'
      }}
    >
      <body style={{ backgroundColor: '#f7f7f7' }}>
        <h3>{"You haven't bookmarked anything yet! "}</h3>
        <FontAwesomeIcon
          icon={faFaceSadTear}
          bounce
          style={{ width: '3rem', height: '3rem' }}
        ></FontAwesomeIcon>
      </body>
    </div>
  )
  //  : null} );
}

export default FavoritesTable
