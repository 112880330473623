import React, { useEffect } from 'react'
import EmailUploader from '../components/EmailAnalyzerComponents/EmailUpload'
import { useState } from 'react'
import { Nav, Navbar } from 'react-bootstrap'
import { cn } from '../utils/cn'
import Dropdown from 'react-bootstrap/Dropdown'
import Button from 'react-bootstrap/Button'
import { useMemo } from 'react'
import { TypewriterEffect } from '../components/ui/typewriter-effect'
import CreateReportModal from '../components/EmailAnalyzerComponents/CreateReportModal'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBarsProgress,
  faPenToSquare,
  faDownload
} from '@fortawesome/free-solid-svg-icons'
import { AgGridReact } from 'ag-grid-react'
import SaveEmailReportModal from '../components/EmailAnalyzerComponents/SaveReportModal'
import APIRequestStatusModal from '../components/CommonSubComponents/APIRequestStatusMessageModal'
import { CSVLink } from 'react-csv'

function EmailAnalyzerScreen ({
  checkAuthenticationInResponse,
  APIEndpointDomain
}) {
  var [emails, setEmails] = useState([])

  var [selectedReport, setSelectedReport] = useState('')
  var firstLoad = true

  var [availableReports, setAvailableReports] = useState('')

  var [isFetching, setIsFetching] = useState(false)
  var [isError, setIsError] = useState(false)
  var [data, setData] = useState([])
  var [showCreateModal, setShowCreateModal] = useState(false)
  var [showSaveModal, setShowSaveModal] = useState(false)
  var [showSaveStatus, setShowSaveStatus] = useState(false)
  var [showSaveStatusMessage, setShowSaveStatusMessage] = useState('')
  var [preMiMLTPData, setPreMiMLTPData] = useState([])

  const gridStyle = useMemo(
    () => ({ height: '100%', width: '100%', backgroundColor: '#e8ebe9' }),
    []
  )

  useEffect(() => {
    getAllExistingReports()
  }, [firstLoad])

  function getAllExistingReports () {
    axios
      .get(APIEndpointDomain + '/track-emails/get-all-existing-reports', {
        params: {},
        headers: {
          'ngrok-skip-browser-warning': 'any',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        console.log(response)
        setAvailableReports(response.data['data'])
        setIsError(false)
      })
      .catch(error => {
        console.log(error)
        checkAuthenticationInResponse(Number(error.response.status))
      })
  }
  function getExistingReport (report_name) {
    setSelectedReport(report_name)
    setIsFetching(true)
    axios
      .get(APIEndpointDomain + '/track-emails/get-existing-report', {
        params: {
          name: report_name
        },
        headers: {
          'ngrok-skip-browser-warning': 'any',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        console.log(response)
        setData(response.data['data'])

        var pre_mim_ltp = response.data['additional_stats']['pre_mim_ltp']
        if (pre_mim_ltp) {
          setPreMiMLTPData(pre_mim_ltp)
        } else {
          setPreMiMLTPData([])
        }

        setIsFetching(false)
        setIsError(false)
      })
      .catch(error => {
        console.log(error)
        setIsFetching(false)
        checkAuthenticationInResponse(Number(error.response.status))

        setIsError(true)
      })
  }

  function saveCurrentReport (report_name) {
    axios
      .post(
        APIEndpointDomain + '/track-emails/save-report',
        {
          name: report_name,
          data: data,
          additional_stats: { pre_mim_ltp: preMiMLTPData }
        },
        {
          headers: {
            'ngrok-skip-browser-warning': 'any',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'application/json' // Set content type for POST request
          }
        }
      )
      .then(response => {
        console.log(response)
        setAvailableReports([report_name, ...availableReports])
        setShowSaveModal(true)
        setShowSaveStatus(true)
        setIsError(false)
        setShowSaveStatusMessage('Report saved successfully!')
      })
      .catch(error => {
        console.log(error)
        setIsFetching(false)
        setIsError(true)
        setShowSaveModal(true)
        checkAuthenticationInResponse(Number(error.response.status))

        setShowSaveStatus(true)
        setShowSaveStatusMessage('Report failed to save!')
      })
  }

  function fetchData (emails, spend, startDate, endDate) {
    setIsFetching(true)

    axios
      .post(
        APIEndpointDomain + '/track-emails',
        {
          emails: emails,
          spend: spend,
          start_date: startDate,
          end_date: endDate
        },
        {
          headers: {
            'ngrok-skip-browser-warning': 'any',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'application/json' // Set content type for POST request
          }
        }
      )
      .then(response => {
        console.log(response)
        setData(response.data['data'])
        setIsFetching(false)
        setIsError(false)

        var pre_mim_ltp = response.data['additional_stats']['pre_mim_ltp']
        if (pre_mim_ltp) {
          setPreMiMLTPData(pre_mim_ltp)
        } else {
          setPreMiMLTPData([])
        }
      })
      .catch(error => {
        checkAuthenticationInResponse(Number(error.response.status))

        console.log(error)
        setIsFetching(false)
        setIsError(true)
      })
  }

  function cellRenderer (params) {
    var key = params.colDef['field']
    var row = params.data
    var data = params.data[key]

    if (key.includes('name') && data == 'pre_mim') {
      return (
        <p>
          {data}{' '}
          {preMiMLTPData && preMiMLTPData.length > 0 ? (
            <span className='relative z-20'>
              <CSVLink filename='pre-mim-ltp.csv' data={preMiMLTPData}>
                <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
              </CSVLink>
            </span>
          ) : null}
        </p>
      )
    } else {
      return <p>{data}</p>
    }
  }

  const rowStyle = {}

  const getRowStyle = params => {
    if (params.node.rowIndex % 2 !== 0) {
      return { background: '#eaeaea' }
    }
  }

  const defaultColDef = useMemo(() => {
    return {
      width: 170,
      filter: true,
      cellStyle: { fontSize: '14px', textAlign: 'center' },
      enableValue: true,
      enableRowGroup: true,
      enablePivot: true
    }
  }, [])

  var headers =
    data && data.length > 0
      ? Object.keys(data[0]).map(key => {
          return {
            headerName: String(key).replace(/_/g, ' '),
            field: key,
            filter:
              key === 'name' ? 'agTextColumnFilter' : 'agNumberColumnFilter',
            floatingFilter: true,
            pinned: key === 'name' ? 'left' : null,
            rowDrag: key === 'name',
            cellRenderer: cellRenderer
          }
        })
      : []

  console.log('headers', headers)
  return (
    <>
      <div className='h-[50rem] h-screen w-screen w-full dark:bg-black bg-white  dark:bg-grid-white/[0.2] bg-grid-black/[0.2] relative flex items-center justify-center'>
        <div className='absolute h-screen w-screen pointer-events-none inset-0 flex items-center justify-center dark:bg-black bg-white [mask-image:radial-gradient(ellipse_at_center,transparent_20%,black)]'></div>
        <div className='grid grid-rows-2 gap-0 bg-opacity-0 h-screen w-screen'>
          <Navbar
            fixed='top'
            //   className='flex justify-between items-center'
            style={{
              borderWidth: 0
            }}
          >
            <div className='flex justify-between items-center w-screen'>
              <Dropdown className='mt-3 ml-3'>
                <Dropdown.Toggle variant='secondary' id='dropdown-basic'>
                  {selectedReport == '' ? (
                    <>
                      <FontAwesomeIcon icon={faPenToSquare} />
                    </>
                  ) : (
                    selectedReport
                  )}
                </Dropdown.Toggle>
                <Dropdown.Menu className='custom-report-dropdown-menu'>
                  {availableReports.length > 0
                    ? availableReports.map(report => (
                        <Dropdown.Item>
                          <Button
                            onClick={e => {
                              getExistingReport(report)
                            }}
                            variant='light'
                          >
                            {' '}
                            {report}
                          </Button>
                        </Dropdown.Item>
                      ))
                    : null}
                </Dropdown.Menu>
              </Dropdown>

              <h4 className='mt-3 text-3xl text-slate-600 font-bold mx-auto'>
                Email Tracker
              </h4>
              <button
                onClick={() => setShowCreateModal(true)}
                className='mt-3 ml-1 px-8 py-2 rounded-full bg-slate-700 text-white text-sm hover:shadow-2xl hover:shadow-white/[0.1] transition duration-200 border border-slate-600'
              >
                <div className='absolute inset-x-0 h-px w-1/2 mx-auto -top-px shadow-2xl bg-gradient-to-r from-transparent via-teal-500 to-transparent' />
                <span className='relative z-20'>Create</span>
              </button>
              <button
                onClick={() => setShowSaveModal(true)}
                className='mt-3 px-8 py-2 rounded-full bg-slate-700 text-white text-sm hover:shadow-2xl hover:shadow-white/[0.1] transition duration-200 border border-slate-600'
              >
                <div className='absolute inset-x-0 h-px w-1/2 mx-auto -top-px shadow-2xl bg-gradient-to-r from-transparent via-teal-500 to-transparent' />
                <span className='relative z-20'>Save</span>
              </button>
              {data.length > 0 ? (
                <button className='mt-3 mr-3 px-8 py-2 rounded-full bg-slate-700 text-white text-sm hover:shadow-2xl hover:shadow-white/[0.1] transition duration-200 border border-slate-600'>
                  <div className='absolute inset-x-0 h-px w-1/2 mx-auto -top-px shadow-2xl bg-gradient-to-r from-transparent via-teal-500 to-transparent' />
                  <span className='relative z-20'>
                    <CSVLink
                      filename='user-tracker-table-export.csv'
                      data={data}
                    >
                      <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                    </CSVLink>
                  </span>
                </button>
              ) : null}
            </div>
          </Navbar>
          <div className='pt-28 bg-opacity-0 w-screen h-screen '>
            {showCreateModal ? (
              <CreateReportModal
                showModal={showCreateModal}
                setShowModal={setShowCreateModal}
                fetchData={fetchData}
                emails={emails}
                setEmails={setEmails}
              ></CreateReportModal>
            ) : null}

            {showSaveStatus ? (
              <APIRequestStatusModal
                show={showSaveStatus}
                setShow={setShowSaveStatus}
                message={showSaveStatusMessage}
              ></APIRequestStatusModal>
            ) : null}

            {showSaveModal ? (
              <SaveEmailReportModal
                showModal={showSaveModal}
                setShowModal={setShowSaveModal}
                saveReport={saveCurrentReport}
              />
            ) : null}

            {data.length > 0 && !isFetching && !isError ? (
              <div style={gridStyle} className={'ag-theme-quartz'}>
                <AgGridReact
                  rowData={data}
                  columnDefs={headers}
                  defaultColDef={defaultColDef}
                  rowDragManaged={true}
                  rowSelection='multiple'
                  rowDragMultiRow={true}
                  rowStyle={rowStyle}
                  getRowStyle={getRowStyle}
                />
              </div>
            ) : data.length == 0 && !isFetching ? (
              <div
                style={{ paddingBottom: '200px' }}
                className='flex justify-center items-center h-screen text-center'
              >
                {' '}
                <TypewriterEffect
                  className='text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-400 to-neutral-500 py-8'
                  words={'Create a report or load an existing report!'
                    .split(' ')
                    .map(word => {
                      return { text: word }
                    })}
                ></TypewriterEffect>
              </div>
            ) : isFetching ? (
              <div
                style={{ paddingBottom: '200px' }}
                className='flex justify-center items-center h-screen text-center'
              >
                {' '}
                <FontAwesomeIcon
                  style={{ width: '5rem', height: '5rem' }}
                  icon={faBarsProgress}
                  className='fa-flip'
                />
              </div>
            ) : isError ? (
              <div
                style={{ paddingBottom: '200px' }}
                className='flex justify-center items-center h-screen text-center'
              >
                {' '}
                <TypewriterEffect
                  className='text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-400 to-neutral-500 py-8'
                  words={'Sorry, we failed to generate your report!'
                    .split(' ')
                    .map(word => {
                      return { text: word }
                    })}
                ></TypewriterEffect>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  )
}

export default EmailAnalyzerScreen
