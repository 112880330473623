import { Button } from 'react-bootstrap'
import Badge from 'react-bootstrap/Badge'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFaceSadTear,
  faStar,
  faMagnifyingGlass,
  faLink
} from '@fortawesome/free-solid-svg-icons'
//
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import { useMemo } from 'react'
import { TypewriterEffect } from '../ui/typewriter-effect'
function FavoritesTable ({
  favorites,
  updateMessage,
  saveFavorite,
  setCampaignSearch,
  setAdsetSearch,
  setAdSearch,
  setSelectedLevel,
  selectedAdAccount
}) {
  const stringColumns = [
    'campaign_name',
    'adset_name',
    'ad_name',
    'name',
    'message'
  ]

  var removableColumns = [
    'campaign_id',
    'adset_id',
    'ad_id',
    'campaign_name',
    'adset_name',
    'ad_name',
    'type',
    'status',
    'name',
    'message',
    'level',
    'start_date',
    'end_date',
    'deleted',
    'backburners',
    'no_show',
    'dnp',
    'pressure_sell',
    'ultra_hot',
    'lead_lost',
    'nurturing',
    'sales_usa_cpa',
    'sales_usa_pre',
    'sales_uk_cpa',
    'h_good_usa_cpa',
    'h_good_usa_pre',
    'h_good_uk_cpa',
    'acquired_during_uk_cpa',
    'acquired_during_usa_cpa',
    'acquired_during_usa_pre'
  ]

  var percentageMetrics = [
    'acquisition_loss_rate',
    'acquisition_validation_rate',
    'acquisitions_pending_validation_p',
    'good_percentage',
    'pending_p',
    'pressure_to_acquisition',
    'projected_roi',
    'quality',
    'roi',
    'uhl_to_acquisition',
    'roi_during',
    'h_good_percentage',
    'lead_to_h_good_percentage',
    'ctr',
    'hook_rate',
    'hold_rate',
    'view_to_click',
    'click_to_lead',
    'fifteen_to_25p',
    'twenty_five_p_to_50p',
    'fifty_p_to_75p'
  ]

  var currencyMetrics = [
    'cpaa',
    'premium_ms_revenue',
    'avg_purchase',
    'budget',
    'cpa',
    'cpgl',
    'cpl',
    'cpql',
    'projected_cpa',
    'revenue',
    'total_revenue',
    'upfront_payments',
    'cpaa_during',
    'premium_ms_revenue_during',
    'admit_up_rev_dur',
    'cpa_during',
    'revenue_during',
    'total_revenue_during',
    'upfront_payments_during',
    'cpgl_historic',
    'h_cpa',
    'spend',
    'cpm',
    'cost_per_thruplay',
    'cpc',
    '5_day_cpl',
    '5_day_cpql',
    '7_day_cpgl_h',
    '12_day_cpuhl_h',
    '22_day_cpa',
    'adset_budget'
  ]

  function onNameClick (data) {
    var data = data.data
    if (data['level'] === 'Ad') {
      window.open('/performance/alltime-stats/ad/' + data['ad_id'])
    } else {
      // No action needed
    }
  }

  const rowData = favorites.map(favorite => favorite['favorites'])

  function cellRenderer (params) {
    var key = params.colDef['field']
    var row = params.data
    var data = params.data[key]
    var pinned = Object.keys(params.node).includes('rowPinned') ? true : false
    var level = row['level']

    if (
      (key === 'budget' && pinned) ||
      (key === 'budget' && row['level'] == 'Ad')
    ) {
      return ''
    } else if (key === 'budget' && level.toLowerCase() === 'campaign' && data) {
      return (
        <p>
          {'₹' + Math.round(data).toLocaleString('en-IN')}
          {'  '}
        </p>
      )
    } else if (key === 'budget' && level.toLowerCase() === 'adset' && data) {
      return <p>{'₹' + Math.round(data)}</p>
    } else if (key === 'budget' && level.toLowerCase() === 'campaign') {
      return <p>Using adset budget</p>
    } else if (key === 'budget' && level.toLowerCase() === 'adset') {
      ;<p>Using campaign budget</p>
    } else if (key === 'budget' && level.toLowerCase() === 'ad') {
      return <p>Using adset budget</p>
    } else if (currencyMetrics.includes(key)) {
      return <p>₹{Math.round(data).toLocaleString('en-IN') + '  '}</p>
    } else if (percentageMetrics.includes(key) && key !== 'ctr') {
      return (
        <p>
          {Math.round(data).toLocaleString('en-IN')}%{'  '}
        </p>
      )
    } else if (percentageMetrics.includes(key) && key == 'ctr') {
      return (
        <p>
          {data.toLocaleString('en-IN')}%{'  '}
        </p>
      )
    } else {
      return <p>{data}</p>
    }
  }

  var headers =
    favorites.length > 0
      ? Object.keys(favorites[0]['favorites']).filter(
          header =>
            !removableColumns.includes(header) && !header.includes('_export')
        )
      : []

  var headers2 = headers.map(header => ({
    field: header,
    filter: !stringColumns.includes(header)
      ? 'agNumberColumnFilter'
      : 'agTextColumnFilter',
    floatingFilter: true,
    cellRenderer: cellRenderer
  }))

  var headers3 = [
    {
      field: 'name',
      onCellClicked: onNameClick,

      rowDrag: true,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      filterParams: {
        caseSensitive: false,
        defaultOption: 'contains'
      },
      cellRenderer: NameCellRenderer,
      wrapText: false,
      autoHeight: true,
      width: 300,
      pinned: 'left'
    },
    { field: '', cellRenderer: ButtonCellRenderer, pinned: 'left' },
    {
      field: 'message',
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      filterParams: {
        caseSensitive: false,
        defaultOption: 'contains'
      },
      editable: true,
      cellEditor: 'agTextCellEditor',
      cellEditorParams: {
        maxLength: 10000
      },
      wrapText: true,
      autoHeight: true,
      pinned: 'left'
    },

    ...headers2
  ]

  var accountInfo = {
    mim_essay_ga: '985-964-2362',
    mentr_me_ga: '352-839-7676',
    'mentr_me instagram': '534422526990207',
    mentr_me: '295097958990289',
    mim_essay: '232101678',
    prohed: '512738531179838',
    'mim_essay alternate': '289971403775180',
    'mim_essay-5': '1114568669863569'
  }

  // var selectedAccountID =
  //   selectedAdAccount.toLowerCase() === 'mentr-me'
  //     ? '295097958990289'
  //     : selectedAdAccount.toLowerCase() === 'mim-essay'
  //     ? '232101678'
  //     : selectedAdAccount.toLowerCase() === 'mentr-me instagram'
  //     ? '534422526990207'
  //     : selectedAdAccount.toLowerCase() === 'mim-essay alternate'
  //     ? '289971403775180'
  //     : selectedAdAccount.toLowerCase() === 'prohed'
  //     ? '512738531179838'
  //     : null

  function findInAdAccount (row, account_name) {
    var selectedAccountID = accountInfo[account_name]
    if (row['level'] == 'Campaign') {
      var url =
        'https://adsmanager.facebook.com/adsmanager/manage/campaigns/' +
        '?act=' +
        selectedAccountID +
        '&filter_set=SEARCH_BY_CAMPAIGN_GROUP_ID-STRING%1EEQUAL%1E"' +
        row['campaign_id'] +
        '"'
    } else if (row['level'] == 'Adset') {
      var url =
        'https://adsmanager.facebook.com/adsmanager/manage/adsets/' +
        '?act=' +
        selectedAccountID +
        '&filter_set=SEARCH_BY_CAMPAIGN_ID-STRING%1EEQUAL%1E"' +
        row['adset_id'] +
        '"'
    } else if (row['level'] == 'Ad') {
      var url =
        'https://adsmanager.facebook.com/adsmanager/manage/ads/' +
        '?act=' +
        selectedAccountID +
        '&filter_set=SEARCH_BY_ADGROUP_ID-STRING%1EEQUAL%1E"' +
        row['ad_id'] +
        '"'
    }

    window.open(url)
  }

  function findFolder (row) {
    if (row['level'] == 'Campaign') {
      setCampaignSearch(row['campaign_name'])
      setAdsetSearch('')
      setAdSearch('')
    } else if (row['level'] == 'Adset') {
      setCampaignSearch(row['campaign_name'])
      setAdsetSearch(row['adset_name'])
      setAdSearch('')
    } else if (row['level'] == 'Ad') {
      setCampaignSearch(row['campaign_name'])
      setAdsetSearch(row['adset_name'])
      setAdSearch(row['ad_name'])
    }

    setSelectedLevel(row['level'])
  }

  function ButtonCellRenderer (params) {
    const { data } = params

    return (
      <>
        <Button
          variant={'success'}
          onClick={() =>
            data['level'] === 'Campaign'
              ? saveFavorite(data['campaign_id'], data['level'], data)
              : data['level'] === 'Adset'
              ? saveFavorite(data['adset_id'], data['level'], data)
              : saveFavorite(data['ad_id'], data['level'], data)
          }
        >
          <FontAwesomeIcon className='fa-regular fa-star' icon={faStar} />
        </Button>

        <Button onClick={() => findFolder(data)}>
          <FontAwesomeIcon icon={faMagnifyingGlass} />
        </Button>

        <br></br>
        {selectedAdAccount
          .filter(name => !name.toLowerCase().includes('_ga'))
          .map(account_name => {
            return (
              <>
                <Badge
                  bg='warning'
                  onClick={() =>
                    findInAdAccount(
                      data,
                      account_name.toLowerCase().replace('-', '_')
                    )
                  }
                >
                  {account_name}
                </Badge>{' '}
              </>
            )
          })}
      </>
    )
  }

  function NameCellRenderer (params) {
    const { data } = params

    return (
      <>
        <p style={{ lineHeight: '1.8' }}>
          {data['name']}
          <br></br>
          <Badge bg={'dark'}>{'#' + data['level']}</Badge>
          {data['level'] === 'Ad' ? (
            <>
              {' '}
              <Badge bg={'dark'}>{data['status']}</Badge>{' '}
              <Badge bg='dark'>{data['type']}</Badge>
            </>
          ) : null}
          <br></br>
          {data['level'] === 'Adset' ? (
            <>
              <Badge bg={'dark'}>{'#' + data['campaign_name']}</Badge>
            </>
          ) : data['level'] === 'Ad' ? (
            <>
              <Badge style={{ backgroundColor: '#0e62b0' }}>
                {'#' + data['campaign_name']}
              </Badge>{' '}
              <Badge style={{ backgroundColor: '#0e62b0' }}>
                {'#' + data['adset_name']}
              </Badge>
            </>
          ) : null}
          <br></br>
          {data['start_date'] ? (
            <Badge bg={'dark'}>
              {data['start_date'] + ' TO ' + data['end_date']}
            </Badge>
          ) : null}
        </p>
      </>
    )
  }

  const defaultColDef = useMemo(() => {
    return {
      width: 170,
      filter: true,
      cellStyle: { fontSize: '14px', textAlign: 'center' },
      enableValue: true,
      // allow every column to be grouped
      enableRowGroup: true,
      // allow every column to be pivoted
      enablePivot: true
    }
  }, [])

  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), [])

  // set background colour on every row, this is probably bad, should be using CSS classes
  const rowStyle = { background: '#eaeaea' }

  // set background colour on even rows again, this looks bad, should be using CSS classes
  const getRowStyle = params => {
    if (params.node.rowIndex % 2 === 0) {
      return { background: '#f6f6f6' }
    }
  }

  function handleCellValueChanged (params) {
    updateMessage({ favorites: params.data }, params.newValue)
  }

  return favorites.length > 0 && favorites ? (
    <div style={gridStyle} className={'ag-theme-quartz'}>
      <AgGridReact
        rowData={rowData}
        columnDefs={headers3}
        defaultColDef={defaultColDef}
        rowDragManaged={true}
        rowSelection='multiple'
        rowDragMultiRow={true}
        rowStyle={rowStyle}
        getRowStyle={getRowStyle}
        onCellValueChanged={handleCellValueChanged}
      />
    </div>
  ) : (
    <div className='flex justify-center items-center text-center h-full'>
      <TypewriterEffect
        className='text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-400 to-neutral-500 py-8'
        words={"You haven't bookmarked anything yet!".split(' ').map(word => {
          return { text: word }
        })}
      ></TypewriterEffect>
    </div>
  )
  //  : null} );
}

export default FavoritesTable
