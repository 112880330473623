import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";

function ShareCustomReportModal({
  showShareCustomReportModal,
  setShowCustomReportModal,
  ShareCustomReportWithErrorMessage,
  ShareCustomReportWith,
  setShareCustomReportWith,
  ShareCustomReport,
  selectedCustomReport,
}) {
  return (
    <Modal size="sm" show={showShareCustomReportModal}>
      <Modal.Header closeButton onClick={() => setShowCustomReportModal(false)}>
        <Modal.Title id="contained-modal-title-vcenter">
          Share report with
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{ShareCustomReportWithErrorMessage}</h4>
        <Dropdown>
          <Dropdown.Toggle variant="secondary" id="dropdown-basic">
            {ShareCustomReportWith}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              onClick={(e) => setShareCustomReportWith(e.target.text)}
            >
              jaiveer
            </Dropdown.Item>
            <Dropdown.Item
              onClick={(e) => setShareCustomReportWith(e.target.text)}
            >
              suraj
            </Dropdown.Item>
            <Dropdown.Item
              onClick={(e) => setShareCustomReportWith(e.target.text)}
            >
              edwin
            </Dropdown.Item>
            <Dropdown.Item
              onClick={(e) => setShareCustomReportWith(e.target.text)}
            >
              arsalan
            </Dropdown.Item>
            <Dropdown.Item
              onClick={(e) => setShareCustomReportWith(e.target.text)}
            >
              arshad
            </Dropdown.Item>
            <Dropdown.Item
              onClick={(e) => setShareCustomReportWith(e.target.text)}
            >
              sam
            </Dropdown.Item>
            <Dropdown.Item
              onClick={(e) => setShareCustomReportWith(e.target.text)}
            >
              muskan
            </Dropdown.Item>
            <Dropdown.Item
              onClick={(e) => setShareCustomReportWith(e.target.text)}
            >
              dipanshu
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Modal.Body>
      <Modal.Footer>
        <Button
          color="primary"
          onClick={() => ShareCustomReport(selectedCustomReport)}
        >
          Share
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ShareCustomReportModal;
