'use client'

import { cn } from '../../utils/cn'
import { motion, stagger, useAnimate, useInView } from 'framer-motion'
import { useEffect } from 'react'

export const TypewriterEffect = ({ words, className, cursorClassName }) => {
  // split text inside of words into array of characters
  const wordsArray = words.map(word => {
    return {
      ...word,
      text: word.text.split('')
    }
  })

  const [scope, animate] = useAnimate()
  const isInView = useInView(scope)
  useEffect(() => {
    if (isInView) {
      animate(
        'span',
        {
          display: 'inline-block',
          opacity: 1
        },
        {
          duration: 0.3,
          delay: stagger(0.03),
          ease: 'easeInOut'
        }
      )
    }
  }, [isInView])

  console.log(wordsArray)

  const renderWords = () => {
    return (
      <motion.div ref={scope} className='inline'>
        {/* {wordsArray.map((word, idx) => {
          return (
            <>
              <div
                style={{ lineHeight: '2px' }}
                key={`word-${idx}`}
                className='inline-block'
              >
                <p style={{ lineHeight: '2px' }}>
                  {word.text.map((char, index) => (
                    <motion.span
                      initial={{}}
                      key={`char-${index}`}
                      className={cn(` opacity-10 hidden`, word.className)}
                    >
                      {char}
                    </motion.span>
                  ))}
                </p>
                &nbsp;
              </div>
            </>
          )
        })} */}

        {wordsArray.map((word, idx) => {
          return (
            <div key={`word-${idx}`} className='inline-block'>
              {word.text.map((char, index) => (
                <motion.span
                  initial={{}}
                  key={`char-${index}`}
                  className={cn('opacity-10 hidden', word.className)}
                >
                  {char}
                </motion.span>
              ))}
              &nbsp;
            </div>
          )
        })}
      </motion.div>
    )
  }
  return (
    <div
      className={cn(
        'text-base sm:text-xl md:text-3xl lg:text-5xl font-bold text-center',
        className
      )}
    >
      {renderWords()}
      {/* <motion.span
        initial={{
          opacity: 0
        }}
        animate={{
          opacity: 1
        }}
        transition={{
          duration: 0.8,
          repeat: Infinity,
          repeatType: 'reverse'
        }}
        className={cn(
          'inline-block rounded-sm w-[4px] h-4 md:h-6 lg:h-10 bg-slate-500',
          cursorClassName
        )}
      ></motion.span> */}
    </div>
  )
}

export const TypewriterEffectSmooth = ({
  words,
  className,
  cursorClassName
}) => {
  // split text inside of words into array of characters
  const wordsArray = words.map(word => {
    return {
      ...word,
      text: word.text.split('')
    }
  })
  const renderWords = () => {
    return (
      <div>
        {wordsArray.map((word, idx) => {
          return (
            <div key={`word-${idx}`} className='inline-block'>
              {word.text.map((char, index) => (
                <span
                  key={`char-${index}`}
                  className={cn(`dark:text-white text-black `, word.className)}
                >
                  {char}
                </span>
              ))}
              &nbsp;
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div className={cn('flex space-x-1 my-6', className)}>
      <motion.div
        className='overflow-hidden pb-2'
        initial={{
          width: '0%'
        }}
        whileInView={{
          width: 'fit-content'
        }}
        transition={{
          duration: 2,
          ease: 'linear',
          delay: 1
        }}
      >
        <div
          className='text-xs sm:text-base md:text-xl lg:text:3xl xl:text-5xl font-bold'
          style={{
            whiteSpace: 'nowrap'
          }}
        >
          {renderWords()}{' '}
        </div>{' '}
      </motion.div>
      <motion.span
        initial={{
          opacity: 0
        }}
        animate={{
          opacity: 1
        }}
        transition={{
          duration: 0.2,

          repeat: Infinity,
          repeatType: 'reverse'
        }}
        className={cn(
          'block rounded-sm w-[4px]  h-4 sm:h-6 xl:h-12 bg-blue-500',
          cursorClassName
        )}
      ></motion.span>
    </div>
  )
}
