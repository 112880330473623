import React from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { Form } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import axios from 'axios'

function ScheduleAppointmentButton ({
  currentBooking,
  name,
  show,
  setShow,
  form_id,
  APIEndpointDomain,
  setAllData,
  checkAuthenticationInResponse
}) {
  var [booking, setBooking] = useState(currentBooking)

  var [selectedHour, setSelectedHour] = useState(16)
  var [selectedMinute, setSelectedMinute] = useState(0)

  const availableMinutes = [0, 15, 30, 45]
  const availableHours = [
    8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22
  ]

  function onSubmit (date, hour, minute, form_id) {
    setShow(false)
    axios
      .get(APIEndpointDomain + '/mind-scape/update-lead-slot', {
        params: {
          form_id: form_id,
          date: date,
          hour: hour,
          minute: minute
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        console.log(response)
        setAllData(response.data['all_appointments'])
      })
      .catch(error => {
        checkAuthenticationInResponse(Number(error.response.status))
        console.log(error)
      })
  }

  return (
    <Modal size='sm' show={show}>
      <Modal.Header
        className='bg-slate-600 text-white'
        closeButton
        onClick={() => setShow(false)}
      >
        <Modal.Title id='contained-modal-title-vcenter'>
          Schedule for {name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          className='content-center'
          style={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Form.Control
            type='date'
            placeholder='Name'
            defaultValue={currentBooking}
            onChange={e => setBooking(e.target.value)}
          />
          <Dropdown className='ml-3'>
            <Dropdown.Toggle variant='secondary' id='dropdown-basic'>
              {selectedHour}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {availableHours.map(hour => {
                return (
                  <Dropdown.Item onClick={() => setSelectedHour(hour)}>
                    {hour}
                  </Dropdown.Item>
                )
              })}
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown className='ml-3'>
            <Dropdown.Toggle variant='secondary' id='dropdown-basic'>
              {selectedMinute}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {availableMinutes.map(minute => {
                return (
                  <Dropdown.Item onClick={() => setSelectedMinute(minute)}>
                    {minute}
                  </Dropdown.Item>
                )
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className='rounded-3xl bg-slate-600 text-white'
          onClick={() =>
            onSubmit(booking, selectedHour, selectedMinute, form_id)
          }
        >
          Confirm
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default ScheduleAppointmentButton
