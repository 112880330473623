import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'

function ServerReturnedNoDataMessage () {
  return (
    <div className='h-[50rem] w-screen w-full dark:bg-black bg-white  dark:bg-grid-white/[0.2] bg-grid-black/[0.2] relative flex items-center justify-center'>
      {/* Radial gradient for the container to give a faded look */}
      <div className='absolute w-screen pointer-events-none inset-0 flex items-center justify-center dark:bg-black bg-white [mask-image:radial-gradient(ellipse_at_center,transparent_20%,black)]'></div>
      <h3>
        <FontAwesomeIcon className='fa-beat' icon={faTriangleExclamation} />{' '}
        Server returned no data{' '}
        <FontAwesomeIcon className='fa-beat' icon={faTriangleExclamation} />
      </h3>
      {/* </Alert> */}
    </div>
  )
}

export default ServerReturnedNoDataMessage
