import Modal from 'react-bootstrap/Modal'
import { Row, Col } from 'react-bootstrap'
import DropdownToggle from 'react-bootstrap'
import { Dropdown } from 'react-bootstrap'
import { useState } from 'react'
import { Form } from 'react-bootstrap'
import { ListGroup } from 'react-bootstrap'
import { useRef } from 'react'
import Button from 'react-bootstrap/Button'
import axios from 'axios'
import APIRequestStatusModal from '../CommonSubComponents/APIRequestStatusMessageModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBarsProgress } from '@fortawesome/free-solid-svg-icons'
import InputGroup from 'react-bootstrap/InputGroup'

function BotSuggestionModal ({
  showModal,
  setShowModal,
  selectedAdAccount,
  selectedLeadType,
  APIEndpointDomain
}) {
  var [selectedGoal, setSelectedGoal] = useState('')
  var [budgetChange, setBudgetChange] = useState(0)
  var [maxBudgetPChange, setMaxBudgetPChange] = useState(0)
  var [Metrics, setSelectedMetrics] = useState([
    'CPL',
    'CPQL',
    'Lead Quality',
    'CPGL_H',
    'CPUHL_H',
    'CPA'
  ])
  var [requiredMetrics, setRequiredMetrics] = useState([])
  var [customReportURL, setCustomReportURL] = useState('')
  var [showAPIStatusModal, setShowAPIStatusModal] = useState(false)
  var [showAPIStatusMessage, setShowAPIStatusMessage] = useState('')
  var dragColumn = useRef()
  var dragOverColumn = useRef()
  var [copyToClipboardMessage, setCopyToClipboardMessage] = useState(
    'Click to copy the generated custom report URL!'
  )
  var [isLoading, setIsLoading] = useState(false)
  var defaultCopyToClipboardMessage = ''

  function dragColumnStart (__, position) {
    dragColumn.current = position
  }

  function dragColumnEnter (__, position) {
    dragOverColumn.current = position
  }

  function dropColumn (__) {
    var updatedSelectedColumnFilters = [...Metrics]
    var dragItemContent = updatedSelectedColumnFilters[dragColumn.current]
    updatedSelectedColumnFilters.splice(dragColumn.current, 1)
    updatedSelectedColumnFilters.splice(
      dragOverColumn.current,
      0,
      dragItemContent
    )
    dragColumn.current = null
    dragOverColumn.current = null
    setSelectedMetrics(updatedSelectedColumnFilters)
  }

  function handleCheckboxChange (index) {
    const newCheckedItems = [...requiredMetrics]

    // Toggle the checked status of the item at the specified index
    if (newCheckedItems.includes(index)) {
      newCheckedItems.splice(newCheckedItems.indexOf(index), 1)
    } else {
      newCheckedItems.push(index)
    }

    setRequiredMetrics(newCheckedItems)
  }

  function GenerateRecommendations () {
    setIsLoading(true)
    console.log(isLoading)
    setCustomReportURL('')

    axios
      .get(APIEndpointDomain + '/performance/generate-recommendations', {
        params: {
          required_metrics: '' + requiredMetrics + '',
          metrics_order: '' + Metrics + '',
          ad_account: selectedAdAccount,
          lead_type: selectedLeadType,
          budget_change: budgetChange,
          max_budget_change_allowed: maxBudgetPChange,
          goal: selectedGoal
        },
        headers: {
          'ngrok-skip-browser-warning': 'any',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        console.log(response)
        setShowAPIStatusModal(true)
        setShowAPIStatusMessage('Successfully generated recommendations!')
        setCustomReportURL(response.data['url'])
        setCopyToClipboardMessage(
          'Click to copy the generated custom report URL!'
        )
      })
      .catch(error => {
        console.log(error)
        setShowAPIStatusModal(true)
        setShowAPIStatusMessage('Failed to generate recommendations!')
        setCustomReportURL('')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const textAreaRef = useRef(null)

  const copyToClipboard = () => {
    const url =
      window.location.origin +
      '/performance/custom-report/' +
      encodeURIComponent(customReportURL)

    const textArea = document.createElement('textarea')
    textArea.value = url

    document.body.appendChild(textArea)

    textArea.select()

    document.execCommand('copy')

    document.body.removeChild(textArea)
    setCopyToClipboardMessage('Copied! Click to copy again😀')
  }

  return (
    <div>
      <APIRequestStatusModal
        show={showAPIStatusModal}
        setShow={setShowAPIStatusModal}
        message={showAPIStatusMessage}
      />
      <Modal size={'lg'} show={showModal}>
        <Modal.Header
          closeButton
          onClick={() => {
            setShowModal(false)
            setCustomReportURL('')
            setCopyToClipboardMessage(defaultCopyToClipboardMessage)
          }}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            backgroundColor: '#525252',
            color: 'white'
          }}
        >
          <Modal.Title>Smart Suggestions</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            // display: "flex",
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',

            backgroundColor: '#dedddc'
          }}
        >
          <Row>
            <Col style={{ marginTop: '10px' }}>
              <p>What do you want to do?</p>
              <Dropdown>
                <Dropdown.Toggle variant='secondary' id='dropdown-basic'>
                  {selectedGoal}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={e => setSelectedGoal(e.target.text)}>
                    Increase Budget
                  </Dropdown.Item>
                  <Dropdown.Item onClick={e => setSelectedGoal(e.target.text)}>
                    Decrease Budget
                  </Dropdown.Item>
                  <Dropdown.Item onClick={e => setSelectedGoal(e.target.text)}>
                    Optimize Budget
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              {/* </OverlayTrigger> */}
            </Col>
            {['Increase Budget', 'Decrease Budget'].includes(selectedGoal) ? (
              <Col style={{ marginTop: '10px' }}>
                <p>
                  {selectedGoal} {' by'}
                </p>
                <InputGroup className='mb-3'>
                  <InputGroup.Text id='basic-addon1'>₹</InputGroup.Text>
                  <Form.Control
                    onChange={e => setBudgetChange(e.target.value)}
                    type='number'
                    style={{ maxWidth: '100px' }}
                  />
                </InputGroup>
              </Col>
            ) : null}
            <Col style={{ marginTop: '10px' }}>
              <p>Max. Budget Change Allowed</p>
              <InputGroup className='mb-3'>
                <InputGroup.Text id='basic-addon1'>%</InputGroup.Text>
                <Form.Control
                  onChange={e => setMaxBudgetPChange(e.target.value)}
                  type='number'
                  style={{ maxWidth: '100px' }}
                />
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col style={{ marginTop: '10px' }}>
              <p>Goals: Select, Drag & Prioritize</p>
              <div className='col-lg'>
                <ListGroup horizontal='sm' className='flex-wrap'>
                  {Metrics.map((metric, index) => (
                    <ListGroup.Item key={index}>
                      <div
                        onDragStart={e => dragColumnStart(e, index)}
                        onDragEnter={e => dragColumnEnter(e, index)}
                        onDragEnd={dropColumn}
                        key={index}
                        draggable
                        className='mb-2'
                      >
                        <input
                          checked={requiredMetrics.includes(metric)}
                          onChange={() => handleCheckboxChange(metric)}
                          type='checkbox'
                        ></input>{' '}
                        {metric}
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </div>
            </Col>
          </Row>
        </Modal.Body>

        {customReportURL !== '' ? (
          <div>
            {/* <textarea 
        ref={textAreaRef}
       value={customReportURL}
       readOnly
       style={{ position: 'absolute', left: '-9999px'}}
     /> */}
            <button onClick={copyToClipboard} style={{ minWidth: '100%' }}>
              {copyToClipboardMessage}
            </button>
          </div>
        ) : null}

        <Modal.Footer
          style={{
            // display: "flex",
            justifyContent: 'right',
            alignItems: 'right',
            height: '100%',

            backgroundColor: '#dedddc'
          }}
        >
          <Button
            variant='secondary'
            onClick={() => {
              setShowModal(false)
              setCopyToClipboardMessage(defaultCopyToClipboardMessage)
            }}
          >
            Close
          </Button>
          <Button
            variant='primary'
            disabled={isLoading}
            onClick={() => GenerateRecommendations()}
          >
            {isLoading ? (
              <>
                Generating <FontAwesomeIcon shake icon={faBarsProgress} />
              </>
            ) : (
              'Generate Recommendations'
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default BotSuggestionModal
