import Modal from 'react-bootstrap/Modal'
import { Row, Col } from 'react-bootstrap'
import DropdownToggle from 'react-bootstrap'
import { Dropdown } from 'react-bootstrap'
import { useState } from 'react'
import { Form } from 'react-bootstrap'
import { ListGroup } from 'react-bootstrap'
import { useRef } from 'react'
import Button from 'react-bootstrap/Button'
import axios from 'axios'
import APIRequestStatusModal from '../CommonSubComponents/APIRequestStatusMessageModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBarsProgress } from '@fortawesome/free-solid-svg-icons'
import InputGroup from 'react-bootstrap/InputGroup'
import EmailUploader from './EmailUpload'

function CreateReportModal ({
  showModal,
  setShowModal,
  fetchData,
  emails,
  setEmails
}) {
  var [spend, setSpend] = useState(0)
  var [startDate, setStartDate] = useState(null)
  var [endDate, setEndDate] = useState(null)
  function onModalSubmit () {
    console.log('fetching data for...', emails)
    setShowModal(false)
    fetchData(emails, spend, startDate, endDate)
  }
  function handleStartDate (e) {
    setStartDate(e.target.value)
  }

  function handleEndDate (e) {
    setEndDate(e.target.value)
  }
  return (
    <div>
      <Modal size={'md'} show={showModal}>
        <Modal.Header
          closeButton
          onClick={() => {
            setShowModal(false)
          }}
          className='bg-slate-500'
        >
          <Modal.Title>
            <h4 className='text-2xl text-slate-50 font-bold mx-auto'>
              Create a report
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='bg-slate-100'>
          <Row>
            <Col style={{ marginTop: '10px' }}>
              <p className='text-base text-slate-800 font-bold mx-auto'>
                Upload CSV file
              </p>
              <EmailUploader setCSVData={setEmails}></EmailUploader>
            </Col>
            <Col style={{ marginTop: '10px' }}>
              <p className='text-base text-slate-800 font-bold mx-auto'>
                Enter spend
              </p>
              <InputGroup className='mb-3'>
                <InputGroup.Text id='basic-addon1'>₹</InputGroup.Text>
                <Form.Control
                  onChange={e => setSpend(e.target.value)}
                  type='number'
                  style={{ maxWidth: '100px' }}
                />
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col style={{ marginTop: '10px' }}>
              <Form.Label htmlFor='inputPassword5'>Start Date</Form.Label>
              <Form.Control onSelect={handleStartDate} type='date' size='md' />
            </Col>
            <Col style={{ marginTop: '10px' }}>
              <Form.Label htmlFor='inputPassword5'>End Date</Form.Label>

              <Form.Control onSelect={handleEndDate} type='date' size='md' />
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Body className='place-content-center bg-slate-100'>
          <button
            onClick={() => {
              setShowModal(false)
            }}
            className='"px-4 py-2 rounded-md  bg-red-500 text-white text-neutral-700 text-sm hover:shadow-[4px_4px_0px_0px_rgba(0,0,0)] transition duration-200'
          >
            Close
          </button>
          <button
            onClick={() => onModalSubmit()}
            className='"px-4 py-2 rounded-md bg-teal-500 text-white text-neutral-700 text-sm hover:shadow-[4px_4px_0px_0px_rgba(0,0,0)] transition duration-200'
          >
            Create
          </button>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default CreateReportModal
