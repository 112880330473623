import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { Form } from 'react-bootstrap'

function SaveViewModal ({
  showNameYourViewModal,
  setShowNameYourViewModal,
  setNamingView,
  saveViewSubmit,
  advancedFiltersSelections,
  selectedColumnFilters,
  namingView
}) {
  return (
    <Modal size='sm' show={showNameYourViewModal}>
      <Modal.Header closeButton onClick={() => setShowNameYourViewModal(false)}>
        <Modal.Title id='contained-modal-title-vcenter'>
          Name your view
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Control
          type='text'
          placeholder='Name'
          onChange={e => setNamingView(e.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          color='primary'
          onClick={() =>
            saveViewSubmit(
              advancedFiltersSelections,
              selectedColumnFilters,
              namingView
            )
          }
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default SaveViewModal
