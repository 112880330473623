import React from 'react'
import { useState, useEffect } from 'react'
import axios from 'axios'
import { TypewriterEffect } from '../components/ui/typewriter-effect'
import { BackgroundBeams } from '../components/ui/background-beams'
import { Boxes } from '../components/ui/background-boxes'
import { cn } from '../utils/cn'
import { MultiStepLoader } from '../components/ui/white-multi-step-loader'
import { Button, Navbar } from 'react-bootstrap'
import { ShadCNButton } from '../components/ui/shadcn-button'
import Dropdown from 'react-bootstrap/Dropdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faScroll } from '@fortawesome/free-solid-svg-icons'

function PromptGeneratorScreen ({
  checkAuthenticationInResponse,
  APIEndpointDomain
}) {
  var loadingStates = [
    { text: 'connecting to claude.ai' },
    { text: 'understanding requirements' },
    { text: 'generating prompt' },
    { text: 'finishing up' }
  ]
  var [isFetching, setIsFetching] = useState(false)
  var [isError, setIsError] = useState(false)
  var [prompt, setPrompt] = useState('')
  var [task, setTask] = useState('')

  function generatePrompt (task) {
    setIsFetching(true)
    setIsError(false)

    axios
      .post(
        APIEndpointDomain + '/prompt/generate',
        {
          task: task
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`
          }
        }
      )
      .then(response => {
        console.log(response)
        setPrompt(response.data['prompt'])
        setIsFetching(false)
        setIsError(false)
      })
      .catch(error => {
        checkAuthenticationInResponse(Number(error.response.status))
        console.log(error)
        setIsFetching(false)
        setIsError(true)
      })
  }

  return (
    <div className='h-screen w-screen relative overflow-hidden bg-slate-900 flex flex-col items-center justify-center rounded-lg'>
      <div className='absolute inset-0 w-full h-full bg-slate-900 z-20 [mask-image:radial-gradient(transparent,white)] pointer-events-none' />

      <Boxes />
      <div className='w-screen bg-opacity-0'>
        {!isFetching && !isError ? (
          <>
            <div className=' '>
              {prompt && prompt != '' ? (
                <TypewriterEffect
                  className='text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-400 to-neutral-500 py-8'
                  words={'Generated your prompt!'.split(' ').map(word => {
                    return { text: word }
                  })}
                ></TypewriterEffect>
              ) : prompt == '' ? (
                <>
                  <TypewriterEffect
                    className='mx-auto relative z-20  text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-400 to-neutral-500 py-8'
                    words={'Prompt Engineer'.split(' ').map(word => {
                      return { text: word }
                    })}
                  ></TypewriterEffect>

                  <div className='mx-auto relative z-20 w-1/3 h-52  rounded p-2 overflow-hidden text-gray-600 bg-sky-50 font-mono'>
                    <textarea
                      placeholder='Example: Your task is to write a YouTube video script. You can ask me for...'
                      onChange={e => setTask(e.target.value)}
                      className='w-full h-full resize-none overflow-auto border-none outline-none focus:outline-none text-gray-600 bg-sky-50 font-mono'
                    />
                  </div>
                </>
              ) : null}
            </div>

            {prompt != '' ? (
              <>
                <div className='mx-auto relative z-20 w-11/12 h-96  rounded p-2 overflow-hidden text-gray-600 bg-sky-50 font-mono'>
                  <textarea
                    className='w-full h-full resize-none overflow-auto border-none outline-none focus:outline-none text-gray-600 bg-sky-50 font-mono'
                    value={prompt}
                  />
                </div>
              </>
            ) : null}
            <ShadCNButton
              className={cn(
                'mt-6 flex justify-center items-center mx-auto relative z-20 bg-slate-600  rounded-lg border-slate-900 text-white font-bold'
              )}
              onClick={() =>
                prompt == '' ? generatePrompt(task) : window.location.reload()
              }
            >
              {prompt == '' ? 'GO' : 'Try again'}
            </ShadCNButton>
          </>
        ) : isFetching ? (
          <MultiStepLoader
            className='bg-opacity-0'
            loadingStates={loadingStates}
            loading={isFetching}
            duration={2000}
          ></MultiStepLoader>
        ) : isError ? (
          <div className='flex justify-center items-center text-center h-full'>
            <TypewriterEffect
              className='text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-400 to-neutral-500 py-8'
              words={'Server failed to return data!'.split(' ').map(word => {
                return { text: word }
              })}
            ></TypewriterEffect>
          </div>
        ) : (
          <div className='flex justify-center items-center text-center h-full'>
            <TypewriterEffect
              className='text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-400 to-neutral-500 py-8'
              words={'Unrecognized error occured!'.split(' ').map(word => {
                return { text: word }
              })}
            ></TypewriterEffect>
          </div>
        )}
      </div>
    </div>
  )
}

export default PromptGeneratorScreen
