'use client'

import { useState } from 'react'
import { motion } from 'framer-motion'
import { cn } from '../../utils/cn'
import { useEffect } from 'react'

export const TabsATUI = ({
  tabs: propTabs,
  levelSelector,
  disableButtons,
  selectedLevel,
  containerClassName,
  activeTabClassName,
  tabClassName,
  contentClassName
}) => {
  useEffect(() => {
    setActive({ title: selectedLevel, value: selectedLevel })
  }, [selectedLevel])

  const [active, setActive] = useState(propTabs[0])
  const [tabs, setTabs] = useState(propTabs)

  const moveSelectedTabToTop = idx => {
    const newTabs = [...propTabs]
    const selectedTab = newTabs.splice(idx, 1)
    newTabs.unshift(selectedTab[0])
    setTabs(newTabs)
    setActive(newTabs[0])
  }

  const [hovering, setHovering] = useState(false)

  return (
    <>
      <div
        className={cn(
          'flex flex-row items-center justify-start [perspective:1000px] relative overflow-auto sm:overflow-visible  max-w-full w-full',
          containerClassName
        )}
      >
        {propTabs.map((tab, idx) => (
          <button
            disabled={disableButtons}
            key={tab.title}
            onClick={e => {
              moveSelectedTabToTop(idx)
              levelSelector(tab.value)
            }}
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}
            className={cn(
              'max-w-screen w-1/4 relative px-4 py-2 rounded-full bg-gray-100',
              tabClassName
            )}
            style={{
              transformStyle: 'preserve-3d'
            }}
          >
            {active.value === tab.value && (
              <motion.div
                layoutId='clickedbutton'
                transition={{ type: 'spring', bounce: 0.3, duration: 0.6 }}
                className={cn(
                  'absolute inset-0 bg-emerald-100  rounded-full ',
                  activeTabClassName
                )}
              />
            )}

            <span className='relative block text-black dark:text-white'>
              {tab.title}
            </span>
          </button>
        ))}
      </div>
      <FadeInDiv
        tabs={tabs}
        active={active}
        key={active.value}
        hovering={hovering}
        className={cn('mt-32', contentClassName)}
      />
    </>
  )
}

export const FadeInDiv = ({ className, tabs, hovering }) => {
  const isActive = tab => {
    return tab.value === tabs[0].value
  }
  return (
    <div className='relative w-full h-full'>
      {tabs.map((tab, idx) => (
        <motion.div
          key={tab.value}
          layoutId={tab.value}
          style={{
            scale: 1 - idx * 0.1,
            top: hovering ? idx * -50 : 0,
            zIndex: -idx,
            opacity: idx < 3 ? 1 - idx * 0.1 : 0
          }}
          animate={{
            y: isActive(tab) ? [0, 40, 0] : 0
          }}
          className={cn('w-full h-full absolute top-0 left-0', className)}
        >
          {tab.content}
        </motion.div>
      ))}
    </div>
  )
}
