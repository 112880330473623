import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import '../css/AssistantChatScreen.css'
import {
  Widget,
  addResponseMessage,
  toggleWidget,
  addUserMessage,
  deleteMessages
} from 'react-chat-widget'

import 'react-chat-widget/lib/styles.css'

function AssistantChatScreen () {
  const [messages, setMessages] = useState([])
  const [userMessage, setUserMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [totalCosting, setTotalCosting] = useState(0)
  const flatListRef = useRef(null)

  const fetchInitialMessages = async () => {
    try {
      const response = await axios.get(
        `https://marketingapi.mim-essay.com/api/marketingchatapp/generate-initial-view`
      )
      setMessages(response.data.data) // Assuming `data.data` is the array of message objects
      setTotalCosting(response.data.costing)

      for (let i = 0; i < response.data.data.length; i++) {
        if (
          response.data.data[i]['sender'] == 'user' &&
          response.data.data[i]['message']
        ) {
          addUserMessage(response.data.data[i]['message'])
        } else if (response.data.data[i]['message']) {
          addResponseMessage(response.data.data[i]['message'])
        }
      }
    } catch (error) {
      console.error('Error fetching initial messages:', error)
    } finally {
      if (flatListRef.current) {
        flatListRef.current.scrollTop = flatListRef.current.scrollHeight
      }
    }
  }

  useEffect(() => {
    fetchInitialMessages()
  }, [])

  const formatDate = dateString => {
    return String(dateString).replace('T', ' ').replace('Z', ' ')
  }

  const sendMessage = async () => {
    if (userMessage.trim() === '') return

    setLoading(true)

    try {
      const response = await axios.get(
        `https://marketingapi.mim-essay.com/api/marketingchatapp/process-user-message?command=${userMessage}`
      )
      setTotalCosting(response.data.costing)

      setMessages(prevMessages => [
        ...prevMessages,
        ...response.data.data.filter(
          apiMessage =>
            !prevMessages.some(chatMessage => chatMessage.id === apiMessage.id)
        )
      ])

      for (let i = 0; i < response.data.data.length; i++) {
        if (
          response.data.data[i]['sender'] == 'user' &&
          response.data.data[i]['message']
        ) {
          addUserMessage(response.data.data[i]['message'])
        } else if (response.data.data[i]['message']) {
          addResponseMessage(response.data.data[i]['message'])
        }
      }
    } catch (error) {
      setMessages(prevMessages => [
        ...prevMessages,
        {
          id: prevMessages.length.toString(),
          message: 'Error: Could not fetch response.',
          sender: 'system'
        }
      ])
    } finally {
      setLoading(false)
      setUserMessage('')
      if (flatListRef.current) {
        flatListRef.current.scrollTop = flatListRef.current.scrollHeight
      }
    }
  }

  useEffect(() => {
    if (flatListRef.current) {
      flatListRef.current.scrollTop = flatListRef.current.scrollHeight
    }
  }, [messages])

  useEffect(() => {
    toggleWidget()
  }, [])

  var [launchChat, setLaunchChat] = useState(false)

  function handleChatToggle (toggleStatus) {
    if (!toggleStatus) {
      setLaunchChat(false)
      toggleWidget()
    }
  }

  var [userImage, setUserImage] = useState(
    'https://i.pinimg.com/originals/ea/d1/ca/ead1cac3ce991bf4eb9e2b4c51164ac8.gif'
  )

  return (
    <div className='container'>
      {/* <div className='messageAreaContainer' ref={flatListRef}>
        <div className='messagesContainer'>
          {messages.map(item => (
            <div
              key={item.id}
              className={
                item.sender === 'user'
                  ? 'userMessageContainer'
                  : 'systemMessageContainer'
              }
            >
              {item.sender === 'system' && (
                <img
                  src='https://im.rediff.com/getahead/2018/feb/22dhinchak-pooja.jpg'
                  alt='avatar'
                  className='avatar'
                />
              )}
              <div>
                <p
                  className={
                    item.sender === 'user' ? 'userMessage' : 'systemMessage'
                  }
                >
                  {item.message}
                </p>
                <p
                  className={
                    item.sender === 'user' ? 'timestampRight' : 'timestampLeft'
                  }
                >
                  {formatDate(item.sent_at)}
                </p>
              </div>
              {item.sender === 'user' && (
                <img
                  src='https://wallpapers.com/images/hd/red-blur-giga-chad-5fijtdhh5lid0q3a.jpg'
                  alt='avatar'
                  className='avatar'
                />
              )}
            </div>
          ))}
        </div>
      </div>

      <div className='inputContainer'>
        <input
          type='text'
          className='input'
          placeholder='Type your message...'
          value={userMessage}
          onChange={e => setUserMessage(e.target.value)}
          disabled={loading}
        />
        <button className='sendButton' onClick={sendMessage} disabled={loading}>
          Send
        </button>
      </div> */}

      <Widget
        handleNewUserMessage={sendMessage}
        isWidgetOpened={true}
        title={'#AskDhinchak'}
        subtitle={'₹' + Math.round(totalCosting)}
        handleToggle={handleChatToggle}
        profileAvatar={userImage}
        profileClientAvatar={
          'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTaq8BrtOJMyK4epzOea1n56lxdd0IVtjZbrQ&s'
        }
        emojis={true}
        resizable={true}
      />
    </div>
  )
}

export default AssistantChatScreen
