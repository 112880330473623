import Modal from 'react-bootstrap/Modal'
import { cn } from '../../utils/cn'
import { Navbar } from 'react-bootstrap'
import LoadingMessage from '../CommonSubComponents/LoadingMessage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBarsProgress } from '@fortawesome/free-solid-svg-icons'

function Top5VideosModal ({
  showModal,
  setShowModal,
  data,
  setData,
  focusKeyword
}) {
  return (
    <div>
      <Modal size={'md'} show={showModal}>
        <Modal.Header
          closeButton
          onClick={() => {
            setShowModal(false)
            setData([])
          }}
          className='bg-slate-200'
        >
          <Modal.Title>
            <h4 className='ttext-zinc-100 font-bold tracking-wide '>
              Top 5 Ranking Videos - {focusKeyword}
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='bg-slate-100'>
          {data.length > 0 ? (
            data.map(video => {
              return (
                <Card>
                  <CardTitle>{video['title']}</CardTitle>
                  <CardDescription>
                    {video['channel']}
                    <img
                      onClick={() => window.open(video['link'])}
                      src={video['thumbnail_url']}
                      alt='new'
                    />
                  </CardDescription>
                </Card>
              )
            })
          ) : (
            <FontAwesomeIcon
              style={{ width: '5rem', height: '5rem' }}
              icon={faBarsProgress}
              className='fa-flip'
            />
          )}
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default Top5VideosModal

export const Card = ({ className, children }) => {
  return (
    <div
      className={cn(
        'rounded-2xl  w-full overflow-hidden bg-black border border-transparent dark:border-white/[0.2] group-hover:border-slate-700 ',
        className
      )}
    >
      <div className='relative z-50'>
        <div className='p-4'>{children}</div>
      </div>
    </div>
  )
}
export const CardTitle = ({ className, children }) => {
  return (
    <h4 className={cn('text-zinc-100 font-bold tracking-wide ', className)}>
      {children}
    </h4>
  )
}
export const CardDescription = ({ className, children }) => {
  return (
    <p
      className={cn(
        'text-zinc-400 tracking-wide leading-relaxed text-sm',
        className
      )}
    >
      {children}
    </p>
  )
}
