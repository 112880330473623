import React from 'react'
import { useState, useEffect } from 'react'
import axios from 'axios'
import { TypewriterEffect } from '../components/ui/typewriter-effect'
import { BackgroundBeams } from '../components/ui/background-beams'
import { Boxes } from '../components/ui/background-boxes'
import { cn } from '../utils/cn'
import { MultiStepLoader } from '../components/ui/white-multi-step-loader'
import { Button, Navbar } from 'react-bootstrap'
import { ShadCNButton } from '../components/ui/shadcn-button'
import Dropdown from 'react-bootstrap/Dropdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faScroll } from '@fortawesome/free-solid-svg-icons'

function YouTubeScriptGeneratorScreen ({
  checkAuthenticationInResponse,
  APIEndpointDomain
}) {
  var loadingStates = [
    {
      text: 'connecting to server'
    },
    {
      text: 'fetching top ranking videos'
    },
    {
      text: 'retrieving their transcripts'
    },
    {
      text: 'summarizing transcripts'
    },
    {
      text: 'adding latest data'
    },
    {
      text: 'writing the script'
    },
    {
      text: 'finishing up'
    }
  ]

  var [scriptIDs, setScriptIDs] = useState([])

  var [copyToClipboardMessage, setCopyToClipboardMessage] = useState(
    'Click to copy the generated custom report URL!'
  )
  var defaultCopyToClipboardMessage = ''

  var [searchQuery, setSearchQuery] = useState('')
  var [isFetching, setIsFetching] = useState(false)
  var [isError, setIsError] = useState(false)
  var [script, setScript] = useState('')

  const copyToClipboard = script => {
    // const url =
    //   window.location.origin + '/youtube/script/' + encodeURIComponent(script)

    // const textArea = document.createElement('textarea')
    // textArea.value = url

    // document.body.appendChild(textArea)

    // textArea.select()

    // document.execCommand('copy')
    window.open(
      window.location.origin + '/youtube/script/' + encodeURIComponent(script)
    )

    // document.body.removeChild(textArea)
    // setCopyToClipboardMessage('Copied! Click to copy again😀')
  }

  useEffect(() => {
    getGeneratedScriptIDs()
  }, [])

  function getGeneratedScriptIDs () {
    axios
      .get(APIEndpointDomain + '/youtube/get-generated-script-ids', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        console.log(response)
        setScriptIDs(response.data['data'])
      })
      .catch(error => {
        checkAuthenticationInResponse(Number(error.response.status))
        console.log(error)
      })
  }

  function generateScript (search_term) {
    if (search_term == '') {
      return
    }

    setIsFetching(true)
    setIsError(false)

    axios
      .get(APIEndpointDomain + '/youtube/generate-script', {
        params: {
          search_term: search_term
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        console.log(response)
        setScript(response.data['data'])
        setIsFetching(false)
        setIsError(false)
      })
      .catch(error => {
        checkAuthenticationInResponse(Number(error.response.status))
        console.log(error)
        setIsFetching(false)
        setIsError(true)
      })
  }

  console.log(searchQuery)

  return (
    <div className='h-screen w-screen relative overflow-hidden bg-slate-900 flex flex-col items-center justify-center rounded-lg'>
      <div className='absolute inset-0 w-full h-full bg-slate-900 z-20 [mask-image:radial-gradient(transparent,white)] pointer-events-none' />

      <Boxes />
      <div className='w-screen bg-opacity-0'>
        <Navbar
          fixed='top'
          style={{
            borderWidth: 0
          }}
        >
          <div className='w-screen '>
            {/* <div className='py-0 '> */}
            <div
              className='ml-2 content-left'
              style={{
                display: 'flex',
                justifyContent: 'left'
              }}
            >
              <Dropdown>
                <Dropdown.Toggle
                  style={{ backgroundColor: '#475569', border: '0' }}
                  className='bg-slate-200'
                >
                  <FontAwesomeIcon icon={faScroll}></FontAwesomeIcon>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {scriptIDs.map(id => {
                    return (
                      <Dropdown.Item
                        onClick={e =>
                          window.open(
                            window.location.origin +
                              '/youtube/script/' +
                              encodeURIComponent(id['uid'])
                          )
                        }
                      >
                        {id['uid'].replace('write_script', '')}
                      </Dropdown.Item>
                    )
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </Navbar>
        {!isFetching && !isError ? (
          <>
            <div className=' flex justify-center items-center text-center'>
              {script && script != '' ? (
                <TypewriterEffect
                  className='text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-400 to-neutral-500 py-8'
                  words={'Your script is generated!'.split(' ').map(word => {
                    return { text: word }
                  })}
                ></TypewriterEffect>
              ) : (
                <input
                  onChange={e => setSearchQuery(e.target.value)}
                  type='text'
                  placeholder={
                    script && script != ''
                      ? 'Reload page to create new script...'
                      : 'Enter your video title here...'
                  }
                  className={cn(
                    'relative z-20 font-mono w-1/3 border-black text-center text-xl p-3 text-gray-600 border rounded-lg bg-sky-50 text-base'
                  )}
                />
              )}
            </div>

            <div className='flex justify-center items-center text-center h-full'>
              <ShadCNButton
                className={cn(
                  'relative z-20 bg-slate-600  rounded-lg border-slate-900 text-white font-bold'
                )}
                onClick={() =>
                  script != '' && script
                    ? copyToClipboard(script)
                    : generateScript(searchQuery)
                }
              >
                {isFetching
                  ? 'Generating...'
                  : script && script != ''
                  ? 'View Script'
                  : 'Generate Script'}
              </ShadCNButton>
            </div>
          </>
        ) : isFetching ? (
          <MultiStepLoader
            className='bg-opacity-0'
            loadingStates={loadingStates}
            loading={isFetching}
            duration={6000}
          ></MultiStepLoader>
        ) : isError ? (
          <div className='flex justify-center items-center text-center h-full'>
            <TypewriterEffect
              className='text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-400 to-neutral-500 py-8'
              words={'Failed to generate your script, reload and try again!'
                .split(' ')
                .map(word => {
                  return { text: word }
                })}
            ></TypewriterEffect>
          </div>
        ) : (
          <div className='flex justify-center items-center text-center h-full'>
            <TypewriterEffect
              className='text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-400 to-neutral-500 py-8'
              words={'Unrecognized error occured!'.split(' ').map(word => {
                return { text: word }
              })}
            ></TypewriterEffect>
          </div>
        )}
      </div>
    </div>
  )
}

export default YouTubeScriptGeneratorScreen
