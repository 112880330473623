import axios from 'axios'
import { useEffect, useState, useMemo } from 'react'
import '../css/YouTubeVideosScreen.css'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css' // Core CSS
import 'ag-grid-community/styles/ag-theme-quartz.css' // Theme
import { Navbar } from 'react-bootstrap'
import Dropdown from 'react-bootstrap/Dropdown'
import LoadingMessage from '../components/CommonSubComponents/LoadingMessage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowsRotate,
  faTriangleExclamation,
  faDownload
} from '@fortawesome/free-solid-svg-icons'
import { Button } from 'react-bootstrap'
import ServerReturnedNoDataMessage from '../components/CommonSubComponents/ServerReturnedNoDataMessage'
import { Col } from 'react-bootstrap'
import { Form } from 'react-bootstrap'
import { MultiStepLoader } from '../components/ui/multi-step-loader'
import { CSVLink } from 'react-csv'
import { TypewriterEffect } from '../components/ui/typewriter-effect'
import { ShadCNButton } from '../components/ui/shadcn-button'
import { cn } from '../utils/cn'
function SalesPOCHistoryScreen ({
  checkAuthenticationInResponse,
  APIEndpointDomain
}) {
  var loadingStates = [
    {
      text: 'fetching pocs'
    },

    {
      text: 'calculating performance'
    }
  ]
  var [slots, setSlots] = useState(null)
  var [sameDayFill, setSameDayFill] = useState(null)

  function getSlots (selectedLeadType, startDate, endDate) {
    setSlots('-')
    setSameDayFill('-')
    axios
      .get(APIEndpointDomain + '/support/slots', {
        params: {
          lead_type: selectedLeadType.toLowerCase(),
          start_date: startDate,
          end_date: endDate
        },
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        console.log('heres perfect')

        console.log(response)
        setSlots(response.data['data'])
        setSameDayFill(response.data['same_day_fill'])
      })
      .catch(error => {
        console.log('heres slots')
        checkAuthenticationInResponse(Number(error.response.status))
        console.log(error)
      })
  }

  var today = new Date()
  var start = new Date(new Date().setDate(today.getDate()))
    .toJSON()
    .slice(0, 10)

  var [startDate, setStartDate] = useState(start)
  var [endDate, setEndDate] = useState(start)

  var [selectedLeadType, setSelectedLeadType] = useState('Premium')
  var [data, setData] = useState([])
  var [total, setTotal] = useState([])
  var [pinnedRowData, setPinnedRowData] = useState([])
  var [pinnedBottomRowData, setPinnedBottomRowData] = useState([])

  var [isFetchingSalesPerformanceStats, setIsFetchingSalesPerformanceStats] =
    useState(false)
  var [isServerError, setIsServerError] = useState(false)

  function handleStartDate (e) {
    console.log(selectedLeadType)
    if (selectedLeadType == 'Jobs' && e.target.value < '2024-05-20') {
      console.log('bugger')
      alert(`Please select a date on or after 2024-05-20`)
    } else {
      setStartDate(e.target.value)
    }
  }

  function handleEndDate (e) {
    setEndDate(e.target.value)

    if (selectedLeadType == 'Jobs' && e.target.value < '2024-05-20') {
      console.log('bugger')
      alert(`Please select a date on or after 2024-05-20`)
    } else {
      setEndDate(e.target.value)
    }
  }

  function getLiveSalesPerformanceStats (selectedLeadType, startDate, endDate) {
    setIsFetchingSalesPerformanceStats(true)
    axios
      .get(APIEndpointDomain + '/sales/poc-performance/historical', {
        params: {
          lead_type: selectedLeadType.toLowerCase(),
          start_date: startDate,
          end_date: endDate
        },
        headers: {
          'Access-Control-Allow-Origin': '*',

          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        console.log(response)
        setData(response.data['data'])
        setTotal(response.data['total'])
        setPinnedRowData(response.data['pinned_data'])
        setPinnedBottomRowData(response.data['pinned_bottom_data'])
        setIsFetchingSalesPerformanceStats(false)
        setIsServerError(false)
      })
      .catch(error => {
        checkAuthenticationInResponse(Number(error.response.status))

        console.log(error)
        setData([])
        setTotal([])
        setIsFetchingSalesPerformanceStats(false)
        setIsServerError(true)
        setPinnedRowData([])
        setPinnedBottomRowData([])
      })
  }

  useEffect(() => {
    getLiveSalesPerformanceStats(selectedLeadType, startDate, endDate)
    getSlots(selectedLeadType, startDate, endDate)
  }, [selectedLeadType, startDate, endDate])

  //
  const defaultColDef = useMemo(() => {
    return {
      //   height: '300px',
      filter: true,
      cellStyle: { fontSize: '14px', textAlign: 'center' }
    }
  }, [])

  const gridStyle = useMemo(
    () => ({ height: '100%', width: '100%', backgroundColor: '#e8ebe9' }),
    []
  )
  //
  console.log('total', total)
  const rowStyle = { background: '#eaeaea' }

  const getRowStyle = params => {
    var pipeline = params.data['pipeline__name']

    if (pipeline.toLowerCase() == 'mentr-me') {
      return { background: '#e6fae1', fontWeight: 'bold' }
    } else if (pipeline.toLowerCase() == 'mim-essay') {
      return { background: '#e6fae1', fontWeight: 'bold' }
    } else if (pipeline.toLowerCase() == 'non-website') {
      return { background: '#e6fae1', fontWeight: 'bold' }
    } else if (
      pipeline.toLowerCase() == 'uk cpa' ||
      pipeline.toLowerCase() == 'usa cpa' ||
      pipeline.toLowerCase() == 'usa premium' ||
      pipeline.toLowerCase() == '2024 intake' ||
      pipeline.toLowerCase() == '2025 intake' ||
      pipeline.toLowerCase() == 'other intakes'
    ) {
      return { background: '#fafae1', fontWeight: 'bold' }
    } else if (params.node.rowIndex % 2 === 0) {
      return { background: '#f6f6f6' }
    }
  }

  function cellRenderer (params) {
    var key = params.colDef['field']
    var data = params.data[key]

    if (key.includes('rate')) {
      return (
        <p>
          {data}
          {'%'}
        </p>
      )
    } else {
      return <p>{data}</p>
    }
  }

  var headers =
    data.length > 0 && data
      ? [
          'assigned_call',
          'valid_call',
          'valid_call_rate',
          'projected_valid',
          'projected_valid_rate',
          'dnp',
          'dnp_rate',
          'lead_lost',
          'lead_loss_rate',
          'pending_call',
          'pending_call_rate'
        ]
          .filter(key => key !== 'pipeline__name')
          .map(key => {
            if (key !== 'pipeline__name') {
              return {
                headerName:
                  !key.includes('rate') && !key.includes('name')
                    ? String(key).replace(/_/g, ' ') + ' (' + total[key] + ')'
                    : String(key).replace(/_/g, ' '),
                field: key,
                filter: 'agNumberColumnFilter',
                floatingFilter: true,
                cellRenderer: cellRenderer
                // width: 150
                // autoWidth: true
                // height: 300
              }
            }
          })
      : []

  headers = [
    {
      headerName: 'sales poc',
      field: 'pipeline__name',
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      filterParams: {
        caseSensitive: false,
        defaultOption: 'contains'
      },
      //   autoHeight: true,
      autoWidth: true,
      //   height: 600,
      pinned: 'left',
      rowDrag: params => {
        return !['MentR-Me', 'MiM-Essay', 'non-website'].includes(
          params.data.pipeline__name
        )
      }
    },
    ...headers
  ]

  return (
    <div>
      <Navbar
        fixed='top'
        className='justify-content-between'
        style={{ borderWidth: 0 }}
      >
        <div className='mr-3 ml-3 flex mt-2 justify-between items-center w-screen'>
          <Col sm='0'>
            <Form.Control
              disabled={isFetchingSalesPerformanceStats}
              defaultValue={start}
              onSelect={handleStartDate}
              type='date'
              size='md'
            />
          </Col>
          <Col sm='0'>
            <Form.Control
              disabled={isFetchingSalesPerformanceStats}
              defaultValue={start}
              onSelect={handleEndDate}
              type='date'
              size='md'
            />
          </Col>

          <h4 className='text-3xl text-slate-600 font-bold mx-auto'>
            POC Call Data
          </h4>
          <ShadCNButton
            className={cn(
              'mr-1 flex justify-center items-center relative z-20 bg-slate-600  rounded-lg border-slate-600 text-white font-bold'
            )}
            onClick={() => getSlots(selectedLeadType, startDate, endDate)}
          >
            Total Slots: {slots}
          </ShadCNButton>
          <ShadCNButton
            className={cn(
              'mr-1 flex justify-center items-center relative z-20 bg-slate-600  rounded-lg border-slate-600 text-white font-bold'
            )}
            onClick={() => getSlots(selectedLeadType, startDate, endDate)}
          >
            Post-10 AM Fill: {sameDayFill}
          </ShadCNButton>

          <Dropdown>
            <Dropdown.Toggle
              style={{
                backgroundColor: '#475569',
                borderColor: '#475569',
                textTransform: 'lowercase'
              }}
              className='mr-1 bg-slate-200 border-slate-200'
            >
              {selectedLeadType}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                disabled={isFetchingSalesPerformanceStats}
                onClick={e => setSelectedLeadType(e.target.text)}
              >
                CPA
              </Dropdown.Item>
              <Dropdown.Item
                disabled={isFetchingSalesPerformanceStats}
                onClick={e => setSelectedLeadType(e.target.text)}
              >
                Premium
              </Dropdown.Item>
              <Dropdown.Item
                disabled={isFetchingSalesPerformanceStats}
                onClick={e => setSelectedLeadType(e.target.text)}
              >
                Jobs
              </Dropdown.Item>
              <Dropdown.Item
                disabled={isFetchingSalesPerformanceStats}
                onClick={e => setSelectedLeadType(e.target.text)}
              >
                GMAT
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Button className='mr-3' variant='secondary' size='md'>
            <CSVLink
              filename='sales-performance.csv'
              data={data.concat(pinnedRowData)}
            >
              <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
            </CSVLink>
          </Button>
        </div>
      </Navbar>
      <div
        style={{
          marginTop: '75px',
          minWidth: '100%'
        }}
        className='public-youtube-report-primary-table-body-container'
      >
        {!isFetchingSalesPerformanceStats && !isServerError ? (
          <div style={gridStyle} className={'ag-theme-quartz'}>
            <AgGridReact
              rowData={data}
              columnDefs={headers}
              defaultColDef={defaultColDef}
              rowDragManaged={true}
              rowSelection='multiple'
              rowDragMultiRow={true}
              rowStyle={rowStyle}
              getRowStyle={getRowStyle}
              // pinnedTopRowData={}
              pinnedBottomRowData={pinnedRowData}
            />
          </div>
        ) : isFetchingSalesPerformanceStats ? (
          <MultiStepLoader
            className='bg-opacity-0'
            loadingStates={loadingStates}
            loading={isFetchingSalesPerformanceStats}
            duration={750}
          ></MultiStepLoader>
        ) : (
          <div className='flex justify-center items-center text-center h-full'>
            <TypewriterEffect
              className='text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-400 to-neutral-500 py-8'
              words={"Server returned no data, maybe calls haven't started yet?"
                .split(' ')
                .map(word => {
                  return { text: word }
                })}
            ></TypewriterEffect>
          </div>
        )}
      </div>
    </div>
  )
}

export default SalesPOCHistoryScreen
