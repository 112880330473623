import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from 'react-router-dom'
import PerformanceScreen from './screens/PerformanceScreen'
import LoginScreen from './screens/LoginScreen'
import MarketingScreen from './screens/MarketingScreen'
import CommentManagementScreen from './screens/CommentManagementScreenV2'
import HomePage from './screens/HomePage2'
import FlappyBirdScreen from './screens/FlappyBird'
import PerformanceCustomReportScreen from './screens/PerformanceCustomReportScreen'
import MarketingCustomReportScreen from './screens/MarketingCustomReportScreen'
import PerformanceAdDetailedReportScreen from './screens/PerformanceAdDetailedReportScreen'
import YouTubeVideosScreen from './screens/YouTubeVideosScreen'
import SalesPOCLiveTracker from './screens/SalesPOCLiveTracker'
import SalesPOCHistoryScreen from './screens/SalesPOCHistoryScreen'
import DeletionReportScreen from './screens/DeletionReportScreen'
import ClientsScreen from './screens/ClientsScreen'
import EmailAnalyzerScreen from './screens/EmailAnalyzerScreen'
import PerformanceClustersScreen from './screens/PerformanceClustersScreen'
import MindScapeScreen from './screens/MindScapeScreen'
import PremiumSalesPerformance from './screens/PremiumSalesPerformance'
import YouTubeScriptGeneratorScreen from './screens/YouTubeScriptGeneratorScreen'
import YouTubeAIGenScriptScreen from './screens/YouTubeAIGenScriptScreen'
// import { DrawerDemo } from './screens/DrawerDemo'
import { Dashboard } from './components/PerformanceTableSubComponents/Dashboard'
import YouTubeScriptGeneratorScreenV2 from './screens/YouTubeScriptGeneratorScreenV2'
import FetchYouTubeTranscriptionScreen from './screens/FetchYouTubeTranscriptionScreen'
import PromptGeneratorScreen from './screens/PromptGeneratorScreen'
// import RecentPerformanceAnalyticsScreen from './screens/RecentPerformanceAnalyticsScreen'
import LeadFrequencyScreen from './screens/LeadFrequencyScreen'
import MarketingSalesSupportScreen from './screens/MarketingSupportSalesScreen'
import ResearchContentScreen from './screens/ResearchContentScreen'
import FourOhFour from './screens/404Screen'
import YouTubeTitleResearchScreen from './screens/YouTubeTitleResearchScreen'
import CalendarAnalytics from './screens/CalendarAnalytics'
import AccountBalanceScreen from './screens/AccountBalanceScreen'
import InstagramBoostingAnalytics from './screens/InstagramBoostingAnalytics'
import SalesAnalytics from './screens/SalesAnalytics'
import PECampaignScreen from './screens/PECampaignScreen'
import SlotsOpenScreen from './screens/SlotsOpenScreen'
import { Slot } from '@radix-ui/react-slot'
import ColdCallMarketingAnalytics from './screens/ColdCallMarketingScreen'
import AssistantChatScreen from './screens/AssistantChatScreen'
import PerformancePPCScreen from './screens/PerformancePPCScreen'
import KrayaAnalytics from './screens/KrayaAnalyticsScreen'
import UserSessionSearchScreen from './screens/UserSessionSearchScreen'
import PerformanceMetaIndirectScreen from './screens/PerformanceMetaIndirectAdScreen'

function App () {
  var APIEndpointDomain = 'https://marketingapi.mim-essay.com/api'
  // var APIEndpointDomain = 'http://127.0.0.1:8000/api'
  function checkAuthenticationInResponse (errorStatus) {
    if (errorStatus === 401) {
      localStorage.clear()
      window.location.href = '/login'
    } else {
    }
  }

  function requireAuth (element) {
    if (
      localStorage.getItem('access_token') !== null &&
      localStorage.getItem('access_token') !== undefined
    ) {
      return element
    }
    {
      return <Navigate to='/login' />
    }
  }

  function hideShowLogin (loginPage) {
    if (
      localStorage.getItem('access_token') !== null &&
      localStorage.getItem('access_token') !== undefined
    ) {
      return <Navigate to='/' />
    } else {
      return loginPage
    }
  }
  return (
    <Router>
      <Routes>
        <Route
          path='/performance/meta-direct'
          element={requireAuth(
            <PerformanceScreen
              checkAuthenticationInResponse={checkAuthenticationInResponse}
              APIEndpointDomain={APIEndpointDomain}
              channel={'meta'}
            />
          )}
        />
        <Route
          path='/performance/ga-direct'
          element={requireAuth(
            <PerformanceScreen
              checkAuthenticationInResponse={checkAuthenticationInResponse}
              APIEndpointDomain={APIEndpointDomain}
              channel={'google'}
            />
          )}
        />
        <Route
          path='/comment-management'
          element={requireAuth(
            <div
              style={{
                backgroundColor: '#e8ebe9'
              }}
            >
              <CommentManagementScreen
                checkAuthenticationInResponse={checkAuthenticationInResponse}
                APIEndpointDomain={APIEndpointDomain}
              />
            </div>
          )}
        />
        <Route
          path='/login'
          element={hideShowLogin(
            <LoginScreen
              checkAuthenticationInResponse={checkAuthenticationInResponse}
              APIEndpointDomain={APIEndpointDomain}
            />
          )}
        />
        <Route
          path='/marketing'
          element={requireAuth(
            <MarketingScreen
              checkAuthenticationInResponse={checkAuthenticationInResponse}
              APIEndpointDomain={APIEndpointDomain}
            />
          )}
        />
        <Route
          path='/'
          element={requireAuth(
            <HomePage
              checkAuthenticationInResponse={checkAuthenticationInResponse}
              APIEndpointDomain={APIEndpointDomain}
            />
          )}
        />
        <Route
          path='performance/custom-report/:id'
          element={requireAuth(
            <PerformanceCustomReportScreen
              APIEndpointDomain={APIEndpointDomain}
            />
          )}
        />
        <Route
          path='performance/alltime-stats/ad/:id'
          element={requireAuth(
            <PerformanceAdDetailedReportScreen
              APIEndpointDomain={APIEndpointDomain}
            />
          )}
        />
        <Route
          path='marketing/custom-report/:id'
          element={requireAuth(
            <MarketingCustomReportScreen
              APIEndpointDomain={APIEndpointDomain}
            />
          )}
        />
        <Route
          path='youtube-analytics'
          element={requireAuth(
            <YouTubeVideosScreen
              checkAuthenticationInResponse={checkAuthenticationInResponse}
              APIEndpointDomain={APIEndpointDomain}
            />
          )}
        />
        <Route
          path='sales-live-tracker'
          element={requireAuth(
            <SalesPOCLiveTracker
              checkAuthenticationInResponse={checkAuthenticationInResponse}
              // style={{ backgroundColor: '#e8ebe9' }}

              APIEndpointDomain={APIEndpointDomain}
            />
          )}
        />
        {/* <Route
          path='exports'
          element={requireAuth(
            <ExportsScreen
              style={{ backgroundColor: '#e8ebe9' }}
              APIEndpointDomain={APIEndpointDomain}
            />
          )}
        /> */}
        <Route
          path='sales-poc/call-data'
          element={requireAuth(
            <SalesPOCHistoryScreen
              checkAuthenticationInResponse={checkAuthenticationInResponse}
              style={{ backgroundColor: '#e8ebe9' }}
              APIEndpointDomain={APIEndpointDomain}
            />
          )}
        />
        {/* <Route
          path='thats-marketing/broadcasts'
          element={requireAuth(
            <ThatsMarketingMessagingBroadcast
              style={{ backgroundColor: '#e8ebe9' }}
              APIEndpointDomain={APIEndpointDomain}
            />
          )}
        /> */}
        <Route
          path='marketing/clients'
          element={requireAuth(
            <ClientsScreen
              checkAuthenticationInResponse={checkAuthenticationInResponse}
              style={{ backgroundColor: '#e8ebe9' }}
              APIEndpointDomain={APIEndpointDomain}
            />
          )}
        />
        <Route
          path='email-tracker'
          element={requireAuth(
            <EmailAnalyzerScreen
              checkAuthenticationInResponse={checkAuthenticationInResponse}
              style={{ backgroundColor: '#e8ebe9' }}
              APIEndpointDomain={APIEndpointDomain}
            />
          )}
        />
        <Route
          path='mind-scape'
          element={requireAuth(
            <MindScapeScreen
              checkAuthenticationInResponse={checkAuthenticationInResponse}
              style={{ backgroundColor: '#e8ebe9' }}
              APIEndpointDomain={APIEndpointDomain}
            />
          )}
        />
        <Route
          path='performance/clusters'
          element={requireAuth(
            <PerformanceClustersScreen
              checkAuthenticationInResponse={checkAuthenticationInResponse}
              style={{ backgroundColor: '#e8ebe9' }}
              APIEndpointDomain={APIEndpointDomain}
            />
          )}
        />{' '}
        <Route
          path='sales-performance/premium'
          element={requireAuth(
            <PremiumSalesPerformance
              checkAuthenticationInResponse={checkAuthenticationInResponse}
              // style={{ backgroundColor: '#e8ebe9' }}
              APIEndpointDomain={APIEndpointDomain}
            />
          )}
        />
        <Route
          path='youtube/script-generator'
          element={
            <YouTubeScriptGeneratorScreen
              checkAuthenticationInResponse={checkAuthenticationInResponse}
              APIEndpointDomain={APIEndpointDomain}
            />
          }
        />
        <Route
          path='youtube/script-generator-v2'
          element={
            <YouTubeScriptGeneratorScreenV2
              checkAuthenticationInResponse={checkAuthenticationInResponse}
              APIEndpointDomain={APIEndpointDomain}
            />
          }
        />
        <Route
          path='youtube/get-transcription'
          element={
            <FetchYouTubeTranscriptionScreen
              checkAuthenticationInResponse={checkAuthenticationInResponse}
              APIEndpointDomain={APIEndpointDomain}
            />
          }
        />
        <Route
          path='youtube/script/:id'
          element={
            <YouTubeAIGenScriptScreen
              checkAuthenticationInResponse={checkAuthenticationInResponse}
              APIEndpointDomain={APIEndpointDomain}
            />
          }
        />
        <Route
          path='prompt-engineer'
          element={
            <PromptGeneratorScreen
              checkAuthenticationInResponse={checkAuthenticationInResponse}
              APIEndpointDomain={APIEndpointDomain}
            />
          }
        />
        {/* <Route
          path='performance/trend'
          element={
            <RecentPerformanceAnalyticsScreen
              checkAuthenticationInResponse={checkAuthenticationInResponse}
              APIEndpointDomain={APIEndpointDomain}
            />
          }
        /> */}
        <Route
          path='lead-frequency'
          element={
            <LeadFrequencyScreen
              checkAuthenticationInResponse={checkAuthenticationInResponse}
              APIEndpointDomain={APIEndpointDomain}
            />
          }
        />
        <Route
          path='call-trends'
          element={
            <MarketingSalesSupportScreen
              checkAuthenticationInResponse={checkAuthenticationInResponse}
              APIEndpointDomain={APIEndpointDomain}
            />
          }
        />
        <Route
          path='youtube-title-research'
          element={
            <YouTubeTitleResearchScreen
              checkAuthenticationInResponse={checkAuthenticationInResponse}
              APIEndpointDomain={APIEndpointDomain}
            />
          }
        />
        <Route
          path='content-research'
          element={
            <ResearchContentScreen
              checkAuthenticationInResponse={checkAuthenticationInResponse}
              APIEndpointDomain={APIEndpointDomain}
            />
          }
        />
        <Route
          path='demo'
          element={
            <Dashboard
              checkAuthenticationInResponse={checkAuthenticationInResponse}
              // style={{ backgroundColor: '#e8ebe9' }}
              APIEndpointDomain={APIEndpointDomain}
            />
          }
        />
        <Route
          path='calendar-analytics'
          element={
            <CalendarAnalytics
              checkAuthenticationInResponse={checkAuthenticationInResponse}
              // style={{ backgroundColor: '#e8ebe9' }}
              APIEndpointDomain={APIEndpointDomain}
            />
          }
        />
        <Route
          path='sales-analytics'
          element={
            <SalesAnalytics
              checkAuthenticationInResponse={checkAuthenticationInResponse}
              // style={{ backgroundColor: '#e8ebe9' }}
              APIEndpointDomain={APIEndpointDomain}
            />
          }
        />
        <Route
          path='performance/balance'
          element={
            <AccountBalanceScreen
              checkAuthenticationInResponse={checkAuthenticationInResponse}
              // style={{ backgroundColor: '#e8ebe9' }}
              APIEndpointDomain={APIEndpointDomain}
            />
          }
        />
        <Route
          path='performance/profile-evaluation/campaign-analytics'
          element={
            <PECampaignScreen
              checkAuthenticationInResponse={checkAuthenticationInResponse}
              // style={{ backgroundColor: '#e8ebe9' }}
              APIEndpointDomain={APIEndpointDomain}
            />
          }
        />
        <Route
          path='calendar/slots-open'
          element={
            <SlotsOpenScreen
              checkAuthenticationInResponse={checkAuthenticationInResponse}
              // style={{ backgroundColor: '#e8ebe9' }}
              APIEndpointDomain={APIEndpointDomain}
            />
          }
        />
        <Route
          path='marketing/cold-call'
          element={
            <ColdCallMarketingAnalytics
              checkAuthenticationInResponse={checkAuthenticationInResponse}
              // style={{ backgroundColor: '#e8ebe9' }}
              APIEndpointDomain={APIEndpointDomain}
            />
          }
        />
        <Route
          path='performance/instagram-boosting'
          element={
            <InstagramBoostingAnalytics
              checkAuthenticationInResponse={checkAuthenticationInResponse}
              // style={{ backgroundColor: '#e8ebe9' }}
              APIEndpointDomain={APIEndpointDomain}
            />
          }
        />
        <Route
          path='performance/ga-indirect'
          element={
            <PerformancePPCScreen
              checkAuthenticationInResponse={checkAuthenticationInResponse}
              // style={{ backgroundColor: '#e8ebe9' }}
              APIEndpointDomain={APIEndpointDomain}
            />
          }
        />
        <Route
          path='performance/meta-indirect'
          element={
            <PerformanceMetaIndirectScreen
              checkAuthenticationInResponse={checkAuthenticationInResponse}
              // style={{ backgroundColor: '#e8ebe9' }}
              APIEndpointDomain={APIEndpointDomain}
            />
          }
        />
        <Route
          path='kraya-analytics'
          element={
            <KrayaAnalytics
              checkAuthenticationInResponse={checkAuthenticationInResponse}
              // style={{ backgroundColor: '#e8ebe9' }}
              APIEndpointDomain={APIEndpointDomain}
            />
          }
        />
        <Route
          path='sessions/user-search'
          element={
            <UserSessionSearchScreen
              checkAuthenticationInResponse={checkAuthenticationInResponse}
              // style={{ backgroundColor: '#e8ebe9' }}
              APIEndpointDomain={APIEndpointDomain}
            />
          }
        />
        <Route
          path='assistant-chat'
          element={
            <AssistantChatScreen
              checkAuthenticationInResponse={checkAuthenticationInResponse}
              // style={{ backgroundColor: '#e8ebe9' }}
              APIEndpointDomain={APIEndpointDomain}
            />
          }
        />
        {/* Catch-all route */}
        <Route path='*' element={<FourOhFour></FourOhFour>} />
      </Routes>
    </Router>
  )
}

export default App
