import React, { useEffect } from 'react'
import { Button, Navbar } from 'react-bootstrap'
import { TabsATUI } from '../components/ui/tabs'
import { AgGridReact } from 'ag-grid-react'
import { useMemo, useState } from 'react'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import '../css/PerformanceTable.css'
import axios from 'axios'
import { TypewriterEffect } from '../components/ui/typewriter-effect'
import { MultiStepLoader } from '../components/ui/multi-step-loader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCopy,
  faCalendar,
  faMessage,
  faStar,
  faStarHalf
} from '@fortawesome/free-solid-svg-icons'
import { ShadCNButton } from '../components/ui/shadcn-button.js'
import DrawerMS from '../components/MindScrapeComponents/drawer.js'
import Dropdown from 'react-bootstrap/Dropdown'
import scheduleAppointmentButton from '../components/MindScrapeComponents/scheduleAppointmentButton.js'
import ScheduleAppointmentButton from '../components/MindScrapeComponents/scheduleAppointmentButton.js'
import {
  Widget,
  addResponseMessage,
  toggleWidget,
  addUserMessage,
  deleteMessages
} from 'react-chat-widget'

import 'react-chat-widget/lib/styles.css'
function MindScapeScreen ({ APIEndpointDomain, checkAuthenticationInResponse }) {
  var [selectedLevel, setSelectedLevel] = useState('requests')
  var [allData, setAllData] = useState([])
  var [data, setData] = useState([])
  var [isFetching, setIsFetching] = useState(false)
  var [isError, setIsError] = useState(false)
  var [selectedLeadFilter, setSelectedLeadFilter] = useState('All')
  var loadingStates = [
    {
      text: 'Fetching appointments'
    }
  ]

  var [showAppointmentBookingModal, setShowAppointmentBookingModal] =
    useState(false)

  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), [])
  const rowStyle = { background: '#black' }
  const defaultColDef = useMemo(() => {
    return {
      width: 170,
      filter: true,
      cellStyle: { fontSize: '14px', textAlign: 'center' },
      enableValue: true,
      enableRowGroup: true,
      enablePivot: true,
      filterParams: { newRowsAction: 'keep' }
    }
  }, [])

  const getRowStyle = params => {
    var row = params.data

    if (
      row['ultramsg_id'] != '' &&
      row['recent_message'] &&
      row['recent_message'] != '' &&
      !row['recent_message_acknowledged']
    ) {
      return { background: '#fef3c7' }
    } else if (params.node.rowIndex % 2 !== 0) {
      return { background: '#f1f5f9' }
    } else if (params.node.rowIndex % 2 === 0) {
      return { background: '#f8fafc' }
    }
  }

  function drawerSave (form_id, field, typedLostReason) {
    updateLeadInformation(form_id, field, typedLostReason)
  }
  function getAllAppointments () {
    setIsFetching(true)
    setIsError(false)
    axios
      .get(APIEndpointDomain + '/mind-scape/get-all-leads', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        console.log(response)
        setAllData(response.data['all_appointments'])
        setIsFetching(false)
        setIsError(false)
      })
      .catch(error => {
        checkAuthenticationInResponse(Number(error.response.status))
        console.log(error)
        setIsFetching(false)
        setIsError(true)
      })
  }

  function updateObject (array, idToFind, keyToUpdate, newValue) {
    // Find the index of the object with the given id
    const index = array.findIndex(obj => obj.form_id === idToFind)

    // If the object is found
    if (index !== -1) {
      // Update the value of the specified key
      array[index][keyToUpdate] = newValue
      // Return the updated object
      console.log('updated obj', array[index][keyToUpdate])
      return array
    } else {
      // Object with the given id not found
      console.log('Object not found')
      return null
    }
  }

  function updateStage (form_id, stage) {
    axios
      .get(APIEndpointDomain + '/mind-scape/update-stage', {
        params: {
          form_id: form_id,
          stage: stage
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        console.log(response)
        setAllData(response.data['all_appointments'])
      })
      .catch(error => {
        checkAuthenticationInResponse(Number(error.response.status))
        console.log(error)
      })
  }

  function updateLeadInformation (form_id, field, value) {
    axios
      .get(APIEndpointDomain + '/mind-scape/update-information', {
        params: {
          form_id: form_id,
          field: field,
          value: value
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        console.log(response)
        var new_data = updateObject(allData, form_id, field, value)
        setAllData(new_data)
      })
      .catch(error => {
        checkAuthenticationInResponse(Number(error.response.status))
        console.log(error)
      })
  }

  function handleMessageCheckbox (message_sequence, form_id) {
    var object = allData.find(row => row.form_id == form_id)
    var message_already_sent = object[message_sequence]['sent']

    axios
      .get(APIEndpointDomain + '/mind-scape/update-lead-message-sequence', {
        params: {
          messaged: !message_already_sent,
          form_id: form_id,
          message_sequence: message_sequence
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        console.log(response)
        setAllData(response.data['all_appointments'])
      })
      .catch(error => {
        checkAuthenticationInResponse(Number(error.response.status))
        console.log(error)
      })
  }

  function copyToClipboard (text) {
    console.log(text)
    navigator.clipboard.writeText(text)
  }

  function openWhatsAppLink (phone) {
    var cleaned_phone =
      String(phone).replace(/\s/g, '').length == 10
        ? '91' + String(phone).replace(/\s/g, '')
        : String(phone).replace(/\s/g, '')

    var url = 'https://wa.me/' + cleaned_phone

    window.open(url)
  }

  var [currentSelectedBooking, setCurrentSelectedBooking] = useState(null)
  var [currentSelectedName, setCurrentSelectedName] = useState(null)
  var [currentSelectedFormID, setCurrentSelectedFormID] = useState(null)

  function openAppointmentBookingModal (currentBooking, name, form_id) {
    if (showAppointmentBookingModal) {
      setShowAppointmentBookingModal(false)
    } else {
      setShowAppointmentBookingModal(true)
      setCurrentSelectedBooking(currentBooking)
      setCurrentSelectedName(name)
      setCurrentSelectedFormID(form_id)
    }
  }

  function cellRenderer (params) {
    var key = params.colDef['field']
    var row = params.data
    var data = params.data[key]

    if (key == 'booked_at') {
      return (
        <p>
          {data ? data.replace('T', ' ').replace('Z', '') : null}

          {
            <>
              <br></br>
              <ShadCNButton
                className='bg-transparent'
                onClick={() =>
                  openAppointmentBookingModal(
                    data ? data.split('T')[0] : data,
                    row['name'],
                    row['form_id']
                  )
                }
              >
                <FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon>
              </ShadCNButton>
            </>
          }
        </p>
      )
    } else if (key == 'profile_picture') {
      return (
        <img
          src={
            data ==
            'https://i.pinimg.com/originals/ea/d1/ca/ead1cac3ce991bf4eb9e2b4c51164ac8.gif'
              ? ''
              : data
          }
        ></img>
      )
    } else if (key == 'recent_message') {
      return (
        <p className='font-bold'>
          {data ? '"' + data + '"' : ''}
          <br></br>
          {row['ultramsg_id'] != '' ? (
            <>
              {openChatID == row['ultramsg_id'] &&
              chatboxTitle == 'Fetching chat...' ? (
                <p>Opening chat...</p>
              ) : (
                <ShadCNButton
                  onClick={() =>
                    openChat(row['ultramsg_id'], row['name'], row['phone'])
                  }
                  className='bg-transparent outline outline-1 outline-slate-400'
                  size='sm'
                >
                  <FontAwesomeIcon
                    size='sm'
                    className='fa-regular'
                    icon={faMessage}
                  ></FontAwesomeIcon>
                </ShadCNButton>
              )}
              {row['ultramsg_id'] != '' &&
                row['recent_message'] &&
                row['recent_message'] != '' &&
                !row['recent_message_acknowledged'] && (
                  <ShadCNButton
                    onClick={() => acknowledgeMessage(row['ultramsg_id'])}
                    className='bg-transparent outline outline-1 outline-slate-400'
                    size='sm'
                    // style={{ outlineWidth: '8px' }}
                  >
                    Mark Read
                  </ShadCNButton>
                )}
            </>
          ) : (
            <ShadCNButton
              onClick={() =>
                openChat(row['ultramsg_id'], row['name'], row['phone'])
              }
              className='bg-transparent'
              size='sm'
            >
              #WhatsAppNA
            </ShadCNButton>
          )}
        </p>
      )
    } else if (key == 'message') {
      return <p>{data}</p>
    } else if (key == 'phone') {
      return (
        <p style={{ lineHeight: '0px' }}>
          <DrawerMS
            data={data}
            title={'Update phone number for ' + row['name']}
            drawerSave={drawerSave}
            form_id={row['form_id']}
            field={key}
            APIEndpointDomain={APIEndpointDomain}
            allData={allData}
            setAllData={setAllData}
            checkAuthenticationInResponse={checkAuthenticationInResponse}
          ></DrawerMS>
          {/* <ShadCNButton
            onClick={() => copyToClipboard(data)}
            className='bg-transparent'
            size='sm'
          >
            <FontAwesomeIcon size='sm' icon={faCopy}></FontAwesomeIcon>
          </ShadCNButton> */}
        </p>
      )
    } else if (key.includes('message')) {
      return (
        <p>
          <input
            onClick={() => handleMessageCheckbox(key, row['form_id'])}
            type='checkbox'
            checked={data['sent'] ? true : false}
          ></input>
          {data['sent'] ? (
            <>
              <br></br>
              <p className='text-xs'>{String(data['sent_at']).split(' ')[0]}</p>
            </>
          ) : null}
        </p>
      )
    } else if (key == 'created_at') {
      return <p>{data.replace('T', ' ').replace('Z', '').split(' ')[0]}</p>
    } else if (key == 'update_stage') {
      return (
        <select
          defaultValue={data}
          onChange={event => {
            const selectedValue = event.target.value
            if (selectedValue !== '') {
              updateStage(row.form_id, event.target.value)
            }
          }}
        >
          <option value=''>Update stage...</option>

          {[, 'requests', 'nurturing', 'scheduled', 'called', 'closed', 'lost']
            .filter(level => level !== selectedLevel)
            .map(stage => {
              return (
                <option key={stage} value={stage}>
                  {stage == 'called' ? 'free-call completed' : stage}
                </option>
              )
            })}
        </select>
      )
    } else if (key == 'lost_reason' || key == 'location' || key == 'notes') {
      return (
        <>
          <DrawerMS
            data={data}
            title={
              key == 'lost_reason'
                ? 'Why was ' + row['name'] + ' shifted to lost?'
                : key == 'location'
                ? 'Where is ' + row['name'] + ' from?'
                : key == 'notes'
                ? 'Add notes about your discussion with ' + row['name']
                : ''
            }
            drawerSave={drawerSave}
            form_id={row['form_id']}
            field={key}
            APIEndpointDomain={APIEndpointDomain}
            allData={allData}
            setAllData={setAllData}
            checkAuthenticationInResponse={checkAuthenticationInResponse}
          ></DrawerMS>
        </>
      )
    } else {
      return <p>{data}</p>
    }
  }

  useEffect(() => {
    getAllAppointments()
  }, [])

  function ButtonsCellRenderer (params) {
    var row = params.data
    var pinned = Object.keys(params.node).includes('rowPinned') ? true : false

    if (!pinned) {
      return (
        <p
          style={{
            textAlign: 'center'
          }}
        >
          <Button
            variant={
              row['bookmarked'] == true || bookmarked.includes(row['form_id'])
                ? 'success'
                : 'dark'
            }
            onClick={() => bookmarkAppointment(row['form_id'])}
          >
            {row['bookmarked'] == true ||
            bookmarked.includes(row['form_id']) ? (
              <FontAwesomeIcon className='fa-solid fa-star' icon={faStar} />
            ) : (
              <FontAwesomeIcon
                className='fa-regular fa-star'
                icon={faStarHalf}
              />
            )}
          </Button>
        </p>
      )
    } else {
      return <></>
    }
  }

  var headers =
    allData && allData.length > 0 && selectedLevel == 'requests'
      ? Object.keys(allData[0]).filter(
          key =>
            ![
              'stage',
              'needs_attention',
              'form_id',
              'nurturing_messages',
              'lost_reason',

              'free_call_completed_followup_message_1',
              'free_call_completed_followup_message_2',
              'call_scheduled_reminder_message_1',
              'call_scheduled_reminder_message_2',
              'booked_at',
              'message',
              'ultramsg_id',
              'recent_message_acknowledged',
              'bookmarked',
              'second_followup_message'
            ].includes(key)
        )
      : allData && allData.length > 0 && selectedLevel == 'nurturing'
      ? Object.keys(allData[0]).filter(
          key =>
            ![
              'stage',
              'needs_attention',
              'form_id',
              'welcome_message',
              'first_followup_message',
              'second_followup_message',
              'lost_reason',

              'free_call_completed_followup_message_1',
              'free_call_completed_followup_message_2',
              'call_scheduled_reminder_message_1',
              'call_scheduled_reminder_message_2',
              'booked_at',
              'message',
              'ultramsg_id',
              'recent_message_acknowledged',
              'bookmarked',
              'second_followup_message'
            ].includes(key)
        )
      : allData && allData.length > 0 && selectedLevel == 'scheduled'
      ? Object.keys(allData[0]).filter(
          key =>
            ![
              'stage',
              'needs_attention',
              'form_id',
              'welcome_message',
              'first_followup_message',
              'second_followup_message',
              'lost_reason',
              'nurturing_messages',

              'free_call_completed_followup_message_1',
              'free_call_completed_followup_message_2',
              'needs_attention',
              'form_id',
              'stage',
              'message',
              'ultramsg_id',
              'recent_message_acknowledged',
              'bookmarked',
              'second_followup_message'
            ].includes(key)
        )
      : allData && allData.length > 0 && selectedLevel == 'called'
      ? Object.keys(allData[0]).filter(
          key =>
            ![
              'stage',
              'needs_attention',
              'form_id',
              'welcome_message',
              'first_followup_message',
              'second_followup_message',
              'lost_reason',
              'nurturing_messages',

              'needs_attention',
              'form_id',
              'stage',
              'lost_reason',
              'needs_attention',
              'form_id',
              'stage',

              'call_scheduled_reminder_message_1',
              'call_scheduled_reminder_message_2',
              'message',
              'ultramsg_id',
              'recent_message_acknowledged',
              'bookmarked',
              'second_followup_message'
            ].includes(key)
        )
      : allData && allData.length > 0 && selectedLevel == 'closed'
      ? Object.keys(allData[0]).filter(
          key =>
            ![
              'lost_reason',
              'message',
              'needs_attention',
              'form_id',
              'stage',
              'message',
              'ultramsg_id',
              'recent_message_acknowledged',
              'bookmarked',
              'second_followup_message'
            ].includes(key)
        )
      : allData && allData.length > 0
      ? Object.keys(allData[0]).filter(
          key =>
            ![
              'stage',
              'needs_attention',
              'form_id',
              'message',

              'free_call_completed_followup_message_1',
              'free_call_completed_followup_message_2',
              'call_scheduled_reminder_message_1',
              'call_scheduled_reminder_message_2',
              'booked_at',
              'ultramsg_id',
              'recent_message_acknowledged',
              'bookmarked',
              'second_followup_message'
            ].includes(key)
        )
      : []

  headers = headers.map(header => {
    return {
      field: header,
      // onCellClicked: handleCellClick,
      cellRenderer: cellRenderer,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      filterParams: {
        caseSensitive: false,
        defaultOption: 'contains'
      },
      wrapText:
        header == 'message' ||
        header == 'requirement' ||
        header == 'lost_reason' ||
        header == 'notes'
          ? true
          : false,
      autoHeight: true,
      width:
        header == 'message' ||
        header == 'requirement' ||
        header == 'lost_reason' ||
        header == 'notes'
          ? 300
          : window.screen.width / headers.length,
      pinned: ['profile_picture', 'name', 'phone', 'recent_message'].includes(
        header
      )
        ? 'left'
        : false
    }
  })

  headers = [
    ...headers,
    {
      field: 'update_stage',
      cellRenderer: cellRenderer,
      filter: 'agTextColumnFilter',
      floatingFilter: false,
      filterParams: {
        caseSensitive: false,
        defaultOption: 'contains'
      },
      wrapText: true,
      autoHeight: true,
      width: window.screen.width / headers.length
    }
  ]

  headers = [
    {
      field: '',
      cellRenderer: ButtonsCellRenderer,
      rowDrag: false,
      sortable: false,
      autoHeight: true,
      width: 180,
      pinned: 'left'
    },
    ...headers
  ]

  function filterByStage (allData, selectedLeadFilter, selectedLevel) {
    if (selectedLevel !== 'bookmarked') {
      var copyAllData =
        selectedLeadFilter == 'All'
          ? allData
          : selectedLeadFilter == 'Action needed'
          ? allData.filter(row => row.needs_attention)
          : selectedLeadFilter == 'No action needed'
          ? allData.filter(row => !row.needs_attention)
          : selectedLeadFilter == 'Response required'
          ? allData.filter(
              row =>
                row['ultramsg_id'] != '' &&
                row['recent_message'] &&
                row['recent_message'] != '' &&
                !row['recent_message_acknowledged']
            )
          : selectedLeadFilter == 'WhatsApp NA'
          ? allData.filter(
              row => row['ultramsg_id'] == '' || row['ultramsg_id'] == null
            )
          : selectedLeadFilter == 'Watchlisted'
          ? allData.filter(row => row['bookmarked'] == true)
          : []
      var dataForStage = copyAllData.filter(
        row => row.stage == selectedLevel.toLowerCase()
      )
    } else {
      var copyAllData = allData
      var dataForStage = copyAllData.filter(row => row.bookmarked == true)
    }

    return dataForStage
  }

  useEffect(() => {
    console.log('setting data', allData)
    var dataForStage = filterByStage(allData, selectedLeadFilter, selectedLevel)
    setData(dataForStage)
  }, [allData, selectedLevel, selectedLeadFilter])

  var [openChatID, setOpenChatID] = useState('')

  function handleNewUserMessage (message) {
    axios
      .post(
        APIEndpointDomain + '/mind-scape/send-message',
        {
          message: message,
          chat_id: openChatID
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`
          }
        }
      )
      .then(response => {
        // addUserMessage(message)
      })
      .catch(error => {
        checkAuthenticationInResponse(Number(error.response.status))
        console.log(error)
        alert('Message failed to deliver!')
      })
  }

  var [launchChat, setLaunchChat] = useState(false)

  var [chatboxTitle, setChatboxTitle] = useState('')
  var [chatboxSubtitle, setChatboxSubtitle] = useState('')
  var [userImage, setUserImage] = useState(
    'https://i.pinimg.com/originals/ea/d1/ca/ead1cac3ce991bf4eb9e2b4c51164ac8.gif'
  )

  function openChat (chatID, Name, Phone) {
    setOpenChatID(chatID)
    setChatboxTitle('Fetching chat...')
    setChatboxSubtitle('Please wait!')

    deleteMessages()
    axios
      .get(APIEndpointDomain + '/mind-scape/get-chat', {
        params: {
          chat_id: chatID
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        setChatboxTitle(Name)
        setChatboxSubtitle(Phone)
        console.log(response.data['data'])
        var chats = response.data['data']
        setUserImage(response.data['image'])
        for (let i = 0; i < chats.length; i++) {
          if (chats[i]['from'] == '919650015893@c.us' && chats[i]['body']) {
            console.log('user_found')
            addUserMessage(chats[i]['body'])
          } else if (chats[i]['body']) {
            console.log('not_user_found')
            addResponseMessage(chats[i]['body'])
          }
        }
      })
      .catch(error => {
        checkAuthenticationInResponse(Number(error.response.status))
        console.log('printing error', error)
        setChatboxTitle('Chat not found')
        setChatboxSubtitle(Phone)
        alert('Failed to fetch chat... please close and retry!')
      })
      .finally(() => {
        // toggleWidget()
        setLaunchChat(true)
      })
  }

  useEffect(() => {
    toggleWidget()
  }, [])

  function handleChatToggle (toggleStatus) {
    if (!toggleStatus) {
      setLaunchChat(false)
      toggleWidget()
    }
  }

  function acknowledgeMessage (chat_id) {
    axios
      .get(APIEndpointDomain + '/mind-scape/acknowledge-unread-message', {
        params: {
          chat_id: chat_id
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        setAllData(
          allData.map(row => {
            if (row['ultramsg_id'] === chat_id) {
              return { ...row, recent_message_acknowledged: true }
            }
            return row
          })
        )
      })
      .catch(error => {
        checkAuthenticationInResponse(Number(error.response.status))
        console.log('printing error', error)

        alert('Failed to mark the message as read!')
      })
  }

  var [unbookmarked, setUnbookmarked] = useState([])
  var [bookmarked, setBookmarked] = useState([])

  function bookmarkAppointment (form_id) {
    axios
      .get(APIEndpointDomain + '/mind-scape/bookmark-lead', {
        params: {
          form_id: form_id
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        // if (
        //   ![true].includes(
        //     allData.get(row => row.form_id == form_id)['bookmarked']
        //   )
        // ) {
        //   setBookmarked([...bookmarked, form_id])
        //   setUnbookmarked(bookmarked.filter(id => id !== form_id))
        // } else {
        //   setUnbookmarked([...unbookmarked, form_id])
        //   setBookmarked(bookmarked.filter(id => id !== form_id))
        // }

        setAllData(response.data['data'])
      })
      .catch(error => {
        checkAuthenticationInResponse(Number(error.response.status))
        console.log('printing error', error)

        alert('Failed to bookmark this lead!')
      })
  }

  return (
    <div className='h-[50rem] h-screen w-screen w-full dark:bg-black bg-white  dark:bg-grid-white/[0.2] bg-grid-black/[0.2] relative flex items-center justify-center'>
      <div className='absolute h-screen w-screen pointer-events-null inset-0 flex items-center justify-center dark:bg-black bg-white [mask-image:radial-gradient(ellipse_at_center,transparent_20%,black)]'></div>
      <div className='grid grid-rows-2 gap-0 bg-opacity-0 h-screen w-screen'>
        <div className='w-screen bg-opacity-0'>
          <Navbar
            fixed='top'
            style={{
              borderWidth: 0
            }}
          >
            <div className='w-screen '>
              {/* <div className='py-0 '> */}
              <div
                className='content-center'
                style={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <Dropdown>
                  <Dropdown.Toggle
                    className='ml-3 bg-transparent rounded-xl outline outline-1 outline-slate-500'
                    variant='secondary'
                    id='dropdown-basic'
                  >
                    {selectedLeadFilter}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={e => setSelectedLeadFilter(e.target.text)}
                    >
                      All
                    </Dropdown.Item>

                    <Dropdown.Item
                      onClick={e => setSelectedLeadFilter(e.target.text)}
                    >
                      Watchlisted
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={e => setSelectedLeadFilter(e.target.text)}
                    >
                      Response required
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={e => setSelectedLeadFilter(e.target.text)}
                    >
                      WhatsApp NA
                    </Dropdown.Item>

                    <Dropdown.Item
                      onClick={e => setSelectedLeadFilter(e.target.text)}
                    >
                      Action needed
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={e => setSelectedLeadFilter(e.target.text)}
                    >
                      No action needed
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <h4 className='text-3xl text-slate-600 font-bold mx-auto'>
                  Mindscape Appointments
                </h4>
                <ShadCNButton
                  onClick={() => setSelectedLeadFilter('Response required')}
                  className='mr-3 bg-transparent rounded-xl outline outline-1 outline-slate-500'
                >
                  {'Response required: '}
                  {
                    allData.filter(
                      row =>
                        row['ultramsg_id'] != '' &&
                        row['recent_message'] &&
                        row['recent_message'] != '' &&
                        !row['recent_message_acknowledged']
                    ).length
                  }
                </ShadCNButton>
              </div>
              <div className='pt-1'>
                <TabsATUI
                  tabs={[
                    {
                      title:
                        String('Requests (') +
                        String(
                          allData
                            ? filterByStage(
                                allData,
                                selectedLeadFilter,
                                'requests'
                              ).length
                            : ''
                        ) +
                        '/' +
                        String(
                          allData
                            ? allData.filter(row => row['stage'] == 'requests')
                                .length
                            : ''
                        ) +
                        ')',
                      value: 'requests'
                    },
                    {
                      title:
                        String('Nurturing (') +
                        String(
                          allData
                            ? filterByStage(
                                allData,
                                selectedLeadFilter,
                                'nurturing'
                              ).length
                            : ''
                        ) +
                        '/' +
                        String(
                          allData
                            ? allData.filter(row => row['stage'] == 'nurturing')
                                .length
                            : ''
                        ) +
                        ')',
                      value: 'nurturing'
                    },

                    {
                      title:
                        String('Scheduled (') +
                        String(
                          allData
                            ? filterByStage(
                                allData,
                                selectedLeadFilter,
                                'scheduled'
                              ).length
                            : ''
                        ) +
                        '/' +
                        String(
                          allData
                            ? allData.filter(row => row['stage'] == 'scheduled')
                                .length
                            : ''
                        ) +
                        ')',
                      value: 'scheduled'
                    },
                    {
                      title:
                        String('Free-Call Completed (') +
                        String(
                          allData
                            ? filterByStage(
                                allData,
                                selectedLeadFilter,
                                'called'
                              ).length
                            : ''
                        ) +
                        '/' +
                        String(
                          allData
                            ? allData.filter(row => row['stage'] == 'called')
                                .length
                            : ''
                        ) +
                        ')',
                      value: 'called'
                    },

                    {
                      title:
                        String('Closed (') +
                        String(
                          allData
                            ? filterByStage(
                                allData,
                                selectedLeadFilter,
                                'closed'
                              ).length
                            : ''
                        ) +
                        ')',
                      value: 'closed'
                    },
                    {
                      title:
                        String('Lost (') +
                        String(
                          allData
                            ? filterByStage(allData, selectedLeadFilter, 'lost')
                                .length
                            : ''
                        ) +
                        ')',
                      value: 'lost'
                    },
                    {
                      title:
                        String('Watchlisted (') +
                        String(
                          allData.filter(row => row.bookmarked == true).length
                        ) +
                        ')',
                      value: 'bookmarked'
                    }
                  ]}
                  levelSelector={setSelectedLevel}
                  selectedLevel={selectedLevel}
                />
              </div>
            </div>
          </Navbar>
          <div className='pt-28 bg-opacity-0 w-screen h-screen '>
            {/* <>hi</> */}

            <ScheduleAppointmentButton
              currentBooking={currentSelectedBooking}
              name={currentSelectedName}
              show={showAppointmentBookingModal}
              setShow={setShowAppointmentBookingModal}
              form_id={currentSelectedFormID}
              APIEndpointDomain={APIEndpointDomain}
              setAllData={setAllData}
              checkAuthenticationInResponse={checkAuthenticationInResponse}
            ></ScheduleAppointmentButton>
            {!isFetching && !isError && data && data.length > 0 ? (
              <>
                {launchChat && (
                  <div className='App'>
                    <Widget
                      handleNewUserMessage={handleNewUserMessage}
                      isWidgetOpened={true}
                      title={chatboxTitle}
                      subtitle={chatboxSubtitle}
                      handleToggle={handleChatToggle}
                      profileAvatar={userImage}
                      profileClientAvatar={
                        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTaq8BrtOJMyK4epzOea1n56lxdd0IVtjZbrQ&s'
                      }
                      emojis={true}
                      resizable={true}
                    />
                  </div>
                )}
                <div style={gridStyle} className={'ag-theme-quartz'}>
                  <AgGridReact
                    rowData={data}
                    columnDefs={headers}
                    defaultColDef={defaultColDef}
                    rowDragManaged={true}
                    rowSelection='multiple'
                    rowDragMultiRow={true}
                    rowStyle={rowStyle}
                    getRowStyle={getRowStyle}
                    sideBar={true}
                  />
                </div>
              </>
            ) : isFetching ? (
              <MultiStepLoader
                className='bg-opacity-0'
                loadingStates={loadingStates}
                loading={isFetching}
                duration={2000}
              ></MultiStepLoader>
            ) : isError ? (
              <div className='flex justify-center items-center text-center h-full'>
                <TypewriterEffect
                  className='text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-400 to-neutral-500 py-8'
                  words={'Server returned no data!'.split(' ').map(word => {
                    return { text: word }
                  })}
                ></TypewriterEffect>
              </div>
            ) : !isError && !isFetching && data.length == 0 ? (
              <div className='flex justify-center items-center text-center h-full'>
                <TypewriterEffect
                  className='text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-400 to-neutral-500 py-8'
                  words={'No appointments match the filters!'
                    .split(' ')
                    .map(word => {
                      return { text: word }
                    })}
                ></TypewriterEffect>
              </div>
            ) : (
              <div className='flex justify-center items-center text-center h-full'>
                <TypewriterEffect
                  className='text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-400 to-neutral-500 py-8'
                  words={'Unrecognized error occured!'.split(' ').map(word => {
                    return { text: word }
                  })}
                ></TypewriterEffect>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MindScapeScreen
