import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTrash,
  faLink,
  faBarsProgress,
  faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons'
import Toast from 'react-bootstrap/Toast'
import { useState } from 'react'
import ToastContainer from 'react-bootstrap/ToastContainer'
import APIRequestStatusModal from './CommonSubComponents/APIRequestStatusMessageModal'
import { TypewriterEffect } from '../components/ui/typewriter-effect'
import { MultiStepLoader } from '../components/ui/multi-step-loader'

import { ShadCNButton } from '../components/ui/shadcn-button'
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle
} from '../components/ui/shadcn-card'

import { Input } from '../components/ui/input'
import { Label } from '../components/ui/label'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '../components/ui/select'

function CommentManagementTable ({
  tableData,
  selectedAdAccount,
  APIEndpointDomain,
  setTableData,
  APICalling,
  APIError,
  selectedCommentIDs,
  setSelectedCommentIDs
}) {
  var loadingStates = [
    {
      text: 'Fetching comments'
    }
  ]
  var [showErrorToast, setShowErrorToast] = useState(false)
  var [ErrorMessage, setErrorMessage] = useState('')
  var [shakeCommentIDs, setShakeCommentIDs] = useState([])
  var [shakeReplyIDs, setShakeReplyIDs] = useState([])

  var [replyingMessage, setReplyingMessage] = useState({})

  var [showReplyStatusAlert, setShowReplyStatusAlert] = useState(false)
  var [replyStatusMessage, setReplyStatusMessage] = useState('')

  var [showDeleteCommentStatusAlert, setShowDeleteCommentStatusAlert] =
    useState(false)
  var [deleteCommentStatusMessage, setDeleteCommentStatusMessage] = useState('')

  function selectComment (comment_id) {
    if (selectedCommentIDs.includes(comment_id)) {
      setSelectedCommentIDs(selectedCommentIDs.filter(id => id != comment_id))
    } else {
      setSelectedCommentIDs([...selectedCommentIDs, comment_id])
    }
  }

  function Reply (id, message) {
    axios
      .get(APIEndpointDomain + '/comment-management/reply', {
        params: {
          comment_id: id,
          reply_message: message,
          ad_account: selectedAdAccount
        },
        headers: {
          'ngrok-skip-browser-warning': 'any',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        console.log(response)
        setTableData(response.data['comments'])
        setReplyStatusMessage('Successfully posted your reply!')
        setShowReplyStatusAlert(true)
      })
      .catch(error => {
        console.log(error)
        setErrorMessage(JSON.parse(error.request.response).message)
        setReplyStatusMessage('Failed to post your reply!')
        setShowReplyStatusAlert(true)
      })
  }

  function DeleteComments (comment_ids) {
    axios
      .post(
        APIEndpointDomain + '/comment-management/delete-comments',
        {
          comment_ids: comment_ids,
          ad_account: selectedAdAccount,
          type: 'comment'
        },
        {
          headers: {
            'ngrok-skip-browser-warning': 'any',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`
          }
        }
      )
      .then(response => {
        console.log(response)
        setTableData(
          tableData.filter(row => !comment_ids.includes(row.comment_id))
        )
        // setShakeCommentIDs(shakeCommentIDs.filter(id => id !== comment_id))
        setDeleteCommentStatusMessage('Successfully deleted the comment!')
        setShowDeleteCommentStatusAlert(true)
      })
      .catch(error => {
        console.log(error)
        setErrorMessage(JSON.parse(error.request.response).message)
        // setShakeCommentIDs(shakeCommentIDs.filter(id => id !== comment_id))
        setDeleteCommentStatusMessage('Failed to delete the comment!')
        setShowDeleteCommentStatusAlert(true)
      })
  }

  function DeleteComment (comment_id) {
    setShakeCommentIDs([...shakeCommentIDs, comment_id])

    axios
      .get(APIEndpointDomain + '/comment-management/delete', {
        params: {
          comment_id: comment_id,
          ad_account: selectedAdAccount,
          type: 'comment'
        },
        headers: {
          'ngrok-skip-browser-warning': 'any',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        console.log(response)
        setTableData(tableData.filter(row => row.comment_id !== comment_id))
        setShakeCommentIDs(shakeCommentIDs.filter(id => id !== comment_id))
        setDeleteCommentStatusMessage('Successfully deleted the comment!')
        setShowDeleteCommentStatusAlert(true)
      })
      .catch(error => {
        console.log(error)
        setErrorMessage(JSON.parse(error.request.response).message)
        setShakeCommentIDs(shakeCommentIDs.filter(id => id !== comment_id))
        setDeleteCommentStatusMessage('Failed to delete the comment!')
        setShowDeleteCommentStatusAlert(true)
      })
  }

  function DeleteReply (comment_id) {
    setShakeReplyIDs([...shakeReplyIDs, comment_id])

    axios
      .get(APIEndpointDomain + '/comment-management/delete', {
        params: {
          comment_id: comment_id,
          ad_account: selectedAdAccount,
          type: 'reply'
        },
        headers: {
          'ngrok-skip-browser-warning': 'any',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        console.log(response)
        setTableData(response.data['comments'])
        setShakeReplyIDs(shakeReplyIDs.filter(id => id !== comment_id))
      })
      .catch(error => {
        console.log(error)
        setShowErrorToast(true)
        setErrorMessage(JSON.parse(error.request.response).message)
        setShakeReplyIDs(shakeReplyIDs.filter(id => id !== comment_id))
      })
  }

  return (
    <div className='pt-3 mt-5 bg-opacity-0 mb-3 '>
      <APIRequestStatusModal
        show={showDeleteCommentStatusAlert}
        setShow={setShowDeleteCommentStatusAlert}
        message={deleteCommentStatusMessage}
      />

      <APIRequestStatusModal
        show={showReplyStatusAlert}
        setShow={setShowReplyStatusAlert}
        message={replyStatusMessage}
      />

      {tableData.length > 0 ? (
        <div className='ml-2 mr-2 grid grid-cols-3 justify-center '>
          {tableData.map(comment => (
            <div className='flex justify-center'>
              <Card
                className={
                  comment.is_negative
                    ? 'w-[calc(99%)] bg-amber-800 text-stone-200'
                    : 'w-[calc(99%)] bg-slate-600 text-stone-200'
                }
              >
                <CardHeader>
                  <CardTitle>
                    <input
                      onClick={e => selectComment(comment.comment_id, e)}
                      type='checkbox'
                      checked={selectedCommentIDs.includes(comment.comment_id)}
                      style={{ width: '20px', height: '20px' }}
                    ></input>
                    <br></br>@{comment.username}
                  </CardTitle>
                  <CardDescription>{comment.timestamp}</CardDescription>
                  <CardDescription>{comment.comment}</CardDescription>
                  <div className='mt-3'>
                    <ShadCNButton
                      size='sm'
                      className='text-stone-800'
                      onClick={() => window.open(comment.media_url, '_blank')}
                      variant='outline'
                    >
                      Visit
                    </ShadCNButton>
                    <ShadCNButton
                      size='sm'
                      className={
                        shakeCommentIDs.includes(comment.comment_id)
                          ? 'bg-amber-800 text-stone-200'
                          : 'bg-stone-800 text-stone-200'
                      }
                      onClick={() => DeleteComment(comment.comment_id)}
                    >
                      {shakeCommentIDs.includes(comment.comment_id)
                        ? 'Deleting...'
                        : 'Delete'}
                    </ShadCNButton>
                  </div>

                  {comment.related_replies.map(reply => (
                    <div className='mt-4'>
                      <div className='flex justify-between'>
                        <CardTitle>@{reply.username}</CardTitle>
                        <CardDescription>{reply.reply}</CardDescription>
                        <div className=''>
                          <ShadCNButton
                            size='sm'
                            className='text-stone-800'
                            onClick={() =>
                              window.open(comment.media_url, '_blank')
                            }
                            variant='outline'
                          >
                            Visit
                          </ShadCNButton>
                          <ShadCNButton
                            size='sm'
                            className={
                              shakeReplyIDs.includes(reply.reply_id)
                                ? 'bg-amber-800 text-stone-200'
                                : 'bg-stone-800 text-stone-200'
                            }
                            onClick={() => DeleteReply(reply.reply_id)}
                          >
                            {shakeReplyIDs.includes(reply.reply_id)
                              ? 'Deleting...'
                              : 'Delete'}
                          </ShadCNButton>
                        </div>
                      </div>
                    </div>
                  ))}
                </CardHeader>
              </Card>
            </div>
          ))}
        </div>
      ) : APICalling ? (
        <MultiStepLoader
          className='bg-opacity-0'
          loadingStates={loadingStates}
          loading={APICalling}
          duration={2000}
        ></MultiStepLoader>
      ) : APIError ? (
        <div className='flex justify-center items-center text-center h-full'>
          <TypewriterEffect
            className='text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-400 to-neutral-500 py-8'
            words={'Server returned no data!'.split(' ').map(word => {
              return { text: word }
            })}
          ></TypewriterEffect>
        </div>
      ) : (
        <div className='flex justify-center items-center text-center h-full'>
          <TypewriterEffect
            className='text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-400 to-neutral-500 py-8'
            words={'Select an account!'.split(' ').map(word => {
              return { text: word }
            })}
          ></TypewriterEffect>
        </div>
      )}
    </div>
  )
}

export default CommentManagementTable
