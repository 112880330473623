import Modal from 'react-bootstrap/Modal'
import { cn } from '../../utils/cn'
import { Navbar } from 'react-bootstrap'
import LoadingMessage from '../CommonSubComponents/LoadingMessage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBarsProgress } from '@fortawesome/free-solid-svg-icons'
import { TypewriterEffect } from '../ui/gpt-effect'
import { TextGenerateEffect } from '../ui/text-generate-effect'
import '../../css/TypingAnimation.css'
import { useState, useEffect } from 'react'

function AIRecommendationModal ({
  showModal,
  setShowModal,
  data,
  setData,
  focusKeyword,
  videoTitle,
  cost,
  setCost
}) {
  const [visibleText, setVisibleText] = useState(Array(data.length).fill(0))

  useEffect(() => {
    setVisibleText(Array(data.length).fill(0))
  }, [data])

  useEffect(() => {
    let currentIndex = 0
    const interval = setInterval(() => {
      if (currentIndex === data.length) {
        clearInterval(interval)
        return
      }

      setVisibleText(prev => {
        const updatedText = [...prev]
        updatedText[currentIndex] = 1 // Set opacity to 1 for the current character
        currentIndex += 1
        return updatedText
      })
    }, 10) // Change the interval as needed

    return () => clearInterval(interval)
  }, [data])
  return (
    <div>
      <Modal size={data == '' ? 'sm' : 'xl'} show={showModal}>
        <Modal.Header
          closeButton
          onClick={() => {
            setShowModal(false)
            setData('')
            setCost(0)
          }}
          className='bg-slate-200'
        >
          <Modal.Title>
            <h4 className='text-zinc-600 font-bold tracking-wide '>
              AI Suggestions
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='bg-slate-100'>
          {cost && cost > 0 ? (
            <div
              className='font-sans font-bold'
              style={{ marginBottom: '2px' }}
            >
              Recommendation cost: ₹{Math.round(cost * 1000) / 1000}
            </div>
          ) : null}
          {data ? (
            <Card>
              <div
                style={{ whiteSpace: 'pre-line' }}
                className='text-base text-xl font-mono text-center text-white tracking-wide leading-relaxed text-sm'
              >
                {visibleText.map((opacity, index) => (
                  <span key={index} style={{ opacity }}>
                    {data[index]}
                  </span>
                ))}
              </div>
            </Card>
          ) : (
            <FontAwesomeIcon
              style={{ width: '5rem', height: '5rem' }}
              icon={faBarsProgress}
              className='fa-flip'
            />
          )}
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default AIRecommendationModal

export const Card = ({ className, children }) => {
  return (
    <div
      className={cn(
        'rounded-2xl  w-full overflow-hidden bg-slate-600 border border-transparent dark:border-white/[0.2] group-hover:border-slate-700 ',
        className
      )}
    >
      <div className='relative z-50'>
        <div className='p-4'>{children}</div>
      </div>
    </div>
  )
}
export const CardTitle = ({ className, children }) => {
  return (
    <h4 className={cn('text-zinc-100 font-bold tracking-wide ', className)}>
      {children}
    </h4>
  )
}
export const CardDescription = ({ className, children }) => {
  return (
    <p
      className={cn(
        'text-zinc-400 tracking-wide leading-relaxed text-sm',
        className
      )}
    >
      {children}
    </p>
  )
}
