import React, { useEffect } from 'react'
import '../css/404Screen.css'

function FourOhFour () {
  useEffect(() => {
    const handleMouseMove = event => {
      const torch = document.querySelector('.torch')
      if (torch) {
        torch.style.top = `${event.pageY}px`
        torch.style.left = `${event.pageX}px`
      }
    }

    document.addEventListener('mousemove', handleMouseMove)

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
    }
  }, [])

  return (
    <div>
      <style>{`html {
    height: 100%;
  }
  
  body {
    height: 100%;
    background: url("https://wallpapercave.com/wp/6SLzBEY.jpg") no-repeat left top;
    background-size: cover;
    overflow: hidden;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
  }
  
  .text h1 {
    color: #011718;
    margin-top: -200px;
    font-size: 15em;
    text-align: center;
    text-shadow: -5px 5px 0px rgba(0, 0, 0, 0.7), -10px 10px 0px rgba(0, 0, 0, 0.4), -15px 15px 0px rgba(0, 0, 0, 0.2);
    font-family: monospace;
    font-weight: bold;
  }
  
  .text h2 {
    color: black;
    font-size: 5em;
    text-shadow: -5px 5px 0px rgba(0, 0, 0, 0.7);
    text-align: center;
    margin-top: -150px;
    font-family: monospace;
    font-weight: bold;
  }
  
  .text h3 {
    color: white;
    margin-left: 30px;
    font-size: 2em;
    text-shadow: -5px 5px 0px rgba(0, 0, 0, 0.7);
    margin-top: -40px;
    font-family: monospace;
    font-weight: bold;
  }
  
  .torch {
    margin: -150px 0 0 -150px;
    width: 200px;
    height: 200px;
    box-shadow: 0 0 0 9999em #000000f7;
    opacity: 1;
    border-radius: 50%;
    position: fixed;
    background: rgba(0, 0, 0, 0.3);
  }
  .torch:after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    box-shadow: inset 0 0 40px 2px #000, 0 0 20px 4px rgba(13, 13, 10, 0.2);
  }`}</style>
      <div className='text'>
        <h1>404</h1>
        <h2>Uh, Ohh</h2>
        <h3>
          Sorry we can't find what you are looking for 'cuz it's so dark in here
        </h3>
      </div>
      <div className='torch'></div>
    </div>
  )
}

export default FourOhFour
