import { Button } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'

// function LoginForm({
//   triggerLoginRequest,
//   setEmail,
//   setPassword,
//   setTriggerLoginRequest,
//   email,
//   password,
//   tryLogin,
// }) {
//   return (
//     <Form
//       className="login-form-content"
//       onSubmit={(e) => {
//         e.preventDefault();
//         setTriggerLoginRequest(triggerLoginRequest++);
//       }}
//     >
//       <div
//         className="login-title-container"
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           marginBottom: "20px",
//         }}
//       >
//         <h4 style={{ color: "white" }}>
//           <strong>
//             {"Sign-in to continue "}
//             {<FontAwesomeIcon icon={faUser} />}{" "}
//           </strong>
//         </h4>
//       </div>
//       <Form.Group className="mb-3" controlId="formBasicEmail">
//         <Form.Control
//           type="text"
//           placeholder="Username"
//           value={email}
//           onChange={(e) => setEmail(e.target.value)}
//         />
//       </Form.Group>

//       <Form.Group className="mb-3" controlId="formBasicPassword">
//         <Form.Control
//           type="password"
//           placeholder="Password"
//           value={password}
//           onChange={(e) => setPassword(e.target.value)}
//         />
//       </Form.Group>
//       <div
//         className="login-button-container"
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//         }}
//       >
//         <Button
//           onClick={() => tryLogin(email, password)}
//           variant="dark"
//           size="lg"
//           type="submit"
//           className="mt-3"
//         >
//           Login
//         </Button>
//       </div>
//     </Form>
//   );
// }

// export default LoginForm;

import React from 'react'
import { Label } from '../components/ui/label'
import { Input } from '../components/ui/input'
import { cn } from '../utils/cn'

export default function LoginForm ({
  triggerLoginRequest,
  setEmail,
  setPassword,
  setTriggerLoginRequest,
  email,
  password,
  tryLogin,
  showInvalidPassword
}) {
  return (
    <div className='max-w-md w-full mx-auto rounded-none md:rounded-2xl p-4 md:p-8 shadow-input bg-white dark:bg-black'>
      <h2 className='font-bold text-xl text-neutral-800 dark:text-neutral-200'>
        One stop shop for your marketing needs!
      </h2>
      <p className='text-neutral-600 text-sm max-w-sm mt-2 dark:text-neutral-300'></p>

      <form
        className='my-8'
        onSubmit={e => {
          e.preventDefault()
          setTriggerLoginRequest(triggerLoginRequest++)
        }}
      >
        <div className='flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2 mb-4'>
          <LabelInputContainer className='mb-4'>
            <Label className='font-bold' htmlFor='username'>
              Username
            </Label>
            <Input
              id='username'
              type='username'
              placeholder='binod'
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </LabelInputContainer>
          <LabelInputContainer className='mb-4'>
            <Label className='font-bold' htmlFor='password'>
              Password
            </Label>
            <Input
              type='password'
              value={password}
              onChange={e => setPassword(e.target.value)}
              placeholder='••••••••'
            />
          </LabelInputContainer>
        </div>
        {showInvalidPassword ? (
          <p className='text-red-600 text-sm max-w-sm mt-2 dark:text-neutral-300'>
            Unauthorized login attempt!
          </p>
        ) : null}
        <button
          className='bg-gradient-to-br relative group/btn from-black dark:from-zinc-900 dark:to-zinc-900 to-neutral-600 block dark:bg-zinc-800 w-full text-white rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset] dark:shadow-[0px_1px_0px_0px_var(--zinc-800)_inset,0px_-1px_0px_0px_var(--zinc-800)_inset]'
          type='submit'
          onClick={() => tryLogin(email, password)}
        >
          Sign in &rarr;
          <BottomGradient />
        </button>

        {/* <div className='bg-gradient-to-r from-transparent via-neutral-300 dark:via-neutral-700 to-transparent my-8 h-[1px] w-full' /> */}

        {/* <div className='flex flex-col space-y-4'>
          <button
            className=' relative group/btn flex space-x-2 items-center justify-start px-4 w-full text-black rounded-md h-10 font-medium shadow-input bg-gray-50 dark:bg-zinc-900 dark:shadow-[0px_0px_1px_1px_var(--neutral-800)]'
            type='submit'
          >
            <IconBrandGithub className='h-4 w-4 text-neutral-800 dark:text-neutral-300' />
            <span className='text-neutral-700 dark:text-neutral-300 text-sm'>
              GitHub
            </span>
            <BottomGradient />
          </button>
          <button
            className=' relative group/btn flex space-x-2 items-center justify-start px-4 w-full text-black rounded-md h-10 font-medium shadow-input bg-gray-50 dark:bg-zinc-900 dark:shadow-[0px_0px_1px_1px_var(--neutral-800)]'
            type='submit'
          >
            <IconBrandGoogle className='h-4 w-4 text-neutral-800 dark:text-neutral-300' />
            <span className='text-neutral-700 dark:text-neutral-300 text-sm'>
              Google
            </span>
            <BottomGradient />
          </button>
          <button
            className=' relative group/btn flex space-x-2 items-center justify-start px-4 w-full text-black rounded-md h-10 font-medium shadow-input bg-gray-50 dark:bg-zinc-900 dark:shadow-[0px_0px_1px_1px_var(--neutral-800)]'
            type='submit'
          >
            <IconBrandOnlyfans className='h-4 w-4 text-neutral-800 dark:text-neutral-300' />
            <span className='text-neutral-700 dark:text-neutral-300 text-sm'>
              OnlyFans
            </span>
            <BottomGradient />
          </button>
        </div> */}
      </form>
    </div>
  )
}

const BottomGradient = () => {
  return (
    <>
      <span className='group-hover/btn:opacity-100 block transition duration-500 opacity-0 absolute h-px w-full -bottom-px inset-x-0 bg-gradient-to-r from-transparent via-cyan-500 to-transparent' />
      <span className='group-hover/btn:opacity-100 blur-sm block transition duration-500 opacity-0 absolute h-px w-1/2 mx-auto -bottom-px inset-x-10 bg-gradient-to-r from-transparent via-indigo-500 to-transparent' />
    </>
  )
}

const LabelInputContainer = ({ children, className }) => {
  return (
    <div className={cn('flex flex-col space-y-2 w-full', className)}>
      {children}
    </div>
  )
}
