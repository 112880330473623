import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { Button, Badge } from 'react-bootstrap'
import axios from 'axios'
import { useState } from 'react'
import APIRequestStatusModal from '../CommonSubComponents/APIRequestStatusMessageModal'
import { ListGroup } from 'react-bootstrap'

function SaveNewClusterViewModal ({
  show,
  showSetter,
  APIEndpointDomain,
  clusterNames,
  setViewInformation,
  setAvailableViews
}) {
  var [selectedClusters, setSelectedClusters] = useState([])
  var [viewName, setViewName] = useState('')
  var [tagSearch, setTagSearch] = useState('')
  var [showAPIStatusModal, setShowAPIStatusModal] = useState(false)
  var [showAPIStatusMessage, setShowAPIStatusMessage] = useState(false)
  var [isSavingView, setIsSavingView] = useState(false)

  function saveClusterView (APIEndpointDomain, name, clusters) {
    setIsSavingView(true)

    axios
      .post(
        APIEndpointDomain + '/performance/clusters/save-view',
        {
          name: name,
          clusters: clusters
        },
        {
          headers: {
            'ngrok-skip-browser-warning': 'any',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`
          }
        }
      )
      .then(response => {
        console.log(response)
        setIsSavingView(false)
        setViewInformation(response.data['data'])
        setAvailableViews(response.data['data'].map(view => view['name']))

        setShowAPIStatusMessage('Successfully created the view!')
        showSetter(false)
        setShowAPIStatusModal(true)
      })
      .catch(error => {
        setShowAPIStatusMessage('Failed to create view!')
        setIsSavingView(false)
        showSetter(false)
        console.log(error)
        setShowAPIStatusModal(true)
      })
  }

  console.log(selectedClusters)

  function AddClusterName (cluster) {
    if (selectedClusters.length == 0) {
      setSelectedClusters([cluster])
    } else {
      setSelectedClusters([...selectedClusters, cluster])
    }
  }

  function removeClusterName (clusterToRemove) {
    if (selectedClusters.length != 0) {
      setSelectedClusters(
        selectedClusters.filter(cluster => cluster != clusterToRemove)
      )
    }
  }

  return (
    <div>
      <APIRequestStatusModal
        show={showAPIStatusModal}
        setShow={setShowAPIStatusModal}
        message={showAPIStatusMessage}
      />
      <Modal size='lg' show={show}>
        <Modal.Header
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            backgroundColor: '#525252',
            color: 'white'
          }}
          closeButton
          onClick={() => {
            showSetter(false)
            setSelectedClusters([])
          }}
        >
          <Modal.Title id='contained-modal-title-vcenter'>
            Create a view
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>Name your view:</Form.Label>

          <Form.Control
            type='text'
            placeholder='example: MiM Hooks'
            onChange={e => setViewName(e.target.value)}
          />
          <br></br>

          <Form.Label>Choose clusters to add to your view:</Form.Label>
          <Form.Control
            onChange={e => setTagSearch(e.target.value)}
            type='text'
            placeholder='search tags containing...'
            value={tagSearch}
          />
          <ListGroup horizontal='sm' className='flex-wrap'>
            {clusterNames.map(cluster =>
              cluster.includes(tagSearch.toLowerCase()) ? (
                <div className='py-1'>
                  <div style={{ marginRight: '5px' }}>
                    {/* <ListGroup.Item> */}
                    <Badge
                      id='toggle-check'
                      type='checkbox'
                      bg={
                        selectedClusters.includes(cluster.toLowerCase())
                          ? 'success'
                          : 'light'
                      }
                      value={cluster}
                      onClick={() =>
                        selectedClusters.includes(cluster)
                          ? removeClusterName(cluster)
                          : AddClusterName(cluster)
                      }
                    >
                      {cluster}
                    </Badge>
                  </div>
                </div>
              ) : null
            )}
          </ListGroup>
        </Modal.Body>
        <Button
          onClick={() =>
            saveClusterView(APIEndpointDomain, viewName, selectedClusters)
          }
          style={{ backgroundColor: '#186900' }}
          className='alarm-badge'
          size='xl'
        >
          {!isSavingView ? 'Save View' : 'Saving view... please wait!'}
        </Button>
      </Modal>
    </div>
  )
}

export default SaveNewClusterViewModal
