import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { Form } from 'react-bootstrap'
import { useState } from 'react'

function SaveEmailReportModal ({ showModal, setShowModal, saveReport }) {
  var [reportName, setReportName] = useState('')

  return (
    <Modal size='sm' show={showModal}>
      <Modal.Header
        className='bg-slate-500'
        closeButton
        onClick={() => setShowModal(false)}
      >
        <Modal.Title
          className='text-2xl text-slate-50 font-bold mx-auto'
          id='contained-modal-title-vcenter'
        >
          <h4 className='text-2xl text-slate-50 font-bold mx-auto'>
            Save current report
          </h4>{' '}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='bg-slate-100'>
        <Form.Control
          type='text'
          placeholder='Name'
          onChange={e => setReportName(e.target.value)}
        />
      </Modal.Body>
      <Modal.Body className='bg-slate-100'>
        <button
          onClick={() => saveReport(reportName)}
          className='"px-4 py-2 rounded-md bg-teal-500 text-white text-neutral-700 text-sm hover:shadow-[4px_4px_0px_0px_rgba(0,0,0)] transition duration-200'
        >
          Save Report
        </button>
      </Modal.Body>
    </Modal>
  )
}

export default SaveEmailReportModal
