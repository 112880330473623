import React from 'react'
import { useState, useEffect } from 'react'
import axios from 'axios'
import { TypewriterEffect } from '../components/ui/typewriter-effect'
import { BackgroundBeams } from '../components/ui/background-beams'
import { Boxes } from '../components/ui/background-boxes'
import { cn } from '../utils/cn'
import { MultiStepLoader } from '../components/ui/white-multi-step-loader'
import { Button, Form, Nav, Navbar } from 'react-bootstrap'
import { ShadCNButton } from '../components/ui/shadcn-button'
import Dropdown from 'react-bootstrap/Dropdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faScroll } from '@fortawesome/free-solid-svg-icons'
import ListGroup from 'react-bootstrap/ListGroup'

function YouTubeTitleResearchScreen ({
  checkAuthenticationInResponse,
  APIEndpointDomain
}) {
  var loadingStates = [
    { text: 'connecting to server' },

    { text: 'searching on youtube' },
    { text: 'fetching titles' }
  ]
  var [isFetching, setIsFetching] = useState(false)
  var [isError, setIsError] = useState(false)

  var [data, setData] = useState([])
  var [searchQuery, setSearchQuery] = useState('')
  var [youTubeData, setYouTubeData] = useState([])
  var [selectedChannel, setSelectedChannel] = useState('YouTube')
  var [videoCount, setVideoCount] = useState(15)
  var [copyButtonText, setCopyButtonText] = useState('Copy OverviewPrompt')

  function cleanText (input) {
    // Trim leading and trailing whitespaces
    let cleanedText = input.trim()

    // Replace multiple spaces with a single space
    cleanedText = cleanedText.replace(/\s+/g, ' ')

    // Replace line breaks with a space (or you can remove them if you prefer)
    // cleanedText = cleanedText.replace(/[\r\n]+/g, ' ')

    return cleanedText
  }

  function createOverviewPrompt (youTubeData, searchQuery) {
    var allTextualContentArray = youTubeData
    var allTexualContent =
      '[' + cleanText(allTextualContentArray.join(', ')) + ']'

    var overviewPrompt = `<Instructions>
    You will be acting as a YouTube title generator assistant. Your task is to help a YouTuber come up with engaging, clickbaity titles for their video that are also optimized for YouTube's search algorithm.
    
    First, here are a few existing YouTube video titles related to the YouTuber's topic:
    
    <video_titles>
    {$VIDEO_TITLES}
    </video_titles>
    
    And here is the specific topic the YouTuber wants to cover in their new video:
    
    <video_topic>
    {$VIDEO_TOPIC}
    </video_topic>
    
    Please begin by carefully analyzing the provided video titles and topic. Look for common themes, attention-grabbing phrases, and SEO keywords.
    
    Then, brainstorm some potential title ideas for the YouTuber's new video. Try to incorporate elements from the example titles while focusing on the given topic. Write out your brainstorming inside <brainstorming> tags.
    
    <brainstorming>
    </brainstorming>
    
    Once you have a good list of potential titles, refine them to be as catchy and clickbaity as possible while still being relevant to the video's content. The titles should make people curious and eager to click. Also make sure the titles include relevant keywords to help the video rank well in YouTube searches for the topic.
    
    After refining, please provide a numbered list of the 20 best title options you came up with inside <title_options> tags. Make sure each title is engaging, clickbaity, SEO-optimized, and no more than 15 words long.
    
    <title_options>
    1. 
    2.
    3.
    ...
    20.
    </title_options>
    </Instructions>`

    var copyAbleOverviewPrompt = overviewPrompt
      .replace('{$VIDEO_TOPIC}', searchQuery)
      .replace('{$VIDEO_TITLES}', allTexualContent)

    navigator.clipboard.writeText(copyAbleOverviewPrompt)

    setCopyButtonText('Copied Prompt!')
  }

  useEffect(() => {
    // Set the text after 3 seconds
    const timer = setTimeout(() => {
      setCopyButtonText('Copy Title Prompt')
    }, 3000)

    return () => clearTimeout(timer)
  }, [copyButtonText])

  function generateResearchContent (search_query, video_count) {
    setIsFetching(true)
    setIsError(false)

    axios
      .get(APIEndpointDomain + '/research-youtube-titles', {
        params: {
          search_query: search_query,
          youtube_video_count: video_count
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        console.log(response)
        setData(response.data['youtube'])
        setYouTubeData(response.data['youtube'])
        setIsFetching(false)
        setIsError(false)
      })
      .catch(error => {
        checkAuthenticationInResponse(Number(error.response.status))
        console.log(error)
        setIsFetching(false)
        setIsError(true)
      })
  }

  return (
    <div
      className={
        data.length > 0
          ? 'w-screen relative overflow-y overflow-x-hidden bg-slate-900 flex flex-col items-center justify-center'
          : 'h-screen w-screen relative overflow-hidden bg-slate-900 flex flex-col items-center justify-center'
      }
    >
      <div
        className={
          data.length > 0
            ? 'absolute overflow-y overflow-x-hidden inset-0 w-full h-full bg-slate-900  [mask-image:radial-gradient(transparent,white)] pointer-events-none'
            : 'absolute inset-0 w-full h-full bg-slate-900 z-20 [mask-image:radial-gradient(transparent,white)] pointer-events-none'
        }
      />

      <Boxes />
      <div className='w-screen bg-opacity-0 bg-slate-900'>
        {!isFetching && !isError ? (
          <>
            <Navbar
              fixed='top'
              style={{
                borderWidth: 0
              }}
            >
              <div className='w-screen '>
                {/* <div className='py-0 '> */}
                <div
                  className='ml-2 content-left'
                  style={{
                    display: 'flex',
                    justifyContent: 'left'
                  }}
                >
                  <div className='bg-slate-200 flex items-center justify-center  overflow ml-3 text-black  rounded-lg'>
                    <p className='bg-slate-200 mx-3 my-1'>
                      {' '}
                      YouTube Video Count
                    </p>
                  </div>
                  <input
                    type='number'
                    // style={{ maxWidth: '30px' }}
                    className='w-14 rounded-lg text-center bg-slate-100'
                    value={videoCount}
                    onChange={e => setVideoCount(e.target.value)}
                  />
                  {data.length > 0 && (
                    <>
                      <ShadCNButton
                        className={cn(
                          'ml-3 bg-slate-600  rounded-lg border-slate-900 text-white font-bold'
                        )}
                        onClick={() =>
                          createOverviewPrompt(youTubeData, searchQuery)
                        }
                      >
                        {copyButtonText}
                      </ShadCNButton>
                      <ShadCNButton
                        className={cn(
                          'ml-3 bg-slate-600  rounded-lg border-slate-900 text-white font-bold'
                        )}
                        onClick={() => window.location.reload()}
                      >
                        {'Retry'}
                      </ShadCNButton>
                    </>
                  )}
                </div>
              </div>
            </Navbar>
            <div
              className={
                !data || data == ''
                  ? 'py-5 h-full'
                  : 'py-5 h-screen mt-5 bg-opacity-100'
              }
            >
              {data && data != '' && selectedChannel == 'YouTube' ? (
                <div className='bg-opacity-100'>
                  <TypewriterEffect
                    className='mx-auto relative z-20  text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-400 to-neutral-500 py-4'
                    words={'Here are all the titles on YouTube!'
                      .split(' ')
                      .map(word => {
                        return { text: word }
                      })}
                  ></TypewriterEffect>
                  <ListGroup className='bg-opacity-100'>
                    {youTubeData.map((content, index) => {
                      return (
                        <>
                          <ListGroup.Item className='text-center bg-opacity-0'>
                            <strong>
                              Video {index + 1} {' Title -> '}
                            </strong>{' '}
                            {cleanText(content)}
                          </ListGroup.Item>
                          {/* <h4 className='relative mb-3 mt-3 z-20 text-white mx-auto flex justify-center items-center text-center'>
                        Video {index + 1} {' Title'}
                      </h4>
                      <div className='mx-auto relative z-20 w-11/12  rounded p-2 text-gray-600 bg-sky-50 font-mono'>
                        <textarea
                          className='w-full h-full resize-none overflow-auto border-none outline-none focus:outline-none text-gray-600 bg-sky-50 font-mono'
                          value={cleanText(content)}
                        />
                      </div> */}
                        </>
                      )
                    })}
                  </ListGroup>
                </div>
              ) : data == '' || !data ? (
                <div className='py-5 h-full'>
                  <TypewriterEffect
                    className='mx-auto relative z-20  text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-400 to-neutral-500 py-8'
                    words={'Get YouTube Video Titles in one go!'
                      .split(' ')
                      .map(word => {
                        return { text: word }
                      })}
                  ></TypewriterEffect>

                  <div className=' flex justify-center items-center text-center'>
                    <input
                      onChange={e => setSearchQuery(e.target.value)}
                      type='text'
                      placeholder={
                        data && data != ''
                          ? 'Reload page to analyze again...'
                          : 'Enter your research topic here...'
                      }
                      className={cn(
                        'relative z-20 font-mono w-1/3 border-black text-center text-xl p-3 text-gray-600 border rounded-lg bg-sky-50 text-base'
                      )}
                    />
                  </div>
                </div>
              ) : null}
            </div>
            {data.length == 0 && (
              <ShadCNButton
                className={cn(
                  ' flex justify-center items-center mx-auto relative z-20 bg-slate-600  rounded-lg border-slate-900 text-white font-bold'
                )}
                onClick={() => generateResearchContent(searchQuery, videoCount)}
              >
                {'GO'}
              </ShadCNButton>
            )}
          </>
        ) : isFetching ? (
          <MultiStepLoader
            className='bg-opacity-0'
            loadingStates={loadingStates}
            loading={isFetching}
            duration={1500}
          ></MultiStepLoader>
        ) : isError ? (
          <div className='flex justify-center items-center text-center h-full'>
            <TypewriterEffect
              className='text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-400 to-neutral-500 py-8'
              words={'Server failed to return data!'.split(' ').map(word => {
                return { text: word }
              })}
            ></TypewriterEffect>
          </div>
        ) : (
          <div className='flex justify-center items-center text-center h-full'>
            <TypewriterEffect
              className='text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-400 to-neutral-500 py-8'
              words={'Unrecognized error occured!'.split(' ').map(word => {
                return { text: word }
              })}
            ></TypewriterEffect>
          </div>
        )}
      </div>
    </div>
  )
}

export default YouTubeTitleResearchScreen
