import { Table } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFaceSmile } from '@fortawesome/free-solid-svg-icons'

import { useEffect, useState, useMemo } from 'react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import { TypewriterEffect } from '../ui/typewriter-effect'

function ClustersTable ({
  ClustersTableData,
  selectedLeadType,
  selectedColumnFilters,
  exportCSVData,
  setExportCSVData
}) {
  var percentageMetrics = [
    'acquisition_loss_rate',
    'acquisition_validation_rate',
    'acquisitions_pending_validation_p',
    'good_percentage',
    'pending_p',
    'pressure_to_acquisition',
    'projected_roi',
    'quality',
    'roi',
    'uhl_to_acquisition',
    'roi_during',
    'h_good_percentage',
    'lead_to_h_good_percentage',
    'ctr',
    'hook_rate',
    'hold_rate',
    'view_to_click',
    'click_to_lead',
    'fifteen_to_25p',
    'twenty_five_p_to_50p',
    'fifty_p_to_75p'
  ]

  var currencyMetrics = [
    'cpaa',
    'premium_ms_revenue',
    'avg_purchase',
    'budget',
    'cpa',
    'cpgl',
    'cpl',
    'cpql',
    'projected_cpa',
    'revenue',
    'total_revenue',
    'upfront_payments',
    'cpaa_during',
    'premium_ms_revenue_during',
    'admit_up_rev_dur',
    'cpa_during',
    'revenue_during',
    'total_revenue_during',
    'upfront_payments_during',
    'cpgl_historic',
    'h_cpa',
    'spend',
    'cpm',
    'cost_per_thruplay',
    'cpc',
    '5_day_cpl',
    '5_day_cpql',
    '7_day_cpgl_h',
    '12_day_cpuhl_h',
    '22_day_cpa',
    'adset_budget'
  ]

  function isNumber (n) {
    return Number(n) === n
  }

  useEffect(() => {
    var calculatedData = calculateNewColumns(ClustersTableData)
    setData(calculatedData)
    setExportCSVData(calculatedData)
  }, [ClustersTableData])

  function calculateNewColumns (Data) {
    Data.map(data => {
      var cpm =
        data['impressions'] !== 0
          ? (1000 * data['spend']) / data['impressions']
          : 0

      var cpv = data['thruplays'] !== 0 ? data['spend'] / data['thruplays'] : 0

      var cpc =
        data['link_clicks'] !== 0 ? data['spend'] / data['link_clicks'] : 0

      var hook_rate =
        data['impressions'] !== 0
          ? (100 * data['three_sec_plays']) / data['impressions']
          : 0

      var hold_rate_thruplay =
        data['three_sec_plays'] !== 0
          ? (100 * data['thruplays']) / data['three_sec_plays']
          : 0

      var hold_rate_50_p =
        data['three_sec_plays'] !== 0
          ? (100 * data['fifty_p_plays']) / data['three_sec_plays']
          : 0

      var hold_rate_75_p =
        data['fifty_p_plays'] !== 0
          ? (100 * data['seventy_five_p_plays']) / data['fifty_p_plays']
          : 0

      var view_to_click =
        data['thruplays'] !== 0
          ? (100 * data['link_clicks']) / data['thruplays']
          : 0

      var quality =
        data['leads'] !== 0
          ? (100 * data['qualified']) / (data['leads'] - data['pending'])
          : 0

      var cpl = data['leads'] !== 0 ? data['spend'] / data['leads'] : 0

      var cpql = data['qualified'] !== 0 ? data['spend'] / data['qualified'] : 0

      var cpgl =
        data['good_leads'] !== 0 ? data['spend'] / data['good_leads'] : 0

      var cpa =
        data['acquisitions'] !== 0 ? data['spend'] / data['acquisitions'] : 0

      var h_cpa =
        data['h_acquisitions'] !== 0
          ? data['spend'] / data['h_acquisitions']
          : 0
      var cpaa =
        data['actual_acquisitions'] !== 0
          ? data['spend'] / data['actual_acquisitions']
          : 0

      var acquisitions_lost = data['h_acquisitions'] - data['acquisitions']
      var acquisition_loss_rate =
        data['h_acquisitions'] > 0
          ? (100 * (data['h_acquisitions'] - data['acquisitions'])) /
            data['h_acquisitions']
          : 0

      var acquisition_validation_rate =
        data['actual_acquisitions'] +
          (data['h_acquisitions'] - data['acquisitions']) >
        0
          ? (100 * data['actual_acquisitions']) /
            (data['actual_acquisitions'] +
              (data['h_acquisitions'] - data['acquisitions']))
          : 0

      var acquisitions_pending_validation =
        data['h_acquisitions'] -
        (data['actual_acquisitions'] +
          (data['h_acquisitions'] - data['acquisitions']))

      var acquisitions_pending_validation_p =
        data['h_acquisitions'] > 0
          ? (100 *
              (data['h_acquisitions'] -
                (data['actual_acquisitions'] +
                  (data['h_acquisitions'] - data['acquisitions'])))) /
            data['h_acquisitions']
          : 0

      var cpaa_during =
        data['actually_acquired_during'] !== 0
          ? data['spend'] / data['actually_acquired_during']
          : 0

      var ttl_revenue = data['revenue'] + data['admit_up_rev']

      var roi =
        data['spend'] !== 0
          ? (data['revenue'] + data['admit_up_rev']) / data['spend']
          : 0

      var roi_during =
        data['spend'] !== 0 ? data['revenue_during'] / data['spend'] : 0

      var pending_p =
        data['leads'] !== 0 ? (100 * data['pending']) / data['leads'] : 0

      var cpgl_historic =
        data['h_good'] !== 0 ? data['spend'] / data['h_good'] : 0

      var cpa_during =
        data['acquired_during'] !== 0
          ? data['spend'] / data['acquired_during']
          : 0

      var ttl_revenue_du = data['admit_up_rev_dur'] + data['revenue_during']

      var good_percentage =
        data['qualified'] !== 0
          ? (100 * data['good_leads']) / data['qualified']
          : 0

      var h_good_percentage =
        data['qualified'] !== 0 ? (100 * data['h_good']) / data['qualified'] : 0

      var lead_to_h_good_percentage =
        data['leads'] !== 0 ? (100 * data['h_good']) / data['leads'] : 0

      var uhl_to_acquisition =
        data['h_ultra'] !== 0
          ? (100 * data['acquisitions']) / (data['h_ultra'] - data['ultra_hot'])
          : 0

      var pressure_to_acquisition =
        data['h_pressure'] !== 0
          ? (100 * data['acquisitions']) /
            (data['h_pressure'] - data['pressure_sell'])
          : 0

      var projected_acquisitions =
        data['acquisitions'] +
        (data['ultra_hot'] / 100) * uhl_to_acquisition +
        (data['pressure_sell'] / 100) * pressure_to_acquisition

      var projected_cpa =
        data['acquisitions'] +
          (data['ultra_hot'] / 100) * uhl_to_acquisition +
          (data['pressure_sell'] / 100) * pressure_to_acquisition !==
        0
          ? data['spend'] /
            (data['acquisitions'] +
              (data['ultra_hot'] / 100) * uhl_to_acquisition +
              (data['pressure_sell'] / 100) * pressure_to_acquisition)
          : 0

      var avg_purchase =
        data['acquisitions'] !== 0 ? data['revenue'] / data['acquisitions'] : 0

      var projected_roi =
        data['spend'] !== 0
          ? (100 *
              (data['revenue'] +
                data['admit_up_rev'] +
                (avg_purchase * data['ultra_hot'] * uhl_to_acquisition) / 100 +
                (avg_purchase *
                  data['pressure_sell'] *
                  pressure_to_acquisition) /
                  100)) /
            data['spend']
          : 0

      var avg_conversion_period =
        data['acquisitions'] !== 0
          ? data['conversion_period'] / data['acquisitions']
          : 0

      var avg_validation_period =
        data['actual_acquisitions'] !== 0
          ? data['validation_period'] / data['actual_acquisitions']
          : 0

      var avg_complete_cycle_period =
        data['actual_acquisitions'] !== 0
          ? data['creation_to_complete_validation_period'] /
            data['actual_acquisitions']
          : 0

      data['acquisitions_pending_validation'] = acquisitions_pending_validation
      data['acquisitions_pending_validation_p'] =
        acquisitions_pending_validation_p
      data['cpm'] = isNumber(cpm) ? Math.round(cpm) : cpm
      data['cpv'] = isNumber(cpv) ? Math.round(cpv) : cpv
      data['cpc'] = isNumber(cpc) ? Math.round(cpc) : cpc
      data['hook_rate'] = isNumber(hook_rate)
        ? Math.round(hook_rate)
        : hook_rate
      data['hold_rate_thruplay'] = isNumber(hold_rate_thruplay)
        ? Math.round(hold_rate_thruplay)
        : hold_rate_thruplay
      data['hold_rate_50_p'] = isNumber(hold_rate_50_p)
        ? Math.round(hold_rate_50_p)
        : hold_rate_50_p
      data['hold_rate_75_p'] = isNumber(hold_rate_75_p)
        ? Math.round(hold_rate_75_p)
        : hold_rate_75_p
      data['view_to_click'] = isNumber(view_to_click)
        ? Math.round(view_to_click)
        : view_to_click

      data['acquisitions_lost'] = acquisitions_lost
      data['acquisition_loss_rate'] = acquisition_loss_rate
      data['avg_complete_cycle_period'] = avg_complete_cycle_period

      data['quality'] = isNumber(quality) ? Math.round(quality) : 0
      data['cpl'] = isNumber(cpl) ? Math.round(cpl) : cpl
      data['cpql'] = isNumber(cpql) ? Math.round(cpql) : cpql
      data['cpgl'] = isNumber(cpgl) ? Math.round(cpgl) : cpgl
      data['cpa'] = isNumber(cpa) ? Math.round(cpa) : cpa
      data['h_cpa'] = isNumber(h_cpa) ? Math.round(h_cpa) : h_cpa
      data['cpaa'] = isNumber(cpaa) ? Math.round(cpaa) : cpaa
      data['cpaa_during'] = isNumber(cpaa_during)
        ? Math.round(cpaa_during)
        : cpaa_during

      data['lead_to_h_good_percentage'] = isNumber(lead_to_h_good_percentage)
        ? Math.round(lead_to_h_good_percentage)
        : lead_to_h_good_percentage

      data['total_revenue'] = ttl_revenue
      data['roi'] = isNumber(roi) ? Math.round(100 * roi) : roi
      data['roi_during'] = isNumber(roi_during)
        ? Math.round(100 * roi_during)
        : roi_during
      data['pending_p'] = isNumber(pending_p)
        ? Math.round(pending_p)
        : pending_p
      data['cpgl_historic'] = isNumber(cpgl_historic)
        ? Math.round(cpgl_historic)
        : cpgl_historic

      data['h_cpgl'] = isNumber(cpgl_historic)
        ? Math.round(cpgl_historic)
        : cpgl_historic
      data['cpa_during'] = isNumber(cpa_during)
        ? Math.round(cpa_during)
        : cpa_during
      data['total_revenue_during'] = ttl_revenue_du
      data['good_percentage'] = good_percentage
      data['h_good_percentage'] = h_good_percentage
      data['pressure_to_acquisition'] = pressure_to_acquisition
      data['uhl_to_acquisition'] = uhl_to_acquisition

      data['avg_validation_period'] = avg_validation_period

      data['projected_acquisitions'] = projected_acquisitions
      data['projected_cpa'] = projected_cpa
      data['projected_roi'] = projected_roi
      data['avg_purchase'] = avg_purchase
      data['avg_conversion_period'] = avg_conversion_period
      data['acquisition_validation_rate'] = acquisition_validation_rate
      data['upfront_payments'] =
        isNumber(data['revenue']) && selectedLeadType.toLowerCase() === 'cpa'
          ? data['revenue'] - 20000 * data['acquisitions']
          : isNumber(data['revenue']) &&
            selectedLeadType.toLowerCase() === 'premium'
          ? data['revenue']
          : 0

      data['upfront_payments_during'] =
        isNumber(data['revenue_during']) &&
        selectedLeadType.toLowerCase() === 'cpa'
          ? data['revenue_during'] - 20000 * data['acquired_during']
          : isNumber(data['revenue_during']) &&
            selectedLeadType.toLowerCase() === 'premium'
          ? data['revenue_during']
          : 0
    })

    return Data
  }

  var [Data, setData] = useState([])

  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), [])

  // set background colour on every row, this is probably bad, should be using CSS classes
  const rowStyle = { background: '#eaeaea' }

  // set background colour on even rows again, this looks bad, should be using CSS classes
  const getRowStyle = params => {
    if (params.node.rowIndex % 2 === 0) {
      return { background: '#f6f6f6' }
    }
  }

  function cellRenderer (params) {
    var key = params.colDef['field']
    var data = params.data[key]

    if (key === 'tag_name') {
      return (
        <p style={{ textTransform: 'capitalize' }}>
          {String(data).replace(/_/g, ' ')}
        </p>
      )
    } else if (currencyMetrics.includes(key)) {
      return <p>₹{Math.round(data).toLocaleString('en-IN') + '  '}</p>
    } else if (percentageMetrics.includes(key) && key !== 'ctr') {
      return (
        <p>
          {Math.round(data).toLocaleString('en-IN')}%{'  '}
        </p>
      )
    } else if (percentageMetrics.includes(key) && key == 'ctr') {
      return (
        <p>
          {data.toLocaleString('en-IN')}%{'  '}
        </p>
      )
    } else {
      return <p>{data}</p>
    }
  }

  var headers = selectedColumnFilters.filter(
    column =>
      column.toLowerCase() !== 'ad_id' &&
      column.toLowerCase() !== 'ad_name' &&
      column.toLowerCase() !== 'adset_id' &&
      column.toLowerCase() !== 'adset_name' &&
      column.toLowerCase() !== 'campaign_id' &&
      column.toLowerCase() !== 'campaign_name' &&
      column.toLowerCase() !== 'budget'
  )

  headers.unshift('tag_name')

  var stringColumns = ['tag_name']

  headers = headers.map(header_name => {
    return {
      field: header_name,
      filter: !stringColumns.includes(header_name)
        ? 'agNumberColumnFilter'
        : 'agTextColumnFilter',
      floatingFilter: true,
      cellRenderer: cellRenderer,
      pinned: header_name == 'tag_name' ? 'left' : null
    }
  })

  const defaultColDef = useMemo(() => {
    return {
      width: 170,
      filter: true,
      cellStyle: { fontSize: '14px', textAlign: 'center' },
      enableValue: true,
      // allow every column to be grouped
      enableRowGroup: true,
      // allow every column to be pivoted
      enablePivot: true
    }
  }, [])

  return headers.length > 0 && headers && Data.length > 0 && Data ? (
    // <div className='outer-primary-table-body-container'>
    //   <div className='performance-primary-table-body-container'>
    <div style={gridStyle} className={'ag-theme-quartz'}>
      <AgGridReact
        rowData={Data}
        columnDefs={headers}
        defaultColDef={defaultColDef}
        rowDragManaged={true}
        rowSelection='multiple'
        rowDragMultiRow={true}
        rowStyle={rowStyle}
        getRowStyle={getRowStyle}
      />
    </div>
  ) : (
    <div className='flex justify-center items-center text-center h-full'>
      <TypewriterEffect
        className='text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-400 to-neutral-500 py-8'
        words={'Select a few clusters and hit go!'.split(' ').map(word => {
          return { text: word }
        })}
      ></TypewriterEffect>
      {/* <h3 className='text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-200 to-neutral-500 py-8'>
        {'Select a few clusters and hit go!'}
      </h3> */}
    </div>
  )
  //  : null} );
}

export default ClustersTable
