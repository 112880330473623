import { useParams } from 'react-router-dom'
import axios from 'axios'
import { useEffect, useState, useMemo } from 'react'
import '../css/PerformanceCustomReportScreen.css'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css' // Core CSS
import 'ag-grid-community/styles/ag-theme-quartz.css' // Theme
import Badge from 'react-bootstrap/Badge'

function YouTubeAIGenScriptScreen ({
  APIEndpointDomain,
  checkAuthenticationInResponse
}) {
  const { id } = useParams()
  var [script, setScript] = useState('')

  useEffect(() => {
    axios
      .get(APIEndpointDomain + '/youtube/get-generated-script', {
        params: {
          id: id
        },
        headers: {
          'ngrok-skip-browser-warning': 'any',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        setScript(response.data['data'])
        console.log(response)
      })
      .catch(error => {
        console.log(error)
        checkAuthenticationInResponse(Number(error.response.status))
      })
  }, [])

  return (
    <div className='bg-slate-200 '>
      {script.includes('<script>') ? (
        <>
          <div
            className='content-center'
            style={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <h4 className='mt-2 relative z-20 text-2xl text-slate-600 font-bold mx-auto'>
              Script
            </h4>
          </div>
          {script ? (
            <div className='bg-slate-700 border rounded-lg ml-36 mr-36 relative z-20 text-lg'>
              <p className='text-slate-300 mx-5 my-5 leading-loose'>
                {script.split('<script>')[1].split('</script>')[0]}
              </p>
            </div>
          ) : null}
        </>
      ) : null}

      {script.includes('<notes>') ? (
        <>
          <div
            className='content-center'
            style={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <h4 className='mt-2 relative z-20 text-2xl text-slate-600 font-bold mx-auto'>
              Notes
            </h4>
          </div>
          {script ? (
            <div className='bg-slate-700 border rounded-lg ml-36 mr-36 relative z-20 text-lg'>
              <p className='text-slate-300 mx-5 my-5 leading-loose'>
                {script.split('<notes>')[1].split('</notes>')[0]}
              </p>
            </div>
          ) : null}
        </>
      ) : null}
      {script.includes('<tone_analysis>') ? (
        <>
          <div
            className='content-center'
            style={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <h4 className='mt-2 relative z-20 text-2xl text-slate-600 font-bold mx-auto'>
              Tone Analysis
            </h4>
          </div>
          {script ? (
            <div className='bg-slate-700 border rounded-lg ml-36 mr-36 relative z-20 text-lg'>
              <p className='text-slate-300 mx-5 my-5 leading-loose'>
                {
                  script
                    .split('<tone_analysis>')[1]
                    .split('</tone_analysis>')[0]
                }
              </p>
            </div>
          ) : null}
        </>
      ) : null}
      {script.includes('<outline>') ? (
        <>
          <div
            className='content-center'
            style={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <h4 className='mt-2 relative z-20 text-2xl text-slate-600 font-bold mx-auto'>
              Outline
            </h4>
          </div>
          {script ? (
            <div className='bg-slate-700 border rounded-lg ml-36 mr-36 relative z-20 text-lg'>
              <p className='text-slate-300 mx-5 my-5 leading-loose'>
                {script.split('<outline>')[1].split('</outline>')[0]}
              </p>
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  )
}

export default YouTubeAIGenScriptScreen
