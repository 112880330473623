import PerformanceTable from '../components/PerformanceMetaIndirectTable'
import PerformanceHeader from '../components/PerformanceMetaIndirectHeader'
import { useState, useRef } from 'react'
import axios, { CancelToken } from 'axios'
import '../css/PerformanceScreen.css'
import { useEffect } from 'react'

import {
  Widget,
  addResponseMessage,
  toggleWidget,
  addUserMessage,
  deleteMessages
} from 'react-chat-widget'
import '../css/GlobalChatbox.css'
import 'react-chat-widget/lib/styles.css'

function PerformanceMetaIndirectScreen ({
  checkAuthenticationInResponse,
  APIEndpointDomain
}) {
  var [userImage, setUserImage] = useState(
    'https://i.pinimg.com/736x/b8/38/4d/b8384dc3955cd72a44224e3fd9089745.jpg'
  )
  var [chatboxTitle, setChatboxTitle] = useState('Ask')
  var [chatboxSubtitle, setChatboxSubtitle] = useState("Hint: type 'commands'")

  useEffect(() => {
    fetchAllMessages()
  })

  function handleNewUserMessage (message) {
    axios
      .get(APIEndpointDomain + '/marketingchatapp/process-user-message', {
        params: {
          command: message.toLowerCase()
        },

        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        fetchAllMessages()
        console.log(response)
      })
      .catch(error => {
        checkAuthenticationInResponse(Number(error.response.status))
        console.log(error)
        alert('Message failed to deliver!')
      })
  }

  function fetchAllMessages () {
    axios
      .get(APIEndpointDomain + '/marketingchatapp/generate-initial-view', {
        params: {},

        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })

      .then(response => {
        console.log(response)
        var chats = response.data['data']
        for (let i = 0; i < chats.length; i++) {
          if (chats[i]['sender'] == 'user' && chats[i]['message']) {
            console.log('user_found')
            addUserMessage(chats[i]['message'])
          } else if (chats[i]['message']) {
            console.log('not_user_found')
            addResponseMessage(chats[i]['message'])
          }
        }
      })
      .catch(error => {
        checkAuthenticationInResponse(Number(error.response.status))
        console.log(error)
        alert('Message failed to deliver!')
      })
  }
  var [showAvgComparisonIndicators, setShowAvgComparisonIndicators] =
    useState(true)
  var [aggregateAds, setAggregateAds] = useState(false)
  var [totalsData, setTotalsData] = useState([])
  // Dashboard Variables
  var [currentCPA, setCurrentCPA] = useState(null)
  var [currentCPL, setCurrentCPL] = useState(null)
  var [currentRevenue, setCurrentRevenue] = useState(null)
  var [currentSales, setCurrentSales] = useState(null)
  var [previousCPA, setPreviousCPA] = useState(null)
  var [previousCPL, setPreviousCPL] = useState(null)
  var [previousRevenue, setPreviousRevenue] = useState(null)
  var [previousSales, setPreviousSales] = useState(null)
  var [topPerformers, setTopPerformers] = useState([])
  var [recentSales, setRecentSales] = useState([])

  var [campaignExclusions, setCampaignExclusions] = useState([])
  var [adsetExclusions, setAdsetExclusions] = useState([])
  var [adExclusions, setAdExclusions] = useState([])
  var [dateExclusions, setDateExclusions] = useState([])

  var [exportCSVData, setExportCSVData] = useState([])

  var [currentCampaign, setCurrentCampaign] = useState(null)
  var [currentAdset, setCurrentAdset] = useState(null)
  var [currentAd, setCurrentAd] = useState(null)
  var [currentDate, setCurrentDate] = useState(null)
  var [DataIsLeadType, setDataIsLeadType] = useState('premium')

  var [AttributedRevenueDuring, setAttributedRevenueDuring] = useState(0)
  var [AttributedClientsDuring, setAttributedClientsDuring] = useState(0)

  var today = new Date()

  var end = new Date(new Date().setDate(today.getDate() - 1))
    .toJSON()
    .slice(0, 10)
  var start = new Date(new Date().setDate(today.getDate() - 4))
    .toJSON()
    .slice(0, 10)

  var [showAdTagModal, setShowAdTagModal] = useState(false)

  var [favoritesData, setFavoritesData] = useState([])
  var [favoriteCAIDs, setFavoriteCAIDs] = useState([])
  var [favoriteASIDs, setFavoriteASIDs] = useState([])
  var [favoriteAIDs, setFavoriteAIDs] = useState([])
  var [selectedCustomReport, setSelectedCustomReport] = useState('')

  var [allViews, setAllViews] = useState([{ name: '' }])
  var [selectedLevel, setSelectedLevel] = useState('Campaign')
  var [campaignSearch, setCampaignSearch] = useState('')
  var [adsetSearch, setAdsetSearch] = useState('')
  var [adSearch, setAdSearch] = useState('')
  var [tableData, setTableData] = useState([])
  var [Reminders, setReminders] = useState([])
  var [comparisonTableData, setComparisonTableData] = useState([])
  var [compareRangeRequirement, setCompareRangeRequirement] = useState(false)

  var [adsTagged, setAdsTagged] = useState([])

  var [averageStandards, setAverageStandards] = useState('')
  var [goTriggerPrimaryAPI, setGoTriggerPrimaryAPI] = useState(false)

  var [loadingP, setLoadingP] = useState(100100)
  var [isFirstLoad, setIsFirstLoad] = useState(true)
  var [startDate, setStartDate] = useState(start)
  var [endDate, setEndDate] = useState(end)
  var [selectedAdAccounts, setSelectedAdAccounts] = useState([])
  var [selectedLeadType, setSelectedLeadType] = useState('Premium + CPA')
  var [Data, setData] = useState(tableData)

  var [campaignBudgets, setCampaignBudgets] = useState([])
  var [adsetBudgets, setAdsetBudgets] = useState([])

  var [selectedRows, setSelectedRows] = useState([])
  var [statusFilters, setStatusFilters] = useState({
    active: true,
    paused: true
  })
  var [contentTypeFilters, setContentTypeFilters] = useState({
    reel: true,
    image: true
  })
  var [consolidationType, setConsolidationType] = useState('id')
  var [activeAds, setActiveAds] = useState([])
  var [activeAdsets, setActiveAdsets] = useState([])
  var [activeCampaigns, setActiveCampaigns] = useState([])
  var [selectedClusterNames, setSelectedClusterNames] = useState([])

  var [hideStatusFilter, setHideStatusFilter] = useState(false)
  var [tableDataAPICalling, setTableDataAPICalling] = useState(false)

  var [selectedColumnFilters, setSelectedColumnFilters] = useState([
    // "campaign_name",
    'budget',
    'spend',
    'revenue_during',
    'roi_during',
    'leads',
    'qualified',
    'quality',
    'h_good',
    'h_ultra',
    'lead_to_h_ultra_percentage',
    'acquired_during',
    'cpl',
    'cpql',
    'cpgl_historic',
    'cpuhl_h',
    'cpa_during',
    'avg_conversion_period',
    'pending',
    'pending_p',
    'days_active'
  ])

  var [PrimaryRangeProcessed, setPrimaryRangeProcessed] = useState(false)

  var [CompareRangeProcessed, setCompareRangeProcessed] = useState(false)

  var defaultAdvancedFilters = {
    minROI: 0,
    minRevenue: 0,
    minSpend: 0,
    minLeads: 0,
    minQualified: 0,
    minGood: 0,
    minClients: 0,
    minQuality: 0,
    minCPL: 0,
    minCPQL: 0,
    minCPGL: 0,
    minCPA: 0,
    minDaysActive: 0,

    minAcquisitionDuring: 0,
    minRevenueDuring: 0,
    maxROI: 1000000000,
    maxRevenue: 1000000000,
    maxSpend: 1000000000,
    maxLeads: 1000000000,
    maxQualified: 1000000000,
    maxGood: 1000000000,
    maxClients: 1000000000,
    maxQuality: 1000000000,
    maxCPL: 1000000000,
    maxCPQL: 1000000000,
    maxCPGL: 1000000000,
    maxCPA: 1000000000,
    maxRevenueDuring: 1000000000,
    maxAcquisitionDuring: 1000000000,
    maxDaysActive: 1000000000
  }
  var [customReportNames, setCustomReportNames] = useState([''])
  var [clusterNames, setClusterNames] = useState([])

  var [advancedFiltersSelections, setAdvancedFilterSelections] = useState(
    defaultAdvancedFilters
  )

  var [advancedFilterModalShow, setAdvancedFilterModalShow] = useState(false)

  let cancelTokenSource = null

  var [ClustersTableData, setClustersTableData] = useState([])

  var [trendsData, setTrendsData] = useState([])
  var [showTrends, setShowTrends] = useState(false)
  var [showSpendTrends, setShowSpendTrends] = useState(false)

  useEffect(() => {
    getClusters()
    getCustomReports()
    getFavorites()
    getViews()
  }, [])

  function getTrends (lead_type, ad_accounts) {
    // var accounts = encodeURIComponent(
    //   JSON.stringify(
    //     ad_accounts.map(account_name =>
    //       account_name.toLowerCase().replace('-', '_')
    //     )
    //   )
    // )
    // axios
    //   .get(APIEndpointDomain + '/performance/recent/generate-view', {
    //     params: {
    //       lead_type:
    //         lead_type != 'Jobs'
    //           ? lead_type.toLowerCase().replace('-', '_')
    //           : 'cpa',
    //       ad_account: accounts,
    //       is_job: lead_type == 'Jobs',
    //       is_gmat: lead_type == 'GMAT'
    //     },
    //     headers: {
    //       'ngrok-skip-browser-warning': 'any',
    //       'Access-Control-Allow-Origin': '*',
    //       Authorization: `Bearer ${localStorage.getItem('access_token')}`
    //     }
    //   })
    //   .then(response => {
    //     console.log(response)
    //     setTrendsData(response.data['data'])
    //   })
    //   .catch(error => {
    //     console.log(error)
    //     checkAuthenticationInResponse(Number(error.response.status))
    //   })
  }

  function getClusters () {
    axios
      .get(APIEndpointDomain + '/performance/get-clusters', {
        params: {},
        headers: {
          'ngrok-skip-browser-warning': 'any',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        setClusterNames(response.data['data'])
      })
      .catch(error => {
        console.log(error)
        checkAuthenticationInResponse(Number(error.response.status))
      })
  }

  function getCustomReports () {
    axios
      .get(APIEndpointDomain + '/performance/get-custom-reports', {
        params: {},
        headers: {
          'ngrok-skip-browser-warning': 'any',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        setCustomReportNames(response.data['data'])
      })
      .catch(error => {
        console.log(error)
        checkAuthenticationInResponse(Number(error.response.status))
      })
  }

  function getFavorites () {
    axios
      .get(APIEndpointDomain + '/performance/get-current-favorites', {
        params: {},
        headers: {
          'ngrok-skip-browser-warning': 'any',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        setFavoritesData(response.data['data'])
      })
      .catch(error => {
        console.log(error)
        checkAuthenticationInResponse(Number(error.response.status))
      })
  }

  function getViews () {
    axios
      .get(APIEndpointDomain + '/performance/get-views', {
        params: {},
        headers: {
          'ngrok-skip-browser-warning': 'any',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        setAllViews(response.data['data'])
      })
      .catch(error => {
        console.log(error)
        checkAuthenticationInResponse(Number(error.response.status))
      })
  }

  useEffect(() => {
    setCompareRangeRequirement(false)
  }, [startDate, endDate, selectedAdAccounts, selectedLeadType])

  function getPrimaryTD (
    start_date,
    end_date,
    lead_type,
    ad_accounts,
    range_type
  ) {
    setIsFirstLoad(false)

    var accounts = encodeURIComponent(
      JSON.stringify(
        ad_accounts.map(account_name =>
          account_name.toLowerCase().replace('-', '_')
        )
      )
    )

    setLoadingP(30)
    setTableDataAPICalling(true)

    // Cancel the previous request if it exists
    if (cancelTokenSource) {
      cancelTokenSource.cancel('Operation canceled by the user.')
    }

    // Create a new cancel token
    cancelTokenSource = axios.CancelToken.source()

    axios
      .get(APIEndpointDomain + '/performance/meta-indirect/generate-view', {
        params: {
          start_date: start_date,
          end_date: end_date,
          lead_type:
            lead_type == 'Jobs'
              ? 'cpa'
              : lead_type == 'GMAT'
              ? 'premium'
              : lead_type.toLowerCase().replace('-', '_')
        },
        headers: {
          'ngrok-skip-browser-warning': 'any',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        },
        // Pass the cancel token as a parameter
        cancelToken: cancelTokenSource.token
      })
      .then(response => {
        console.log(response)
        if (range_type === 'primary') {
          setLoadingP(70)
          setTableData(response.data['data'])
          setActiveAds(response.data['active_ads'])
          setActiveAdsets(response.data['active_adsets'])
          setActiveCampaigns(response.data['active_campaigns'])
          setCampaignBudgets(response.data['campaign_budgets'])
          setAdsetBudgets(response.data['adset_budgets'])

          setFavoriteCAIDs(response.data['caids'])
          setFavoriteASIDs(response.data['asids'])
          setFavoriteAIDs(response.data['aids'])
          setTableDataAPICalling(false)
          setAdsTagged(response.data['ads_tagged'])
          setDataIsLeadType(response.data['lead_type'])
        } else if (range_type === 'loading_trigger') {
          setLoadingP(70)
          setTableData([])
          setTableDataAPICalling(true)
        }
      })
      .catch(error => {
        setLoadingP(100)
        setTableData([])
        setActiveAds([])
        setActiveAdsets([])
        setActiveCampaigns([])
        setCampaignBudgets([])
        setAdsetBudgets([])
        setAverageStandards([])
        setClusterNames([])
        setAllViews([])
        setReminders([])
        setFavoritesData([])
        setFavoriteCAIDs([])
        setFavoriteASIDs([])
        setFavoriteAIDs([])
        setCustomReportNames([])
        setTableDataAPICalling(false)
        setAdsTagged([])
        // Check if the error is due to a canceled request
        if (axios.isCancel(error)) {
          console.log('Request canceled:', error.message)
        } else {
          checkAuthenticationInResponse(Number(error.response.status))
          console.log(error)
        }
      })

    // Cleanup the cancel token source on component unmount or when the parameters change
    return () => {
      if (cancelTokenSource) {
        cancelTokenSource.cancel('Operation canceled by the user.')
      }
    }
  }

  var preColumns = [
    'fifteen_to_25p',
    'twenty_five_p_to_50p',
    'fifty_p_to_75p',
    'held_per_1k_impressions',
    'impressions',
    'reach',
    'three_sec_plays',
    'thruplays',
    'twenty_five_p_plays',
    'fifty_p_plays',
    'seventy_five_p_plays',
    'link_clicks',
    'cpm',
    'cost_per_thruplay',
    'cpc',
    'ctr',
    'hook_rate',
    'hold_rate',
    'view_to_click',
    'click_to_lead',
    'acquisitions',
    'acquired_during',
    'avg_purchase',
    'avg_conversion_period',
    'adset_name',
    'budget',
    'campaign_name',
    'cb_during',
    'cpa',
    'cpa_during',
    'cpgl',
    'cpgl_historic',
    'cpl',
    // "cpm",
    'cpql',
    // "cpv",
    'cpuhl_h',
    'days_active',
    'deleted',
    'deleted_during',
    'dnp_during',
    'dnp_recovered',
    // "fifty_p_plays",
    'good_leads',
    'good_percentage',
    'lead_to_h_good_percentage',
    'lead_to_h_ultra_percentage',
    // "hold_rate_50_p",
    // "hold_rate_75_p",
    // "hold_rate_thruplay",
    // "hook_rate",
    'h_good',
    'h_good_percentage',
    'h_pressure',
    'h_ultra',
    // "impressions",
    'leads',
    // "link_clicks",
    'pending',
    'pending_p',
    'pressure_sell',
    'pressure_to_acquisition',
    'projected_acquisitions',
    'projected_cpa',
    'projected_roi',
    'qualified',
    'quality',
    // "reach",
    'revenue',
    'revenue_during',
    'roi',
    'roi_during',
    // "seventy_five_p_plays",
    'spend',
    // "status",
    // "thruplays",
    // "three_sec_plays",
    'total_revenue',
    'total_revenue_during',
    'ultra_hot',
    'uhl_to_acquisition',
    'upfront_payments',
    'upfront_payments_during',
    // "view_to_click",
    'campaign_id',
    'adset_id',
    'ad_id',
    'nurturing',
    'dnp',
    'no_show',
    'backburners',
    'lead_lost'
  ]

  var allColumns =
    selectedLeadType.toLowerCase() !== 'cpa'
      ? preColumns
      : [
          ...preColumns,
          'admit_up',
          'admit_up_during',
          'admit_up_rev',
          'admit_up_rev_dur',

          'actually_acquired_during',
          'h_acquisitions',
          'actual_acquisitions',
          'premium_ms_revenue',
          'premium_ms_revenue_during',
          'h_cpa',
          'cpaa',
          'cpaa_during',
          'acquisitions_lost',
          'acquisition_loss_rate',
          'acquisition_validation_rate',
          'acquisitions_pending_validation',
          'acquisitions_pending_validation_p',
          'avg_validation_period',
          'avg_complete_cycle_period',
          'real_revenue_during',
          'real_acquisitions_during',
          'real_roi_during',
          'cpra_during'
        ].sort()

  var allColumnFilters =
    selectedLevel === 'Ad' ? [...allColumns, 'campaign_name'] : allColumns
  var [selectedASIDCount, setSelectedASIDCount] = useState('')
  var [selectedCAIDCount, setSelectedCAIDCount] = useState('')
  var [selectedAIDCount, setSelectedAIDCount] = useState('')

  return (
    <>
      <div class='h-[50rem] h-screen w-screen w-full dark:bg-black bg-white dark:bg-grid-white/[0.2] bg-grid-black/[0.2] relative flex items-center justify-center'>
        <div class='absolute h-screen w-screen pointer-events-none inset-0 flex items-center justify-center'></div>
        <div class='grid grid-rows-2 gap-0 h-screen w-screen'>
          <div class='bg-white bg-opacity-50'>
            <PerformanceHeader
              APIEndpointDomain={APIEndpointDomain}
              contentTypeFilters={contentTypeFilters}
              setContentTypeFilters={setContentTypeFilters}
              statusFilters={statusFilters}
              setStatusFilters={setStatusFilters}
              columnFilters={allColumnFilters}
              selectedLevel={selectedLevel}
              setSelectedLevel={setSelectedLevel}
              setCampaignSearch={setCampaignSearch}
              setAdsetSearch={setAdsetSearch}
              setAdSearch={setAdSearch}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              defaultStartDate={start}
              defaultEndDate={end}
              setNewAdvanceFilter={setAdvancedFilterSelections}
              advancedFiltersSelections={advancedFiltersSelections}
              selectedColumnFilters={selectedColumnFilters}
              setSelectedColumnFilters={setSelectedColumnFilters}
              setSelectedAdAccount={setSelectedAdAccounts}
              setSelectedLeadType={setSelectedLeadType}
              selectedLeadType={selectedLeadType}
              selectedAdAccount={selectedAdAccounts}
              defaultAdvancedFilters={defaultAdvancedFilters}
              setAdvancedFilterModalShow={setAdvancedFilterModalShow}
              advancedFilterModalShow={advancedFilterModalShow}
              selectedIDS={{
                selectedCAIDCount: selectedCAIDCount,
                selectedASIDCount: selectedASIDCount,
                selectedAIDCount: selectedAIDCount
              }}
              consolidationType={consolidationType}
              setConsolidationType={setConsolidationType}
              hideStatusFilter={hideStatusFilter}
              setHideStatusFilter={setHideStatusFilter}
              allViews={allViews}
              setAllViews={setAllViews}
              Data={Data}
              setData={setData}
              getPrimaryTD={getPrimaryTD}
              startDate={startDate}
              endDate={endDate}
              setComparisonTableData={setComparisonTableData}
              compareRangeRequirement={compareRangeRequirement}
              setCompareRangeRequirement={setCompareRangeRequirement}
              loadingP={loadingP}
              setLoadingP={setLoadingP}
              tableDataAPICalling={tableDataAPICalling}
              favoritesData={favoritesData}
              setFavoritesData={setFavoritesData}
              favoriteCAIDs={favoriteCAIDs}
              setFavoriteCAIDs={setFavoriteCAIDs}
              favoriteASIDs={favoriteASIDs}
              setFavoriteASIDs={setFavoriteASIDs}
              favoriteAIDs={favoriteAIDs}
              setFavoriteAIDs={setFavoriteAIDs}
              customReportNames={customReportNames}
              setCustomReportNames={setCustomReportNames}
              selectedCustomReport={selectedCustomReport}
              setSelectedCustomReport={setSelectedCustomReport}
              goTriggerPrimaryAPI={goTriggerPrimaryAPI}
              setGoTriggerPrimaryAPI={setGoTriggerPrimaryAPI}
              campaignExclusions={campaignExclusions}
              setCampaignExclusions={setCampaignExclusions}
              adsetExclusions={adsetExclusions}
              setAdsetExclusions={setAdsetExclusions}
              adExclusions={adExclusions}
              setAdExclusions={setAdExclusions}
              dateExclusions={dateExclusions}
              setDateExclusions={setDateExclusions}
              currentCampaign={currentCampaign}
              setCurrentCampaign={setCurrentCampaign}
              currentAdset={currentAdset}
              setCurrentAdset={setCurrentAdset}
              currentAd={currentAd}
              setCurrentAd={setCurrentAd}
              currentDate={currentDate}
              setCurrentDate={setCurrentDate}
              showAvgComparisonIndicators={showAvgComparisonIndicators}
              setShowAvgComparisonIndicators={setShowAvgComparisonIndicators}
              campaignSearch={campaignSearch}
              adsetSearch={adsetSearch}
              adSearch={adSearch}
              selectedAIDCount={selectedAIDCount}
              showAdTagModal={showAdTagModal}
              setShowAdTagModal={setShowAdTagModal}
              clusterNames={clusterNames}
              setClusterNames={setClusterNames}
              selectedClusterNames={selectedClusterNames}
              setSelectedClusterNames={setSelectedClusterNames}
              exportCSVData={exportCSVData}
              setExportCSVData={setExportCSVData}
              aggregateAds={aggregateAds}
              setAggregateAds={setAggregateAds}
              AttributedClients={AttributedClientsDuring}
              AttributedRevenue={AttributedRevenueDuring}
              setAttributedClients={setAttributedClientsDuring}
              setAttributedRevenue={setAttributedRevenueDuring}
              tableData={tableData}
              getTrends={getTrends}
              showTrends={showTrends}
              setShowTrends={setShowTrends}
              getClusters={getClusters}
              getCustomReports={getCustomReports}
              getFavorites={getFavorites}
              getViews={getViews}
              totalsData={totalsData}
              showSpendTrends={showSpendTrends}
              setShowSpendTrends={setShowSpendTrends}
            />
            {/* <main className='py-2'>
            <main className='py-5'>
              <main className='py-5'>
                <main className='py-5'> */}
            <PerformanceTable
              campaignSearch={campaignSearch}
              adsetSearch={adsetSearch}
              adSearch={adSearch}
              tableData={tableData}
              selectedLevel={selectedLevel}
              setSelectedLevel={setSelectedLevel}
              loadingP={loadingP}
              setLoadingP={setLoadingP}
              advancedFilterSelections={advancedFiltersSelections}
              selectedColumnFilters={selectedColumnFilters}
              advancedFilterModalShow={advancedFilterModalShow}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              setSelectedASIDCount={setSelectedASIDCount}
              setSelectedAIDCount={setSelectedAIDCount}
              setSelectedCAIDCount={setSelectedCAIDCount}
              statusFilters={statusFilters}
              activeAds={activeAds}
              contentTypeFilters={contentTypeFilters}
              startDate={startDate}
              endDate={endDate}
              selectedLeadType={selectedLeadType}
              selectedAdAccount={selectedAdAccounts}
              consolidationType={consolidationType}
              setConsolidationType={setConsolidationType}
              setHideStatusFilter={setHideStatusFilter}
              checkAuthenticationInResponse={checkAuthenticationInResponse}
              APIEndpointDomain={APIEndpointDomain}
              tableDataAPICalling={tableDataAPICalling}
              Data={Data}
              setData={setData}
              compareRangeRequirement={compareRangeRequirement}
              setCompareRangeRequirement={setCompareRangeRequirement}
              comparisonTableData={comparisonTableData}
              Reminders={Reminders}
              setReminders={setReminders}
              PrimaryRangeProcessed={PrimaryRangeProcessed}
              setPrimaryRangeProcessed={setPrimaryRangeProcessed}
              CompareRangeProcessed={CompareRangeProcessed}
              setCompareRangeProcessed={setCompareRangeProcessed}
              activeAdsets={activeAdsets}
              activeCampaigns={activeCampaigns}
              favoritesData={favoritesData}
              setFavoritesData={setFavoritesData}
              favoriteCAIDs={favoriteCAIDs}
              setFavoriteCAIDs={setFavoriteCAIDs}
              favoriteASIDs={favoriteASIDs}
              setFavoriteASIDs={setFavoriteASIDs}
              favoriteAIDs={favoriteAIDs}
              setFavoriteAIDs={setFavoriteAIDs}
              selectedCustomReport={selectedCustomReport}
              setSelectedCustomReport={setSelectedCustomReport}
              campaignBudgets={campaignBudgets}
              adsetBudgets={adsetBudgets}
              setCampaignBudgets={setCampaignBudgets}
              setAdsetBudgets={setAdsetBudgets}
              averageStandards={averageStandards}
              showAvgComparisonIndicators={showAvgComparisonIndicators}
              setShowAvgComparisonIndicators={setShowAvgComparisonIndicators}
              setActiveAdsets={setActiveAdsets}
              setActiveCampaigns={setActiveCampaigns}
              setActiveAds={setActiveAds}
              setCampaignSearch={setCampaignSearch}
              setAdsetSearch={setAdsetSearch}
              setAdSearch={setAdSearch}
              showAdTagModal={showAdTagModal}
              setShowAdTagModal={setShowAdTagModal}
              ClustersTableData={ClustersTableData}
              setClustersTableData={setClustersTableData}
              setClusterNames={setClusterNames}
              adsTagged={adsTagged}
              setAdsTagged={setAdsTagged}
              clusterNames={clusterNames}
              DataIsLeadType={DataIsLeadType}
              exportCSVData={exportCSVData}
              setExportCSVData={setExportCSVData}
              isFirstLoad={isFirstLoad}
              setIsFirstLoad={setIsFirstLoad}
              aggregateAds={aggregateAds}
              setAggregateAds={setAggregateAds}
              currentRevenue={currentRevenue}
              previousRevenue={previousRevenue}
              currentSales={currentSales}
              previousSales={previousSales}
              currentCPA={currentCPA}
              previousCPA={previousCPA}
              currentCPL={currentCPL}
              previousCPL={previousCPL}
              topPerformers={topPerformers}
              recentSales={recentSales}
              AttributedClients={AttributedClientsDuring}
              AttributedRevenue={AttributedRevenueDuring}
              setAttributedClients={setAttributedClientsDuring}
              setAttributedRevenue={setAttributedRevenueDuring}
              showTrends={showTrends}
              setShowTrends={setShowTrends}
              trendsData={trendsData}
              setTotalsData={setTotalsData}
              showSpendTrends={showSpendTrends}
              setShowSpendTrends={setShowSpendTrends}
            />
            {/* </main>
              </main>
            </main>
          </main> */}
            <div className='App'>
              <Widget
                handleNewUserMessage={handleNewUserMessage}
                isWidgetOpened={true}
                title={chatboxTitle}
                subtitle={chatboxSubtitle}
                // handleToggle={handleChatToggle}
                profileAvatar={userImage}
                profileClientAvatar={
                  'https://w0.peakpx.com/wallpaper/471/473/HD-wallpaper-daffy-duck-black-funny-duck-daffy-thumbnail.jpg'
                }
                emojis={true}
                resizable={true}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PerformanceMetaIndirectScreen
