import React from 'react'
import { useState, useEffect } from 'react'
import axios from 'axios'
import { TypewriterEffect } from '../components/ui/typewriter-effect'
import { BackgroundBeams } from '../components/ui/background-beams'
import { Boxes } from '../components/ui/background-boxes'
import { cn } from '../utils/cn'
import { MultiStepLoader } from '../components/ui/white-multi-step-loader'
import { Button, Navbar } from 'react-bootstrap'
import { ShadCNButton } from '../components/ui/shadcn-button'
import Dropdown from 'react-bootstrap/Dropdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faScroll } from '@fortawesome/free-solid-svg-icons'

function FetchYouTubeTranscriptionScreen ({
  checkAuthenticationInResponse,
  APIEndpointDomain
}) {
  var loadingStates = [
    { text: 'finding video' },
    { text: 'fetching transcription' }
  ]
  var [isFetching, setIsFetching] = useState(false)
  var [isError, setIsError] = useState(false)
  var [script, setScript] = useState('')
  var [url, setUrl] = useState('')

  function getTranscription (url) {
    setIsFetching(true)
    setIsError(false)

    axios
      .get(APIEndpointDomain + '/youtube/video/get-transcription', {
        params: {
          url: url
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        console.log(response)
        setScript(response.data['data'])
        setIsFetching(false)
        setIsError(false)
      })
      .catch(error => {
        checkAuthenticationInResponse(Number(error.response.status))
        console.log(error)
        setIsFetching(false)
        setIsError(true)
      })
  }

  return (
    <div className='h-screen w-screen relative overflow-hidden bg-slate-900 flex flex-col items-center justify-center rounded-lg'>
      <div className='absolute inset-0 w-full h-full bg-slate-900 z-20 [mask-image:radial-gradient(transparent,white)] pointer-events-none' />

      <Boxes />
      <div className='w-screen bg-opacity-0'>
        {!isFetching && !isError ? (
          <>
            <div className=' '>
              {script && script != '' ? (
                <TypewriterEffect
                  className='text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-400 to-neutral-500 py-8'
                  words={'Found the transcription!'.split(' ').map(word => {
                    return { text: word }
                  })}
                ></TypewriterEffect>
              ) : script == '' ? (
                <>
                  <TypewriterEffect
                    className='mx-auto relative z-20  text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-400 to-neutral-500 py-8'
                    words={'Get Transcription'.split(' ').map(word => {
                      return { text: word }
                    })}
                  ></TypewriterEffect>
                  <input
                    onChange={e => setUrl(e.target.value)}
                    type='text'
                    placeholder={'Enter the youtube video url here...'}
                    className={cn(
                      'flex justify-center items-center text-center mx-auto relative z-20 font-mono w-1/3 border-black text-center text-xl p-3 text-gray-600 border rounded-lg bg-sky-50 text-base'
                    )}
                  />
                </>
              ) : null}
            </div>

            {script != '' ? (
              <>
                <div className='mx-auto relative z-20 w-11/12 h-96  rounded p-2 overflow-hidden text-gray-600 bg-sky-50 font-mono'>
                  <textarea
                    className='w-full h-full resize-none overflow-auto border-none outline-none focus:outline-none text-gray-600 bg-sky-50 font-mono'
                    value={script}
                  />
                </div>
              </>
            ) : null}
            <ShadCNButton
              className={cn(
                'mt-6 flex justify-center items-center mx-auto relative z-20 bg-slate-600  rounded-lg border-slate-900 text-white font-bold'
              )}
              onClick={() =>
                script == '' ? getTranscription(url) : window.location.reload()
              }
            >
              {script == '' ? 'GO' : 'Try again'}
            </ShadCNButton>
          </>
        ) : isFetching ? (
          <MultiStepLoader
            className='bg-opacity-0'
            loadingStates={loadingStates}
            loading={isFetching}
            duration={1000}
          ></MultiStepLoader>
        ) : isError ? (
          <div className='flex justify-center items-center text-center h-full'>
            <TypewriterEffect
              className='text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-400 to-neutral-500 py-8'
              words={'Video has no transcription added!'
                .split(' ')
                .map(word => {
                  return { text: word }
                })}
            ></TypewriterEffect>
          </div>
        ) : (
          <div className='flex justify-center items-center text-center h-full'>
            <TypewriterEffect
              className='text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-400 to-neutral-500 py-8'
              words={'Unrecognized error occured!'.split(' ').map(word => {
                return { text: word }
              })}
            ></TypewriterEffect>
          </div>
        )}
      </div>
    </div>
  )
}

export default FetchYouTubeTranscriptionScreen
