import React from 'react'
import { useState, useEffect } from 'react'
import axios from 'axios'
import { TypewriterEffect } from '../components/ui/typewriter-effect'
import { BackgroundBeams } from '../components/ui/background-beams'
import { Boxes } from '../components/ui/background-boxes'
import { cn } from '../utils/cn'
import { MultiStepLoader } from '../components/ui/white-multi-step-loader'
import { Button, Form, Nav, Navbar } from 'react-bootstrap'
import { ShadCNButton } from '../components/ui/shadcn-button'
import Dropdown from 'react-bootstrap/Dropdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faScroll } from '@fortawesome/free-solid-svg-icons'

function ResearchContentScreen ({
  checkAuthenticationInResponse,
  APIEndpointDomain
}) {
  var loadingStates = [
    { text: 'connecting to server' },
    { text: 'searching on google' },
    { text: 'fetching results' },
    { text: 'fetching page content' },
    { text: 'searching on youtube' },
    { text: 'fetching results' },
    { text: 'getting transcripts' },
    { text: 'finishing up' }
  ]
  var [isFetching, setIsFetching] = useState(false)
  var [isError, setIsError] = useState(false)

  var [data, setData] = useState([])
  var [searchQuery, setSearchQuery] = useState('')
  var [googleData, setGoogleData] = useState([])
  var [youTubeData, setYouTubeData] = useState([])
  var [selectedChannel, setSelectedChannel] = useState('Google')
  var [blogCount, setBlogCount] = useState(5)
  var [videoCount, setVideoCount] = useState(5)
  var [copyButtonText, setCopyButtonText] = useState('Copy OverviewPrompt')

  function cleanText (input) {
    // Trim leading and trailing whitespaces
    let cleanedText = input.trim()

    // Replace multiple spaces with a single space
    cleanedText = cleanedText.replace(/\s+/g, ' ')

    // Replace line breaks with a space (or you can remove them if you prefer)
    // cleanedText = cleanedText.replace(/[\r\n]+/g, ' ')

    return cleanedText
  }

  function createOverviewPrompt (googleData, youTubeData, searchQuery) {
    var allTextualContentArray = googleData.concat(youTubeData)
    var allTexualContent = cleanText(allTextualContentArray.join(' '))

    var overviewPrompt = `<Instructions>
    You will be acting as a content summarization assistant. Your task is to analyze a large chunk of textual content from various sources like blogs and YouTube video transcripts, and create a comprehensive summary of the most important points, organized around a given title.
    
    Here is the content you need to analyze and summarize:
    
    <content>
    {$CONTENT}
    </content>
    
    And here is the title you should focus on while summarizing the content:
    
    <title>
    {$TITLE}
    </title>
    
    Please read through the content carefully multiple times to fully understand it. As you go through it, identify the key points, main ideas, and crucial pieces of information that are most relevant to the title. Try to pick out at least 50 of the most important key points to include in your summary.
    
    After you have finished extracting the key points from the provided content, add numbers and statistics to all those points (as close to reality as possible) and use your knowledge to add your own data-driven points, statistics, and fascinating facts that are related to the topic. This will help enhance the summary and make it more informative.
    
    When you have gathered all the information, please summarize it into bullet point format. Each bullet point should be a clear, concise statement that captures a main idea from the content or one of your own added points. Make sure all the bullet points you include are relevant to the given title and help explain or expand upon the topic in some way.
    
    Please provide your completed summary inside <summary> tags. I look forward to seeing what insights you discover!
    </Instructions>`

    var copyAbleOverviewPrompt = overviewPrompt
      .replace('{$TITLE}', searchQuery)
      .replace('{$CONTENT}', allTexualContent)

    navigator.clipboard.writeText(copyAbleOverviewPrompt)

    setCopyButtonText('Copied Prompt!')
  }

  useEffect(() => {
    // Set the text after 3 seconds
    const timer = setTimeout(() => {
      setCopyButtonText('Copy OverviewPrompt')
    }, 3000)

    return () => clearTimeout(timer)
  }, [copyButtonText])

  function generateResearchContent (search_query, blog_count, video_count) {
    setIsFetching(true)
    setIsError(false)

    axios
      .get(APIEndpointDomain + '/research-content', {
        params: {
          search_query: search_query,
          youtube_video_count: video_count,
          blog_count: blog_count
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        console.log(response)
        setData(response.data['google'])
        setGoogleData(response.data['google'])
        setYouTubeData(response.data['youtube'])
        setIsFetching(false)
        setIsError(false)
      })
      .catch(error => {
        checkAuthenticationInResponse(Number(error.response.status))
        console.log(error)
        setIsFetching(false)
        setIsError(true)
      })
  }

  return (
    <div
      className={
        data.length > 0
          ? 'w-screen relative overflow-y overflow-x-hidden bg-slate-900 flex flex-col items-center justify-center'
          : 'h-screen w-screen relative overflow-hidden bg-slate-900 flex flex-col items-center justify-center'
      }
    >
      <div
        className={
          data.length > 0
            ? 'absolute overflow-y overflow-x-hidden inset-0 w-full h-full bg-slate-900 z-20 [mask-image:radial-gradient(transparent,white)] pointer-events-none'
            : 'absolute inset-0 w-full h-full bg-slate-900 z-20 [mask-image:radial-gradient(transparent,white)] pointer-events-none'
        }
      />

      <Boxes />
      <div className='w-screen bg-opacity-0 bg-slate-900'>
        {!isFetching && !isError ? (
          <>
            <Navbar
              fixed='top'
              style={{
                borderWidth: 0
              }}
            >
              <div className='w-screen '>
                {/* <div className='py-0 '> */}
                <div
                  className='ml-2 content-left'
                  style={{
                    display: 'flex',
                    justifyContent: 'left'
                  }}
                >
                  <div className='bg-slate-200 flex items-center justify-center  overflow ml-3 text-black  rounded-lg'>
                    <p className='bg-slate-200 mx-3 my-1'> Blog Count</p>
                  </div>
                  <input
                    type='number'
                    // style={{ maxWidth: '30px' }}
                    className='w-14 rounded-lg text-center bg-slate-100'
                    value={blogCount}
                    onChange={e => setBlogCount(e.target.value)}
                  />

                  <div className='bg-slate-200 flex items-center justify-center  overflow ml-3 text-black  rounded-lg'>
                    <p className='bg-slate-200 mx-3 my-1'>
                      {' '}
                      YouTube Video Count
                    </p>
                  </div>
                  <input
                    type='number'
                    // style={{ maxWidth: '30px' }}
                    className='w-14 rounded-lg text-center bg-slate-100'
                    value={videoCount}
                    onChange={e => setVideoCount(e.target.value)}
                  />
                  {data.length > 0 && (
                    <>
                      <Dropdown className='ml-3'>
                        <Dropdown.Toggle
                          style={{
                            backgroundColor: '#475569',
                            borderColor: '#475569'
                          }}
                          className='bg-slate-200'
                        >
                          {selectedChannel}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={e => setSelectedChannel(e.target.text)}
                          >
                            Google
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={e => setSelectedChannel(e.target.text)}
                          >
                            YouTube
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>

                      <ShadCNButton
                        className={cn(
                          'ml-3 bg-slate-600  rounded-lg border-slate-900 text-white font-bold'
                        )}
                        onClick={() =>
                          createOverviewPrompt(
                            googleData,
                            youTubeData,
                            searchQuery
                          )
                        }
                      >
                        {copyButtonText}
                      </ShadCNButton>
                      <ShadCNButton
                        className={cn(
                          'ml-3 bg-slate-600  rounded-lg border-slate-900 text-white font-bold'
                        )}
                        onClick={() => window.location.reload()}
                      >
                        {'Retry'}
                      </ShadCNButton>
                    </>
                  )}
                </div>
              </div>
            </Navbar>
            <div className={'py-5 h-full'}>
              {data && data != '' && selectedChannel == 'Google' ? (
                googleData.map((content, index) => {
                  return (
                    <>
                      <h2 className='relative z-20 text-white mx-auto flex justify-center items-center text-center'>
                        Blog {index + 1} {' Content'}
                      </h2>
                      <div className='mx-auto relative z-20 w-11/12 h-96  rounded p-2 text-gray-600 bg-sky-50 font-mono'>
                        <textarea
                          className='w-full h-full resize-none overflow-auto border-none outline-none focus:outline-none text-gray-600 bg-sky-50 font-mono'
                          value={cleanText(content)}
                        />
                      </div>
                    </>
                  )
                })
              ) : data && data != '' && selectedChannel == 'YouTube' ? (
                youTubeData.map((content, index) => {
                  return (
                    <>
                      <h2 className='relative z-20 text-white mx-auto flex justify-center items-center text-center'>
                        Video {index + 1} {' Transcription'}
                      </h2>
                      <div className='mx-auto relative z-20 w-11/12 h-96  rounded p-2 text-gray-600 bg-sky-50 font-mono'>
                        <textarea
                          className='w-full h-full resize-none overflow-auto border-none outline-none focus:outline-none text-gray-600 bg-sky-50 font-mono'
                          value={cleanText(content)}
                        />
                      </div>
                    </>
                  )
                })
              ) : data == '' || !data ? (
                <>
                  <TypewriterEffect
                    className='mx-auto relative z-20  text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-400 to-neutral-500 py-8'
                    words={'Surf Google & YouTube in one go!'
                      .split(' ')
                      .map(word => {
                        return { text: word }
                      })}
                  ></TypewriterEffect>

                  <div className=' flex justify-center items-center text-center'>
                    <input
                      onChange={e => setSearchQuery(e.target.value)}
                      type='text'
                      placeholder={
                        data && data != ''
                          ? 'Reload page to analyze again...'
                          : 'Enter your research topic here...'
                      }
                      className={cn(
                        'relative z-20 font-mono w-1/3 border-black text-center text-xl p-3 text-gray-600 border rounded-lg bg-sky-50 text-base'
                      )}
                    />
                  </div>
                </>
              ) : null}
            </div>
            {data.length == 0 && (
              <ShadCNButton
                className={cn(
                  ' flex justify-center items-center mx-auto relative z-20 bg-slate-600  rounded-lg border-slate-900 text-white font-bold'
                )}
                onClick={() =>
                  generateResearchContent(searchQuery, blogCount, videoCount)
                }
              >
                {'GO'}
              </ShadCNButton>
            )}
          </>
        ) : isFetching ? (
          <MultiStepLoader
            className='bg-opacity-0'
            loadingStates={loadingStates}
            loading={isFetching}
            duration={3000}
          ></MultiStepLoader>
        ) : isError ? (
          <div className='flex justify-center items-center text-center h-full'>
            <TypewriterEffect
              className='text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-400 to-neutral-500 py-8'
              words={'Server failed to return data!'.split(' ').map(word => {
                return { text: word }
              })}
            ></TypewriterEffect>
          </div>
        ) : (
          <div className='flex justify-center items-center text-center h-full'>
            <TypewriterEffect
              className='text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-400 to-neutral-500 py-8'
              words={'Unrecognized error occured!'.split(' ').map(word => {
                return { text: word }
              })}
            ></TypewriterEffect>
          </div>
        )}
      </div>
    </div>
  )
}

export default ResearchContentScreen
