import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import axios from 'axios'
import Button from 'react-bootstrap/Button'
import { useEffect, useState, useRef, useMemo } from 'react'
import { CSVLink } from 'react-csv'
import { Navbar } from 'react-bootstrap'
import { Form } from 'react-bootstrap'
import { Col } from 'react-bootstrap'
import { AgGridReact } from 'ag-grid-react'
import { MultiStepLoader } from '../components/ui/multi-step-loader'
// import { TypewriterEffect } from './ui/typewriter-effect.js'
import { TypewriterEffect } from '../components/ui/typewriter-effect.js'
import { faClipboard, faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function UserSessionSearchScreen ({
  APIEndpointDomain,
  checkAuthenticationInResponse
}) {
  var loadingStates = [
    {
      text: 'Finding user'
    },
    {
      text: 'Analyzing sessions'
    },
    {
      text: 'Finishing up'
    }
  ]
  var [pii, setPii] = useState('')
  var [domain, setDomain] = useState('mim-essay')

  var [headers, setHeaders] = useState([])
  var [piiType, setPiiType] = useState('email')
  var [isFetching, setIsFetching] = useState(false)
  var [isError, setIsError] = useState(false)
  var [tableData, setTableData] = useState([])
  var [customPath, setCustomPath] = useState('')
  function handlePiiChange (e) {
    setPii(e.target.value)
  }

  function copyToClipboard (url) {
    navigator.clipboard.writeText(url)
    // Create a temporary textarea element to hold the URL
    const textArea = document.createElement('textarea')
    textArea.value = url

    // Append the textarea element to the document
    document.body.appendChild(textArea)

    // Select the URL in the textarea
    textArea.select()

    // Execute the copy command
    document.execCommand('copy')

    // Remove the temporary textarea element
    document.body.removeChild(textArea)

    // Check if the URL was successfully copied to the clipboard
    // if (document.queryCommandSupported("copy")) {
    //   console.log("URL copied to clipboard: " + url);
    // } else {
    //   console.error("Copy to clipboard not supported in this browser.");
    // }
  }

  function handleGoButtonClick () {
    setIsError(false)
    setIsFetching(true)
    axios
      .get(APIEndpointDomain + '/sessions/user-search', {
        params: {
          pii: pii.toLowerCase(),
          pii_type: piiType,
          domain: domain
        },

        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        setTableData(response.data['data'])
        setHeaders(
          Object.keys(response.data['data'][0]).map(key => {
            return {
              headerName: String(key).replace(/_/g, ' '),
              field: key,
              filter: 'agNumberColumnFilter',
              floatingFilter: true,
              autoHeight: true,

              width: 240
            }
          })
        )
        setCustomPath(response.data['custom_paths'])
        console.log(response)
      })
      .catch(error => {
        checkAuthenticationInResponse(Number(error.response.status))
        console.log(error)
        setIsError(true)
        alert('Failed to find user!')
      })
      .finally(() => {
        setIsFetching(false)
      })
  }

  const defaultColDef = useMemo(() => {
    return {
      width: 170,
      filter: true,
      cellStyle: { fontSize: '14px', textAlign: 'center' },
      enableValue: true,
      enableRowGroup: true,
      enablePivot: true,
      filterParams: { newRowsAction: 'keep' }
    }
  }, [])

  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), [])

  const rowStyle = { background: '#black' }

  const getRowStyle = params => {
    if (params.node.rowIndex % 2 !== 0) {
      return { background: '#f1f5f9' }
    } else if (params.node.rowIndex % 2 === 0) {
      return { background: '#f8fafc' }
    }
  }
  const gridRef = useRef()

  return (
    <>
      <div className='h-[50rem] h-screen w-screen w-full dark:bg-black bg-white  dark:bg-grid-white/[0.2] bg-grid-black/[0.2] relative flex items-center justify-center'>
        <div className='absolute h-screen w-screen pointer-events-null inset-0 flex items-center justify-center dark:bg-black bg-white [mask-image:radial-gradient(ellipse_at_center,transparent_20%,black)]'></div>
        <div className='grid grid-rows-2 gap-0 bg-opacity-0 h-screen w-screen'>
          <div className='w-screen bg-opacity-0'>
            <div className='w-screen'>
              <Navbar
                fixed='top'
                style={{
                  borderWidth: 0
                }}
                className='mt-0'
              >
                <div className='w-screen '>
                  <div
                    className='content-center'
                    style={{
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    <Dropdown>
                      <Dropdown.Toggle variant='secondary' id='dropdown-basic'>
                        {domain}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className='custom-report-dropdown-menu'>
                        <Dropdown.Item
                          onClick={e => setPiiType(e.target.text)}
                          disabled={isFetching}
                        >
                          mim-essay
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={e => setPiiType(e.target.text)}
                          disabled={isFetching}
                        >
                          mentr-me
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={e => setPiiType(e.target.text)}
                          disabled={isFetching}
                        >
                          all
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown>
                      <Dropdown.Toggle variant='secondary' id='dropdown-basic'>
                        {piiType}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className='custom-report-dropdown-menu'>
                        <Dropdown.Item
                          onClick={e => setPiiType(e.target.text)}
                          disabled={isFetching}
                        >
                          email
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={e => setPiiType(e.target.text)}
                          disabled={isFetching}
                        >
                          phone
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <Form.Control
                      className='max-w-md'
                      size='md'
                      type='text'
                      id='pii'
                      placeholder={'Email or Phone'}
                      onChange={handlePiiChange}
                      value={pii}
                    />

                    {/* <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 1500, hide: 400 }}
                    overlay={renderButtonTooltip(
                      "Click to fetch the latest data! (Only required if you've changed the date, ad account or lead type)"
                    )}
                  > */}
                    <Button
                      size='sm'
                      id='toggle-check'
                      variant='danger'
                      onClick={() => handleGoButtonClick()}
                      disabled={isFetching || pii == ''}
                    >
                      Go
                    </Button>
                    {customPath && !isFetching && !isError && (
                      <>
                        <Button
                          onClick={() => copyToClipboard(customPath)}
                          size='md'
                          id='toggle-check'
                          variant='primary'
                          disabled={isFetching}
                        >
                          <FontAwesomeIcon
                            size='md'
                            className='fa-regular'
                            icon={faClipboard}
                          ></FontAwesomeIcon>
                        </Button>
                        <Button variant='light'>
                          <CSVLink
                            filename='performance-crm-export.csv'
                            data={tableData}
                          >
                            <FontAwesomeIcon
                              icon={faDownload}
                            ></FontAwesomeIcon>
                          </CSVLink>
                        </Button>
                      </>
                    )}
                  </div>
                </div>
                {/* </OverlayTrigger> */}
              </Navbar>
            </div>
            <div className={'pt-14 w-screen h-screen bg-opacity-0'}>
              {tableData.length > 0 && !isFetching && !isError ? (
                <div style={gridStyle} className={'ag-theme-quartz'}>
                  <AgGridReact
                    rowData={tableData}
                    columnDefs={headers}
                    defaultColDef={defaultColDef}
                    rowDragManaged={true}
                    rowSelection='multiple'
                    rowDragMultiRow={true}
                    rowStyle={rowStyle}
                    getRowStyle={getRowStyle}
                    sideBar={true}
                    ref={gridRef}
                  />
                  {/* </div> */}
                </div>
              ) : isError ? (
                <div className='flex justify-center items-center text-center h-full'>
                  <TypewriterEffect
                    className='text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-400 to-neutral-500 py-8'
                    words={'Server returned no data!'.split(' ').map(word => {
                      return { text: word }
                    })}
                  ></TypewriterEffect>
                </div>
              ) : isFetching ? (
                <MultiStepLoader
                  className='bg-opacity-0'
                  loadingStates={loadingStates}
                  loading={isFetching}
                  duration={2100}
                ></MultiStepLoader>
              ) : (
                <div className='flex justify-center items-center text-center h-full'>
                  <TypewriterEffect
                    className='text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-400 to-neutral-500 py-8'
                    words={'Enter email or phone no. and hit go!'
                      .split(' ')
                      .map(word => {
                        return { text: word }
                      })}
                  ></TypewriterEffect>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserSessionSearchScreen
