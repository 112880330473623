import React, { useEffect } from 'react'
import { Navbar } from 'react-bootstrap'
import { TabsATUI } from '../ui/tabs'
import { AgGridReact } from 'ag-grid-react'
import { useMemo, useState } from 'react'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import axios from 'axios'
import { TypewriterEffect } from '../ui/typewriter-effect'
import { MultiStepLoader } from '../ui/multi-step-loader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComments, faCopy } from '@fortawesome/free-solid-svg-icons'
import Button from 'react-bootstrap/Button'
import { Form } from 'react-bootstrap'
import { Input } from '../ui/input.js'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger
} from '../ui/drawer.js'
import { Textarea } from '../ui/textarea.js'
import { ShadCNButton } from '../ui/shadcn-button.js'

function DrawerMS ({
  data,
  title,
  drawerSave,
  form_id,
  field,
  APIEndpointDomain,
  allData,
  setAllData,
  checkAuthenticationInResponse
}) {
  var [typedValue, setTypedValue] = useState(data)

  function updateObject (array, idToFind, keyToUpdate, newValue) {
    // Find the index of the object with the given id
    const index = array.findIndex(obj => obj.form_id === idToFind)

    // If the object is found
    if (index !== -1) {
      // Update the value of the specified key
      array[index][keyToUpdate] = newValue
      // Return the updated object
      console.log('updated obj', array[index][keyToUpdate])
      return array
    } else {
      // Object with the given id not found
      console.log('Object not found')
      return null
    }
  }

  function updateLeadInformation (form_id, field, value) {
    axios
      .get(APIEndpointDomain + '/mind-scape/update-information', {
        params: {
          form_id: form_id,
          field: field,
          value: value
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        console.log(response.data)
        var new_data = updateObject(allData, form_id, field, value)
        setAllData(new_data)
      })
      .catch(error => {
        checkAuthenticationInResponse(Number(error.response.status))
        console.log(error)
      })
  }

  function copyToClipboard (text) {
    console.log(text)
    navigator.clipboard.writeText(text)
  }
  return (
    <p>
      <Drawer>
        <DrawerTrigger asChild>
          {/* <div></div> */}
          {/* <> */}
          <ShadCNButton
            className='bg-transparent'
            // size='sm'
            // onClick={() => setUtmDrawerOpen(true)}
          >
            {data && typedValue == data ? (
              <p>{data}</p>
            ) : typedValue != data ? (
              <p>{typedValue}</p>
            ) : (
              ''
            )}
          </ShadCNButton>
          {/* {field == 'phone' && (
              <>
                <br></br>
                <ShadCNButton
                  onClick={() => copyToClipboard(data)}
                  className='bg-transparent'
                  size='sm'
                >
                  <FontAwesomeIcon size='sm' icon={faCopy}></FontAwesomeIcon>
                </ShadCNButton>
              </>
            )} */}
          {/* </> */}
        </DrawerTrigger>
        <DrawerContent className='bg-slate-100'>
          <div className='mx-auto w-full px-20'>
            <DrawerHeader>
              <div
                className='content-center'
                style={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <h4 className='text-3xl text-slate-800 font-bold mx-auto'>
                  {title}
                </h4>
              </div>
            </DrawerHeader>

            <div className=''>
              <div class='flex items-center justify-center flex-row '>
                <Textarea
                  onChange={e => setTypedValue(e.target.value)}
                  type='textArea'
                  value={typedValue}
                  placeholder={'Type here...'}
                />
              </div>
            </div>

            <div className='mx-auto w-full max-w-sm '>
              <DrawerFooter>
                <DrawerClose asChild>
                  <Button
                    onClick={() =>
                      updateLeadInformation(form_id, field, typedValue)
                    }
                    variant='danger'
                  >
                    Save
                  </Button>
                </DrawerClose>
              </DrawerFooter>
            </div>
          </div>
        </DrawerContent>
      </Drawer>
    </p>
  )
}

export default DrawerMS
