import LoginForm from '../components/LoginForm'
// import '../css/LoginScreen.css'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { Toast } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUserPlus,
  faFilter,
  faTableColumns,
  faPenToSquare,
  faEye,
  faWallet,
  faTrash,
  faShareNodes
} from '@fortawesome/free-solid-svg-icons'
import { Boxes } from '../components/ui/background-boxes'
import { cn } from '../utils/cn'
import { TypewriterEffect } from '../components/ui/typewriter-effect'

function LoginScreen ({ checkAuthenticationInResponse, APIEndpointDomain }) {
  var [loginRequest, setLoginRequest] = useState(0)
  var [email, setEmail] = useState('')
  var [password, setPassword] = useState('')
  var [showInvalidPassword, setShowInvalidPassword] = useState(false)
  var [isRequesting, setIsRequesting] = useState(false)

  function tryLogin (try_email, try_password) {
    setShowInvalidPassword(false)
    setIsRequesting(true)

    axios
      .post(APIEndpointDomain + '/login/token/', {
        username: try_email,
        password: try_password,

        headers: {
          'ngrok-skip-browser-warning': 'any',
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        console.log(response)
        setIsRequesting(false)
        var data = response.data
        localStorage.clear()
        localStorage.setItem('access_token', data.data.access)

        axios.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${data['access']}`

        window.location.href = '/'
      })
      .catch(error => {
        console.log(error)
        checkAuthenticationInResponse(error.status)
        setShowInvalidPassword(true)
        setIsRequesting(false)
      })
  }

  return (
    <>
      {/* <div className='login-form-container'> */}
      <div className='h-screen w-screen relative overflow-hidden bg-slate-900 flex flex-col items-center justify-center rounded-lg'>
        <div className='absolute inset-0 w-full h-full bg-slate-900 z-20 [mask-image:radial-gradient(transparent,white)] pointer-events-none' />

        <Boxes />
        <h1 className={cn('md:text-4xl text-xl text-white relative z-20')}>
          Marketing Tools
        </h1>
        <div className={cn('relative z-20')}>
          <LoginForm
            setTriggerLoginRequest={setLoginRequest}
            triggerLoginRequest={loginRequest}
            setEmail={setEmail}
            setPassword={setPassword}
            email={email}
            password={password}
            tryLogin={tryLogin}
            showInvalidPassword={showInvalidPassword}
          />
        </div>
        <p className='text-center mt-2 text-neutral-300 relative z-20'>
          {/* Framer motion is the best animation library ngl */}
        </p>
      </div>

      {/* </div> */}
    </>
  )
}

export default LoginScreen
// 'use client'
// import React from 'react'
// import { Label } from '../components/ui/label'
// import { Input } from '../components/ui/input'
// import { cn } from '../utils/cn'
// import {
//   IconBrandGithub,
//   IconBrandGoogle,
//   IconBrandOnlyfans
// } from '@tabler/icons-react'

// export default function LoginForm () {

//   const handleSubmit = e => {
//     e.preventDefault()
//     console.log('Form submitted')
//   }
//   return (
//     <div className='max-w-md w-full mx-auto rounded-none md:rounded-2xl p-4 md:p-8 shadow-input bg-white dark:bg-black'>
//       <h2 className='font-bold text-xl text-neutral-800 dark:text-neutral-200'>
//         Welcome to Aceternity
//       </h2>
//       <p className='text-neutral-600 text-sm max-w-sm mt-2 dark:text-neutral-300'>
//         Login to aceternity if you can because we don&apos;t have a login flow
//         yet
//       </p>

//       <form className='my-8' onSubmit={handleSubmit}>
//         <div className='flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2 mb-4'>
//           <LabelInputContainer>
//             <Label htmlFor='firstname'>First name</Label>
//             <Input id='firstname' placeholder='Tyler' type='text' />
//           </LabelInputContainer>
//           <LabelInputContainer>
//             <Label htmlFor='lastname'>Last name</Label>
//             <Input id='lastname' placeholder='Durden' type='text' />
//           </LabelInputContainer>
//         </div>
//         <LabelInputContainer className='mb-4'>
//           <Label htmlFor='email'>Email Address</Label>
//           <Input id='email' placeholder='projectmayhem@fc.com' type='email' />
//         </LabelInputContainer>
//         <LabelInputContainer className='mb-4'>
//           <Label htmlFor='password'>Password</Label>
//           <Input id='password' placeholder='••••••••' type='password' />
//         </LabelInputContainer>
//         <LabelInputContainer className='mb-8'>
//           <Label htmlFor='twitterpassword'>Your twitter password</Label>
//           <Input
//             id='twitterpassword'
//             placeholder='••••••••'
//             type='twitterpassword'
//           />
//         </LabelInputContainer>

//         <button
//           className='bg-gradient-to-br relative group/btn from-black dark:from-zinc-900 dark:to-zinc-900 to-neutral-600 block dark:bg-zinc-800 w-full text-white rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset] dark:shadow-[0px_1px_0px_0px_var(--zinc-800)_inset,0px_-1px_0px_0px_var(--zinc-800)_inset]'
//           type='submit'
//         >
//           Sign up &rarr;
//           <BottomGradient />
//         </button>

//         <div className='bg-gradient-to-r from-transparent via-neutral-300 dark:via-neutral-700 to-transparent my-8 h-[1px] w-full' />

//         <div className='flex flex-col space-y-4'>
//           <button
//             className=' relative group/btn flex space-x-2 items-center justify-start px-4 w-full text-black rounded-md h-10 font-medium shadow-input bg-gray-50 dark:bg-zinc-900 dark:shadow-[0px_0px_1px_1px_var(--neutral-800)]'
//             type='submit'
//           >
//             <IconBrandGithub className='h-4 w-4 text-neutral-800 dark:text-neutral-300' />
//             <span className='text-neutral-700 dark:text-neutral-300 text-sm'>
//               GitHub
//             </span>
//             <BottomGradient />
//           </button>
//           <button
//             className=' relative group/btn flex space-x-2 items-center justify-start px-4 w-full text-black rounded-md h-10 font-medium shadow-input bg-gray-50 dark:bg-zinc-900 dark:shadow-[0px_0px_1px_1px_var(--neutral-800)]'
//             type='submit'
//           >
//             <IconBrandGoogle className='h-4 w-4 text-neutral-800 dark:text-neutral-300' />
//             <span className='text-neutral-700 dark:text-neutral-300 text-sm'>
//               Google
//             </span>
//             <BottomGradient />
//           </button>
//           <button
//             className=' relative group/btn flex space-x-2 items-center justify-start px-4 w-full text-black rounded-md h-10 font-medium shadow-input bg-gray-50 dark:bg-zinc-900 dark:shadow-[0px_0px_1px_1px_var(--neutral-800)]'
//             type='submit'
//           >
//             <IconBrandOnlyfans className='h-4 w-4 text-neutral-800 dark:text-neutral-300' />
//             <span className='text-neutral-700 dark:text-neutral-300 text-sm'>
//               OnlyFans
//             </span>
//             <BottomGradient />
//           </button>
//         </div>
//       </form>
//     </div>
//   )
// }

// const BottomGradient = () => {
//   return (
//     <>
//       <span className='group-hover/btn:opacity-100 block transition duration-500 opacity-0 absolute h-px w-full -bottom-px inset-x-0 bg-gradient-to-r from-transparent via-cyan-500 to-transparent' />
//       <span className='group-hover/btn:opacity-100 blur-sm block transition duration-500 opacity-0 absolute h-px w-1/2 mx-auto -bottom-px inset-x-10 bg-gradient-to-r from-transparent via-indigo-500 to-transparent' />
//     </>
//   )
// }

// const LabelInputContainer = ({ children, className }) => {
//   return (
//     <div className={cn('flex flex-col space-y-2 w-full', className)}>
//       {children}
//     </div>
//   )
// }
