import { Link, useParams } from 'react-router-dom'
import axios from 'axios'
import { CSVLink } from 'react-csv'
import DrawerMS from '../components/MindScrapeComponents/drawer'
import { useEffect, useState, useMemo } from 'react'
import '../css/YouTubeVideosScreen.css'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css' // Core CSS
import 'ag-grid-community/styles/ag-theme-quartz.css' // Theme
import Badge from 'react-bootstrap/Badge'
import { Button } from 'react-bootstrap'
import APIRequestStatusModal from '../components/CommonSubComponents/APIRequestStatusMessageModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowsRotate,
  faSearch,
  faRankingStar,
  faRobot,
  faWallet,
  faDownload,
  faPenToSquare
} from '@fortawesome/free-solid-svg-icons'
import { Navbar } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
import ListGroup from 'react-bootstrap/ListGroup'
import Top5VideosModal from '../components/YouTubeRankerComponents/Top5VideosModal'
import AIRecommendationModal from '../components/YouTubeRankerComponents/AIRecommendationModal'
import { TextGenerateEffect } from '../components/ui/text-generate-effect'
import Dropdown from 'react-bootstrap/Dropdown'
import AssignCustomTagModal from '../components/YouTubeSubComponents/assignCustomTagModal'
import { ShadCNButton } from '../components/ui/shadcn-button'
import { MultiStepLoader } from '../components/ui/multi-step-loader'
import { TypewriterEffect } from '../components/ui/typewriter-effect'

function YouTubeVideosScreen ({
  checkAuthenticationInResponse,
  APIEndpointDomain
}) {
  var loadingStates = [
    {
      text: 'fetching videos'
    },

    {
      text: 'finishing up'
    }
  ]

  var [apiData, setAPIData] = useState([])
  var [showCustomTagModal, setShowCustomTagModal] = useState(false)
  var [showingCustomTagModalForData, setShowingCustomTagModalForData] =
    useState()
  var [InputtedClusterName, setInputtedClusterName] = useState('')
  var [data, setData] = useState([])
  var [selectedRankingFilter, setSelectedRankingFilter] =
    useState('All Rank Videos')
  var [isFetching, setIsFetching] = useState(false)
  var [isError, setIsError] = useState(false)

  var [selectedGroupingFilter, setSelectedGroupingFilter] =
    useState('All Groupings')
  var [APIStatusMessage, setAPIStatusMessage] = useState('')
  var [showAPIStatusModal, setShowAPIStatusModal] = useState(false)
  var [isFetchingRanking, setIsFetchingRanking] = useState(false)
  var [recommendationCost, setRecommendationCost] = useState(0)
  var [isFetchingAIRecommendations, setIsFetchingAIRecommendations] =
    useState(false)
  var [showAIRecommendationsModal, setShowAIRecommendationsModal] =
    useState(false)
  var [AIRecommendationMessage, setAIRecommendationMessage] = useState('')
  var [
    showAIRecommendationMessageForVideo,
    setShowAIRecommendationMessageForVideo
  ] = useState('')
  var [
    showAIRecommendationMessageForKeyword,
    setShowAIRecommendationMessageForKeyword
  ] = useState('')

  var [isUpdatingAllRankings, setIsUpdatingAllRankings] = useState(false)
  var [isUpdatingAllVideos, setIsUpdatingAllVideos] = useState(false)
  var [showTop5VideosForFocusKeyword, setShowTop5VideosForFocusKeyword] =
    useState('')

  var [showTopRankingVideosModal, setShowTopRankingVideosModal] =
    useState(false)
  var [topRankingVideos, setTopRankingVideos] = useState([])

  var [isUpdatingForVideo, setIsUpatingForVideo] = useState([])
  var [showDescriptionModal, setShowDescriptionModal] = useState(false)
  var [showDescription, setShowDescription] = useState([])
  var [showDescriptionForVideo, setShowDescriptionForVideo] = useState('')

  var [showTagsModal, setShowTagsModal] = useState(false)
  var [showTags, setShowTags] = useState([])
  var [showTagsForVideo, setShowTagsForVideo] = useState('')

  var [showSEOScoreModal, setShowSEOScoreModal] = useState(false)
  var [showSEOScore, setShowSEOScore] = useState({
    good_messages: [],
    bad_messages: []
  })
  var [showSEOScoreForVideo, setShowSEOScoreForVideo] = useState('')

  useEffect(() => {
    setIsFetching(true)
    setIsError(false)
    axios
      .get(APIEndpointDomain + '/all-youtube-videos', {
        params: {},
        headers: {
          'ngrok-skip-browser-warning': 'any',
          'Access-Control-Allow-Origin': '*',
          //   Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        console.log(response)
        setIsFetching(false)
        setAPIData(response.data['videos'])
        setTotalAPICost(response.data['total_spend'])
        setIsError(false)
      })
      .catch(error => {
        console.log(error)
        setIsFetching(false)
        setIsError(true)
        checkAuthenticationInResponse(Number(error.response.status))
      })
  }, [])

  function groupingFilteration (data, grouping) {
    if (grouping == 'All Groupings') {
      return data
    } else {
      return data.filter(row => String(row.custom_tags).includes(grouping))
    }
  }

  useEffect(() => {
    if (selectedRankingFilter == 'All Rank Videos') {
      setData(groupingFilteration(apiData, selectedGroupingFilter))
    } else if (selectedRankingFilter == '1-5 Rank Videos') {
      setData(
        groupingFilteration(apiData, selectedGroupingFilter).filter(
          row => row.ranking >= 1 && row.ranking <= 5
        )
      )
    } else if (selectedRankingFilter == '6-10 Rank Videos') {
      setData(
        groupingFilteration(apiData, selectedGroupingFilter).filter(
          row => row.ranking >= 6 && row.ranking <= 10
        )
      )
    } else if (selectedRankingFilter == '11-20 Rank Videos') {
      setData(
        groupingFilteration(apiData, selectedGroupingFilter).filter(
          row => row.ranking >= 11 && row.ranking <= 20
        )
      )
    } else if (selectedRankingFilter == '20+ Rank Videos') {
      setData(
        groupingFilteration(apiData, selectedGroupingFilter).filter(
          row => row.ranking > 20 || String(row.ranking).includes('>')
        )
      )
    }
  }, [apiData, selectedRankingFilter, selectedGroupingFilter])

  // function updateAllRankings () {
  //   setIsUpdatingAllRankings(true)

  //   axios
  //     .get(APIEndpointDomain + '/youtube/update-all-video-rankings', {
  //       params: {},
  //       headers: {
  //         'ngrok-skip-browser-warning': 'any',
  //         'Access-Control-Allow-Origin': '*',
  //         //   Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  //         Authorization: `Bearer ${localStorage.getItem('access_token')}`
  //       }
  //     })
  //     .then(response => {
  //       setData(response.data['videos'])
  //       setIsUpdatingAllRankings(false)
  //       setShowAPIStatusModal(true)
  //       setAPIStatusMessage('Updated all your video rankings!😀')
  //     })
  //     .catch(error => {
  //       console.log(error)
  //       setIsUpdatingAllRankings(false)
  //       setShowAPIStatusModal(true)
  //       setAPIStatusMessage('Failed to update all your video rankings!')
  //     })
  // }

  function updateAllVideos () {
    setIsUpdatingAllVideos(true)

    axios
      .get(APIEndpointDomain + '/youtube/update-all-videos', {
        params: {},
        headers: {
          'ngrok-skip-browser-warning': 'any',
          'Access-Control-Allow-Origin': '*',
          //   Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        setData(response.data['videos'])
        setIsUpdatingAllVideos(false)
        setShowAPIStatusModal(true)
        setAPIStatusMessage('Updated all your videos!😀')
      })
      .catch(error => {
        console.log(error)
        setIsUpdatingAllVideos(false)
        setShowAPIStatusModal(true)
        setAPIStatusMessage('Failed to update your videos!')
      })
  }

  // function updateRanking (videoId) {
  //   setIsFetchingRanking(true)
  //   setIsUpatingForVideo([videoId])

  //   axios
  //     .get(APIEndpointDomain + '/youtube/refresh-video-ranking', {
  //       params: {
  //         video_id: videoId
  //       },
  //       headers: {
  //         'ngrok-skip-browser-warning': 'any',
  //         'Access-Control-Allow-Origin': '*',
  //         //   Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  //         Authorization: `Bearer ${localStorage.getItem('access_token')}`
  //       }
  //     })
  //     .then(response => {
  //       setData(response.data['videos'])
  //       setIsFetchingRanking(false)
  //       setIsUpatingForVideo([])
  //     })
  //     .catch(error => {
  //       console.log(error)
  //       setShowAPIStatusModal(true)
  //       setAPIStatusMessage('Failed to get ranking!')
  //       setData(data)
  //       setIsFetchingRanking(false)
  //       setIsUpatingForVideo([])
  //     })
  // }

  function updateFocusKeyword (videoId, newKeyword) {
    if (
      newKeyword.trim() === '' ||
      newKeyword == undefined ||
      newKeyword === null
    ) {
      return null
    } else {
      axios
        .get(APIEndpointDomain + '/youtube/update-focus-keyword', {
          params: {
            video_id: videoId,
            new_keyword: newKeyword
          },
          headers: {
            'ngrok-skip-browser-warning': 'any',
            'Access-Control-Allow-Origin': '*',
            //   Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            Authorization: `Bearer ${localStorage.getItem('access_token')}`
          }
        })
        .then(response => {})
        .catch(error => {
          console.log(error)
          setShowAPIStatusModal(true)
          setAPIStatusMessage('Failed to update your focus keyword!')
          setAPIData(data)
        })
    }
  }

  function updateSecondaryKeyword (videoId, newKeyword) {
    if (
      newKeyword.trim() === '' ||
      newKeyword == undefined ||
      newKeyword === null
    ) {
      return null
    } else {
      axios
        .get(APIEndpointDomain + '/youtube/update-secondary-keyword', {
          params: {
            video_id: videoId,
            new_keyword: newKeyword
          },
          headers: {
            'ngrok-skip-browser-warning': 'any',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`
          }
        })
        .then(response => {})
        .catch(error => {
          console.log(error)
          setShowAPIStatusModal(true)
          setAPIStatusMessage('Failed to update your focus keyword!')
          setAPIData(data)
        })
    }
  }

  function updateNotes (videoId, newNotes) {
    if (newNotes.trim() === '' || newNotes === undefined || newNotes === null) {
      return null
    } else {
      axios
        .post(
          APIEndpointDomain + '/youtube/update-video-notes',
          {
            video_id: videoId,
            new_notes: newNotes
          },
          {
            headers: {
              'ngrok-skip-browser-warning': 'any',
              'Access-Control-Allow-Origin': '*',
              //   Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              Authorization: `Bearer ${localStorage.getItem('access_token')}`
            }
          }
        )
        .then(response => {
          console.log(response)
        })
        .catch(error => {
          console.log(error)
          setShowAPIStatusModal(true)
          setAPIStatusMessage('Failed to add this note!')
          setAPIData(data)
        })
    }
  }

  function fetchTop5VideosForKeyword (focus_keyword) {
    setShowTopRankingVideosModal(true)
    setShowTop5VideosForFocusKeyword(focus_keyword)

    axios
      .get(APIEndpointDomain + '/youtube/get-top-5-ranking-videos', {
        params: {
          focus_keyword: focus_keyword
        },
        headers: {
          'ngrok-skip-browser-warning': 'any',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        console.log(response)
        setTopRankingVideos(response.data['data'])
      })
      .catch(error => {
        console.log(error)
        setShowAPIStatusModal(true)
        setAPIStatusMessage('Failed to get top 5 ranking videos!')
        setTopRankingVideos([])
      })
  }

  var [totalAPICost, setTotalAPICost] = useState(0)

  function getAIRankingImprovementRecommendations (
    video_id,
    focus_keyword,
    video_name
  ) {
    setIsFetchingAIRecommendations(true)
    setShowAIRecommendationMessageForVideo(video_name)
    setShowAIRecommendationMessageForKeyword(focus_keyword)
    setShowAIRecommendationsModal(true)

    axios
      .get(APIEndpointDomain + '/youtube/ranking-improvement-ai-suggestions', {
        params: {
          focus_keyword: focus_keyword,
          video_id: video_id
        },
        headers: {
          'ngrok-skip-browser-warning': 'any',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        console.log(response)
        setIsFetchingAIRecommendations(false)
        setShowAPIStatusModal(false)
        setAIRecommendationMessage(response.data['data']['content'])
        setRecommendationCost(response.data['spend'])
        setTotalAPICost(response.data['total_spend'])
      })
      .catch(error => {
        console.log(error)
        setShowAIRecommendationsModal(false)
        setIsFetchingAIRecommendations(false)
        setShowAPIStatusModal(true)
        setAPIStatusMessage('Failed to get AI recommendations!')
      })
  }

  function cellRenderer (params) {
    var key = params.colDef['field']
    var row = params.data
    var data = params.data[key]
    var pinned = Object.keys(params.node).includes('rowPinned') ? true : false

    if (key === 'thumbnail_url') {
      return (
        <img
          onClick={() => window.open(params.data['url'])}
          src={data}
          alt='new'
        />
      )
    } else if (key === 'custom_tags') {
      return (
        <ShadCNButton
          className='bg-transparent'
          // size='sm'
          onClick={() => {
            setShowingCustomTagModalForData(row)
            setInputtedClusterName(
              data && data.length > 0 ? data.join(', ') : ''
            )
            setShowCustomTagModal(true)
          }}
        >
          {data && data.length > 0 && String(data).trim() !== '' ? (
            <>{data.join(', ')}</>
          ) : (
            <FontAwesomeIcon icon={faPenToSquare}></FontAwesomeIcon>
          )}
        </ShadCNButton>
      )
    } else if (key === 'url') {
      return (
        <Button size='sm' variant='dark' onClick={() => window.open(data)}>
          View Video
        </Button>
      )
    } else if (key === 'ranking') {
      return (
        <p>
          {data}{' '}
          <Badge
            bg={
              row['ranking_change'] && row['has_improved_ranking']
                ? 'success'
                : (row['ranking_change'] &&
                    !row['has_improved_ranking'] &&
                    Math.round(row['ranking_change']) !== 0) ||
                  (data && data.includes('>'))
                ? 'danger'
                : 'warning'
            }
          >
            {data && data.includes('>')
              ? 'Poor'
              : row['ranking_change'] &&
                row['has_improved_ranking'] &&
                row['ranking_change'] != -999
              ? Math.round(row['ranking_change'])
              : row['ranking_change'] == -999
              ? 'High'
              : row['ranking_change'] &&
                !row['has_improved_ranking'] &&
                Math.round(row['ranking_change']) !== 0 &&
                row['ranking_change'] !== 999
              ? '+' + Math.round(row['ranking_change'])
              : row['ranking_change'] == 999
              ? 'Poor'
              : Math.round(row['ranking_change']) === 0
              ? 0
              : ''}
          </Badge>
        </p>
      )
    } else if (key === 'secondary_keyword') {
      return (
        <p
          style={{
            textAlign: 'center'
            // minWidth: "250px",
          }}
          // className="other-columns table-light"
          contentEditable
          onBlur={e => {
            updateSecondaryKeyword(row['video_id'], e.target.textContent)
          }}
        >
          {data}
        </p>
      )
    } else if (key === 'focus_keyword') {
      return (
        <>
          <p
            style={{
              textAlign: 'center'
              // minWidth: "250px",
            }}
            // className="other-columns table-light"
            contentEditable
            onBlur={e => {
              updateFocusKeyword(row['video_id'], e.target.textContent)
            }}
          >
            {data}
          </p>
          {data ? (
            <>
              <button
                onClick={() =>
                  window.open(
                    'https://www.youtube.com/results?search_query=' +
                      String(data).split(' ').join('+')
                  )
                }
                className='px-2 py-1 rounded-md border border-black bg-white text-neutarl-700 text-sm hover:shadow-[4px_4px_0px_0px_rgba(0,0,0)] transition duration-200'
              >
                <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
              </button>
              <button
                onClick={() => fetchTop5VideosForKeyword(data)}
                className='px-2 py-1 rounded-md border border-black bg-white text-neutarl-700 text-sm hover:shadow-[4px_4px_0px_0px_rgba(0,0,0)] transition duration-200'
              >
                <FontAwesomeIcon icon={faRankingStar}></FontAwesomeIcon>
              </button>
              {row.ranking.includes('>') || row.ranking > 5 ? (
                <button
                  onClick={() =>
                    getAIRankingImprovementRecommendations(
                      row['video_id'],
                      data,
                      row['title']
                    )
                  }
                  className='px-2 py-1 rounded-md border border-black bg-white text-neutarl-700 text-sm hover:shadow-[4px_4px_0px_0px_rgba(0,0,0)] transition duration-200'
                >
                  <FontAwesomeIcon
                    shake={
                      isFetchingAIRecommendations &&
                      showAIRecommendationMessageForVideo == row['title']
                    }
                    icon={faRobot}
                  ></FontAwesomeIcon>
                </button>
              ) : null}
            </>
          ) : null}
        </>
      )
    } else if (key === 'notes') {
      return (
        <p
          style={{
            textAlign: 'center',
            minHeight: '250px'
            // lineHeight: '1.2'
          }}
          // className="other-columns table-light"
          contentEditable
          onBlur={e => {
            updateNotes(row['video_id'], e.target.textContent)
          }}
        >
          {data}
        </p>
      )
    } else if (key === 'description') {
      return (
        <p
          variant='light'
          size='sm'
          onClick={() => {
            setShowDescription(data.split('\\n'))
            setShowDescriptionForVideo(row.title)
            setShowDescriptionModal(true)
          }}
        >
          {data}
        </p>
      )
    } else if (key === 'tags') {
      return (
        <p
          variant='light'
          size='sm'
          onClick={() => {
            setShowTags(data)
            setShowTagsForVideo(row.title)
            setShowTagsModal(true)
          }}
        >
          {data.map(tag => tag + ', ')}
        </p>
      )
    } else if (key === 'seo_score') {
      return (
        <p
          variant='light'
          size='sm'
          onClick={() => {
            setShowSEOScore({
              good_messages: row.seo_good_messages,
              bad_messages: row.seo_bad_messages
            })
            setShowSEOScoreForVideo(row.title)
            setShowSEOScoreModal(true)
          }}
        >
          {data}
        </p>
      )
    } else if (key === 'ranking_change') {
      return (
        <p variant='light' size='sm'>
          {data === 999
            ? 'NA'
            : data === -999
            ? 0
            : String(row['ranking']).includes('>')
            ? 'NA'
            : Math.round(data) > 0
            ? '+' + Math.round(data)
            : Math.round(data)}
        </p>
      )
    } else {
      return <p>{data}</p>
    }
  }

  var headers = [
    {
      headerName: 'Focus Keyword',
      field: 'focus_keyword',
      filter: 'agTextColumnFilter',
      floatingFilter: false,
      filterParams: {
        caseSensitive: false,
        defaultOption: 'contains'
      },
      // editable: true,
      // cellEditor: "agTextCellEditor",
      // cellEditorParams: {
      //   maxLength: 10000,
      // },
      // wrapText: true,
      autoHeight: true,
      autoWidth: true,
      pinned: 'left',
      cellRenderer: cellRenderer,
      width: 200
    },

    {
      headerName: 'Secondary Keyword',
      field: 'secondary_keyword',
      filter: 'agTextColumnFilter',
      floatingFilter: false,
      filterParams: {
        caseSensitive: false,
        defaultOption: 'contains'
      },
      autoHeight: true,
      autoWidth: true,
      pinned: 'left',
      cellRenderer: cellRenderer,
      width: 200
    },

    // {
    //   headerName: 'Notes',
    //   field: 'notes',
    //   filter: 'agTextColumnFilter',
    //   floatingFilter: false,
    //   cellRenderer: cellRenderer
    // },

    {
      headerName: 'Ranking',
      field: 'ranking',
      filter: 'agNumberColumnFilter',
      floatingFilter: false,
      cellRenderer: cellRenderer,
      pinned: 'left',
      width: 130
    },

    {
      headerName: 'SEO Score',
      field: 'seo_score',
      filter: 'agNumberColumnFilter',
      floatingFilter: false,
      cellRenderer: cellRenderer,
      pinned: 'left',
      width: 130
    },

    {
      headerName: 'Thumbnail',
      field: 'thumbnail_url',
      filter: 'agTextColumnFilter',
      floatingFilter: false,
      cellRenderer: cellRenderer,
      autoHeight: true,
      pinned: 'left'
    },

    {
      headerName: 'Video Title',
      field: 'title',
      filter: 'agTextColumnFilter',
      floatingFilter: false,
      width: 400
      // pinned: "left"
    },

    {
      headerName: 'Custom Tags',
      field: 'custom_tags',
      filter: 'agTextColumnFilter',
      floatingFilter: false,
      editable: true,
      cellRenderer: cellRenderer,
      autoHeight: true,
      autoWidth: true
    },

    // {
    //   headerName: "URL",
    //   field: "url",
    //   filter: "agTextColumnFilter",
    //   floatingFilter: true,
    //   cellRenderer: cellRenderer
    // },

    {
      headerName: 'Views',
      field: 'view_count',
      filter: 'agNumberColumnFilter',
      floatingFilter: false,
      width: 130
    },

    {
      headerName: 'Video Length',
      field: 'video_length',
      filter: 'agNumberColumnFilter',
      floatingFilter: false,
      width: 130
    },

    {
      headerName: 'Publish Date',
      field: 'publish_time',
      filter: 'agDateColumnFilter',
      floatingFilter: false,
      width: 140
    },

    {
      headerName: 'Tags',
      field: 'tags',
      filter: 'agTextColumnFilter',
      floatingFilter: false,
      cellRenderer: cellRenderer,
      width: 130
    },

    {
      headerName: 'Description',
      field: 'description',
      filter: 'agTextColumnFilter',
      floatingFilter: false,
      editable: true,
      cellRenderer: cellRenderer,
      autoHeight: true,
      autoWidth: true
    }
  ]

  //
  const defaultColDef = useMemo(() => {
    return {
      width: 210,
      filter: true,
      cellStyle: { fontSize: '14px', textAlign: 'center' }
    }
  }, [])

  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), [])
  //
  const rowStyle = { background: '#eaeaea' }

  const getRowStyle = params => {
    if (params.node.rowIndex % 2 === 0) {
      return { background: '#f1f5f9' }
    } else {
      return { background: '#f8fafc' }
    }
  }

  return (
    <div className='h-[50rem] h-screen w-screen w-full dark:bg-black bg-white  dark:bg-grid-white/[0.2] bg-grid-black/[0.2] relative flex items-center justify-center'>
      <div className='absolute h-screen w-screen pointer-events-null inset-0 flex items-center justify-center dark:bg-black bg-white [mask-image:radial-gradient(ellipse_at_center,transparent_20%,black)]'></div>
      <div className='grid grid-rows-2 gap-0 bg-opacity-0 h-screen w-screen'>
        <div className='w-screen bg-opacity-0'>
          {' '}
          <Top5VideosModal
            showModal={showTopRankingVideosModal}
            setShowModal={setShowTopRankingVideosModal}
            data={topRankingVideos}
            setData={setTopRankingVideos}
            focusKeyword={showTop5VideosForFocusKeyword}
          ></Top5VideosModal>
          <AIRecommendationModal
            showModal={showAIRecommendationsModal}
            setShowModal={setShowAIRecommendationsModal}
            data={AIRecommendationMessage}
            setData={setAIRecommendationMessage}
            focusKeyword={showAIRecommendationMessageForKeyword}
            videoTitle={showAIRecommendationMessageForVideo}
            cost={recommendationCost}
            setCost={setRecommendationCost}
          />
          <Modal size={'lg'} show={showDescriptionModal}>
            <Modal.Header
              closeButton
              onClick={() => {
                setShowDescriptionModal(false)
              }}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                backgroundColor: '#525252',
                color: 'white'
              }}
            >
              <Modal.Title>Description - {showDescriptionForVideo}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {showDescription.map(line => (
                <p contentEditable>
                  {line} <br />
                </p>
              ))}
            </Modal.Body>
          </Modal>
          <Modal size={'lg'} show={showTagsModal}>
            <Modal.Header
              closeButton
              onClick={() => {
                setShowTagsModal(false)
              }}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                backgroundColor: '#525252',
                color: 'white'
              }}
            >
              <Modal.Title>Tags - {showTagsForVideo}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* {
            showTags.map((tag) => 
            <p style={{lineHeight: "1"}}>
              {tag} <br/>
            </p>)
          } */}

              {showTags.map(tag => {
                return (
                  <ListGroup>
                    <ListGroup.Item
                      style={{
                        backgroundColor: '#f0f0f0'
                      }}
                    >
                      {tag}
                    </ListGroup.Item>
                  </ListGroup>
                )
              })}
            </Modal.Body>
          </Modal>
          <Modal size={'lg'} show={showSEOScoreModal}>
            <Modal.Header
              closeButton
              onClick={() => {
                setShowSEOScoreModal(false)
              }}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                backgroundColor: '#525252',
                color: 'white'
              }}
            >
              <Modal.Title>
                <strong>#SEOReportCard</strong> - {showSEOScoreForVideo}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{
                // display: "flex",
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',

                backgroundColor: '#f7fbff'
              }}
            >
              <div>
                {showSEOScore['good_messages'].length > 0 ? (
                  <>
                    <h5>What have you done well?</h5>
                    {showSEOScore['good_messages'].map(message => {
                      return (
                        <ListGroup>
                          <ListGroup.Item
                            style={{
                              backgroundColor: '#aff5ae'
                            }}
                          >
                            {message}
                          </ListGroup.Item>
                        </ListGroup>
                      )
                    })}
                    <br></br>
                  </>
                ) : null}

                {showSEOScore['bad_messages'].length > 0 ? (
                  <>
                    <h5>Areas of improvement</h5>
                    {showSEOScore['bad_messages'].map(message => {
                      return (
                        <ListGroup>
                          <ListGroup.Item
                            style={{
                              backgroundColor: '#ffe4db'
                            }}
                          >
                            {message}
                          </ListGroup.Item>
                        </ListGroup>
                      )
                    })}
                  </>
                ) : null}
              </div>
              {/* </div> */}
            </Modal.Body>
          </Modal>
          <Navbar
            fixed='top'
            className='justify-content-between'
            style={{ borderWidth: 0 }}
          >
            <div className='flex mt-2 justify-between items-center w-screen'>
              <Dropdown className='ml-3'>
                <Dropdown.Toggle variant='secondary' id='dropdown-basic'>
                  {selectedRankingFilter}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={e => setSelectedRankingFilter(e.target.text)}
                  >
                    All Rank Videos
                  </Dropdown.Item>

                  <Dropdown.Item
                    onClick={e => setSelectedRankingFilter(e.target.text)}
                  >
                    1-5 Rank Videos
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={e => setSelectedRankingFilter(e.target.text)}
                  >
                    6-10 Rank Videos
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={e => setSelectedRankingFilter(e.target.text)}
                  >
                    11-20 Rank Videos
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={e => setSelectedRankingFilter(e.target.text)}
                  >
                    20+ Rank Videos
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown className=''>
                <Dropdown.Toggle variant='secondary' id='dropdown-basic'>
                  {selectedGroupingFilter}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={e => setSelectedGroupingFilter(e.target.text)}
                  >
                    All Groupings
                  </Dropdown.Item>

                  <Dropdown.Item
                    onClick={e => setSelectedGroupingFilter(e.target.text)}
                  >
                    MBA
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={e => setSelectedGroupingFilter(e.target.text)}
                  >
                    MiM
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={e => setSelectedGroupingFilter(e.target.text)}
                  >
                    MFin
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={e => setSelectedGroupingFilter(e.target.text)}
                  >
                    MSBA
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={e => setSelectedGroupingFilter(e.target.text)}
                  >
                    MeM
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={e => setSelectedGroupingFilter(e.target.text)}
                  >
                    Test Prep
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <h4 className='text-3xl text-slate-600 font-bold mx-auto'>
                YouTube Analytics
              </h4>
              <Button variant='secondary'>Total Videos: {data.length}</Button>

              <Button className='mr-3' variant='secondary' size='md'>
                <CSVLink filename='youtube-analytics.csv' data={data}>
                  <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                </CSVLink>
              </Button>
            </div>
          </Navbar>
          <div
            style={{ marginTop: '75px', minWidth: '100%' }}
            className='public-youtube-report-primary-table-body-container'
          >
            {!isFetching && !isError ? (
              <div style={gridStyle} className={'ag-theme-quartz'}>
                <AssignCustomTagModal
                  show={showCustomTagModal}
                  showSetter={setShowCustomTagModal}
                  APIEndpointDomain={APIEndpointDomain}
                  rowInformation={showingCustomTagModalForData}
                  clusterNames={['MBA', 'MiM', 'MSBA', 'MeM', 'MFin']}
                  inputtedName={InputtedClusterName}
                  setInputtedName={setInputtedClusterName}
                  setAPIData={setAPIData}
                  apiData={apiData}
                ></AssignCustomTagModal>

                <APIRequestStatusModal
                  show={showAPIStatusModal}
                  setShow={setShowAPIStatusModal}
                  message={APIStatusMessage}
                />
                <AgGridReact
                  rowData={data}
                  columnDefs={headers}
                  defaultColDef={defaultColDef}
                  rowDragManaged={true}
                  rowSelection='multiple'
                  rowDragMultiRow={true}
                  rowStyle={rowStyle}
                  getRowStyle={getRowStyle}
                />
              </div>
            ) : isFetching ? (
              <MultiStepLoader
                className='bg-opacity-0'
                loadingStates={loadingStates}
                loading={isFetching}
                duration={750}
              ></MultiStepLoader>
            ) : isError ? (
              <div className='flex justify-center items-center text-center h-full'>
                <TypewriterEffect
                  className='text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-400 to-neutral-500 py-8'
                  words={'Server returned no data!'.split(' ').map(word => {
                    return { text: word }
                  })}
                ></TypewriterEffect>
              </div>
            ) : (
              <div className='flex justify-center items-center text-center h-full'>
                <TypewriterEffect
                  className='text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-400 to-neutral-500 py-8'
                  words={'Unrecognized error occured!'.split(' ').map(word => {
                    return { text: word }
                  })}
                ></TypewriterEffect>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default YouTubeVideosScreen
