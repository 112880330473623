import axios from 'axios'
import { useEffect, useState, useMemo } from 'react'
import '../css/YouTubeVideosScreen.css'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css' // Core CSS
import 'ag-grid-community/styles/ag-theme-quartz.css' // Theme
import { Navbar } from 'react-bootstrap'
import Dropdown from 'react-bootstrap/Dropdown'
import LoadingMessage from '../components/CommonSubComponents/LoadingMessage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsRotate, faUpDown } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'react-bootstrap'
import ServerReturnedNoDataMessage from '../components/CommonSubComponents/ServerReturnedNoDataMessage'
import { io } from 'socket.io-client'
import Badge from 'react-bootstrap/Badge'
import InputGroup from 'react-bootstrap/InputGroup'
import { Form } from 'react-bootstrap'
import { MultiStepLoader } from '../components/ui/multi-step-loader'

function SalesPOCLiveTracker ({
  checkAuthenticationInResponse,
  APIEndpointDomain
}) {
  var today = new Date()
  var start = new Date(new Date().setDate(today.getDate() - 1))
    .toJSON()
    .slice(0, 10)

  var loadingStates = [
    {
      text: 'Fetching live data'
    }
  ]

  var [comparisonDate, setComparisonDate] = useState(start)

  var [selectedLeadType, setSelectedLeadType] = useState('Premium')
  var [data, setData] = useState([])
  var [total, setTotal] = useState([])
  var [pinnedRowData, setPinnedRowData] = useState([])
  var [pinnedBottomRowData, setPinnedBottomRowData] = useState([])
  var [showIndicators, setShowIndicators] = useState(false)

  var [historyData, setHistoryData] = useState([])
  var [historyTotal, setHistoryTotal] = useState([])
  var [historyPinnedRowData, setHistoryPinnedRowData] = useState([])
  var [historyPinnedBottomRowData, setHistoryPinnedBottomRowData] = useState([])

  var [isFetchingSalesPerformanceStats, setIsFetchingSalesPerformanceStats] =
    useState(false)
  var [isServerError, setIsServerError] = useState(false)
  var [slots, setSlots] = useState(null)
  function handleStartDate (e) {
    setComparisonDate(e.target.value)
  }

  function getLiveSalesPerformanceStats (selectedLeadType, comparisonDate) {
    setIsFetchingSalesPerformanceStats(true)
    axios
      .get(APIEndpointDomain + '/sales/poc-performance/today', {
        params: {
          lead_type: selectedLeadType.toLowerCase(),
          comparison_date: comparisonDate
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        console.log(response)
        setData(response.data['data'])
        setTotal(response.data['total'])
        setPinnedRowData(response.data['pinned_data'])
        setPinnedBottomRowData(response.data['pinned_bottom_data'])

        setHistoryData(response.data['history']['data'])
        setHistoryTotal(response.data['history']['total'])
        setHistoryPinnedRowData(response.data['history']['pinned_data'])
        setHistoryPinnedBottomRowData(
          response.data['history']['pinned_bottom_data']
        )

        setIsFetchingSalesPerformanceStats(false)
        setIsServerError(false)
      })
      .catch(error => {
        checkAuthenticationInResponse(Number(error.response.status))
        console.log(error)
        setData([])
        setTotal([])
        setIsFetchingSalesPerformanceStats(false)
        setIsServerError(true)
        setPinnedRowData([])
        setPinnedBottomRowData([])

        setHistoryData([])
        setHistoryTotal([])
        setHistoryPinnedRowData([])
        setHistoryPinnedBottomRowData([])
      })
  }

  function getLiveSlots (selectedLeadType) {
    axios
      .get(APIEndpointDomain + '/support/slots', {
        params: {
          lead_type: selectedLeadType.toLowerCase(),
          comparison_date: comparisonDate
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        console.log(response)
        setSlots(response.data['data'])
      })
      .catch(error => {
        checkAuthenticationInResponse(Number(error.response.status))
        console.log(error)
      })
  }

  var [count, setCount] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCount(count + 1)
    }, 30000)

    getLiveSalesPerformanceStats(selectedLeadType, comparisonDate)
    getLiveSlots(selectedLeadType)
    return () => clearInterval(interval)
  }, [selectedLeadType, count, comparisonDate])

  const defaultColDef = useMemo(() => {
    return {
      //   height: '300px',
      filter: true,
      cellStyle: { fontSize: '14px', textAlign: 'center' }
    }
  }, [])

  const gridStyle = useMemo(
    () => ({ height: '100%', width: '100%', backgroundColor: '#e8ebe9' }),
    []
  )
  //
  console.log('total', total)
  const rowStyle = { background: '#eaeaea' }

  const getRowStyle = params => {
    var pipeline = params.data['pipeline__name']

    if (pipeline.toLowerCase() == 'mentr-me') {
      return { background: '#e6fae1', fontWeight: 'bold' }
    } else if (pipeline.toLowerCase() == 'mim-essay') {
      return { background: '#e6fae1', fontWeight: 'bold' }
    } else if (pipeline.toLowerCase() == 'non-website') {
      return { background: '#e6fae1', fontWeight: 'bold' }
    } else if (
      pipeline.toLowerCase() == 'uk cpa' ||
      pipeline.toLowerCase() == 'usa cpa' ||
      pipeline.toLowerCase() == 'usa premium' ||
      pipeline.toLowerCase() == '2024 intake' ||
      pipeline.toLowerCase() == '2025 intake' ||
      pipeline.toLowerCase() == 'other intakes'
    ) {
      return { background: '#fafae1', fontWeight: 'bold' }
    } else if (params.node.rowIndex % 2 === 0) {
      return { background: '#f6f6f6' }
    }
  }

  function cellRenderer (params) {
    var key = params.colDef['field']
    var row = params.data
    var data = params.data[key]
    var historyEquivalentObject = historyData.find(
      historyObject => historyObject['pipeline__name'] == row['pipeline__name']
    )
      ? historyData.find(
          historyObject =>
            historyObject['pipeline__name'] == row['pipeline__name']
        )
      : historyPinnedBottomRowData.find(
          historyObject =>
            historyObject['pipeline__name'] == row['pipeline__name']
        )
      ? historyPinnedBottomRowData.find(
          historyObject =>
            historyObject['pipeline__name'] == row['pipeline__name']
        )
      : historyPinnedRowData.find(
          historyObject =>
            historyObject['pipeline__name'] == row['pipeline__name']
        )
      ? historyPinnedRowData.find(
          historyObject =>
            historyObject['pipeline__name'] == row['pipeline__name']
        )
      : null

    if (key.includes('rate')) {
      return (
        <p>
          {data}
          {'%'}
        </p>
      )
    } else {
      return (
        <p>
          {data}{' '}
          {historyEquivalentObject && showIndicators ? (
            <Badge
              // className=''
              bg={
                data - historyEquivalentObject[key] == 0
                  ? 'warning'
                  : data - historyEquivalentObject[key] >= 0 &&
                    key !== 'lead_lost' &&
                    key !== 'dnp' &&
                    key !== 'pending_call'
                  ? 'success'
                  : data - historyEquivalentObject[key] >= 0 &&
                    (key == 'lead_lost' || key == 'dnp') &&
                    key !== 'pending_call'
                  ? 'danger'
                  : data - historyEquivalentObject[key] < 0 &&
                    key !== 'lead_lost' &&
                    key !== 'dnp' &&
                    key !== 'pending_call'
                  ? 'danger'
                  : data - historyEquivalentObject[key] < 0 &&
                    (key == 'lead_lost' || key == 'dnp') &&
                    key !== 'pending_call'
                  ? 'success'
                  : key == 'pending_call'
                  ? 'warning'
                  : null
              }
            >
              {data - historyEquivalentObject[key] >= 0
                ? '+' + String(data - historyEquivalentObject[key])
                : String(data - historyEquivalentObject[key])}
            </Badge>
          ) : null}
        </p>
      )
    }
  }

  var headers =
    data.length > 0 && data
      ? Object.keys(data[0])
          .filter(key => key !== 'pipeline__name')
          .map(key => {
            if (key !== 'pipeline__name') {
              return {
                headerName:
                  !key.includes('rate') && !key.includes('name')
                    ? String(key).replace(/_/g, ' ') +
                      ' (' +
                      total[key] +
                      ') (' +
                      String(
                        total[key] - historyTotal[key] >= 0
                          ? '+' + String(total[key] - historyTotal[key])
                          : total[key] - historyTotal[key]
                      ) +
                      ')'
                    : String(key).replace('_', ' '),
                field: key,
                filter: 'agNumberColumnFilter',
                floatingFilter: true,
                cellRenderer: cellRenderer
                // width: 150
                // autoWidth: true
                // height: 300
              }
            }
          })
      : []

  headers = [
    {
      headerName: 'sales poc',
      field: 'pipeline__name',
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      filterParams: {
        caseSensitive: false,
        defaultOption: 'contains'
      },
      //   autoHeight: true,
      autoWidth: true,
      //   height: 600,
      pinned: 'left',
      rowDrag: params => {
        return !['MentR-Me', 'MiM-Essay', 'non-website'].includes(
          params.data.pipeline__name
        )
      }
    },
    ...headers
  ]

  return (
    <div>
      <Navbar
        fixed='top'
        className='justify-content-between'
        style={{ borderWidth: 0, marginTop: '10px' }}
      >
        <div
          className='d-flex align-items-center'
          style={{ marginLeft: '20px' }}
        >
          <Button
            variant={showIndicators ? 'success' : 'dark'}
            onClick={() => {
              setShowIndicators(!showIndicators)
            }}
          >
            <FontAwesomeIcon icon={faUpDown}></FontAwesomeIcon>
          </Button>

          <InputGroup
            style={{ maxWidth: '300px', marginLeft: '10px' }}
            className=''
          >
            <InputGroup.Text id='basic-addon1'>Compare with</InputGroup.Text>
            <Form.Control
              disabled={isFetchingSalesPerformanceStats}
              defaultValue={start}
              onSelect={handleStartDate}
              type='date'
              size='sm'
            />
          </InputGroup>
        </div>

        <div className='d-flex align-items-center justify-content-center'>
          <h3>
            <strong style={{ color: '#333' }}>#SalesLiveTracker</strong>
          </h3>
        </div>

        <div
          className='d-flex align-items-center'
          style={{ marginRight: '20px' }}
        >
          <Button onClick={() => getLiveSlots(selectedLeadType)} variant='dark'>
            Slots: {slots}
          </Button>

          <Dropdown>
            <Dropdown.Toggle variant='dark' id='dropdown-basic'>
              {selectedLeadType}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                disabled={isFetchingSalesPerformanceStats}
                onClick={e => setSelectedLeadType(e.target.text)}
              >
                CPA
              </Dropdown.Item>
              <Dropdown.Item
                disabled={isFetchingSalesPerformanceStats}
                onClick={e => setSelectedLeadType(e.target.text)}
              >
                Premium
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Navbar>

      <div
        style={{
          marginTop: '75px'
          // minWidth: '100%'
        }}
        className='w-screen public-youtube-report-primary-table-body-container'
      >
        {!isFetchingSalesPerformanceStats && !isServerError ? (
          <div style={gridStyle} className={'ag-theme-quartz'}>
            <AgGridReact
              rowData={data}
              columnDefs={headers}
              defaultColDef={defaultColDef}
              rowDragManaged={true}
              rowSelection='multiple'
              rowDragMultiRow={true}
              rowStyle={rowStyle}
              getRowStyle={getRowStyle}
              // pinnedTopRowData={}
              pinnedBottomRowData={pinnedBottomRowData.concat(pinnedRowData)}
            />
          </div>
        ) : isFetchingSalesPerformanceStats ? (
          <MultiStepLoader
            className='bg-opacity-0'
            loadingStates={loadingStates}
            loading={isFetchingSalesPerformanceStats}
            duration={2000}
          ></MultiStepLoader>
        ) : (
          <ServerReturnedNoDataMessage></ServerReturnedNoDataMessage>
        )}
      </div>
    </div>
  )
}

export default SalesPOCLiveTracker
