import axios from 'axios'
import { useEffect, useState, useMemo } from 'react'
import '../css/YouTubeVideosScreen.css'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css' // Core CSS
import 'ag-grid-community/styles/ag-theme-quartz.css' // Theme
import { Navbar } from 'react-bootstrap'
import Dropdown from 'react-bootstrap/Dropdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'react-bootstrap'
import { io } from 'socket.io-client'
import Badge from 'react-bootstrap/Badge'
import InputGroup from 'react-bootstrap/InputGroup'
import { Form } from 'react-bootstrap'
import { MultiStepLoader } from '../components/ui/multi-step-loader'
import { TypewriterEffect } from '../components/ui/typewriter-effect'
import { CSVLink } from 'react-csv'
import { Col } from 'react-bootstrap'

function MarketingSalesSupportScreen ({
  checkAuthenticationInResponse,
  APIEndpointDomain
}) {
  var [selectedLeadType, setSelectedLeadType] = useState('premium')
  var [isFetching, setIsFetching] = useState(false)
  var [isError, setIsError] = useState(false)
  var [data, setData] = useState([])

  var loadingStates = [
    {
      text: 'connecting to server'
    },

    {
      text: 'performing calculations'
    }
  ]

  var [sourceSearch, setSourceSearch] = useState('')

  function handleSourceSearch (e) {
    setSourceSearch(e.target.value)
  }

  function getMarketingSupportSalesData (lead_type) {
    setIsFetching(true)
    setIsError(false)
    axios
      .get(APIEndpointDomain + '/lead-health-today/generate-view', {
        params: {
          lead_type: lead_type
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        console.log(response)
        setData(response.data['data'])
        setIsFetching(false)
        setIsError(false)
      })
      .catch(error => {
        checkAuthenticationInResponse(Number(error.response.status))
        console.log(error)
        setIsFetching(false)
        setIsError(true)
      })
  }

  useEffect(() => {
    getMarketingSupportSalesData(selectedLeadType, sourceSearch)
  }, [selectedLeadType])

  function toTitleCase (str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
  }

  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), [])
  const rowStyle = { background: '#black' }
  const defaultColDef = useMemo(() => {
    return {
      width: 170,
      filter: true,
      cellStyle: { fontSize: '14px', textAlign: 'center' },
      enableValue: true,
      enableRowGroup: true,
      enablePivot: true,
      filterParams: { newRowsAction: 'keep' }
    }
  }, [])

  const getRowStyle = params => {
    var row = params.data

    if (row['needs_attention']) {
      return { background: '#fecaca' }
    } else if (params.node.rowIndex % 2 !== 0) {
      return { background: '#f1f5f9' }
    } else if (params.node.rowIndex % 2 === 0) {
      return { background: '#f8fafc' }
    }
  }

  var headers =
    data && data.length > 0 ? Object.keys(data[0]).map(header => header) : []

  function cellRenderer (params) {
    var key = params.colDef['field']
    var data = params.data[key]

    return <p>{String(data).replace('_', ' ')}</p>
  }

  headers = headers.map(header => {
    return {
      headerName: toTitleCase(header.replace(/_/g, ' ')),
      field: header,
      // onCellClicked: handleCellClick,
      cellRenderer: cellRenderer,
      filter: 'agTextColumnFilter',
      rowDrag: header == 'metric' ? true : false,

      filterParams: {
        caseSensitive: false,
        defaultOption: 'contains'
      },

      autoHeight: true,
      width: window.screen.width / headers.length,
      pinned: ['time_frame'].includes(header) ? 'left' : false
    }
  })

  return (
    <div className='h-[50rem] w-full dark:bg-black bg-white  dark:bg-dot-white/[0.2] bg-dot-black/[0.2] relative flex items-center justify-center'>
      {/* Radial gradient for the container to give a faded look */}
      <div className='absolute pointer-events-none inset-0 flex items-center justify-center dark:bg-black bg-white [mask-image:radial-gradient(ellipse_at_center,transparent_20%,black)]'></div>
      <div className='w-screen bg-opacity-0'>
        <Navbar
          fixed='top'
          style={{
            borderWidth: 0
          }}
        >
          <div className='w-screen '>
            {/* <div className='py-0 '> */}
            <div
              className='content-center'
              style={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Col sm='0'>
                <Dropdown className='ml-3'>
                  <Dropdown.Toggle variant='secondary' id='dropdown-basic'>
                    {selectedLeadType}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={e => setSelectedLeadType(e.target.text)}
                    >
                      premium
                    </Dropdown.Item>

                    <Dropdown.Item
                      onClick={e => setSelectedLeadType(e.target.text)}
                    >
                      cpa
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={e => setSelectedLeadType(e.target.text)}
                    >
                      jobs
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={e => setSelectedLeadType(e.target.text)}
                    >
                      gmat
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>

              <h4 className='text-3xl text-slate-600 font-bold mx-auto'>
                Call Trends
              </h4>
              <Col sm='0'>
                <Button variant='secondary' className='mr-3'>
                  <CSVLink filename='lead-frequency-export.csv' data={data}>
                    <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                  </CSVLink>
                </Button>
              </Col>
            </div>
          </div>
        </Navbar>
        <div className='pt-14 bg-opacity-0 w-screen h-screen '>
          {/* <>hi</> */}

          {!isFetching && !isError && data && data.length > 0 ? (
            <div style={gridStyle} className={'ag-theme-quartz'}>
              <AgGridReact
                rowData={data}
                columnDefs={headers}
                defaultColDef={defaultColDef}
                rowDragManaged={true}
                rowSelection='multiple'
                rowDragMultiRow={true}
                rowStyle={rowStyle}
                getRowStyle={getRowStyle}
                sideBar={true}
              />
            </div>
          ) : isFetching ? (
            <MultiStepLoader
              className='bg-opacity-0'
              loadingStates={loadingStates}
              loading={isFetching}
              duration={500}
            ></MultiStepLoader>
          ) : isError ? (
            <div className='flex justify-center items-center text-center h-full'>
              <TypewriterEffect
                className='text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-400 to-neutral-500 py-8'
                words={'Server returned no data!'.split(' ').map(word => {
                  return { text: word }
                })}
              ></TypewriterEffect>
            </div>
          ) : !isError && !isFetching && data.length == 0 ? (
            <div className='flex justify-center items-center text-center h-full'>
              <TypewriterEffect
                className='text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-400 to-neutral-500 py-8'
                words={'No appointments match the filters!'
                  .split(' ')
                  .map(word => {
                    return { text: word }
                  })}
              ></TypewriterEffect>
            </div>
          ) : (
            <div className='flex justify-center items-center text-center h-full'>
              <TypewriterEffect
                className='text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-400 to-neutral-500 py-8'
                words={'Unrecognized error occured!'.split(' ').map(word => {
                  return { text: word }
                })}
              ></TypewriterEffect>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default MarketingSalesSupportScreen
