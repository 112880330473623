import axios from 'axios'
import { useEffect, useState, useMemo, useRef } from 'react'
import '../css/YouTubeVideosScreen.css'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css' // Core CSS
import 'ag-grid-community/styles/ag-theme-quartz.css' // Theme
import { Navbar } from 'react-bootstrap'
import Dropdown from 'react-bootstrap/Dropdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faUser } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'react-bootstrap'
import { Col } from 'react-bootstrap'
import { Form } from 'react-bootstrap'
import { MultiStepLoader } from '../components/ui/multi-step-loader'
import { CSVLink } from 'react-csv'
import { TypewriterEffect } from '../components/ui/typewriter-effect'
import { ShadCNButton } from '../components/ui/shadcn-button'
import { cn } from '../utils/cn'
import { ToggleButton } from 'react-bootstrap'
import {
  Widget,
  addResponseMessage,
  toggleWidget,
  addUserMessage,
  deleteMessages
} from 'react-chat-widget'
import '../css/GlobalChatbox.css'
import 'react-chat-widget/lib/styles.css'

function KrayaAnalytics ({ checkAuthenticationInResponse, APIEndpointDomain }) {
  var [userImage, setUserImage] = useState(
    'https://i.pinimg.com/736x/b8/38/4d/b8384dc3955cd72a44224e3fd9089745.jpg'
  )
  var [chatboxTitle, setChatboxTitle] = useState('Ask')
  var [chatboxSubtitle, setChatboxSubtitle] = useState("Hint: type 'commands'")

  useEffect(() => {
    fetchAllMessages()
  })

  function handleNewUserMessage (message) {
    axios
      .get(APIEndpointDomain + '/marketingchatapp/process-user-message', {
        params: {
          command: message.toLowerCase()
        },

        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        fetchAllMessages()
        console.log(response)
      })
      .catch(error => {
        checkAuthenticationInResponse(Number(error.response.status))
        console.log(error)
        alert('Message failed to deliver!')
      })
  }

  function fetchAllMessages () {
    axios
      .get(APIEndpointDomain + '/marketingchatapp/generate-initial-view', {
        params: {},

        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })

      .then(response => {
        console.log(response)
        var chats = response.data['data']
        for (let i = 0; i < chats.length; i++) {
          if (chats[i]['sender'] == 'user' && chats[i]['message']) {
            console.log('user_found')
            addUserMessage(chats[i]['message'])
          } else if (chats[i]['message']) {
            console.log('not_user_found')
            addResponseMessage(chats[i]['message'])
          }
        }
      })
      .catch(error => {
        checkAuthenticationInResponse(Number(error.response.status))
        console.log(error)
        alert('Message failed to deliver!')
      })
  }

  var loadingStates = [
    {
      text: 'fetching leads'
    },

    {
      text: 'calculating'
    },

    {
      text: 'finishing up'
    }
  ]
  function isNumber (n) {
    return Number(n) === n
  }
  var [selectedGrouping, setSelectedGrouping] = useState({
    source: true,
    medium: false,
    campaign: false,
    id: false,
    term: false,
    content: false
  })
  var today = new Date()
  var start = new Date(new Date().setDate(today.getDate()))
    .toJSON()
    .slice(0, 10)

  var [startDate, setStartDate] = useState(start)
  var [endDate, setEndDate] = useState(start)
  var [totals, setTotals] = useState({})

  var [selectedLeadType, setSelectedLeadType] = useState('Premium')
  var [data, setData] = useState([])
  var [allData, setAllData] = useState([])
  var [total, setTotal] = useState([])
  var [pinnedRowData, setPinnedRowData] = useState([])
  var [pinnedBottomRowData, setPinnedBottomRowData] = useState([])

  var [isFetchingSalesPerformanceStats, setIsFetchingSalesPerformanceStats] =
    useState(false)
  var [isServerError, setIsServerError] = useState(false)

  function handleStartDate (e) {
    console.log(selectedLeadType)
    if (selectedLeadType == 'Jobs' && e.target.value < '2024-05-20') {
      console.log('bugger')
      alert(`Please select a date on or after 2024-05-20`)
    } else {
      setStartDate(e.target.value)
    }
  }

  function calculateNewColumns (groupedData) {
    groupedData.map(data => {
      var lead_quality =
        data['leads'] !== 0 && data['leads']
          ? (100 * data['qualified']) / (data['leads'] - data['pending'])
          : 0

      var invalid_rate =
        data['leads'] !== 0 && data['leads']
          ? (100 * data['invalid']) / (data['leads'] - data['pending'])
          : 0

      var pending_p =
        data['leads'] !== 0 && data['leads']
          ? (100 * data['pending']) / data['leads']
          : 0
      data['lead_quality'] = Math.round(lead_quality)
      data['invalid_rate'] = Math.round(invalid_rate)
      data['pending_p'] = Math.round(pending_p)
    })

    return groupedData
  }

  useEffect(() => {
    setData(calculateNewColumns(groupByUTMParams(allData)))
  }, [selectedGrouping, allData])

  function getTotals (arr) {
    const sums = {}

    arr.forEach(obj => {
      for (let key in obj) {
        // Skip keys containing '_rate'
        if (key.includes('_rate') || key.includes('name')) {
          continue
        }
        if (sums[key]) {
          sums[key] += obj[key]
        } else {
          sums[key] = obj[key]
        }
      }
    })

    return sums
  }

  console.log(getTotals(data))
  // Output: { key1: 5, key2: 7 }

  useEffect(() => {
    setTotals(getTotals(data))
  }, [data])

  const gridRef = useRef()

  function onFilterChanged () {
    const filteredNodes = gridRef.current.api.getRenderedNodes()
    const filteredData = filteredNodes.map(node => node.data)
    setTotals(getTotals(filteredData))
  }

  function handleEndDate (e) {
    setEndDate(e.target.value)

    if (selectedLeadType == 'Jobs' && e.target.value < '2024-05-20') {
      console.log('bugger')
      alert(`Please select a date on or after 2024-05-20`)
    } else {
      setEndDate(e.target.value)
    }
  }

  function getTableData (selectedLeadType, startDate, endDate) {
    setIsFetchingSalesPerformanceStats(true)
    axios
      .get(APIEndpointDomain + '/kraya-analytics/generate-view', {
        params: {
          lead_type: selectedLeadType.toLowerCase(),
          start_date: startDate,
          end_date: endDate
        },
        headers: {
          'Access-Control-Allow-Origin': '*',

          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        console.log(response)
        setAllData(response.data['data'])
        console.log(response.data['data'])
        // setTotal(response.data['total'])
        // setPinnedRowData(response.data['pinned_data'])
        // setPinnedBottomRowData(response.data['pinned_bottom_data'])
        setIsFetchingSalesPerformanceStats(false)
        setIsServerError(false)
      })
      .catch(error => {
        checkAuthenticationInResponse(Number(error.response.status))

        console.log(error)
        setAllData([])
        setTotal([])
        setIsFetchingSalesPerformanceStats(false)
        setIsServerError(true)
        setPinnedRowData([])
        setPinnedBottomRowData([])
      })
  }

  //   function getUserExportData (selectedLeadType, startDate, endDate) {
  //     setIsFetchingSalesPerformanceStats(true)
  //     axios
  //       .get(APIEndpointDomain + '/calendar-analytics/generate-view', {
  //         params: {
  //           lead_type: selectedLeadType.toLowerCase(),
  //           start: startDate,
  //           end: endDate,
  //           download_request: true
  //         },
  //         headers: {
  //           'Access-Control-Allow-Origin': '*',

  //           Authorization: `Bearer ${localStorage.getItem('access_token')}`
  //         }
  //       })
  //       .then(response => {
  //         console.log(response)
  //         setUserExportData(response.data['data'])
  //       })
  //       .catch(error => {
  //         checkAuthenticationInResponse(Number(error.response.status))

  //         console.log(error)
  //       })
  //   }

  useEffect(() => {
    getTableData(selectedLeadType, startDate, endDate)
    // getUserExportData(selectedLeadType, startDate, endDate)
  }, [selectedLeadType, startDate, endDate])

  //
  const defaultColDef = useMemo(() => {
    return {
      //   height: '300px',
      filter: true,
      cellStyle: { fontSize: '14px', textAlign: 'center' }
    }
  }, [])

  const gridStyle = useMemo(
    () => ({ height: '100%', width: '100%', backgroundColor: '#e8ebe9' }),
    []
  )
  //
  //   console.log('total', total)
  const rowStyle = { background: '#eaeaea' }

  const getRowStyle = params => {
    var pinned = Object.keys(params.node).includes('rowPinned') ? true : false

    if (pinned) {
      return { background: '#2c9c72', color: 'white', fontWeight: 'bold' }
    } else if (params.node.rowIndex % 2 === 0) {
      return { background: '#f6f6f6' }
    }
  }

  function groupByUTMParams (td) {
    if (td && td.length >= 1) {
      var groupings = Object.keys(selectedGrouping).filter(
        key => selectedGrouping[key] === true
      )

      groupings = groupings.map(key => 'utm_' + key)

      var removableColumns = [
        // "name",
        // "utm_source",
        // "utm_medium",
        // "utm_campaign",
        // "utm_id",
        // "utm_term",
        // "utm_content",
      ]

      var identifiers = []
      var groupedData = []
      var all_keys = Object.keys(td[0]).filter(
        key => !removableColumns.includes(key)
      )

      td.map(row => {
        var name = groupings
          .map(param => {
            return row[param]
          })
          .join(' | ')

        if (!identifiers.includes(name)) {
          identifiers.push(name)

          var updatedRow = {}

          all_keys.map(key => {
            updatedRow[key] = row[key]
          })

          updatedRow[name] = name
          updatedRow['name'] = name

          groupedData.push(updatedRow)
        } else if (identifiers.includes(name)) {
          var existingObject = groupedData.filter(
            groupedObject => groupedObject[name] == name
          )[0]

          all_keys.map(key => {
            existingObject[key] = existingObject[key] + row[key]
            existingObject['name'] = name
            existingObject[name] = name
          })
        }
      })
      return groupedData
    } else {
      return []
    }
  }

  function cellRenderer (params) {
    var key = params.colDef['field']
    var data = params.data[key]

    if (key.includes('rate')) {
      return (
        <p>
          {data}
          {'%'}
        </p>
      )
    } else {
      return <p>{data}</p>
    }
  }

  var headers =
    data.length > 0 && data
      ? [
          'total_leads',
          'leads_na',
          'leads',
          'qualified',
          'lead_quality',
          'acquisitions',
          'invalid',
          'invalid_rate',
          'pending',
          'pending_rate'
        ]
          .filter(
            k =>
              !k.includes('utm_') &&
              !k.includes('name') &&
              !k.includes('facebook') &&
              !k.includes('google') &&
              !k.includes('youtube') &&
              !k.includes('instagram') &&
              !k.includes('seo') &&
              !k.includes('direct')
          )
          .map(key => {
            return {
              field: key,
              filter: 'agNumberColumnFilter',
              floatingFilter: true,
              cellRenderer: cellRenderer,
              width: 150
              // autoWidth: true
              // height: 300
            }
          })
      : []

  headers = [
    {
      headerName: 'Source',
      field: 'name',
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      filterParams: {
        caseSensitive: false,
        defaultOption: 'contains'
      },
      wrapText: true,
      autoHeight: true,
      width: 380,
      pinned: 'left'
    },
    ...headers
  ]

  return (
    <div>
      <Navbar
        fixed='top'
        className='justify-content-between'
        style={{ borderWidth: 0 }}
      >
        <div className='mr-3 ml-3 flex mt-2 justify-between items-center w-screen'>
          <Col sm='0'>
            <Form.Control
              disabled={isFetchingSalesPerformanceStats}
              defaultValue={start}
              onChange={handleStartDate}
              type='date'
              size='md'
            />
          </Col>
          <Col sm='0'>
            <Form.Control
              disabled={isFetchingSalesPerformanceStats}
              defaultValue={start}
              onChange={handleEndDate}
              type='date'
              size='md'
            />
          </Col>
          <Col sm='0'>
            <ShadCNButton
              style={{ width: '90px' }}
              className={
                selectedGrouping.source
                  ? 'bg-slate-800 text-white border-rounded-xl border-slate-800 border-2'
                  : 'bg-slate-200 text-black border border-gray-950'
              }
              value='source'
              onClick={() =>
                selectedGrouping.source === true
                  ? setSelectedGrouping({
                      ...selectedGrouping,
                      source: false
                    })
                  : setSelectedGrouping({
                      ...selectedGrouping,
                      source: true
                    })
              }
            >
              source
            </ShadCNButton>
          </Col>
          <Col sm='0'>
            <ShadCNButton
              style={{ width: '90px' }}
              className={
                selectedGrouping.medium
                  ? 'bg-slate-800 text-white border-rounded-xl border-slate-800 border-2'
                  : 'bg-slate-200 text-black border border-gray-950'
              }
              // onClick={setSelectedGrouping({})}
              value='medium'
              onClick={() =>
                selectedGrouping.medium === true
                  ? setSelectedGrouping({
                      ...selectedGrouping,
                      medium: false
                    })
                  : setSelectedGrouping({
                      ...selectedGrouping,
                      medium: true
                    })
              }
            >
              medium
            </ShadCNButton>
          </Col>
          <Col sm='0'>
            <ShadCNButton
              style={{ width: '90px' }}
              id='toggle-check'
              type='checkbox'
              className={
                selectedGrouping.campaign
                  ? 'bg-slate-800 text-white border-rounded-xl border-slate-800 border-2'
                  : 'bg-slate-200 text-black border border-gray-950'
              }
              checked={selectedGrouping.campaign}
              // onClick={setSelectedGrouping({})}
              value='campaign'
              onClick={() =>
                selectedGrouping.campaign === true
                  ? setSelectedGrouping({
                      ...selectedGrouping,
                      campaign: false
                    })
                  : setSelectedGrouping({
                      ...selectedGrouping,
                      campaign: true
                    })
              }
            >
              campaign
            </ShadCNButton>
          </Col>

          <h4 className='text-3xl text-slate-600 font-bold mx-auto'>
            Kraya Analytics
          </h4>
          <Col sm='0'>
            <ShadCNButton
              style={{ width: '90px' }}
              id='toggle-check'
              type='checkbox'
              className={
                selectedGrouping.id
                  ? 'bg-slate-800 text-white border-rounded-xl border-slate-800 border-2'
                  : 'bg-slate-200 text-black border border-gray-950'
              }
              checked={selectedGrouping.id}
              // onClick={setSelectedGrouping({})}
              value='id'
              onClick={() =>
                selectedGrouping.id === true
                  ? setSelectedGrouping({
                      ...selectedGrouping,
                      id: false
                    })
                  : setSelectedGrouping({
                      ...selectedGrouping,
                      id: true
                    })
              }
            >
              id
            </ShadCNButton>
          </Col>
          <Col sm='0'>
            <ShadCNButton
              style={{ width: '90px' }}
              id='toggle-check'
              type='checkbox'
              className={
                selectedGrouping.term
                  ? 'bg-slate-800 text-white border-rounded-xl border-slate-800 border-2'
                  : 'bg-slate-200 text-black border border-gray-950'
              }
              checked={selectedGrouping.term}
              // onClick={setSelectedGrouping({})}
              value='term'
              onClick={() =>
                selectedGrouping.term === true
                  ? setSelectedGrouping({
                      ...selectedGrouping,
                      term: false
                    })
                  : setSelectedGrouping({
                      ...selectedGrouping,
                      term: true
                    })
              }
            >
              term
            </ShadCNButton>
          </Col>
          <Col sm='0'>
            <ShadCNButton
              style={{ width: '90px' }}
              id='toggle-check'
              type='checkbox'
              className={
                selectedGrouping.content
                  ? 'bg-slate-800 text-white border-rounded-xl border-slate-800 border-2'
                  : 'bg-slate-200 text-black border border-gray-950'
              }
              checked={selectedGrouping.content}
              // onClick={setSelectedGrouping({})}
              value='content'
              onClick={() =>
                selectedGrouping.content === true
                  ? setSelectedGrouping({
                      ...selectedGrouping,
                      content: false
                    })
                  : setSelectedGrouping({
                      ...selectedGrouping,
                      content: true
                    })
              }
            >
              content
            </ShadCNButton>
          </Col>

          <Button className='' variant='secondary' size='md'>
            <CSVLink
              filename='calendar-analytics-table.csv'
              data={data.concat(pinnedRowData)}
            >
              <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
            </CSVLink>
          </Button>
        </div>
      </Navbar>
      <div
        style={{
          marginTop: '75px',
          minWidth: '100%'
        }}
        className='public-youtube-report-primary-table-body-container'
      >
        {!isFetchingSalesPerformanceStats && !isServerError ? (
          <div style={gridStyle} className={'ag-theme-quartz'}>
            <AgGridReact
              ref={gridRef}
              rowData={data}
              columnDefs={headers}
              defaultColDef={defaultColDef}
              rowDragManaged={true}
              rowSelection='multiple'
              rowDragMultiRow={true}
              rowStyle={rowStyle}
              getRowStyle={getRowStyle}
              onFilterChanged={onFilterChanged}
              pinnedBottomRowData={calculateNewColumns([totals])}
            />
          </div>
        ) : isFetchingSalesPerformanceStats ? (
          <MultiStepLoader
            className='bg-opacity-0'
            loadingStates={loadingStates}
            loading={isFetchingSalesPerformanceStats}
            duration={750}
          ></MultiStepLoader>
        ) : (
          <div className='flex justify-center items-center text-center h-full'>
            <TypewriterEffect
              className='text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-400 to-neutral-500 py-8'
              words={'Server returned no data!'.split(' ').map(word => {
                return { text: word }
              })}
            ></TypewriterEffect>
          </div>
        )}
      </div>
      <div className='App'>
        <Widget
          handleNewUserMessage={handleNewUserMessage}
          isWidgetOpened={true}
          title={chatboxTitle}
          subtitle={chatboxSubtitle}
          // handleToggle={handleChatToggle}
          profileAvatar={userImage}
          profileClientAvatar={
            'https://w0.peakpx.com/wallpaper/471/473/HD-wallpaper-daffy-duck-black-funny-duck-daffy-thumbnail.jpg'
          }
          emojis={true}
          resizable={true}
        />
      </div>
    </div>
  )
}

export default KrayaAnalytics
